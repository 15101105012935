import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useMutation } from 'react-query';
import { SharePaymentLink } from '../types';

export const useSharePaymentLink = () => {
  const mutation = useMutation(({ hotelId, paymentLinkId, target }: SharePaymentLink) => {
    return stationApiAxios({
      url: `/rest/v1/hotels/${hotelId}/payment_links/${paymentLinkId}/messages`,
      method: 'POST',
      data: {
        to: target,
      },
    });
  });

  return mutation;
};
