import { useEffect, useState } from 'react';

const useVerifyCode = ({
  onCodeSubmit,
  initialCode = '',
  codeLength = 6,
}: {
  onCodeSubmit: (code: string) => void;
  codeLength?: number;
  initialCode?: string;
}) => {
  const [code, setCode] = useState<string>(initialCode);
  const handleCodeChange = (val: string) => {
    setCode(val);
  };

  useEffect(() => {
    if (code.length === codeLength) {
      onCodeSubmit(code);
    }
  }, [code]);

  return {
    code,
    handleCodeChange,
  };
};

export default useVerifyCode;
