import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'app/styles/colors';
import { FiltersOptions } from '../types';
import Filter from './filters/components/Filter';
import { buildFiltersQuery, filters, FILTERS_KEYS, getFilterLabel } from './filters/lib/filters';

interface Props {
  handleSearchQueryChange: (value: string) => void;
}

function ReservationsFilters({ handleSearchQueryChange }: Props) {
  const [filterCriteria, setFiltersCriteria] = useState<FiltersOptions | undefined>(undefined);
  const ref = useRef();
  const [width, setwidth] = useState(0);

  useEffect(
    /* istanbul ignore next */ () => {
      const observer = new ResizeObserver((entries) => {
        setwidth(entries[0].contentRect.width);
      });

      observer.observe(ref.current!);
      return () => ref.current && observer.unobserve(ref.current);
    },
    [],
  );

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        ...(width < 700 && {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
        }),
      }}
      data-testid="reservations-filters"
    >
      <Typography
        fontSize="12px"
        fontWeight={450}
        letterSpacing="0.48px"
        lineHeight="100%"
        textTransform="uppercase"
        color={colors.grey[700]}
        sx={{ mr: '20px' }}
      >
        Filter By:
      </Typography>
      <Box sx={{ gap: '4px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {filters.map(({ key, label, component }) => {
          const { formatedLabel, hasFormattedlabel } = getFilterLabel(label, key, filterCriteria);

          return (
            <Filter
              key={key}
              btnProps={{
                label: formatedLabel,
                sx: { width: 'fit-content', minWidth: '150px' },
              }}
              hasFormattedlabel={hasFormattedlabel}
              component={component}
              componentProps={{
                label,
                filterCriteria,
                filterKey: key,
                onApply: (args: FiltersOptions) => {
                  setFiltersCriteria({ ...filterCriteria, ...args });
                  handleSearchQueryChange(buildFiltersQuery({ ...filterCriteria, ...args }));
                },
              }}
              paperSX={{
                width: FILTERS_KEYS.PAYMENT_STATUS === key ? '223px' : '378px',
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default ReservationsFilters;
