import { Stack, Typography } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import colors from 'app/styles/colors';
import { forwardRef, Fragment, Ref } from 'react';
import CalendarIcon2 from '../icons/CalendarIcon2';

export interface InputLikeButtonProps extends ButtonBaseProps {
  label: string;
  formattedDate: string;
  required?: boolean;
  backgroundVariant?: 'default' | 'white';
  error?: string;
}

function InputLikeDayPickerButton(
  {
    label,
    formattedDate,
    required,
    backgroundVariant = 'default',
    sx,
    error,
    disabled,
    ...rest
  }: InputLikeButtonProps,
  ref: Ref<any>,
) {
  let backgroundColor;
  if (error) {
    backgroundColor = colors.critical[200] as string;
  } else {
    backgroundColor = backgroundVariant === 'white' ? 'white' : 'grey.200';
  }

  return (
    <Fragment>
      <ButtonBase
        ref={ref}
        sx={{
          height: 48,
          width: 196,
          px: '16px',
          py: '8px',
          borderRadius: '4px',
          backgroundColor,
          border: 1,
          borderColor: error ? colors.critical[400] : 'transparent',
          color: disabled ? 'grey.600' : 'grey.900',
          fontSize: 14,
          ':focus-within, :focus-visible': {
            borderColor: 'grey.500',
            backgroundColor: backgroundVariant === 'white' ? 'white' : 'grey.200',
          },
          ':hover': {
            backgroundColor: backgroundVariant === 'white' ? 'grey.100' : 'grey.300',
          },
          ...sx,
        }}
        disabled={disabled}
        {...rest}
        {...(error && {
          'aria-invalid': true,
          'aria-describedby': `error-message-${label}`,
        })}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack alignItems="start">
            <Typography
              fontSize={formattedDate ? '9px' : '14px'}
              fontWeight={500}
              color={disabled ? colors.grey[600] : colors.grey[700]}
              letterSpacing={1}
              textTransform="uppercase"
            >
              {required ? `${label} *` : label}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={300}
              color={disabled ? colors.grey[600] : colors.grey[700]}
              letterSpacing="0.5px"
            >
              {formattedDate}
            </Typography>
          </Stack>
          <CalendarIcon2 height="20px" width="20px" aria-label="Select Date" />
        </Stack>
      </ButtonBase>
      {error && (
        <Typography
          id={`error-message-${label}`}
          style={{ color: colors.critical[600], fontSize: '12px', paddingTop: '5px' }}
        >
          {error}
        </Typography>
      )}
    </Fragment>
  );
}

export default forwardRef(InputLikeDayPickerButton);
