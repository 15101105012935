import colors from 'app/styles/colors';

export const inputDatePickerCss = `
  .rdp {
    --rdp-cell-size: 32px !important;
  }

  .rdp-day {
    width: 32px !important;
    height: 32px !important;
    background-color: #dee2e6 !important;
    font-size: 12px !important;
    color: ${colors.grey[800]} !important;
  }

  .rdp-day:hover, .rdp-day:focus, .rdp-day:focus-visible, .rdp-day:focus-within {
    width: 32px !important;
    height: 32px !important;
  }

  .rdp-day_selected {
    color: #343a40 !important;
    background-color: #fff !important;
  }

  .rdp-head_cell {
    font-size: 12px !important;
  }

  .sb-caption-month {
    font-weight: 450;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #495057;
  }
`;
