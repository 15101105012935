// https://www.figma.com/file/hQ81k8hHsCtk08OJYqGZOt/Design-System?node-id=3153%3A14372
const baseColors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  googlePay: '#1A73E8',
  creditCard: '#FF718B',
  grey: {
    100: '#F8F9FA',
    200: '#F0F2F4',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    550: '#717579',
    600: '#6C757D',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
  },
  success: {
    200: '#CEECDA',
    400: '#8DD4A8',
    600: '#5CC183',
    800: '#1A6537',
  },
  critical: {
    200: '#FCEAEA',
    400: '#EE9597',
    600: '#DD2C2F',
    800: '#B00020',
  },
  warning: {
    200: '#FFEFC4',
    400: '#FFDA75',
    600: '#FFCA3A',
  },
  affirm: {
    200: '#F4F4FE',
    400: '#A4A4F9',
    600: '#4A4AF4',
  },
};

export const colors = {
  ...baseColors,
  primary: baseColors.grey,
  secondary: baseColors.grey,
  info: baseColors.grey,
  error: baseColors.critical,
  warning: baseColors.warning,
  success: baseColors.success,
};

export default colors;
