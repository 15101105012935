import { formatDateTimeToParts } from 'app/lib/dates';
import { DateTimeStringObject } from 'app/lib/types';
import { flow } from 'lodash';
import { getCustomerEmail, getCustomerPhone } from '../lib/guests';
import { getReservationIdText } from '../lib/reservationIdText';
import { Reservation } from '../types';

const locale = 'en-US';
const reservationArriveDepartFormatOptions: DateTimeStringObject = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as DateTimeStringObject;

const reservationArriveDepartFormatter = (dateString: string) =>
  formatDateTimeToParts(new Date(dateString), locale, reservationArriveDepartFormatOptions);

const formatReservationArriveDepartDateString = (date: DateTimeStringObject) =>
  `${date.month} ${date.day}, ${date.year}`;

const formatReservationArriveDepartDate = flow(
  reservationArriveDepartFormatter,
  formatReservationArriveDepartDateString,
);

export type SubheaderUi = {
  arriveDate: string;
  departDate: string;
  customerPhone: string;
  customerEmail: string;
  reservationId: string;
};

export const buildSubheaderUi = (apiData: Reservation): SubheaderUi => {
  const customerEmail = getCustomerEmail(apiData);

  const subheaderUi = {
    arriveDate: formatReservationArriveDepartDate(apiData.arriveAt),
    departDate: formatReservationArriveDepartDate(apiData.departAt),
    customerPhone: getCustomerPhone(apiData),
    customerEmail,
    reservationId: getReservationIdText(apiData),
  };

  return subheaderUi;
};
