import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import StripeConnectPrompt from 'app/features/stripe-connect/components/StripeConnectPrompt';
import { useAfterLoginContext } from '../context/useAfterLoginContext';
import { StationAdminAfterLoginSetupSteps } from '../types';
import StationAdminAfterLoginSteps from './StationAdminAfterLoginSteps';
import UploadLogo from './UploadLogo';

function StationAdminAfterLogin() {
  const {
    afterLoginPrompt,
    closeAfterLoginModal,
    shouldShowUploadLogoPrompt,
    shouldShowStripePrompt,
    currentStep,
  } = useAfterLoginContext();

  const handleModalClose = () => {
    closeAfterLoginModal();
  };

  return afterLoginPrompt ? (
    <SelfbookDialog
      data-testid="station-admin-after-login-modal"
      open={afterLoginPrompt}
      onClose={handleModalClose}
    >
      <SelfbookDialog.Title data-testid="station-admin-after-login-modal-title" onClose={handleModalClose}>
        Complete Setup
      </SelfbookDialog.Title>
      <SelfbookDialog.Content
        data-testid="station-admin-after-login-modal-content"
        sx={{ width: '460px', p: 3, pb: 4 }}
      >
        {shouldShowUploadLogoPrompt && shouldShowStripePrompt && (
          <StationAdminAfterLoginSteps currentStep={currentStep} />
        )}
        {currentStep === StationAdminAfterLoginSetupSteps.UPLOAD_LOGO && <UploadLogo />}
        {currentStep === StationAdminAfterLoginSetupSteps.STRIPE_SETUP && <StripeConnectPrompt />}
      </SelfbookDialog.Content>
    </SelfbookDialog>
  ) : null;
}

export default StationAdminAfterLogin;
