import { SxProps } from '@mui/material';
import colors from 'app/styles/colors';

export const selfbookRadioContainerStyle: SxProps = {
  border: '1px solid',
  borderColor: colors.grey[400],
  borderRadius: '4px',
  px: 2,
  py: 1,
  ':focus, :focus-within, :hover': {
    borderColor: 'grey.600',
  },
};

export const selfbookRadioLabelStyle: SxProps = {
  width: '100%',
  color: colors.grey[700],
  letterSpacing: '0.02em',
  fontSize: 16,
};

export const selfbookRadioStyle: SxProps = {
  color: colors.primary[700],
  '&.Mui-checked': {
    color: colors.primary[700],
  },
  '&.Mui-disabled': {
    color: colors.grey[400],
  },
};
