import { SVGProps } from 'react';
import SrOnlyText from '../sr-only-text/SrOnlyText';

function VisaIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="visa-icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H32C33.1046 0 34 0.895431 34 2V24C34 25.1046 33.1046 26 32 26H2C0.89543 26 0 25.1046 0 24V2ZM14.1073 9.14811L12.819 16.9767H14.8505L16.1387 9.14811H14.1073ZM24.8086 9.79224C25.0068 9.29675 25.3536 9.14811 25.8491 9.14811H27.4346L29.0697 17.0263H27.1869C27.1869 17.0263 26.9887 16.1344 26.9392 15.8371H24.3626C24.2635 16.0353 23.9167 17.0263 23.9167 17.0263H21.7861L24.8086 9.79224ZM25.7004 12.0219C25.7004 12.0219 25.0563 13.7561 24.9077 14.202V14.2516H26.5923C26.4932 13.8552 26.0968 11.9724 26.0968 11.9724L25.9482 11.2787L25.7004 12.0219ZM20.2027 12.3197C19.509 11.9729 19.0631 11.7252 19.0631 11.3288C19.0631 10.9819 19.4099 10.6351 20.2027 10.6351C20.8468 10.6351 21.3423 10.7837 21.7387 10.9324L21.9369 11.0315L22.2342 9.34684C21.8378 9.19819 21.1936 9 20.4009 9C18.4189 9 16.982 10.0901 16.982 11.5765C16.982 12.7161 17.973 13.3602 18.7658 13.7071C19.5586 14.1035 19.8063 14.3512 19.8063 14.6981C19.8063 15.2431 19.1622 15.4413 18.5676 15.4413C17.7748 15.4413 17.3289 15.3422 16.6352 15.0449L16.3875 14.8962L16.0902 16.6304C16.5857 16.8286 17.428 17.0268 18.3694 17.0268C20.5 17.0268 21.8873 15.9863 21.8873 14.3512C21.9369 13.5584 21.3918 12.8648 20.2027 12.3197ZM9.15302 14.4993L11.135 9.14811H13.216L10.0449 16.9767H7.91431L6.27922 10.8328C6.13058 10.3868 5.83329 9.99043 5.38735 9.79224C4.99097 9.59404 4.54503 9.4454 4.04955 9.29675C4 9.29675 4 9.24721 4 9.19766C4.02477 9.19766 4.03716 9.18527 4.04955 9.17288C4.06194 9.1605 4.07432 9.14811 4.0991 9.14811H7.31973C7.76567 9.14811 8.16206 9.29675 8.26115 9.79224L8.95483 13.4093L9.15302 14.4993Z"
        fill="#212529"
      />

      <SrOnlyText>Visa Card</SrOnlyText>
    </svg>
  );
}
export default VisaIcon;
