import { SVGProps } from 'react';

function UserFollowIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <g clipPath="url(#clip0_3199_107406)">
        <path d="M20 8.75H17.5V6.25H16.25V8.75H13.75V10H16.25V12.5H17.5V10H20V8.75Z" fill="#495057"/>
        <path d="M7.5 2.5C8.11807 2.5 8.72225 2.68328 9.23616 3.02666C9.75006 3.37004 10.1506 3.8581 10.3871 4.42911C10.6236 5.00013 10.6855 5.62847 10.565 6.23466C10.4444 6.84085 10.1467 7.39767 9.70971 7.83471C9.27267 8.27175 8.71585 8.56938 8.10966 8.68995C7.50347 8.81053 6.87513 8.74865 6.30411 8.51212C5.7331 8.2756 5.24504 7.87506 4.90166 7.36116C4.55828 6.84725 4.375 6.24307 4.375 5.625C4.375 4.7962 4.70424 4.00134 5.29029 3.41529C5.87634 2.82924 6.6712 2.5 7.5 2.5ZM7.5 1.25C6.63471 1.25 5.78885 1.50659 5.06938 1.98732C4.34992 2.46805 3.78916 3.15133 3.45803 3.95076C3.12689 4.75019 3.04025 5.62985 3.20906 6.47852C3.37787 7.32719 3.79455 8.10674 4.40641 8.71859C5.01826 9.33045 5.79781 9.74713 6.64648 9.91594C7.49515 10.0847 8.37481 9.99811 9.17424 9.66697C9.97367 9.33584 10.6569 8.77508 11.1377 8.05562C11.6184 7.33615 11.875 6.49029 11.875 5.625C11.875 4.46468 11.4141 3.35188 10.5936 2.53141C9.77312 1.71094 8.66032 1.25 7.5 1.25Z" fill="#495057"/>
        <path d="M13.75 18.75H12.5V15.625C12.5 14.7962 12.1708 14.0013 11.5847 13.4153C10.9987 12.8292 10.2038 12.5 9.375 12.5H5.625C4.7962 12.5 4.00134 12.8292 3.41529 13.4153C2.82924 14.0013 2.5 14.7962 2.5 15.625V18.75H1.25V15.625C1.25 14.4647 1.71094 13.3519 2.53141 12.5314C3.35188 11.7109 4.46468 11.25 5.625 11.25H9.375C10.5353 11.25 11.6481 11.7109 12.4686 12.5314C13.2891 13.3519 13.75 14.4647 13.75 15.625V18.75Z" fill="#495057"/>
      </g>
      <defs>
        <clipPath id="clip0_3199_107406">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserFollowIcon