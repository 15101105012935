import { Grid, Stack, Typography } from '@mui/material';

interface Props {
  message: string;
}

function FeatureRestrictedPage({ message }: Props) {
  return (
    <Grid
      container
      sx={{ justifyContent: 'center', alignItems: 'center', height: '90vh' }}
      data-testid="feature-restricted-screen"
    >
      <Grid item sx={{ mt: -40 }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Typography fontSize={20} fontWeight="light">
            This feature is restricted as you do not have
          </Typography>
          <Stack direction="row" spacing={0.5}>
            <Typography fontSize={20} fontWeight="semibold">
              {message}
            </Typography>
            <Typography fontSize={20} fontWeight="light">
              enabled for your hotel.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default FeatureRestrictedPage;
