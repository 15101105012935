import colors from 'app/styles/colors';
import { borderRadius } from 'app/styles/layout';

const useCodeInputStyles = ({
  hasError = false,
  isDisabled = false,
}: {
  hasError: boolean;
  isDisabled: boolean;
}) => {
  return {
    '& .react-code-input': {
      display: 'flex',
    },

    '& .react-code-input input': {
      height: 48,
      width: 58,
      mr: '5px',
      px: 0,
      textAlign: 'center',
      borderRadius: borderRadius.base,
      backgroundColor: 'grey.200',
      fontWeight: 300,
      border: 1,
      borderColor: 'transparent',
      fontSize: 16,
      '::placeholder': {
        color: 'grey.500',
      },
      ':focus-within, :focus-visible': {
        borderColor: 'grey.500',
        outline: 'none',
      },
      ':hover': {
        backgroundColor: 'grey.300',
      },
      ...(hasError && {
        borderColor: 'error.main',
        backgroundColor: colors.error[200],
        ':hover': {
          backgroundColor: colors.error[200],
        },
        ':focus-within, :focus-visible': {
          backgroundColor: colors.error[200],
        },
      }),
      ...(isDisabled && {
        cursor: 'not-allowed',
        backgroundColor: 'grey.200',
        ':hover': {
          backgroundColor: 'grey.200',
        },
        ':focus-within, :focus-visible': {
          backgroundColor: 'grey.200',
        },
      }),
    },
  };
};

export default useCodeInputStyles;
