import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthenticationPaths,
  TopLevelPaths,
  IndexPath,
  NewUserPaths,
  MainPaths,
} from 'app/routing/route-paths';
import { clearAllLocalStorageUserData, getJwtIsExpired } from 'app/features/authentication/lib/lib';
import { useEffect } from 'react';
import { useUser } from 'app/features/authentication/context/useUser';
import { CircularProgress } from '@mui/material';
import usePermissions from 'app/features/permissions/usePermissions';

function InitialScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearCurrentUser } = useUser();
  const { isFetchingPermissions, userIsSelfbookAdmin, currentUserHotelRole } = usePermissions();

  useEffect(() => {
    // If this is a direct landing on set new password, don't redirect
    if (
      location.pathname ===
      `/${IndexPath}/${TopLevelPaths.Authentication}/${AuthenticationPaths.ResetPasswordNew}`
    ) {
      clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
      return;
    }

    // If this is a direct landing on create new password, don't redirect
    if (
      location.pathname === `/${IndexPath}/${TopLevelPaths.NewUser}/${NewUserPaths.CreateInitialPassword}`
    ) {
      clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
      return;
    }

    const jwtExpired = getJwtIsExpired();
    if (jwtExpired) {
      clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
      navigate(`${TopLevelPaths.Authentication}/${AuthenticationPaths.Login}`);
    }
  }, []);

  useEffect(() => {
    if (isFetchingPermissions || currentUserHotelRole === 'Unknown Role') return;
    if (location.pathname === `/${IndexPath}`) {
      if (userIsSelfbookAdmin) {
        navigate(`${TopLevelPaths.SelfbookAdmin}`);
      } else {
        navigate(`${TopLevelPaths.Main}/${MainPaths.Reservations}`);
      }
    }
  }, [location.pathname, userIsSelfbookAdmin, isFetchingPermissions]);

  return isFetchingPermissions ? (
    <CircularProgress size={80} sx={{ position: 'absolute', top: '25%', left: '47%' }} />
  ) : (
    <Outlet />
  );
}

export default InitialScreen;
