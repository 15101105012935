import { useUser } from 'app/features/authentication/context/useUser';
import { useHotelQuery } from 'app/features/hotel/api/useHotel';
import { HotelStatus } from 'app/features/hotel/types';
import usePermissions from 'app/features/permissions/usePermissions';
import { UserPermissionRole } from 'app/features/user/lib/userRoles';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getHasSeenStripePromptInSession, setHasSeenStripePromptInSession } from '../../stripe-connect/lib';
import { StationAdminAfterLoginSetupSteps } from '../types';

type State = {
  shouldShowUploadLogoPrompt: boolean;
  shouldShowStripePrompt: boolean;
  shouldShowHeaderButton: boolean;
  handleCompleteAccountClick: () => void;
  afterLoginPrompt: boolean;
  openAfterLoginModal: () => void;
  closeAfterLoginModal: () => void;
  currentStep: StationAdminAfterLoginSetupSteps;
  setCurrentStep: (step: StationAdminAfterLoginSetupSteps) => void;
};

const AfterLoginConnectContext = createContext<State | undefined>(undefined);

function AfterLoginContextProvider({ children }: { children: React.ReactNode }) {
  const [currentStep, setCurrentStep] = useState<StationAdminAfterLoginSetupSteps>(
    StationAdminAfterLoginSetupSteps.UPLOAD_LOGO,
  );
  const { currentHotelId, currentUserHotelRole } = useUser();
  const { userIsMasqueradingAdmin, showStripeSetup } = usePermissions();
  const { data: hotelData } = useHotelQuery(currentHotelId!);
  const [afterLoginPrompt, setAfterLoginPrompt] = useState<boolean>(false);
  const hasSeenStripePromptInSession = getHasSeenStripePromptInSession();

  const getIsTrueHotelAdmin = () => {
    return currentUserHotelRole === UserPermissionRole.HOTEL_ADMIN && !userIsMasqueradingAdmin;
  };

  const getHotelStripeConnectLink = () => {
    return hotelData?.stripe?.connected_account_link;
  };

  const getHotelHasStartedSetup = () => {
    return hotelData?.status === HotelStatus.STRIPE_SETUP_STARTED && !!getHotelStripeConnectLink();
  };

  const getShouldShowStripeConnectPrompt = () => {
    if (!showStripeSetup) return false;
    return getIsTrueHotelAdmin() && getHotelHasStartedSetup() && !hasSeenStripePromptInSession;
  };

  const getShouldShowStripeConnectHeaderButton = () => {
    if (!showStripeSetup) return false;
    return getIsTrueHotelAdmin() && getHotelHasStartedSetup();
  };

  const getShouldShowUploadLogoPrompt = () => {
    return getIsTrueHotelAdmin() && !hotelData?.logo_url && !hasSeenStripePromptInSession;
  };

  const shouldShowStripePrompt = getShouldShowStripeConnectPrompt();
  const shouldShowHeaderButton = getShouldShowStripeConnectHeaderButton();
  const shouldShowUploadLogoPrompt = getShouldShowUploadLogoPrompt();

  const openAfterLoginModal = () => {
    if (shouldShowUploadLogoPrompt) {
      setCurrentStep(StationAdminAfterLoginSetupSteps.UPLOAD_LOGO);
    }

    if (!shouldShowUploadLogoPrompt && shouldShowStripePrompt) {
      setCurrentStep(StationAdminAfterLoginSetupSteps.STRIPE_SETUP);
    }

    if (shouldShowUploadLogoPrompt || shouldShowStripePrompt) {
      setAfterLoginPrompt(true);
    }
  };

  const closeAfterLoginModal = () => {
    setHasSeenStripePromptInSession('true');
    setAfterLoginPrompt(false);
  };

  useEffect(() => {
    if (!afterLoginPrompt && !hasSeenStripePromptInSession) {
      setTimeout(openAfterLoginModal, 2000);
    }
  }, [hasSeenStripePromptInSession, currentUserHotelRole, hotelData, userIsMasqueradingAdmin]);

  const handleCompleteAccountClick = () => {
    window.location.assign(getHotelStripeConnectLink()!);
  };

  const value = useMemo(
    () => ({
      shouldShowUploadLogoPrompt,
      shouldShowStripePrompt,
      shouldShowHeaderButton,
      handleCompleteAccountClick,
      closeAfterLoginModal,
      openAfterLoginModal,
      afterLoginPrompt,
      currentStep,
      setCurrentStep,
    }),
    [
      shouldShowStripePrompt,
      shouldShowHeaderButton,
      shouldShowUploadLogoPrompt,
      handleCompleteAccountClick,
      closeAfterLoginModal,
      openAfterLoginModal,
      afterLoginPrompt,
      currentStep,
      setCurrentStep,
    ],
  );

  return <AfterLoginConnectContext.Provider value={value}>{children}</AfterLoginConnectContext.Provider>;
}

const useAfterLoginContext = () => {
  const context = useContext(AfterLoginConnectContext);
  if (context === undefined) {
    throw new Error('useAfterLoginContext must be used within a AfterLoginConnectContext');
  }
  return context;
};

export { useAfterLoginContext, AfterLoginContextProvider };
