import { useState } from 'react';

export interface FormValues {
  [field: string]: any;
}

/* From Formik docs */
export declare type FormErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends object
      ? FormErrors<Values[K][number]>[] | string | string[]
      : string | string[]
    : Values[K] extends object
    ? FormErrors<Values[K]>
    : string;
};

export interface FormConfig<Values> {
  initialValues: Values;
  formRef: React.RefObject<HTMLFormElement>;
  errorAttribute?: string;
  initialErrors?: FormErrors<Values>;
  validations?: {
    [K in keyof Values]?: (value: Values[K], formValues: Values) => string | boolean | undefined;
  };
}

function useForm<Values extends FormValues = FormValues>({
  formRef,
  initialValues,
  initialErrors,
  validations,
  errorAttribute = 'field-has-error',
}: FormConfig<Values>) {
  const emptyErrors: FormErrors<unknown> = {};

  const [values, setValues] = useState<Values>(initialValues);
  const [errors, setErrors] = useState<FormErrors<Values>>(initialErrors || emptyErrors);

  function updateFieldError(fieldName: string, message: string) {
    setErrors((prev) => ({
      ...prev,
      [fieldName]: message,
    }));
  }

  function updateFieldValue(
    fieldName: string,
    value: any,
    options: { clearFieldError?: boolean } = { clearFieldError: true },
  ) {
    setValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
    if (options.clearFieldError) {
      updateFieldError(fieldName, '');
    }
  }

  function setFieldErrorAttribute(fieldName: string, value: boolean) {
    const elInForm = formRef.current?.querySelector(`[name=${String(fieldName)}]`);
    elInForm?.setAttribute(errorAttribute, String(value));
  }

  function removeErrorAttributes() {
    formRef.current?.querySelectorAll(`[${errorAttribute}="true"]`).forEach((field) => {
      field.removeAttribute(errorAttribute);
    });
  }

  function validateField(
    fieldName: string,
    options: { addErrorAttribute?: boolean } = { addErrorAttribute: false },
  ): boolean {
    if (!validations) return true;

    const validation = validations[fieldName];
    if (!validation) return true;

    const result = validation(values[fieldName], values);
    if (typeof result === 'string') {
      updateFieldError(fieldName, result);
      if (options.addErrorAttribute) {
        setFieldErrorAttribute(fieldName, true);
      }
      return false;
    }

    updateFieldError(fieldName, '');
    return true;
  }

  function focusFirstFieldWithError() {
    const invalidFields = formRef.current?.querySelectorAll(`[${errorAttribute}="true"]`) || [];
    if (invalidFields.length > 0) {
      // @ts-ignore
      invalidFields[0].focus();
    }
  }

  function resetErrors() {
    removeErrorAttributes();
    setErrors({});
  }

  function getIsFormValid() {
    let formValid = true;
    Object.keys(values).forEach((k) => {
      const isValid = validateField(k, { addErrorAttribute: true });
      if (!isValid) {
        formValid = false;
      }
    });

    return formValid;
  }

  return {
    values,
    errors,
    updateFieldValue,
    validateField,
    setFieldErrorAttribute,
    removeErrorAttributes,
    focusFirstFieldWithError,
    resetErrors,
    getIsFormValid,
    setValues,
    setErrors,
    updateFieldError,
  };
}

export default useForm;
