import { SVGProps } from 'react';

function ContentIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="content-icon"
      {...props}
    >
      <path d="M13.375 14H10.875V16.5H13.375V14Z" fill="currentColor" />
      <path d="M17.75 14H15.25V16.5H17.75V14Z" fill="currentColor" />
      <path d="M13.375 9.625H10.875V12.125H13.375V9.625Z" fill="currentColor" />
      <path d="M17.75 9.625H15.25V12.125H17.75V9.625Z" fill="currentColor" />
      <path
        d="M9.625 14H1.5V5.25H16.5V8.375H17.75V5.25C17.7496 4.9186 17.6178 4.60087 17.3835 4.36654C17.1491 4.1322 16.8314 4.00038 16.5 4H12.75V1.5C12.7496 1.1686 12.6178 0.850875 12.3835 0.616537C12.1491 0.382199 11.8314 0.25038 11.5 0.25H6.5C6.16858 0.250331 5.85083 0.382133 5.61648 0.616482C5.38213 0.850831 5.25033 1.16858 5.25 1.5V4H1.5C1.16858 4.00033 0.850831 4.13213 0.616482 4.36648C0.382133 4.60083 0.250331 4.91858 0.25 5.25V14C0.25038 14.3314 0.382199 14.6491 0.616537 14.8835C0.850875 15.1178 1.1686 15.2496 1.5 15.25H9.625V14ZM6.5 1.5H11.5V4H6.5V1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ContentIcon;
