import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SelfbookTextInput from 'app/components/selfbook-text-input/SelfbookTextInput';
import useForm from 'app/hooks/useForm';
import { AuthenticationPaths } from 'app/routing/route-paths';
import { stringHasValue } from 'app/lib/validation-functions';
import { useRef } from 'react';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import ReactRouterLinkButton from 'app/components/react-router-link-button/ReactRouterLinkButton';
import { getErrorMessage } from 'app/api/lib/error-handling';
import colors from 'app/styles/colors';
import { ArrowBack } from '@mui/icons-material';
import '../../fonts/font.css';

interface Props {
  onSubmit: (email: string) => void;
  isLoading?: boolean;
  submitError?: any;
}

function ResetPasswordRequestSubmit({ onSubmit, isLoading = false, submitError }: Props) {
  const formRef = useRef<HTMLFormElement>(null);

  enum FormFields {
    email = 'email',
  }

  const { values, errors, resetErrors, updateFieldValue, getIsFormValid, focusFirstFieldWithError } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.email]: '',
      },
      validations: {
        [FormFields.email]: (val) => {
          if (!stringHasValue(val)) return 'Email is required.';
          return true;
        },
      },
    },
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    onSubmit(values.email);
  };

  return (
    <Stack sx={{ maxWidth: '400px' }} data-testid="reset-password-request">
      <Stack>
        <Typography component="h1" variant="h2" fontFamily="PP Neue Montreal" fontSize="34px">
          Reset password
        </Typography>
        <Typography
          component="p"
          fontSize="18px"
          fontWeight="300"
          color={colors.grey[700]}
          sx={{ mt: 2 }}
          lineHeight="1.3"
        >
          Enter the email associated with your account and we will send you instructions to reset your
          password
        </Typography>
      </Stack>
      <Box component="form" sx={{ width: '100%', mt: 2 }} onSubmit={handleSubmit} noValidate ref={formRef}>
        <SelfbookTextInput
          id="reset-password-request-email"
          data-testid="reset-password-request-email"
          required
          label="Email"
          type="email"
          value={values.email}
          name={FormFields.email}
          onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
        />

        {submitError && (
          <Alert
            severity="error"
            data-testid="reset-password-request-error"
            id="reset-password-request-error"
          >
            {getErrorMessage(submitError)}
          </Alert>
        )}

        <LoadingButton loading={isLoading} type="submit" fullWidth sx={{ mt: 2 }}>
          Send Instructions
        </LoadingButton>
      </Box>
      <Divider sx={{ mt: 6, mb: 4 }} />
      <Stack direction="row">
        <ReactRouterLinkButton
          variant="textMuted"
          to={`../${AuthenticationPaths.Login}`}
          startIcon={<ArrowBack />}
        >
          Back to Sign In
        </ReactRouterLinkButton>
      </Stack>
    </Stack>
  );
}

export default ResetPasswordRequestSubmit;
