import { SVGProps } from 'react';

function ArrowDownIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="arrow-down-icon"
      {...props}
    >
      <path
        d="M8 9.50019L0.5 2.00019L1.55 0.950195L8 7.4002L14.45 0.950195L15.5 2.00019L8 9.50019Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowDownIcon;
