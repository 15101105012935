import { SVGProps } from 'react';

function ReceiptIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
            data-testid="receipt-icon"
            {...props}>
            <path d="M14.25 11H12.75V12.5H14.25V11Z" fill="#495057" />
            <path d="M9.75 11H3.75V12.5H9.75V11Z" fill="#495057" />
            <path d="M14.25 8H12.75V9.5H14.25V8Z" fill="#495057" />
            <path d="M9.75 8H3.75V9.5H9.75V8Z" fill="#495057" />
            <path d="M14.25 5H3.75V6.5H14.25V5Z" fill="#495057" />
            <path d="M15.75 0.5H2.25C1.8523 0.500397 1.471 0.65856 1.18978 0.939778C0.90856 1.221 0.750397 1.6023 0.75 2V20.75C0.75 20.9489 0.829018 21.1397 0.96967 21.2803C1.11032 21.421 1.30109 21.5 1.5 21.5H2.25C2.36645 21.5001 2.48131 21.473 2.58546 21.4209C2.68962 21.3688 2.78019 21.2932 2.85 21.2L4.5 19.0002L6.15 21.2C6.22284 21.2887 6.31443 21.3601 6.41817 21.4091C6.52192 21.4582 6.63525 21.4836 6.75 21.4836C6.86475 21.4836 6.97808 21.4582 7.08183 21.4091C7.18557 21.3601 7.27716 21.2887 7.35 21.2L9 19.0002L10.65 21.2C10.7228 21.2887 10.8144 21.3601 10.9182 21.4091C11.0219 21.4582 11.1352 21.4836 11.25 21.4836C11.3648 21.4836 11.4781 21.4582 11.5818 21.4091C11.6856 21.3601 11.7772 21.2887 11.85 21.2L13.5 19.0002L15.15 21.2C15.2198 21.2932 15.3104 21.3688 15.4146 21.4209C15.5187 21.4729 15.6336 21.5 15.75 21.5H16.5C16.6989 21.5 16.8897 21.421 17.0303 21.2803C17.171 21.1397 17.25 20.9489 17.25 20.75V2C17.2495 1.60232 17.0914 1.22105 16.8102 0.939844C16.529 0.658638 16.1477 0.500457 15.75 0.5ZM15.75 19.4998L14.1 17.3C14.0272 17.2113 13.9356 17.1399 13.8318 17.0909C13.7281 17.0418 13.6148 17.0164 13.5 17.0164C13.3852 17.0164 13.2719 17.0418 13.1682 17.0909C13.0644 17.1399 12.9728 17.2113 12.9 17.3L11.25 19.4998L9.6 17.3C9.52716 17.2113 9.43557 17.1399 9.33183 17.0909C9.22808 17.0418 9.11475 17.0164 9 17.0164C8.88525 17.0164 8.77192 17.0418 8.66817 17.0909C8.56443 17.1399 8.47284 17.2113 8.4 17.3L6.75 19.4998L5.1 17.3C5.02716 17.2113 4.93557 17.1399 4.83183 17.0909C4.72808 17.0418 4.61475 17.0164 4.5 17.0164C4.38525 17.0164 4.27192 17.0418 4.16817 17.0909C4.06443 17.1399 3.97284 17.2113 3.9 17.3L2.25 19.4998V2H15.75V19.4998Z" fill="#495057" />
        </svg>
    );
}

export default ReceiptIcon;
