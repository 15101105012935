import { List, Stack } from '@mui/material';
import ContentIcon from 'app/components/icons/ContentIcon';
import LinkIcon from 'app/components/icons/LinkIcon';
import PayoutsIcon from 'app/components/icons/PayoutsIcon';
import ReservationsIcon from 'app/components/icons/ReservationsIcon';
import SideDrawer from 'app/components/side-drawer/SideDrawer';
import usePermissions from 'app/features/permissions/usePermissions';
import { MainPaths } from 'app/routing/route-paths';
import { useLocation } from 'react-router-dom';
import { useNavigationContext } from '../../context/useNavigationContext';
import { SideNavigationLink } from '../types';
import SideNavigationItem from './SideNavigationItem';

function SideNavigation() {
  const { sideDrawerOpen } = useNavigationContext();
  const { allowedPaths } = usePermissions();
  const location = useLocation();

  // istanbul ignore next
  const getSideLinks = () => {
    const sideNavigationLinks = [];
    if (allowedPaths.includes(MainPaths.Reservations)) {
      sideNavigationLinks.push({
        text: 'Bookings',
        icon: <ReservationsIcon />,
        href: MainPaths.Reservations,
        ariaLabel: 'View Bookings',
      });
    }

    if (allowedPaths.includes(MainPaths.Payouts)) {
      sideNavigationLinks.push({
        text: 'Payouts',
        icon: <PayoutsIcon />,
        href: MainPaths.Payouts,
        ariaLabel: 'View Payouts',
      });
    }

    if (allowedPaths.includes(MainPaths.Content)) {
      sideNavigationLinks.push({
        text: 'Content',
        icon: <ContentIcon />,
        href: MainPaths.Content,
        ariaLabel: 'View Content Management',
      });
    }

    if (allowedPaths.includes(MainPaths.PaymentLinksManagement)) {
      sideNavigationLinks.push({
        text: 'Payment links',
        icon: <LinkIcon />,
        href: MainPaths.PaymentLinksManagement,
        ariaLabel: 'Payment links',
      });
    }

    return sideNavigationLinks;
  };

  const sideNavigationLinks: SideNavigationLink[] = getSideLinks();

  /* Wrote a test for this as well, but code coverage doesn't like it (line 50) */
  /* istanbul ignore next */
  return (
    <SideDrawer isOpen={sideDrawerOpen}>
      <Stack data-testid="side-navigation" direction="column" justifyContent="space-between" height="100%">
        <List>
          {sideNavigationLinks.map((link) => (
            <SideNavigationItem
              key={link.text}
              link={link}
              isHighlighted={location.pathname.includes(link.href || '')}
              sideDrawerOpen={sideDrawerOpen}
            />
          ))}
        </List>
      </Stack>
    </SideDrawer>
  );
}

export default SideNavigation;
