import { getGuestSummary } from '../lib/guests';
import { Reservation, Room } from '../types';

export type RoomUi = {
  guestSummaryText: string;
  roomId: string;
  roomName: string;
  synxisReservationId: string;
};

export const buildRoomData = (apiData: Reservation, room: Room) => {
  return {
    guestSummaryText: apiData.guestSummary ? getGuestSummary(apiData.guestSummary) : '',
    roomId: room.oid,
    roomName: room.name,
    synxisReservationId: apiData.reservation_data?.reservation_id ?? '',
  };
};

export type RoomsUi = {
  rooms: RoomUi[];
};

export const buildRoomsUi = (apiData: Reservation): RoomsUi => {
  const rooms = (apiData.reservation_data?.rooms || []).map((room) => buildRoomData(apiData, room));
  return { rooms };
};
