import { adjustTimeZoneHours } from 'app/features/reservations/lib/reservationStatus';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { DateTimeStringObject } from './types';

export const formatDateTimeToParts = (
  date: Date,
  locale: string = 'en-US',
  options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  },
  isUTCDate: boolean = false,
): DateTimeStringObject => {
  const utcDate = adjustTimeZoneHours(date.toISOString());
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options);
  const parts = dateTimeFormat.formatToParts(isUTCDate ? date : utcDate);
  const dateTimeParts: DateTimeStringObject = {} as DateTimeStringObject;
  parts.forEach((part) => {
    dateTimeParts[part.type] = part.value;
  });

  return dateTimeParts;
};

export const isDateInInterval = (startDate: Date, endDate: Date, desiredDate: Date = new Date()) => {
  return isAfter(desiredDate, startDate) && isBefore(desiredDate, endDate);
};
