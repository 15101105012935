import { SxProps } from '@mui/material';
import colors from 'app/styles/colors';
import { borderRadius } from 'app/styles/layout';

interface Props {
  backgroundVariant: 'default' | 'white';
  multiline?: boolean;
}

const useSelfbookInputStyles = ({ multiline, backgroundVariant }: Props) => {
  const textFieldRootStyle: SxProps = {
    ':focus-visible, :focus-visible > *': {
      outline: 'none',
    },
  };

  const inputRootStyle: SxProps = {
    height: multiline ? 'auto' : 48,
    px: 0,
    borderRadius: borderRadius.base,
    backgroundColor: backgroundVariant === 'white' ? 'white' : 'grey.200',
    fontWeight: 300,
    border: 1,
    borderColor: 'transparent',
    color: 'grey.900',
    fontSize: 14,
    '::placeholder': {
      color: 'grey.500',
    },
    ':focus-within, :focus-visible': {
      borderColor: 'grey.500',
      backgroundColor: backgroundVariant === 'white' ? 'white' : 'grey.200',
    },
    ':hover': {
      backgroundColor: backgroundVariant === 'white' ? 'grey.100' : 'grey.300',
    },
    '&.Mui-error': {
      borderColor: 'error.main',
      backgroundColor: colors.error[200],
      ':hover': {
        backgroundColor: colors.error[200],
      },
      ':focus-within, :focus-visible': {
        backgroundColor: colors.error[200],
      },
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'grey.200',
    },
  };

  const inputInputStyle: SxProps = {
    px: { xs: 1.8, md: 2.1 },
    mt: { xs: -0.6, md: -0.3 },
    letterSpacing: 0.5,
    '&.Mui-disabled': {
      WebkitTextFillColor: colors.grey[600],
      opacity: 0.7,
    },
  };

  const labelStyle: SxProps = {
    textTransform: 'uppercase',
    fontSize: { xs: 12, md: 14 },
    fontWeight: 400,
    lineHeight: '100%',
    color: 'grey.700',
    px: { xs: 0.65, md: 1 },
    pt: 0.25,
    letterSpacing: 1,
    '&.Mui-error': {
      color: 'grey.700',
    },
    '&.Mui-disabled': {
      color: 'grey.600',
      opacity: 0.65,
    },
  };

  const helperTextStyle: SxProps = {
    fontSize: { xs: 12, md: 14 },
    fontWeight: 300,
    color: 'grey.600',
    mx: 0,
    '&.Mui-error': {
      color: 'error.dark',
    },
  };

  return { textFieldRootStyle, inputRootStyle, inputInputStyle, helperTextStyle, labelStyle };
};

export default useSelfbookInputStyles;
