import { Link, Typography } from '@mui/material';
import RestrictedPage from 'app/components/RestrictedPage/RestrictedPage';
import colors from 'app/styles/colors';

export default function BookingsRestricted() {
  const videoUrl =
    'https://player.vimeo.com/video/838623243?h=5255f7e54e&badge=0&autoplay=1&loop=1&player_id=0&controls=0&app_id=58479';

  return (
    <RestrictedPage
      wrapperTestId="bookings-restricted-wrapper"
      name="Selfbook Bookings Demo"
      videoUrl={videoUrl}
    >
      <Typography width="500px" textAlign="center" fontWeight="300" fontSize="16px" color={colors.grey[900]}>
        Get in touch with{' '}
        <Link href="mailto:sales@selfbook.com" underline="always">
          Sales
        </Link>{' '}
        to gain full access to Station&apos;s Bookings functionality.
      </Typography>
    </RestrictedPage>
  );
}
