import { getInitials } from 'app/features/user/lib/userInitials';
import { getCustomerFullName, getCustomerEmail } from '../lib/guests';
import { Reservation } from '../types';

export type HeaderUi = {
  customerName: string;
  customerInitials: string;
};

export const buildHeaderUi = (apiData: Reservation): HeaderUi => {
  const customerName = getCustomerFullName(apiData);
  const customerEmail = getCustomerEmail(apiData);
  const customerInitials = getInitials(customerName, customerEmail);

  const headerUi = {
    customerName,
    customerInitials,
  };

  return headerUi;
};
