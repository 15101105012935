import { isDateInInterval } from 'app/lib/dates';
import { logMessage } from 'app/lib/logging';
import { isToday } from 'date-fns';
import { RESERVATION_MENU_OPTIONS } from '../components/ReservationsStatusMenu';
import { ReservationsFilter } from '../context/useTimeLineContext';
import { Card, Customer, Reservation, ReservationData, Room } from '../types';
import { getCustomerFullName, getGuestFirstName, getGuestLastName } from './guests';
import { getReservationIdText } from './reservationIdText';
import {
  adjustTimeZoneHours,
  getReservationPaymentStatusColors,
  getReservationStatusClassname,
} from './reservationStatus';

const mapCustomerObject = (customer: any) => {
  const mappedCustomer: Customer = {} as Customer;
  if (customer) {
    mappedCustomer.full_name = customer.full_name;
    mappedCustomer.phone = customer.phone;
    mappedCustomer.email = customer.email;
  }
  return mappedCustomer;
};

const mapCardObject = (card: any) => {
  const mappedCard: Card = {} as Card;
  if (card) {
    mappedCard.code = card.code;
    mappedCard.expire_date = card.expire_date;
    mappedCard.holder_name = card.holder_name;
    mappedCard.number = card.number;
  }
  return mappedCard;
};

const mapRooms = (rooms: any[]) => {
  const mappedRooms: Room[] = [] as Room[];

  if (rooms) {
    rooms.forEach((room) => {
      const mappedRoom: Room = {} as Room;
      mappedRoom.code = room.code;
      mappedRoom.description = room.description;
      mappedRoom.name = room.name;
      mappedRoom.oid = room.oid;

      mappedRooms.push(mappedRoom);
    });
  }

  return mappedRooms;
};

export const mapReservationData = (reservation_data?: any) => {
  const mappedReservationData: ReservationData = {} as ReservationData;
  mappedReservationData.customer = mapCustomerObject(reservation_data?.customer);
  mappedReservationData.card = mapCardObject(reservation_data?.card);
  mappedReservationData.reservation_id = reservation_data?.reservation_id;
  mappedReservationData.reservation_total_amount = reservation_data?.reservation_total_amount;
  mappedReservationData.currency_code = reservation_data?.currency_code;
  mappedReservationData.reservation_total_amount_before_tax =
    reservation_data?.reservation_total_amount_before_tax;
  mappedReservationData.rooms = mapRooms(reservation_data?.rooms);
  mappedReservationData.rate = reservation_data?.rate;
  mappedReservationData.nightly_rates = reservation_data?.nightly_rates;
  mappedReservationData.services = reservation_data?.services;
  mappedReservationData.taxes = reservation_data?.taxes;
  mappedReservationData.reservation_token = reservation_data?.reservation_token;

  return mappedReservationData;
};

export const mapReservation = (item: any) => {
  const reservation: Reservation = {} as Reservation;
  try {
    if (item) {
      const firstName = getGuestFirstName(item?.guest_information?.name);
      const lastName = getGuestLastName(item?.guest_information?.name);

      reservation.arriveAt = item.arrive_at;
      reservation.departAt = item.depart_at;
      reservation.createdAt = item.created_at;
      reservation.guestSummary = item.guest_summary;
      reservation.guests = item.guests;
      reservation.hotel = item.hotel;
      reservation.hotelGroup = item.hotel_group;
      reservation.id = item.id;
      reservation.metadata = item.metadata;
      reservation.payments = item.payments;
      reservation.updatedAt = item.updated_at;
      reservation.firstName = firstName;
      reservation.lastName = lastName;
      reservation.invoice = item.invoice;
      reservation.reservation_data = mapReservationData(item.reservation_data);
      reservation.status = item.status;
      reservation.storedCharge = item.stored_charge;
      reservation.payment_schedule = item.payment_schedule;
      reservation.guest_information = item.guest_information;
      reservation.payment_methods = item.payment_methods;
    }
  } catch (e) {
    // istanbul ignore next
    logMessage(e);
  }
  return reservation;
};

export const mapReservations = (items: any[] = []) => {
  const results = items.map(mapReservation);
  return results;
};

export const compareReservationsByStartDate = (resA: Reservation, resB: Reservation) => {
  const startDate = adjustTimeZoneHours(resA.arriveAt);
  const endDate = adjustTimeZoneHours(resB.arriveAt);

  return startDate.getTime() - endDate.getTime();
};

// istanbul ignore next // this is tested
export const orderReservationsByDate = (reservations: Reservation[]) => {
  const reservationEndingTodaySet = new Set<Reservation>();
  const reservationInProgressTodaySet = new Set<Reservation>();
  const reservationStartingTodaySet = new Set<Reservation>();
  const reservationUpcomingSet = new Set<Reservation>();

  reservations.forEach((res) => {
    const arriveAt = adjustTimeZoneHours(res.arriveAt);
    const departAt = adjustTimeZoneHours(res.departAt);

    if (isToday(departAt)) {
      reservationEndingTodaySet.add(res);
    } else if (arriveAt) {
      reservationStartingTodaySet.add(res);
    } else if (isDateInInterval(arriveAt, departAt)) {
      reservationInProgressTodaySet.add(res);
    } else {
      reservationUpcomingSet.add(res);
    }
  });

  const orderedReservations: Reservation[] = [
    ...Array.from(reservationEndingTodaySet).sort(compareReservationsByStartDate),
    ...Array.from(reservationInProgressTodaySet).sort(compareReservationsByStartDate),
    ...Array.from(reservationStartingTodaySet).sort(compareReservationsByStartDate),
    ...Array.from(reservationUpcomingSet).sort(compareReservationsByStartDate),
  ] as Reservation[];

  return orderedReservations;
};

export const mapReservationsToTimeline = (reservation: Reservation, index: number) => {
  const reservationStatus = reservation?.invoice?.invoice_lines[0]?.status;

  return {
    reservation,
    title: `${getCustomerFullName(reservation)} - ${getReservationIdText(reservation)}`,
    start: adjustTimeZoneHours(reservation.arriveAt),
    end: adjustTimeZoneHours(reservation.departAt),
    color: getReservationPaymentStatusColors(reservationStatus).bgColor,
    className: getReservationStatusClassname(reservationStatus),
    resourceId: `${index}`,
  };
};

export const applyFilters = (reservations: Reservation[], filters: ReservationsFilter) => {
  let results = reservations;

  if (filters.status) {
    if (RESERVATION_MENU_OPTIONS.paid === filters.status) {
      results = reservations.filter(
        (reservation) => reservation?.invoice?.invoice_lines[0]?.status === 'PAID',
      );
    } else if (RESERVATION_MENU_OPTIONS.partial === filters.status) {
      results = reservations.filter(
        (reservation) => reservation?.invoice?.invoice_lines[0]?.status === 'PARTIAL',
      );
    } else if (RESERVATION_MENU_OPTIONS.unpaid === filters.status) {
      results = reservations.filter(
        (reservation) => reservation?.invoice?.invoice_lines[0]?.status === 'UNPAID',
      );
    } else if (RESERVATION_MENU_OPTIONS.failed === filters.status) {
      results = reservations.filter(
        (reservation) => reservation?.invoice?.invoice_lines[0]?.status === 'FAILED',
      );
    }
  }

  return results;
};
