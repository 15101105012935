// placeholder at the moment -- this could be replaced by some sort of logging service in the future
export const logMessage = (...args: any[]) => {
  // eslint-disable-next-line no-console
  console.log(...args);
};

export const logError = (...args: any[]) => {
  // eslint-disable-next-line no-console
  console.error(...args);
};
