import { Typography, Box, Button } from '@mui/material';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import colors from 'app/styles/colors';

interface Props {
  isModalOpen: boolean;
  togglePaymentAutomationModal: () => void;
  loadingStopPaymentAutomation: boolean;
  handleStopPaymentAutomation: () => void;
}

function StopPaymentAutomationConfirmationModal({
  isModalOpen,
  togglePaymentAutomationModal,
  loadingStopPaymentAutomation,
  handleStopPaymentAutomation,
}: Props) {

  return (
    <SelfbookDialog
      data-testid="stop-payment-automation-confirm-modal"
      open={isModalOpen}
      onClose={togglePaymentAutomationModal}
    >
      <SelfbookDialog.Title onClose={togglePaymentAutomationModal}>
        Stop Payment Automation
      </SelfbookDialog.Title>
      <SelfbookDialog.Content sx={{ width: '460px' }}>
        <Typography>Do you really want to stop payment automation for this booking?</Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gridTemplateRows="46px" columnGap={1} mt={2} mb={1}>
          <Button onClick={togglePaymentAutomationModal} variant="outlined">
            NO, THANKS
          </Button>
          <LoadingButton
            loading={loadingStopPaymentAutomation}
            onClick={handleStopPaymentAutomation}
            variant="outlined"
            color="error"
            sx={{
              borderColor: colors.error[800],
              backgroundColor: colors.error[200],
              color: colors.error[800],
              '&:hover': {
                backgroundColor: colors.error[200],
              },
            }}
          >
            YES
          </LoadingButton>
        </Box>
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default StopPaymentAutomationConfirmationModal;
