import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import useSelfbookInputStyles from '../selfbook-text-input/useSelfbookInputStyles';

interface Props {
  backgroundVariant?: 'default' | 'white';
  helperText?: string;
  options: Array<{ value: string; label: string }>;
  required?: boolean;
}

function SelfbookSelect({
  id,
  name,
  value,
  label,
  options,
  helperText,
  error,
  backgroundVariant = 'default',
  fullWidth,
  required,
  ...rest
}: SelectProps & Props) {
  const { textFieldRootStyle, inputRootStyle, inputInputStyle, labelStyle } = useSelfbookInputStyles({
    backgroundVariant,
  });

  return (
    <FormControl
      required={required}
      error={error}
      fullWidth={fullWidth}
      variant="filled"
      sx={{
        backgroundColor: 'transparent',
        ...textFieldRootStyle,
      }}
    >
      <InputLabel
        shrink={!!value}
        id={id}
        sx={{
          ...labelStyle,
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...rest}
        value={value}
        labelId={id}
        name={name}
        label={label}
        id={`select-${id}`}
        inputProps={{
          'data-testid': `input-${id}`,
          sx: {
            ...inputInputStyle,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: '14px',
                fontWeight: 300,
              },
            },
          },
        }}
        sx={{
          ...inputRootStyle,
          '& > .MuiSelect-select, & > .MuiSelect-select:focus': {
            background: 'transparent',
          },
        }}
      >
        {options?.map((item) => {
          return (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? (
        <FormHelperText
          error={error}
          sx={{
            fontSize: '14px',
            fontWeight: 300,
            letterSpacing: '0.28px',
            color: 'error800',
            margin: 0,
            marginTop: '8px',
          }}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default SelfbookSelect;
