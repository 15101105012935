import { Box, Typography } from '@mui/material';
import CalendarIcon from 'app/components/icons/CalendarIcon';
import EnvelopeIcon from 'app/components/icons/EnvelopeIcon';
import KeyIcon from 'app/components/icons/KeyIcon';
import PeopleIcon from 'app/components/icons/PeopleIcon';
import PhoneIcon from 'app/components/icons/PhoneIcon';
import RoomIcon from 'app/components/icons/RoomIcon';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import { subHeaderTextStyles } from './styles';

export default function ReservationDetailPanelSubHeader() {
  const {
    subheaderUi: { arriveDate, departDate, customerEmail, reservationId, customerPhone },
    roomsUi: { rooms },
  } = useReservationDetailContext();

  return (
    <Box sx={{ marginTop: 2, display: 'flex' }}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarIcon style={{ marginRight: '13px', width: '12.5px', height: '14px' }} />
          <Typography
            variant="subtitle1"
            sx={subHeaderTextStyles}
            data-testid="reservation-dates"
            lineHeight="16px"
            letterSpacing="0.01em"
          >
            {arriveDate} — {departDate}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1.5 }}>
          <RoomIcon style={{ marginRight: '13px', width: '14px', height: '14px' }} />
          <Typography variant="subtitle1" sx={subHeaderTextStyles}>
            {rooms && rooms[0]?.roomName}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1.5 }}>
          <PeopleIcon style={{ marginRight: '13px', width: '14px', height: '14px' }} />
          <Typography variant="subtitle1" sx={subHeaderTextStyles}>
            {rooms && rooms[0]?.guestSummaryText}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ ml: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <KeyIcon style={{ marginRight: '13px', width: '14px', height: '14px' }} />
          <Typography variant="subtitle1" sx={subHeaderTextStyles}>
            {reservationId}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1.5 }}>
          <PhoneIcon style={{ marginRight: '13px', width: '13px', height: '13px' }} />
          <Typography variant="subtitle1" sx={subHeaderTextStyles}>
            {customerPhone}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1.5 }}>
          <EnvelopeIcon style={{ marginRight: '13px', width: '14px', height: '10px' }} />
          <Typography variant="subtitle1" sx={subHeaderTextStyles}>
            {customerEmail}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
