import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDownIcon from 'app/components/icons/ArrowDownIcon';
import ArrowUpIcon from 'app/components/icons/ArrowUpIcon';
import colors from 'app/styles/colors';
import React, { Fragment, useState } from 'react';
import { useTimeLineContext } from '../context/useTimeLineContext';
import { filterButtonArrowsStyles } from './styles';

interface RESERVATION_MENU_MAP {
  [key: string]: string;
  paid: string;
  partial: string;
  unpaid: string;
  failed: string;
}

export const RESERVATION_MENU_OPTIONS: RESERVATION_MENU_MAP = {
  paid: 'Fully paid',
  partial: 'Partially paid',
  unpaid: 'Unpaid',
  failed: 'Failed',
  allReservations: 'All Bookings',
};

export default function ReservationsStatusMenu() {
  const { filters, setFilters } = useTimeLineContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonHover, setButtonHover] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuSelect = (menu: string) => {
    setFilters({
      ...filters,
      status: menu,
    });
  };

  return (
    <Fragment>
      <Button
        id="reservations-status-filter-button"
        data-testid="reservations-status-filter-button"
        aria-controls={open ? 'reservations-status-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        onMouseOver={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
        sx={{
          display: 'flex',
          width: '263px',
          backgroundColor: 'transparent',
          color: colors.grey[700],
          border: '0',
          ...(open && { backgroundColor: colors.grey[700], color: colors.white }),
          '&:hover': { backgroundColor: colors.grey[700], color: colors.white },
        }}
      >
        <Box>{filters.status}</Box>
        {open ? (
          <ArrowUpIcon style={filterButtonArrowsStyles} color={colors.white} />
        ) : (
          <ArrowDownIcon
            style={filterButtonArrowsStyles}
            color={buttonHover ? colors.white : colors.grey[700]}
          />
        )}
      </Button>

      <Menu
        id="reservations-status-filter-menu"
        data-testid="reservations-status-filter-menu"
        MenuListProps={{
          'aria-labelledby': 'reservations-status-filter-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '263px',
            overflow: 'visible',
            filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,0.1))',
          },
        }}
      >
        {Object.keys(RESERVATION_MENU_OPTIONS).map((key: string) => {
          const label = RESERVATION_MENU_OPTIONS[key];
          return (
            <MenuItem
              onClick={() => {
                handleMenuSelect(label);
                handleClose();
              }}
              data-testid={`reservations-status-filter-menu-${label}`}
              key={key}
              sx={{
                ':hover': { backgroundColor: colors.grey[200] },
              }}
            >
              <Typography
                variant="subtitle1"
                fontSize={14}
                letterSpacing="0.02em"
                fontWeight={450}
                sx={{ py: 1 }}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}
