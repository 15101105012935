import { ReservationChargeUi } from './roomCharge';

export const showPaymentAutomationCanceledText = (charge: ReservationChargeUi) => {
  let show = false;
  charge.transactionItems.forEach((item) => {
    if (item.status === 'ABANDONED') show = true;
  });

  return show;
};
