import { useUser } from 'app/features/authentication/context/useUser';
import { createContext, useContext, useMemo, useReducer, useState } from 'react';
import { useReservationDetail } from '../api/useReservationDetail';
import useSendPaymentLink, { generateScheduledTransactionPaymentLink } from '../api/useSendPaymentLink';
import { RefundActionPayload, TriggerPaymentLinkActionPayload } from '../sidebar-layer/roomCharge';
import { getReservationSidebar, ReservationSidebar } from '../sidebar-layer/sidebar';
import { Reservation } from '../types';

type RefundState = {
  refundChargeOpen: boolean;
  refundActionPayload: RefundActionPayload | null;
};

type State = {
  reservationId?: string;
  reservation?: Reservation;
  reservationLoading: boolean;
  sendingPaymentLinkOpen: boolean;
  triggerScheduledTransactionPaymentLink: (payload: TriggerPaymentLinkActionPayload) => void;
  closePaymentLinkSend: () => void;
  sendPaymentLinkError?: unknown;
  sendPaymentLinkStatus: 'loading' | 'error' | 'success' | 'idle';
  openRefundCharge: (refundActionPayload: RefundActionPayload) => void;
  closeRefundCharge: () => void;
  reservationRefetch: () => void;
} & ReservationSidebar &
  RefundState;

const ReservationDetailContext = createContext<State | undefined>(undefined);

function ReservationDetailContextProvider({
  children,
  reservationId,
}: {
  children: React.ReactNode;
  reservationId?: string;
}) {
  const { currentHotelId } = useUser();
  const {
    data: reservation,
    isLoading: reservationLoading,
    refetch: reservationRefetch,
  } = useReservationDetail(reservationId, currentHotelId!);

  // Begin send payment link
  const {
    mutate: submitPaymentLinkRequest,
    error: sendPaymentLinkError,
    status: sendPaymentLinkStatus,
    reset: resetSendPaymentLinkMutation,
  } = useSendPaymentLink(currentHotelId!);

  const [sendingPaymentLinkOpen, setSendingPaymentLinkOpen] = useState<boolean>(false);

  const closePaymentLinkSend = () => {
    setSendingPaymentLinkOpen(false);
    // Force a slight delay for smoother UI
    // istanbul ignore next
    setTimeout(() => resetSendPaymentLinkMutation(), 500);
  };

  const triggerScheduledTransactionPaymentLink = ({
    scheduledTransactionId,
  }: TriggerPaymentLinkActionPayload) => {
    const template = generateScheduledTransactionPaymentLink({
      transactionId: scheduledTransactionId,
      reservationId: reservationId!,
    });
    setSendingPaymentLinkOpen(true);

    // Force a slight delay for smoother UI
    // istanbul ignore next
    setTimeout(() => {
      submitPaymentLinkRequest(template);
    }, 500);
  };

  // End send payment link

  // Refund Charge
  const [refundState, updateRefundState] = useReducer(
    (state: RefundState, newState: RefundState) => ({ ...state, ...newState }),
    {
      refundChargeOpen: false,
      refundActionPayload: null,
    },
  );

  const openRefundCharge = (refundActionPayload: RefundActionPayload) => {
    updateRefundState({
      refundChargeOpen: true,
      refundActionPayload,
    });
  };

  const closeRefundCharge = () => {
    updateRefundState({
      refundChargeOpen: false,
      refundActionPayload: null,
    });
  };

  // End refund charge

  const sidebarValues = useMemo(() => getReservationSidebar(reservation), [reservation]);

  const value = useMemo(
    () => ({
      reservationId,
      reservation,
      reservationLoading,
      sendingPaymentLinkOpen,
      triggerScheduledTransactionPaymentLink,
      closePaymentLinkSend,
      sendPaymentLinkError,
      sendPaymentLinkStatus,
      openRefundCharge,
      closeRefundCharge,
      ...refundState,
      ...sidebarValues,
      reservationRefetch,
    }),
    [
      reservationId,
      reservation,
      reservationLoading,
      sendingPaymentLinkOpen,
      triggerScheduledTransactionPaymentLink,
      closePaymentLinkSend,
      sendPaymentLinkError,
      sendPaymentLinkStatus,
      openRefundCharge,
      closeRefundCharge,
      refundState,
      sidebarValues,
      reservationRefetch,
    ],
  );

  return <ReservationDetailContext.Provider value={value}>{children}</ReservationDetailContext.Provider>;
}

// istanbul ignore next // somehow coverage says this isn't tested, thats impossible
const useReservationDetailContext = () => {
  const context = useContext(ReservationDetailContext);
  if (context === undefined) {
    throw new Error('useReservationDetailContext must be used within a ReservationDetailContext');
  }
  return context;
};

export { useReservationDetailContext, ReservationDetailContextProvider };
