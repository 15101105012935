import { tableCellClasses } from '@mui/material/TableCell';
import { SxProps } from '@mui/material';
import colors from 'app/styles/colors';

export const tabelCellHeadStyles: SxProps = {
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.grey[100],
    color: colors.grey[800],
    fontSize: '12px',
    fontWeight: '450',
    letterSpacing: '0.12px',
    padding: '24px',
    lineHeight: '100%',
    textTransform: 'uppercase',
    borderTop: `1px solid ${colors.grey[200]}`,
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
};

export const tabelCellBodyStyles: SxProps = {
  [`&.${tableCellClasses.body}`]: {
    color: colors.grey[600],
    fontSize: '14px',
    fontWeight: '450',
    lineHeight: '100%',
    letterSpacing: '0.084px',
    padding: '0px',
    height: '64px',
    paddingLeft: '24px',
    '& span': {
      padding: '6px',
      cursor: 'pointer',
    },
  },
};

export const tabelRowStyles: SxProps = {
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.grey[100]}`,
};

export const disabledCellBodyStyles: SxProps = {
  backgroundColor: colors.white,
  color: `${colors.grey[500]} !important`,
  cursor: 'not-allowed !important',
  '& span': {
    cursor: 'not-allowed !important',
  },
}

export const getTableCellBodyStyles = (disabled: boolean) : SxProps => ({
  ...tabelCellBodyStyles,
  ...(disabled ? disabledCellBodyStyles : ''),
})

export const tableRowClickableStyles: SxProps = {
  ...tabelRowStyles,
  cursor: 'pointer',
  '> .MuiTableCell-root': {
    width: '233px',
  },
  ':focus-within, :focus-visible, :hover': {
    backgroundColor: 'grey.100',
    borderBottom: `1px solid ${colors.grey[200]}`,
    '> .MuiTableCell-root': {
      color: 'grey.700',
    },
    '.table-status-chip-CONVEYED': {
      cursor: 'not-allowed !important'
    },
    '.table-status-chip-SUCCESSFUL, .table-status-chip-UNKNOWN, .table-status-chip-PAID, .table-status-chip-PENDING, .table-status-chip-PAID-pymnt-link':
      {
        border: `1px solid ${colors.success[600]}`,
      },
    '.table-status-chip-IN-PROGRESS, .table-status-chip-PARTIAL, .table-status-chip-UNPAID-pymnt-link': {
      border: `1px solid ${colors.warning[600]}`,
    },
    '.table-status-chip-CANCELLED, .table-status-chip-FAILED': {
      border: `1px solid ${colors.critical[600]}`,
    },
    '.table-status-chip-UNPAID': {
      border: `1px solid ${colors.grey[600]}`,
    },
  },
};
