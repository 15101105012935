import { createContext, useContext, useMemo, useState } from 'react';

interface SupportModalState {
  isOpen: boolean;
  screen: 'form' | 'confirm';
  ticketNumber: string | null;
}

type State = {
  sideDrawerOpen: boolean;
  openSideDrawer: () => void;
  closeSideDrawer: () => void;
  supportModalState: SupportModalState;
  openSupportModalForm: () => void;
  openSupportModalConfirm: (ticketNumber: string) => void;
  closeSupportModal: () => void;
};

const NavigationContext = createContext<State | undefined>(undefined);

function NavigationContextProvider({ children }: { children: React.ReactNode }) {
  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [supportModalState, setSupportModalState] = useState<SupportModalState>({
    isOpen: false,
    screen: 'form',
    ticketNumber: null,
  });

  const openSideDrawer = () => {
    setSideDrawerOpen(true);
  };

  const closeSideDrawer = () => {
    setSideDrawerOpen(false);
  };

  const openSupportModalForm = () => {
    setSupportModalState({ isOpen: true, screen: 'form', ticketNumber: null });
  };

  const openSupportModalConfirm = (ticketNumber: string) => {
    setSupportModalState({ isOpen: true, screen: 'confirm', ticketNumber });
  };

  const closeSupportModal = () => {
    setSupportModalState((prevState) => ({ ...prevState, isOpen: false }));
    // Delay updating the components so the modal can slide out of view before content changes
    /* istanbul ignore next */ // There is a test for this
    setTimeout(() => {
      setSupportModalState({ isOpen: false, screen: 'form', ticketNumber: null });
    }, 500);
  };

  const value = useMemo(
    () => ({
      sideDrawerOpen,
      openSideDrawer,
      closeSideDrawer,
      supportModalState,
      openSupportModalForm,
      openSupportModalConfirm,
      closeSupportModal,
    }),
    [
      sideDrawerOpen,
      openSideDrawer,
      closeSideDrawer,
      supportModalState,
      openSupportModalForm,
      openSupportModalConfirm,
      closeSupportModal,
    ],
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigationContext must be used within a NavigationContext');
  }
  return context;
};

export { useNavigationContext, NavigationContextProvider };
