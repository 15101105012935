import { SVGProps } from 'react';

function EventIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden
      data-testid="event-icon"
      {...props}
    >
      <g clipPath="url(#clip0_2966_125304)">
        <path
          d="M13.125 18.75C12.1361 18.75 11.1694 18.4568 10.3471 17.9073C9.5249 17.3579 8.88404 16.577 8.5056 15.6634C8.12716 14.7498 8.02815 13.7445 8.22107 12.7745C8.414 11.8046 8.8902 10.9137 9.58947 10.2145C10.2887 9.5152 11.1796 9.039 12.1495 8.84607C13.1195 8.65315 14.1248 8.75216 15.0384 9.1306C15.952 9.50904 16.7329 10.1499 17.2823 10.9721C17.8318 11.7944 18.125 12.7611 18.125 13.75C18.125 15.0761 17.5982 16.3479 16.6605 17.2855C15.7229 18.2232 14.4511 18.75 13.125 18.75ZM13.125 10C12.3833 10 11.6583 10.2199 11.0416 10.632C10.4249 11.044 9.94428 11.6297 9.66045 12.3149C9.37662 13.0002 9.30236 13.7542 9.44705 14.4816C9.59175 15.209 9.9489 15.8772 10.4733 16.4017C10.9978 16.9261 11.666 17.2833 12.3934 17.4279C13.1208 17.5726 13.8748 17.4984 14.5601 17.2145C15.2453 16.9307 15.831 16.4501 16.243 15.8334C16.6551 15.2167 16.875 14.4917 16.875 13.75C16.875 12.7554 16.4799 11.8016 15.7767 11.0983C15.0734 10.3951 14.1196 10 13.125 10Z"
          fill="#495057"
        />
        <path
          d="M14.1187 15.625L12.5 14.0063V11.25H13.75V13.4937L15 14.7437L14.1187 15.625Z"
          fill="#495057"
        />
        <path
          d="M17.5 3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5H13.75V1.25H12.5V2.5H7.5V1.25H6.25V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H6.25V16.25H3.75V3.75H6.25V5H7.5V3.75H12.5V5H13.75V3.75H16.25V7.5H17.5V3.75Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_2966_125304">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default EventIcon;
