import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SideNavigationLink } from '../types';

interface Props {
  link: SideNavigationLink;
  sideDrawerOpen: boolean;
  isHighlighted?: boolean;
}

function SideNavigationItem({ link, sideDrawerOpen, isHighlighted = false }: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link.onClick) {
      link.onClick()
    }
    if (link.href) {
      navigate(link.href);
    }
  }

  return (
    <ListItem
      data-testid={`side-navigation-item-${link.text}`}
      disablePadding
      sx={{
        display: 'block',
        borderLeft: '2px solid',
        borderLeftColor: 'transparent',
        ...(isHighlighted && {
          borderLeftColor: 'white',
          backgroundColor: 'grey.700',
        }),
        ':hover': { backgroundColor: 'grey.700', borderLeftColor: 'white' },
      }}
    >
      <ListItemButton data-testid={`side-navigation-item-${link.text}-button`} sx={{
        height: 48,
        justifyContent: 'initial',
        px: 2.5,
      }}
        onClick={handleClick}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: sideDrawerOpen ? 3 : 'auto',
            justifyContent: 'center',
            color: 'white'
          }}
          aria-label={link.ariaLabel}
          data-testid={`side-navigation-item-${link.text}-icon`}
        >
          {link.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0.08em',
          }}
          primary={link.text}
          sx={{
            opacity: sideDrawerOpen ? 1 : 0,
          }}
          data-testid={`side-navigation-item-${link.text}-text`}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default SideNavigationItem;
