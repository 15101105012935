import CreditCardIcon from '../icons/CreditCardIcon';
import AmexIcon from '../icons/AmexIcon';
import DiscoverIcon from '../icons/DiscoverIcon';
import JcbIcon from '../icons/JcbIcon';
import MasterCard from '../icons/MasterCard';
import VisaIcon from '../icons/VisaIcon';
import DinersIcon from '../icons/DinersIcon';

export default function PaymentMethodIcon({ paymentMethod }: { paymentMethod?: string }) {
  switch (paymentMethod) {
    case 'VISA':
      return <VisaIcon />;
    case 'MASTERCARD':
      return <MasterCard />;
    case 'JCB':
      return <JcbIcon />;
    case 'AMEX':
      return <AmexIcon />;
    case 'DISCOVER':
      return <DiscoverIcon />;
    case 'DINERS_CLUB':
      return <DinersIcon />;
    default:
      return <CreditCardIcon />;
  }
}
