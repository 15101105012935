import { SVGProps } from 'react';

function HamburgerIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="hamburger-icon"
      {...props}
    >
      <path d="M18 0.5H0V2H18V0.5Z" fill="white" />
      <path d="M18 14H0V15.5H18V14Z" fill="white" />
      <path d="M18 5H0V6.5H18V5Z" fill="white" />
      <path d="M18 9.5H0V11H18V9.5Z" fill="white" />
    </svg>
  );
}

export default HamburgerIcon;
