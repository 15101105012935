import reactQueryClient from 'app/api/react-query/react-query-client';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useInfiniteQuery } from 'react-query';
import { InvoiceCategory, InvoiceCategoryPaginationResponse } from '../types';

export const invalidateInvoiceCategoriesQuery = async (hotelId: string) => {
  await reactQueryClient.invalidateQueries(['InvoiceCategories', hotelId]);
};

async function getInvoiceCategories({
  hotelId,
  pageParam = 1,
  pageSize = 100,
}: {
  hotelId: string;
  pageParam?: number;
  pageSize?: number;
}) {
  const response = await stationApiAxios({
    url: `rest/v1/hotels/${hotelId}/invoice_categories?page=${pageParam}&page_size=${pageSize}&expand=invoice_category_fees`,
    method: 'GET',
  });
  const data: InvoiceCategoryPaginationResponse = {
    invoiceCategories: response.data?.items,
    total: response.data?.total_items_without_paging,
  };
  return data;
}

export const useInvoiceCategoriesQuery = (hotelId: string) => {
  const getAllCategoriesFromPageResponse = (pages: InvoiceCategoryPaginationResponse[] = []) => {
    const allCategories: InvoiceCategory[] = pages.reduce((accum, page) => {
      return [...accum, ...page.invoiceCategories];
    }, [] as InvoiceCategory[]);

    return allCategories;
  };

  const results = useInfiniteQuery<InvoiceCategoryPaginationResponse, Error>(
    ['InvoiceCategories', hotelId],
    (args) => {
      return getInvoiceCategories({ hotelId, pageParam: args.pageParam });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const allCategories: InvoiceCategory[] = getAllCategoriesFromPageResponse(allPages);
        const dataLength = allCategories.length;
        return lastPage.total - dataLength > 0 ? allPages.length + 1 : undefined;
      },
    },
  );

  const allInvoiceCategories: InvoiceCategory[] = getAllCategoriesFromPageResponse(results?.data?.pages);
  const dataLength = allInvoiceCategories.length;

  return { ...results, dataLength, allInvoiceCategories };
};
