import { Box, Divider, Skeleton, Typography, useTheme } from '@mui/material';
import { useUser } from 'app/features/authentication/context/useUser';
import { useHotelMetricsQuery } from 'app/features/hotel/api/useHoteMetrics';
import colors from 'app/styles/colors';
import { mapPaymentMethodsMetrics } from '../lib/reservationMetrics';
import ReservationDoughnutGraph from './ReservationDoughnutGraph';
import ReservationsRevenueMetrics from './ReservationsRevenueMetrics';
import ReservationsDeviceMetrics from './ReservationsDeviceMetrics';

export default function ReservationsMetrics() {
  const theme = useTheme();
  const { currentHotelId } = useUser();
  const { data: hotelMetrics, status } = useHotelMetricsQuery(currentHotelId as string);

  const mappedHotelMetrics = mapPaymentMethodsMetrics(hotelMetrics);

  return (
    <Box
      sx={{ background: colors.grey[200], px: 5, py: 4, display: 'flex', flexDirection: 'column' }}
      data-testid="reservations-metrics"
    >
      <Box sx={{ mb: 2 }}>
        <Typography
          fontWeight={450}
          fontSize={14}
          lineHeight="100%"
          letterSpacing="0.04em"
          color={colors.grey[700]}
        >
          LAST 30 DAYS
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Box sx={{ p: 4, backgroundColor: colors.white, display: 'flex', flex: 1 }}>
          {status === 'loading' ? (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              data-testid="reservations-metrics-loading"
            >
              <Skeleton sx={{ height: '50px' }} />
              <Skeleton sx={{ height: '50px' }} />
              <Skeleton sx={{ height: '50px' }} />
              <Skeleton sx={{ height: '50px' }} />
              <Skeleton sx={{ height: '50px' }} />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flex: 1 }}>
              <ReservationDoughnutGraph hotelMetrics={hotelMetrics} />
              <Divider
                variant="inset"
                orientation="vertical"
                flexItem
                sx={{
                  mx: 4,
                  my: 2,
                  [theme.breakpoints.up('xl')]: {
                    mx: 6,
                  },
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                <ReservationsRevenueMetrics />
                <Divider
                  sx={{
                    my: 2,
                    [theme.breakpoints.up('lg')]: {
                      my: 4,
                    },
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box component="dl" data-label="last 30 days">
                    {[...Array.from(mappedHotelMetrics)].map((paymentMethod) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            ':not(:last-child)': { mb: '23px' },
                            alignItems: 'center',
                          }}
                          data-testid={`${paymentMethod[0]}-label-and-value`}
                          key={`${paymentMethod[0]}-label-and-value`}
                        >
                          <Box
                            sx={{
                              width: '92px',
                              mr: '12px',
                              [theme.breakpoints.up('lg')]: {
                                width: '130px',
                                mr: 2,
                              },
                            }}
                          >
                            <Typography
                              fontWeight={300}
                              fontSize="16px"
                              lineHeight="12.8px"
                              color={colors.grey[900]}
                              component="dt"
                              sx={{
                                [theme.breakpoints.up('lg')]: {
                                  fontSize: '18px',
                                  lineHeight: '14.4px',
                                },
                              }}
                            >
                              {paymentMethod[0]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '44px',
                              [theme.breakpoints.up('lg')]: {
                                width: '50px',
                                mr: 1.5,
                              },
                            }}
                          >
                            <Typography
                              fontWeight={450}
                              fontSize="18px"
                              lineHeight="14.4px"
                              color={colors.grey[900]}
                              letterSpacing="0.04em"
                              component="dd"
                            >
                              {paymentMethod[1].count}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                    aria-hidden
                  >
                    {[...Array.from(mapPaymentMethodsMetrics(hotelMetrics))].map((paymentMethod) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            ':not(:last-child)': { mb: 3 },
                            width: '100%',
                            alignItems: 'center',
                            height: '14.39px',
                          }}
                          data-testid={`${paymentMethod[0]}-bar`}
                          key={`${paymentMethod[0]}-bar`}
                        >
                          <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box
                              style={{
                                height: '6px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                flexWrap: 'wrap',
                                width: '100%',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                style={{
                                  width: `100%`,
                                  backgroundColor: colors.grey[300],
                                  height: '2px',
                                  borderRadius: '4px',
                                }}
                              />
                              <Box
                                style={{
                                  width: `${paymentMethod[1].percentage}%`,
                                  backgroundColor: paymentMethod[1].color,
                                  height: '6px',
                                  marginTop: '-6px',
                                  borderRadius: '4px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <ReservationsDeviceMetrics />
      </Box>
    </Box>
  );
}
