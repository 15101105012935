import { Box, SxProps } from '@mui/material';

interface Props {
  height?: number;
  width?: number;
  grow?: boolean;
  sx?: SxProps;
}

function Spacer({ height, width, grow, sx }: Props) {
  return <Box data-testid="spacer" sx={{ height, width, flexGrow: grow ? 1 : 0, ...sx }} />;
}

export default Spacer;
