import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from 'app/features/authentication/login/components/Login';
import VerifyCode from 'app/features/authentication/mfa/components/VerifyCode';
import ResetPasswordRequest from 'app/features/authentication/reset-password/components/ResetPasswordRequest';
import Reservations from 'app/features/reservations/components/Reservations';
import { useUser } from 'app/features/authentication/context/useUser';
import { lazy, useEffect, Suspense } from 'react';
import InvalidCodeScreen from 'app/features/authentication/mfa/components/InvalidCodeScreen';
import { PaymentLinksPath } from 'app/payment-links/routing/paymentLinkPath';
import usePermissions from 'app/features/permissions/usePermissions';
import BookingsRestricted from 'app/features/reservations/components/BookingsRestricted';
import { LayersRouter } from 'app/layers/router';
import {
  MainPaths,
  TopLevelPaths,
  AuthenticationPaths,
  NewUserPaths,
  IndexPath,
  AdminPaths,
  TransactionReceiptPath,
  PaymentLinkReceiptPath,
} from './route-paths';
import InitialScreen from './app-sections/InitialScreen';
import MainSection from './app-sections/MainSection';
import AuthenticationSection from './app-sections/AuthenticationSection';
import IndexPageRedirect from './app-sections/IndexPageRedirect';
import PageNotFound from './app-sections/PageNotFound';
import FeatureRoute from './components/FeatureRoute';
import PaymentLinksShortCodeRedirect from './app-sections/PaymentLinksShortCodeRedirect';

const ManageHotels = lazy(() => import('app/features/selfbook-admin/hotels/components/Hotels'));
const HotelById = lazy(() => import('app/features/selfbook-admin/hotels/components/HotelById'));

const PasswordRequestSuccessful = lazy(
  () => import('app/features/authentication/reset-password/components/PasswordResetSuccessful'),
);
const NewPasswordSetSuccessful = lazy(
  () => import('app/features/authentication/reset-password/components/NewPasswordSetSuccessful'),
);
const TransactionReceipt = lazy(() => import('app/features/reservations/components/TransactionReceipt'));
const PaymentLinkReceipt = lazy(() => import('app/features/payment-links/components/PaymentLinkReceipt'));
const SelfbookAdminSection = lazy(() => import('./app-sections/SelfbookAdminSection'));
const PaymentLinks = lazy(() => import('app/features/payment-links/components/PaymentLinks'));
const ContentManagement = lazy(() => import('app/features/content-management/components/ContentManagement'));

const PaymentLinksPage = lazy(() => import('app/payment-links/page/PaymentLink'));

const Payouts = lazy(() => import('app/features/payouts/components/Payouts'));
const CreateInitialPassword = lazy(
  () => import('app/features/authentication/reset-password/components/CreateInitialPassword'),
);
const ChooseNewPassword = lazy(
  () => import('app/features/authentication/reset-password/components/ChooseNewPassword'),
);

function Router() {
  const { fetchCurrentUserFromJwt } = useUser();
  const { allowedPaths, hasBookingsAccess } = usePermissions();

  useEffect(() => {
    fetchCurrentUserFromJwt();
  }, []);

  const hasPayouts = allowedPaths.includes(MainPaths.Payouts);
  const hasContent = allowedPaths.includes(MainPaths.Content);
  const hasPaymentLinks = allowedPaths.includes(MainPaths.PaymentLinksManagement);

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/*" element={<PaymentLinksShortCodeRedirect />} />
          <Route path="/" element={<IndexPageRedirect />} />
          <Route path={PaymentLinksPath} element={<PaymentLinksPage />} />
          <Route path={TransactionReceiptPath} element={<TransactionReceipt />} />
          <Route path={PaymentLinkReceiptPath} element={<PaymentLinkReceipt />} />

          <Route path={IndexPath} element={<InitialScreen />}>
            <Route path={TopLevelPaths.Main} element={<MainSection />}>
              <Route
                path={MainPaths.Reservations}
                element={
                  <FeatureRoute
                    hasAccess
                    noAccessMessage="Payment Processing"
                    element={hasBookingsAccess ? <Reservations /> : <BookingsRestricted />}
                  />
                }
              />
              <Route
                path={MainPaths.Payouts}
                element={
                  <FeatureRoute
                    hasAccess={hasPayouts}
                    noAccessMessage="Payment Processing"
                    element={<Payouts />}
                  />
                }
              />
              <Route
                path={MainPaths.Content}
                element={
                  <FeatureRoute
                    hasAccess={hasContent}
                    noAccessMessage="Content Management"
                    element={<ContentManagement />}
                  />
                }
              />
              <Route
                path={MainPaths.PaymentLinksManagement}
                element={
                  <FeatureRoute
                    hasAccess={hasPaymentLinks}
                    noAccessMessage="Payment Links Management"
                    element={<PaymentLinks />}
                  />
                }
              />
            </Route>

            <Route path={TopLevelPaths.NewUser} element={<AuthenticationSection />}>
              <Route path={NewUserPaths.CreateInitialPassword} element={<CreateInitialPassword />} />
              <Route path={NewUserPaths.PasswordSetSuccessful} element={<NewPasswordSetSuccessful />} />
            </Route>

            <Route path={TopLevelPaths.Authentication} element={<AuthenticationSection />}>
              <Route path={AuthenticationPaths.Login} element={<Login />} />
              <Route path={AuthenticationPaths.ResetPasswordRequest} element={<ResetPasswordRequest />} />
              <Route path={AuthenticationPaths.ResetPasswordNew} element={<ChooseNewPassword />} />
              <Route
                path={AuthenticationPaths.PasswordResetSuccessful}
                element={<PasswordRequestSuccessful />}
              />
              <Route path={AuthenticationPaths.VerifyCode} element={<VerifyCode />} />
              <Route path={AuthenticationPaths.InvalidCode} element={<InvalidCodeScreen />} />
            </Route>

            <Route path={TopLevelPaths.SelfbookAdmin} element={<SelfbookAdminSection />}>
              <Route index element={<ManageHotels />} />
              <Route path={AdminPaths.HotelById} element={<HotelById />} />
            </Route>

            {LayersRouter}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
