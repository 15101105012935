import { STATION_API_URL } from 'app/config/constants';
import {
  clearAllLocalStorageUserData,
  getAccessToken,
  getJwtExpiresIn,
  getJwtIsExpired,
  refreshCredentials,
} from 'app/features/authentication/lib/lib';
import axios from 'axios';

const baseTimeout = 1000 * 30;
const stationApiAxios = axios.create({
  timeout: baseTimeout,
  baseURL: STATION_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

export default stationApiAxios;

export const configureAxiosInterceptors = () => {
  stationApiAxios.interceptors.request.use((config) => {
    const token = getAccessToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: config.headers?.Authorization ? config.headers?.Authorization : `Bearer ${token}`,
      },
    };
  });

  stationApiAxios.interceptors.response.use(
    (response) => {
      const token = getAccessToken();
      const accessTokenExpired = getJwtIsExpired();
      const tokenIsCloseToExpiration = getJwtExpiresIn({ minutes: 15 });
      if (
        token &&
        tokenIsCloseToExpiration &&
        !accessTokenExpired &&
        response.config.url !== '/api/v1/token'
      ) {
        refreshCredentials();
      }
      return response;
    },
    (error) => {
      // If the response is unauthorized, we either do not have a token,
      // or we have an invalid/expired token. Either way, remove all the user data
      // from localstorage and redirect to auth section (via passed in function)
      if (error.response.status === 401 && error.config.url !== '/api/v1/token') {
        clearAllLocalStorageUserData();
        window.location.href = '/app/authentication/login';
      }
      return Promise.reject(error);
    },
  );
};

configureAxiosInterceptors();
