import { SVGProps } from 'react';

function ArrowUpIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
            data-testid="arrow-up-icon"
            {...props}>
            <path d="M8 0.5L15.5 8L14.45 9.05L8 2.6L1.55 9.05L0.5 8L8 0.5Z" fill="currentColor" />
        </svg>
    );
}

export default ArrowUpIcon;
