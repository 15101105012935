import { Typography } from '@mui/material';
import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import colors from 'app/styles/colors';
import { Fragment, useState } from 'react';
import { AddHotelUser } from '../../invoices/types';
import useAddHotelUser from '../api/useAddHotelUser';
import AddHotelAdminForm from './AddHotelAdminForm';
import ConfirmModal from './ConfirmModal';

interface Props {
  onClose: () => void;
  open: boolean;
  hotelId: string;
}

enum ScreenStates {
  FORM = 'FORM',
  CONFIRM = 'CONFIRM',
}

function AddHotelAdminModal({ open, onClose, hotelId }: Props) {
  const [screen, setScreen] = useState<ScreenStates>(ScreenStates.FORM);

  const {
    mutate: addHotelUserMutation,
    error: addHotelUserError,
    isLoading: addHotelUserLoading,
    reset: addHotelUserReset,
  } = useAddHotelUser();

  const handleSuccessCallback = () => {
    setScreen(ScreenStates.CONFIRM);
  };

  const handleSubmit = (request: AddHotelUser) => {
    return addHotelUserMutation(
      {
        hotelId,
        ...request,
      },
      {
        onSuccess: handleSuccessCallback,
      },
    );
  };

  /* istanbul ignore next */
  const handleModalClose = () => {
    onClose();
    addHotelUserReset();
    setTimeout(() => setScreen(ScreenStates.FORM));
  };

  const renderTitle = () => {
    switch (screen) {
      case ScreenStates.FORM:
        return 'Invite Admin';
      default:
        return 'Admin Invited';
    }
  };

  const renderContent = () => {
    switch (screen) {
      case ScreenStates.FORM:
        return (
          <Fragment>
            <Typography variant="body1" color={colors.grey[700]} fontWeight="100">
              Anyone with admin access will be granted access to the hotel&apos;s Station dashboard.
            </Typography>
            <AddHotelAdminForm
              onSubmit={handleSubmit}
              submitError={addHotelUserError}
              isLoading={addHotelUserLoading}
            />
          </Fragment>
        );
      default:
        return <ConfirmModal onCloseClick={handleModalClose} header="Invite Successfully Sent" />;
    }
  };

  return (
    <SelfbookDialog data-testid="add-hotel-admin-modal" open={open} onClose={handleModalClose} maxWidth="sm">
      <SelfbookDialog.Title data-testid="add-hotel-admin-title" onClose={onClose}>
        {renderTitle()}
      </SelfbookDialog.Title>
      <SelfbookDialog.Content data-testid="add-hotel-admin-content">{renderContent()}</SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default AddHotelAdminModal;
