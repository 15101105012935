import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CodeInput from 'app/components/code-input/CodeInput';
import { getErrorMessage } from 'app/api/lib/error-handling';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaths, IndexPath } from 'app/routing/route-paths';
import { useEffect, useRef, useState } from 'react';
import { obfuscateEmailAddress } from 'app/lib/obfuscation';
import SelfbookCheckbox from 'app/components/selfbook-checkbox/SelfbookCheckbox';
import { Button, Divider } from '@mui/material';
import colors from 'app/styles/colors';
import ArrowLeftIcon from 'app/components/icons/ArrowLeftIcon';
import useVerifyCode from './useVerifyCode';
import useMfaValidate from '../api/useMfaValidate';
import { useAuthenticationContext } from '../../context/useAuthenticationContext';
import { setAccessToken, setClientId, setRefreshToken } from '../../lib/lib';
import { useUser } from '../../context/useUser';

function VerifyCode() {
  const navigate = useNavigate();
  const { loginState } = useAuthenticationContext();
  const { email, mfa_nonce } = loginState;
  const { mutate, isLoading, error } = useMfaValidate();
  const { fetchCurrentUserFromJwt } = useUser();
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  useEffect(() => {
    if (!email || !mfa_nonce) {
      navigate(`../${AuthenticationPaths.Login}`, { replace: true });
    }
  }, []);

  const codeInputRef = useRef<any>();

  const handleCodeSubmit = (code: string) => {
    mutate(
      { email, mfa_nonce, auth_code: code, remember_me: rememberMe },
      {
        onSuccess: (response) => {
          setRefreshToken(response.data?.refresh_token);
          setAccessToken(response.data?.access_token);
          setClientId(response.data?.client_id);
          fetchCurrentUserFromJwt();
          navigate(`../../../${IndexPath}`, { replace: true });
        },
        onError: (err: unknown) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleCodeChange('');
          codeInputRef.current?.clear();
          const message = getErrorMessage(err);
          if (message.toLowerCase().includes('maximum login attempts')) {
            navigate(`../${AuthenticationPaths.InvalidCode}`, {
              replace: true,
            });
          }
        },
      },
    );
  };

  const { code, handleCodeChange } = useVerifyCode({ onCodeSubmit: handleCodeSubmit });

  return (
    <Box data-testid="verify-code-screen" sx={{ alignItems: 'center', maxWidth: '400px' }}>
      <Stack>
        <Typography component="h1" variant="h2" fontFamily="PP Neue Montreal" fontSize="34px">
          Authentication
        </Typography>
        <Typography
          component="p"
          fontSize="18px"
          fontWeight="300"
          sx={{ mt: 2 }}
          lineHeight="130%"
          color={colors.grey[700]}
        >
          Enter the 6 digit verification code that we sent to your{' '}
          <strong>{obfuscateEmailAddress(email)}</strong> email.
        </Typography>
      </Stack>
      <Box component="fieldset" sx={{ width: '100%', mt: 2, border: 'none', pl: 0, ml: 0 }}>
        <CodeInput
          ref={codeInputRef}
          value={code}
          // @ts-ignore
          onChange={handleCodeChange}
          hiddenLabelText="Enter verification code"
          name="verify-code"
          isDisabled={isLoading}
          data-testid="verify-code-field"
        />
        <SelfbookCheckbox
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          name="remember-me"
          sx={{ mt: 2, color: 'gray.700' }}
          label="Remember this device for 7 days"
          data-testid="remember-me-checkbox"
        />
      </Box>
      {isLoading && <CircularProgress sx={{ color: 'gray.900' }} size={40} />}
      {!!error && (
        <Alert
          id="verify-code-error"
          data-testid="verify-code-error"
          sx={{ boxShadow: 0, mt: 3 }}
          severity="error"
        >
          {`${getErrorMessage(error)}. Please try again.`}
        </Alert>
      )}

      <Divider
        orientation="horizontal"
        sx={{ borderBottom: `1px solid ${colors.grey[300]}`, marginTop: 6, width: '100%' }}
      />
      <Button
        data-testid="change-account-link"
        variant="textMuted"
        onClick={() => navigate(-1)}
        sx={{ alignSelf: 'baseline', mt: 4 }}
        startIcon={<ArrowLeftIcon />}
      >
        Change Account
      </Button>
    </Box>
  );
}

export default VerifyCode;
