import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { LoginRequest } from '../types';

const useLogin = () => {
  const mutation = useMutation((request: LoginRequest) => {
    return stationApiAxios({
      url: '/api/v1/login',
      method: 'POST',
      data: request,
    });
  });

  return mutation;
};

export default useLogin;
