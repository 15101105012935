import { useEffect } from 'react';

const useFocusFirstFormField = (formRef: React.RefObject<HTMLFormElement>) => {
  useEffect(() => {
    const firstField = formRef.current?.querySelectorAll('input')?.[0];
    firstField?.focus();
  }, [formRef.current]);
};

export default useFocusFirstFormField;
