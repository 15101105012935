import { SVGProps } from 'react';

function SendIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="send-icon"
      {...props}
    >
      <path
        d="M12.7252 5.555L1.72519 0.0550032C1.63897 0.0118866 1.54213 -0.00538936 1.44633 0.00525571C1.35052 0.0159008 1.25983 0.0540129 1.18519 0.115003C1.1139 0.174749 1.06069 0.253196 1.03154 0.341525C1.00239 0.429854 0.99846 0.524562 1.02019 0.615003L2.50019 6L1.00019 11.37C0.9798 11.4455 0.97742 11.5248 0.993238 11.6014C1.00906 11.678 1.04263 11.7499 1.09127 11.8111C1.1399 11.8724 1.20223 11.9214 1.27325 11.9542C1.34427 11.987 1.422 12.0027 1.50019 12C1.57846 11.9995 1.65553 11.9807 1.72519 11.945L12.7252 6.445C12.8071 6.40304 12.8758 6.3393 12.9238 6.26078C12.9718 6.18226 12.9972 6.09203 12.9972 6C12.9972 5.90798 12.9718 5.81774 12.9238 5.73923C12.8758 5.66071 12.8071 5.59696 12.7252 5.555ZM2.27519 10.555L3.38019 6.5H8.00019V5.5H3.38019L2.27519 1.445L11.3802 6L2.27519 10.555Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SendIcon;
