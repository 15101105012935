import { ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import useSideDrawerStyles from './useSideDrawerStyles';

interface Props {
  isOpen: boolean;
  children: ReactNode;
}

function SideDrawer({ children, isOpen }: Props) {
  const { drawerPaperStyle, drawerContainerStyle } = useSideDrawerStyles({ isOpen });
  return (
    <Drawer
      PaperProps={{
        sx: drawerPaperStyle,
      }}
      sx={drawerContainerStyle}
      variant="permanent"
    >
      {children}
    </Drawer>
  );
}

export default SideDrawer;
