import { SVGProps } from 'react';

function EyeIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.205 8.745C22.3229 6.46324 20.7915 4.48996 18.8001 3.06906C16.8087 1.64817 14.4446 0.84193 12 0.75C9.55537 0.84193 7.19128 1.64817 5.19989 3.06906C3.2085 4.48996 1.67711 6.46324 0.794986 8.745C0.735412 8.90978 0.735412 9.09022 0.794986 9.255C1.67711 11.5368 3.2085 13.51 5.19989 14.9309C7.19128 16.3518 9.55537 17.1581 12 17.25C14.4446 17.1581 16.8087 16.3518 18.8001 14.9309C20.7915 13.51 22.3229 11.5368 23.205 9.255C23.2646 9.09022 23.2646 8.90978 23.205 8.745ZM12 15.75C8.02499 15.75 3.82499 12.8025 2.30249 9C3.82499 5.1975 8.02499 2.25 12 2.25C15.975 2.25 20.175 5.1975 21.6975 9C20.175 12.8025 15.975 15.75 12 15.75Z"
        fill="currentColor"
      />
      <path
        d="M12 4.5C11.11 4.5 10.2399 4.76392 9.49992 5.25839C8.7599 5.75285 8.18312 6.45566 7.84253 7.27792C7.50194 8.10019 7.41282 9.00499 7.58645 9.87791C7.76009 10.7508 8.18867 11.5526 8.81801 12.182C9.44734 12.8113 10.2492 13.2399 11.1221 13.4135C11.995 13.5872 12.8998 13.4981 13.7221 13.1575C14.5443 12.8169 15.2471 12.2401 15.7416 11.5001C16.2361 10.76 16.5 9.89002 16.5 9C16.5 7.80653 16.0259 6.66193 15.182 5.81802C14.3381 4.97411 13.1935 4.5 12 4.5ZM12 12C11.4066 12 10.8266 11.8241 10.3333 11.4944C9.83993 11.1648 9.45541 10.6962 9.22835 10.1481C9.00128 9.59987 8.94188 8.99667 9.05763 8.41473C9.17339 7.83279 9.45911 7.29824 9.87867 6.87868C10.2982 6.45912 10.8328 6.1734 11.4147 6.05764C11.9967 5.94189 12.5999 6.0013 13.148 6.22836C13.6962 6.45542 14.1648 6.83994 14.4944 7.33329C14.824 7.82664 15 8.40666 15 9C15 9.79565 14.6839 10.5587 14.1213 11.1213C13.5587 11.6839 12.7956 12 12 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EyeIcon;
