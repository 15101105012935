import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

interface Props {
    onCloseClick: () => void;
    header?: string;
    subtitle?: string;
}

function ConfirmModal({ onCloseClick, header, subtitle }: Props) {

    const closeButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        closeButtonRef.current?.focus();
    }, [closeButtonRef.current]);

    return (
        <Stack data-testid="confirm-modal">
          <Stack>
            <Typography component="h1" variant="h2">
              { header }
            </Typography>
            <Typography component="p" variant="subtitle1" sx={{ mt: 1 }}>
              { subtitle }
            </Typography>
          </Stack>
          <Button ref={closeButtonRef} onClick={onCloseClick} fullWidth sx={{ mt: 3 }}>
            Close
          </Button>
        </Stack>
    );
}

export default ConfirmModal;