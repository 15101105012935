import stationApiAxios from 'app/api/station-api/station-api-axios';
import axios from 'axios';
import { resizeImage } from './resizeImage';

export const uploadHotelLogo = async (logo: File, hotelId?: string, firstLogin: boolean = false) => {
  const fileType = logo.type.split('/')[1].toUpperCase();

  const {
    data: { logo_url, upload_url },
  } = await stationApiAxios({
    url: `api/v1/aws/create_s3_hotel_logo_url${firstLogin ? `?hotel[id]=${hotelId}` : ''}`,
    method: 'POST',
    data: {
      media_file_extension: fileType,
    },
  });

  const resizedLogo = await resizeImage({ image: logo, quality: 0.8 });

  await axios.put(upload_url, resizedLogo, {
    headers: {
      'Content-Type': logo.type,
    },
  });

  return logo_url;
};
