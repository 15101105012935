import { formatNumberToPrice } from 'app/lib/numbers';
import { formatToBeCollectedString, formatTransactionDate } from '../lib/reservationDetail';
import {
  InvoiceLine,
  ApiReservationTransaction,
  Reservation,
  InvoiceLineStatus,
  InvoiceFee,
  ScheduledTransactionStatus,
  ApiReservationTransactionStatus,
  ReservationPaymentScheduleScheduledTransaction,
} from '../types';
import { getChargeTransactions, getReservationCurrency, getTransactionIdText } from './data';

export const getRoomChargeInvoiceLine = (allInvoiceLines: InvoiceLine[]): InvoiceLine | undefined => {
  const roomChargeInvoiceLine = allInvoiceLines[0];
  return roomChargeInvoiceLine;
};

export const getRoomChargeTransactions = (roomChargeInvoiceLine?: InvoiceLine) => {
  const roomChargeTransactions = roomChargeInvoiceLine?.transactions || [];
  return roomChargeTransactions;
};

export const getRoomChargesTotalAmount = (roomChargeInvoiceLine?: InvoiceLine) => {
  const roomChargeChargesTotalAmount = roomChargeInvoiceLine?.amount || 0;
  return roomChargeChargesTotalAmount;
};

export const getRoomChargeScheduledTransactions = (apiData: Reservation) => {
  const roomChargeScheduledTransactions = apiData?.payment_schedule?.scheduled_transactions;
  return roomChargeScheduledTransactions;
};

export type ReservationChargeUiLineItem = {
  amount: string;
  name: string;
};

export type ReservationChargeUi = {
  id: string;
  name: string;
  status: InvoiceLineStatus | null;
  price: string;
  lineItems: ReservationChargeUiLineItem[];
  createdAt: string;
  transactionItems: TransactionBreakdownItem[];
};

export const getFormattedReservationChargeFees = (
  currency: string,
  fees: InvoiceFee[] = [],
): ReservationChargeUiLineItem[] => {
  const formattedFees = fees.map((fee) => ({
    name: fee.name,
    amount: formatNumberToPrice({
      price: fee.amount / 100,
      currency,
    }),
  }));

  return formattedFees;
};

export type RefundActionPayload = {
  chargeId: string;
  chargeAmount: number;
  chargeCurrency: string;
  chargeDescription: string;
};

export type TriggerPaymentLinkActionPayload = {
  scheduledTransactionId: string;
};

export type ViewReceiptActionPayload = {
  chargeId: string;
  hotelId: string;
};

export type TransactionBreakdownItem = {
  name: string;
  description: string;
  status: ScheduledTransactionStatus | ApiReservationTransactionStatus;
  amount: string;
  associatedTransactionId?: string;
  refundAction?: RefundActionPayload;
  paymentLinkAction?: TriggerPaymentLinkActionPayload;
  viewReceiptAction?: ViewReceiptActionPayload;
};

export const getChargeTransactionBreakdownItem = (
  ct: ApiReservationTransaction,
  parentStatus: InvoiceLineStatus,
): TransactionBreakdownItem => {
  function getDescriptionPrefix() {
    if (ct.status === 'SUCCESSFUL') return 'PAID ON';
    if (ct.status === 'FAILED') return 'FAILED ON';
    return 'SCHEDULED FOR';
  }

  const currency = ct.currency || 'USD';
  const descriptionPre = getDescriptionPrefix();
  const formattedDate = formatToBeCollectedString(ct.created_at);
  const fullDescription = `${descriptionPre} ${formattedDate}`;
  const priceFromCents = ct.amount / 100;

  const item: TransactionBreakdownItem = {
    name: 'Deposit',
    description: fullDescription,
    associatedTransactionId: `#${getTransactionIdText(ct.id)}`,
    status: ct.status!,
    amount: formatNumberToPrice({
      price: priceFromCents,
      currency,
    }),
  };

  if (ct.status === 'SUCCESSFUL' && ct.amount > 0) {
    item.refundAction = {
      chargeId: ct.id,
      chargeAmount: ct.amount,
      chargeCurrency: currency,
      chargeDescription: ct.description,
    };

    item.viewReceiptAction = {
      chargeId: ct.id,
      hotelId: ct.hotel.id,
    };
  }

  // only show payment link action if invoice is marked as failed
  if (ct.status === 'FAILED' && parentStatus === 'FAILED') {
    const splitDescription = ct.description.split(' ');
    const scheduledTransactionId = splitDescription[splitDescription.length - 1];
    item.paymentLinkAction = {
      scheduledTransactionId,
    };
  }

  return item;
};

export const getScheduledTransactionBreakdownItem = (st: ReservationPaymentScheduleScheduledTransaction) => {
  const currency = st.currency || 'USD';
  const formattedDate = st.run_at ? formatToBeCollectedString(st.run_at) : '';

  const fullDescription =
    st.status === 'ABANDONED'
      ? `CANCELED ON ${formatToBeCollectedString(st.updated_at!)}`
      : `SCHEDULED FOR ${formattedDate}`;

  const priceFromCents = st.amount / 100;

  const item: TransactionBreakdownItem = {
    name: 'Deposit',
    description: fullDescription,
    status: st.status!,
    amount: formatNumberToPrice({
      price: priceFromCents,
      currency,
    }),
  };

  return item;
};

export const buildRoomChargeUi = (
  roomChargeInvoiceLine?: InvoiceLine,
  scheduledTransactions: ReservationPaymentScheduleScheduledTransaction[] = [],
): ReservationChargeUi | null => {
  if (!roomChargeInvoiceLine) return null;

  const currency = getReservationCurrency(roomChargeInvoiceLine);
  const roomPriceFromCents = roomChargeInvoiceLine.amount / 100;

  const subtotalLineItem: ReservationChargeUiLineItem = {
    name: 'Subtotal',
    amount: formatNumberToPrice({
      price: roomChargeInvoiceLine.subtotal / 100,
      currency,
    }),
  };

  const chargeTransactionsToShow = getChargeTransactions(roomChargeInvoiceLine.transactions).filter((trx) => {
    if (trx.amount <= 0) return false;
    // hide failed charge transactions if the room charge invoice line is not failed
    if (trx.status === 'FAILED' && roomChargeInvoiceLine.status !== 'FAILED') return false;

    return true;
  });

  const scheduledTransactionsToShow = scheduledTransactions.filter(
    (st) => st.status === 'PENDING' || st.status === 'ABANDONED',
  );

  const chargeTransactionsToTransactionBreakdownItems: TransactionBreakdownItem[] =
    chargeTransactionsToShow.map((ct) => getChargeTransactionBreakdownItem(ct, roomChargeInvoiceLine.status));

  const scheduledTransactionsToTransactionBreakdownItems: TransactionBreakdownItem[] =
    scheduledTransactionsToShow.map((st) => getScheduledTransactionBreakdownItem(st));

  const roomChargeUi = {
    id: roomChargeInvoiceLine.id,
    name: roomChargeInvoiceLine.name,
    status: roomChargeInvoiceLine.status,
    price: formatNumberToPrice({
      price: roomPriceFromCents,
      currency,
    }),
    lineItems: [subtotalLineItem, ...getFormattedReservationChargeFees(currency, roomChargeInvoiceLine.fees)],
    createdAt: formatTransactionDate(roomChargeInvoiceLine.created_at),
    transactionItems: [
      ...chargeTransactionsToTransactionBreakdownItems,
      ...scheduledTransactionsToTransactionBreakdownItems,
    ],
  };

  return roomChargeUi;
};
