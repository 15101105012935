import { SVGProps } from 'react';

function PeopleIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
            data-testid="people-icon"
            {...props}>
            <path d="M17.75 17.75H16.5V14.625C16.4991 13.7965 16.1695 13.0022 15.5837 12.4163C14.9978 11.8305 14.2035 11.5009 13.375 11.5V10.25C14.5349 10.2513 15.647 10.7126 16.4672 11.5328C17.2874 12.353 17.7487 13.4651 17.75 14.625V17.75Z" fill="#212529" />
            <path d="M12.75 17.75H11.5V14.625C11.499 13.7965 11.1695 13.0022 10.5836 12.4164C9.99779 11.8305 9.2035 11.501 8.375 11.5H4.625C3.7965 11.501 3.00221 11.8305 2.41637 12.4164C1.83053 13.0022 1.50098 13.7965 1.5 14.625V17.75H0.25V14.625C0.251356 13.4651 0.712728 12.3531 1.53291 11.5329C2.35308 10.7127 3.46509 10.2514 4.625 10.25H8.375C9.53491 10.2514 10.6469 10.7127 11.4671 11.5329C12.2873 12.3531 12.7486 13.4651 12.75 14.625V17.75Z" fill="#212529" />
            <path d="M11.5 0.25V1.5C12.3288 1.5 13.1237 1.82924 13.7097 2.41529C14.2958 3.00134 14.625 3.7962 14.625 4.625C14.625 5.4538 14.2958 6.24866 13.7097 6.83471C13.1237 7.42076 12.3288 7.75 11.5 7.75V9C12.6603 9 13.7731 8.53906 14.5936 7.71859C15.4141 6.89812 15.875 5.78532 15.875 4.625C15.875 3.46468 15.4141 2.35188 14.5936 1.53141C13.7731 0.710936 12.6603 0.25 11.5 0.25Z" fill="#212529" />
            <path d="M6.5 1.5C7.11807 1.5 7.72225 1.68328 8.23616 2.02666C8.75006 2.37004 9.1506 2.8581 9.38712 3.42911C9.62365 4.00013 9.68553 4.62847 9.56495 5.23466C9.44438 5.84085 9.14675 6.39767 8.70971 6.83471C8.27267 7.27175 7.71585 7.56938 7.10966 7.68995C6.50347 7.81053 5.87513 7.74865 5.30411 7.51212C4.7331 7.2756 4.24504 6.87506 3.90166 6.36116C3.55828 5.84725 3.375 5.24307 3.375 4.625C3.375 3.7962 3.70424 3.00134 4.29029 2.41529C4.87634 1.82924 5.6712 1.5 6.5 1.5ZM6.5 0.25C5.63471 0.25 4.78885 0.506589 4.06938 0.98732C3.34992 1.46805 2.78916 2.15133 2.45803 2.95076C2.12689 3.75019 2.04025 4.62985 2.20906 5.47852C2.37787 6.32719 2.79455 7.10674 3.40641 7.71859C4.01826 8.33045 4.79781 8.74712 5.64648 8.91594C6.49515 9.08475 7.37481 8.99811 8.17424 8.66697C8.97367 8.33584 9.65695 7.77508 10.1377 7.05562C10.6184 6.33615 10.875 5.49029 10.875 4.625C10.875 3.46468 10.4141 2.35188 9.59359 1.53141C8.77312 0.710936 7.66032 0.25 6.5 0.25Z" fill="#212529" />
        </svg >
    );
}

export default PeopleIcon;
