import { Chip as MuiChip, SxProps, Typography } from '@mui/material';

function Chip({
  bgColor,
  fontColor,
  status,
  border,
  dataTestId,
  sx,
  cxPostfix = ''
}: {
  bgColor: string;
  fontColor: string;
  status: string;
  border?: string;
  dataTestId?: string;
  sx?: SxProps;
  cxPostfix?: string;
}) {
  const statusClass = status ? status.replaceAll(' ', '-') : 'UNKNOWN';
  return (
    <MuiChip
      data-testid={dataTestId}
      className={`table-status-chip-${statusClass}${cxPostfix}`}
      sx={{
        backgroundColor: bgColor,
        width: 'fit-content',
        borderRadius: '4px',
        textAlign: 'center',
        height: '28px',
        border,
        '& .MuiChip-label': {
          padding: 1,
        },
        ...sx,
      }}
      label={
        <Typography
          variant="h6"
          fontWeight={450}
          letterSpacing="0.04em"
          lineHeight="12px"
          textTransform="uppercase"
          color={fontColor}
        >
          {status}
        </Typography>
      }
    />
  );
}

export default Chip;
