import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import { useUser } from 'app/features/authentication/context/useUser';
import { useState } from 'react';
import useNewCharge from '../api/useNewCharge';
import { invalidateReservationDetailsQuery } from '../api/useReservationDetail';
import { CardOnRecord, NewChargeRequestPayload } from '../types';
import NewChargeModalConfirm from './NewChargeModalConfirm';
import NewChargeModalForm from './NewChargeModalForm';

interface Props {
  reservationId: string;
  open: boolean;
  onClose: () => void;
  paymentMethods: CardOnRecord[] | null;
}

function NewChargeModal({ open, onClose, reservationId, paymentMethods }: Props) {
  const { currentHotelId } = useUser();
  const { mutate, error, isLoading, reset } = useNewCharge(currentHotelId!, reservationId);
  const [screen, setScreen] = useState<'form' | 'confirm'>('form');

  const handleModalClose = () => {
    onClose();
    reset();
    /* istanbul ignore next */
    setTimeout(() => {
      setScreen('form');
    }, 500);
  };

  const handleSubmit = (newChargeRequestPayload: NewChargeRequestPayload) => {
    mutate(newChargeRequestPayload, {
      onSuccess: (response) => {
        if (response.status === 201 && response.data.status === 'PENDING') {
          throw new Error('3D Secure authentication required');
        }
        setScreen('confirm');
        invalidateReservationDetailsQuery(reservationId, currentHotelId!);
      },
    });
  };

  return (
    <SelfbookDialog data-testid="new-charge-modal" open={open} onClose={handleModalClose}>
      <SelfbookDialog.Title
        data-testid="new-charge-modal-title"
        onClose={handleModalClose}
        aria-label="New Charge"
      >
        New Charge
      </SelfbookDialog.Title>
      <SelfbookDialog.Content data-testid="new-charge-modal-content" sx={{ width: '460px' }}>
        {screen === 'form' && (
          <NewChargeModalForm
            onSubmit={handleSubmit}
            submitError={error}
            isLoading={isLoading}
            paymentMethods={paymentMethods}
          />
        )}
        {screen === 'confirm' && <NewChargeModalConfirm onCloseClick={handleModalClose} />}
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default NewChargeModal;
