import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import { useUser } from 'app/features/authentication/context/useUser';
import { useState } from 'react';
import useRefundCharge from '../api/useRefundCharge';
import { invalidateReservationDetailsQuery } from '../api/useReservationDetail';
import { RefundActionPayload } from '../sidebar-layer/roomCharge';
import RefundModalConfirm from './RefundModalConfirm';
import RefundModalForm from './RefundModalForm';

interface Props {
  reservationId: string;
  chargeToRefund?: RefundActionPayload | null;
  open: boolean;
  onClose: () => void;
}

function RefundChargeModal({ chargeToRefund, open, onClose, reservationId }: Props) {
  const { currentHotelId } = useUser();
  const { mutate, error, isLoading, reset } = useRefundCharge(currentHotelId!, reservationId);
  const [screen, setScreen] = useState<'form' | 'confirm'>('form');

  const handleModalClose = () => {
    onClose();
    reset();
    /* istanbul ignore next */
    setTimeout(() => {
      setScreen('form');
    }, 500);
  };

  if (!chargeToRefund) return null;

  const handleSubmit = (amount: number) => {
    mutate(
      {
        id: chargeToRefund.chargeId,
        amount,
        description: chargeToRefund.chargeDescription,
      },
      {
        onSuccess: () => {
          setScreen('confirm');
          invalidateReservationDetailsQuery(reservationId, currentHotelId!);
        },
      },
    );
  };

  return (
    <SelfbookDialog data-testid="refund-charge-modal" open={open} onClose={handleModalClose}>
      <SelfbookDialog.Title data-testid="refund-charge-modal-title" onClose={handleModalClose}>
        Refund Charge
      </SelfbookDialog.Title>
      <SelfbookDialog.Content data-testid="refund-charge-modal-content" sx={{ width: '460px' }}>
        {screen === 'form' && (
          <RefundModalForm
            chargeToRefund={chargeToRefund}
            onSubmit={handleSubmit}
            submitError={error}
            isLoading={isLoading}
          />
        )}
        {screen === 'confirm' && <RefundModalConfirm onCloseClick={handleModalClose} />}
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default RefundChargeModal;
