import stationApiAxios from 'app/api/station-api/station-api-axios';
import { User } from 'app/features/user/types';
import { AxiosResponse } from 'axios';
import { getDecodedJwt } from './lib';

export const getCurrentUserFn = (): Promise<AxiosResponse<User | null>> => {
  return stationApiAxios({
    method: 'GET',
    url: `/rest/v1/users/me`,
  });
};

export const getUserIdFromJwt = () => {
  const decodedJwt = getDecodedJwt();
  const userId = decodedJwt?.user?.id;
  return userId;
};
