import { Box, Button, Typography } from '@mui/material';
import SelfbookCheckbox from 'app/components/selfbook-checkbox/SelfbookCheckbox';
import { FiltersOptions, PaymentStatusFilters } from 'app/features/reservations/types';
import colors from 'app/styles/colors';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

interface Props {
  label: string;
  filterKey: string;
  onApply: (args: FiltersOptions) => void;
  filterCriteria: FiltersOptions | undefined;
}

export enum PAYMENT_STATUS_FILTER_VALUES {
  failed = 'failed',
  partial = 'partial',
  paid = 'paid',
  unpaid = 'unpaid',
}

export default function PaymentStatus({ label, filterKey, onApply, filterCriteria }: Props) {
  const [criteria, setCriteria] = useState<PaymentStatusFilters>({
    failed: filterCriteria?.paymentStatus?.failed || false,
    partial: filterCriteria?.paymentStatus?.partial || false,
    paid: filterCriteria?.paymentStatus?.paid || false,
    unpaid: filterCriteria?.paymentStatus?.unpaid || false,
  });

  const handleCheckBoxChange = (field: string, checked: boolean) => {
    setCriteria({ ...criteria, [field]: checked });
  };

  return (
    <Box sx={{ width: '100%' }} key={filterKey} data-testid={`${filterKey}-filter`}>
      <Box
        data-testid="payment-status-filter-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${colors.grey[200]}`,
          p: 2,
        }}
      >
        <Typography
          fontSize="16px"
          fontWeight={450}
          lineHeight="100%"
          letterSpacing="0.026px"
          color={colors.grey[700]}
        >
          {label}
        </Typography>
        <Button
          variant="textMuted"
          data-testid={`${filterKey}-clear-button`}
          sx={{ minWidth: 'fit-content', textDecorationColor: colors.grey[500] }}
          onClick={() => {
            onApply({
              [filterKey]: undefined,
            });
            setCriteria({
              failed: false,
              partial: false,
              paid: false,
              unpaid: false,
            });
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight={450}
            lineHeight="100%"
            letterSpacing="0.022px"
            color={colors.grey[500]}
          >
            Clear
          </Typography>
        </Button>
      </Box>
      <Box
        data-testid="payment-status-filter-content"
        sx={{ p: 2, borderBottom: `1px solid ${colors.grey[200]}` }}
      >
        <Box sx={{ '> :not(:last-child)': { mb: 2 }, display: 'flex', flexDirection: 'column' }}>
          {Object.keys(PAYMENT_STATUS_FILTER_VALUES).map((status: string) => {
            return (
              <SelfbookCheckbox
                key={status}
                checked={criteria[status]}
                onChange={(e) => handleCheckBoxChange(status, e.target.checked)}
                name={status}
                sx={{ color: 'gray.700', m: 0, p: 0, '> span': { p: '0!important' } }}
                label={
                  <Typography
                    fontSize="14px"
                    fontWeight="450"
                    lineHeight="100%"
                    letterSpacing="0.14px"
                    color={colors.grey[700]}
                    ml={1}
                  >
                    {capitalize(status)}
                  </Typography>
                }
                data-testid={`${status}-filter-checkbox`}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Button
          onClick={() => {
            onApply({
              paymentStatus: criteria,
            });
          }}
          fullWidth
        >
          <Typography fontSize="14px" fontWeight="450" lineHeight="100%" letterSpacing="0.56px">
            Apply
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
