export enum LayersPaths {
  TopLevel = '_layers_',
  Rooms = 'rooms',
  RoomDetail = ':id',
  RoomDetailImages = 'images',
  RoomDetailInformation = 'information',
  Settings = 'settings',
  GeneralSettings = 'general',
  GuestSettings = 'guests',
  Workflows = 'workflows',
  WorkflowDetail = ':id',
  WorkflowDetailTheme = 'theme',
}
