import stationApiAxios from 'app/api/station-api/station-api-axios';
import { uploadHotelLogo } from 'app/lib/upload-logo';
import { useMutation } from 'react-query';
import { AddOrEditHotel } from '../../invoices/types';

export const useEditHotel = (hotelId: string, firstLogin: boolean = false) => {
  const mutation = useMutation(async (request: AddOrEditHotel) => {
    let logo_url = request.logo as string;

    if (typeof request.logo === 'object') {
      logo_url = await uploadHotelLogo(request.logo, hotelId, firstLogin);
    }

    const createHotelRequest = {
      ...request,
      logo_url,
    };

    return stationApiAxios({
      url: `rest/v1/hotels/${hotelId}`,
      method: 'PATCH',
      data: createHotelRequest,
    });
  });

  return mutation;
};
