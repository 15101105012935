import { SVGProps } from 'react';
import SrOnlyText from '../sr-only-text/SrOnlyText';

function DinersIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="diners-icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V24C0 25.1046 0.89543 26 2 26H32C33.1046 26 34 25.1046 34 24V2C34 0.895431 33.1046 0 32 0H2ZM4.06201 13.1255C4.06201 7.48448 8.64101 2.92285 14.2647 2.92285C19.9058 2.92285 24.4673 7.48448 24.4674 13.1255C24.4674 18.7491 19.9058 23.3282 14.2647 23.3282C8.64101 23.3282 4.06201 18.7491 4.06201 13.1255ZM7.8053 13.1255C7.8053 15.8764 9.52898 18.2269 11.9491 19.1495V7.08399C9.52898 8.02421 7.8053 10.3746 7.8053 13.1255ZM16.5803 7.08399V19.1671C19.0004 18.2269 20.7241 15.8764 20.7241 13.1255C20.7241 10.3745 19.0004 8.02421 16.5803 7.08399ZM14.2473 24.2857H19.4879C25.5816 24.2857 31.1357 19.3237 31.1357 13.2474C31.1357 6.59644 25.5816 2 19.4879 2H14.2473C8.08384 2 3 6.59644 3 13.2474C3 19.3237 8.08384 24.2857 14.2473 24.2857Z"
        fill="#212529"
      />
      <SrOnlyText>Diners Card</SrOnlyText>
    </svg>
  );
}

export default DinersIcon;
