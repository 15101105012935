import { formatNumberToPrice } from 'app/lib/numbers';
import { formatTransactionDate, formatToBeCollectedString } from '../lib/reservationDetail';
import { ApiReservationTransaction } from '../types';
import { getTransactionIdText } from './data';
import { ReservationChargeUi, ReservationChargeUiLineItem } from './roomCharge';

export const getRefundTransactions = (transactions: ApiReservationTransaction[] = []) => {
  const refunds = transactions.filter((t) => t.transaction_type === 'REFUND');
  return refunds;
};

export type RefundsUi = {
  refunds: ReservationChargeUi[];
};

export type BuildRefundsUiOptions = {
  isRoomChargeRefund?: boolean;
};

export const buildRefundsUiItems = (
  refunds: ApiReservationTransaction[],
  options: BuildRefundsUiOptions = { isRoomChargeRefund: false },
): ReservationChargeUi[] => {
  const refundsUiItems: ReservationChargeUi[] = refunds.map((refund) => {
    const priceFromCents = refund.amount / 100;
    const currency = refund.currency || 'USD';

    const amountLineItem: ReservationChargeUiLineItem = {
      name: 'Total',
      amount: `- ${formatNumberToPrice({
        price: priceFromCents,
        currency,
      })}`,
    };

    const name = options.isRoomChargeRefund ? 'Room Charge' : refund.description;
    const associatedTransactionId = refund.id;

    return {
      id: refund.id,
      name,
      status: null,
      price: `- ${formatNumberToPrice({
        price: priceFromCents,
        currency,
      })}`,
      lineItems: [amountLineItem],
      createdAt: formatTransactionDate(refund.created_at),
      transactionItems: [
        {
          name: 'Refund',
          associatedTransactionId: `#${getTransactionIdText(associatedTransactionId)}`,
          description: `REFUNDED ON ${formatToBeCollectedString(refund.created_at)}`,
          status: refund.status!,
          amount: `- ${formatNumberToPrice({
            price: priceFromCents,
            currency,
          })}`,
          viewReceiptAction: {
            chargeId: refund.id,
            hotelId: refund.hotel.id,
          },
        },
      ],
    };
  });

  return refundsUiItems;
};

export const buildRefundsUi = (refundsUiItems: ReservationChargeUi[]): RefundsUi => {
  return { refunds: refundsUiItems };
};
