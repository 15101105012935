import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReactRouterLinkButton from 'app/components/react-router-link-button/ReactRouterLinkButton';
import { AuthenticationPaths } from 'app/routing/route-paths';

interface Props {
  submittedEmail?: string;
}

function ResetPasswordRequestReceived({ submittedEmail }: Props) {
  return (
    <Stack sx={{ maxWidth: '400px' }} data-testid="reset-password-request-received">
      <Stack>
        <Typography
          component="h1"
          variant="h2"
          fontFamily="PP Neue Montreal"
          fontSize="34px"
          lineHeight="38px"
        >
          Your password reset has been requested.
        </Typography>
        <Typography component="p" sx={{ mt: 2 }} fontWeight="300" fontSize="18px" lineHeight="1.3">
          If an account exists for {submittedEmail}, <br /> an e-mail will be sent with further instructions.
        </Typography>
      </Stack>

      <ReactRouterLinkButton to={`../${AuthenticationPaths.Login}`} fullWidth sx={{ mt: 4 }}>
        Back to Login
      </ReactRouterLinkButton>
    </Stack>
  );
}

export default ResetPasswordRequestReceived;
