const HAS_SEEN_PROMPT_KEY = 'avocado';

export const setHasSeenStripePromptInSession = (value: 'true' | 'false') => {
  sessionStorage.setItem(HAS_SEEN_PROMPT_KEY, value);
};

export const getHasSeenStripePromptInSession = () => {
  const key = sessionStorage.getItem(HAS_SEEN_PROMPT_KEY);
  return key && key === 'true';
};

export const clearHasSeenStripePromptInSession = () => {
  sessionStorage.removeItem(HAS_SEEN_PROMPT_KEY);
};
