import { useUser } from 'app/features/authentication/context/useUser';
import { clearAllLocalStorageUserData, getJwtIsExpired } from 'app/features/authentication/lib/lib';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaths, TopLevelPaths } from '../route-paths';

const useUnAuthRedirect = () => {
  const navigate = useNavigate();
  const { clearCurrentUser } = useUser();
  useEffect(() => {
    const jwtExpired = getJwtIsExpired();
    if (jwtExpired) {
      clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
      navigate(`../${TopLevelPaths.Authentication}/${AuthenticationPaths.Login}`, { replace: true });
    }
  }, []);
};

export default useUnAuthRedirect;
