export const getInitials = (userName?: string, userEmail?: string) => {
    let initials: string = 'U';

    const splitName = (userName || userEmail)?.split(' ') ?? '';

    if (splitName && splitName.length === 1) {
      const name = splitName[0];
      initials = `${name[0]}`;
    } else if (splitName && splitName.length > 1) {
      const firstName = splitName[0];
      const lastName = splitName[1];

      initials = `${firstName[0]}${lastName[0]}`;
    }

    return initials;
  };