import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useMutation } from 'react-query';
import { ReservationPaymentScheduleScheduledTransaction } from '../types';

interface UseStopPaymentAutomation {
  scheduledTransactions: ReservationPaymentScheduleScheduledTransaction[];
}

export const useStopPaymentAutomation = (hotelId: string) => {
  const mutation = useMutation(({ scheduledTransactions }: UseStopPaymentAutomation) => {
    const promises: any = scheduledTransactions.map((transaction) => {
      const payload = {
        status: 'ABANDONED',
      };

      return stationApiAxios({
        method: 'PATCH',
        url: `/rest/v1/hotels/${hotelId}/reservations/${transaction.reservation.id}/scheduled_transactions/${transaction.id}`,
        data: payload,
      });
    });

    return Promise.all(promises);
  });

  return mutation;
};
