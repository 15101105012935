import { FEE_TYPES } from 'app/features/selfbook-admin/invoices/constants';
import { InvoiceCategoryFee } from 'app/features/selfbook-admin/invoices/types';
import { ApiReservationTransaction, InvoiceLine, MappedInvoiceLine } from '../types';

export const getTotalNewChargeAmount = (amount: number, fees?: InvoiceCategoryFee[]): number => {
  let total = amount;

  fees?.forEach((fee) => {
    if (fee.fee_type === FEE_TYPES.FLAT_FEE) {
      total += fee.amount / 100;
    } else {
      total += (amount * fee.amount) / 100;
    }
  });
  return total;
};

export const computeFeeAdditionalPrice = ({
  isFlatFee,
  fee,
  amount,
}: {
  isFlatFee: boolean;
  fee: InvoiceCategoryFee;
  amount: string;
}) => {
  return isFlatFee ? Number(fee.amount / 100) : Number(fee.amount / 100) * Number(amount);
};

export const shouldShowRefundPrompt = (invoiceLine: MappedInvoiceLine) => {
  if (!invoiceLine.chargeTransactionToRefundAgainst) return false;
  if (!invoiceLine.chargeTransactionToRefundAgainst.spreedly?.payment_method_token) return false;

  return true;
};

export const getTotalAmountForSuccessfulTransactions = (transactions: ApiReservationTransaction[] = []) => {
  const totalAmount: number = transactions.reduce((total: number, trx: ApiReservationTransaction) => {
    if (trx.transaction_type === 'CHARGE' && trx.status === 'SUCCESSFUL') {
      return total + trx.amount;
    }

    return total;
  }, 0);

  return totalAmount;
};

export const getChargePaidAmount = (invoiceLine: InvoiceLine) => {
  const paidAmount = getTotalAmountForSuccessfulTransactions(invoiceLine.transactions);
  return paidAmount;
};

export const getChargeOutstandingAmount = (invoiceLine: InvoiceLine) => {
  const totalAmount = invoiceLine.amount;
  const paidAmount = getChargePaidAmount(invoiceLine);
  return totalAmount - paidAmount;
};
