import { MainPaths } from 'app/routing/route-paths';
import { useUser } from '../authentication/context/useUser';
import { getTempCsmAccessToken } from '../authentication/lib/lib';
import { useHotelQuery } from '../hotel/api/useHotel';
import { CRSType } from '../hotel/types';
import { UserPermissionRole } from '../user/lib/userRoles';

const usePermissions = () => {
  const { currentUserHotelRole, currentHotelId, firstFetchComplete: userFetched, currentUser } = useUser();
  const { data: hotel, isFetched: hotelFetched } = useHotelQuery(currentHotelId || '');

  const userIsSelfbookAdmin = currentUserHotelRole === UserPermissionRole.SELFBOOK_CSM;

  const userIsMasqueradingAdmin =
    currentUserHotelRole !== UserPermissionRole.SELFBOOK_CSM && !!getTempCsmAccessToken();

  // istanbul ignore next
  const isFetchingPermissions =
    !userFetched || (!hotelFetched && userFetched && !!currentUser && !userIsSelfbookAdmin);

  const isOperaHotel = hotel?.crs_type === CRSType.OPERA;
  const isStationHotel = hotel?.crs_type === CRSType.STATION;
  const isSynxisHotel = hotel?.crs_type === CRSType.SYNXIS;
  const isTravelClickHotel = hotel?.crs_type === CRSType.TRAVELCLICK;

  const hasBookingsAccess = isOperaHotel || isSynxisHotel || isTravelClickHotel;
  const hasPaymentProcessing = !!isOperaHotel || !!isStationHotel || !!isSynxisHotel || isTravelClickHotel;
  const hasPayouts = !!isOperaHotel || !!isStationHotel || !!isSynxisHotel || isTravelClickHotel;
  const hasContentManagement = !!isOperaHotel;

  const getAllowedPaths = () => {
    const allowedPaths = [];

    allowedPaths.push(MainPaths.Reservations);

    if (hasPayouts) {
      allowedPaths.push(MainPaths.Payouts);
    }

    allowedPaths.push(MainPaths.PaymentLinksManagement);

    if (hasContentManagement) {
      allowedPaths.push(MainPaths.Content);
    }

    return allowedPaths;
  };

  const allowedPaths = getAllowedPaths();
  const showStripeSetup = !!hasPaymentProcessing;

  return {
    isFetchingPermissions,
    userIsSelfbookAdmin,
    isOperaHotel,
    isSynxisHotel,
    currentUserHotelRole,
    userIsMasqueradingAdmin,
    allowedPaths,
    showStripeSetup,
    hasBookingsAccess,
  };
};

export default usePermissions;
