import { SVGProps } from 'react';

function RoomIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-testid="room-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="user--multiple 2" clipPath="url(#clip0_2905_74398)">
        <g id="Vector">
          <path
            d="M11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6Z"
            fill="#495057"
          />
          <path
            d="M11.8 1.2C11.4 1.05 10.95 1 10.5 1C8 1 6 3 6 5.5C6 5.95 6.05 6.4 6.2 6.8L1 12V15H4L9.2 9.8C9.65 9.95 10.05 10 10.5 10L10.55 9.15L10.65 9C10.6 9 10.55 9 10.5 9C10.15 9 9.8 8.95 9.5 8.85L8.95 8.7L8.5 9.1L6.9 10.7L6.2 10L5.5 10.7L6.2 11.4L5.4 12.2L4.7 11.5L4 12.2L4.7 12.9L3.6 14H2V12.4L7.3 7.1L7.15 6.5C6.6 4.65 7.65 2.7 9.5 2.15C11.35 1.6 13.3 2.65 13.85 4.5C14.2 5.6 13.95 6.75 13.3 7.6L14 8.35C14.35 7.9 14.65 7.4 14.85 6.8C15.55 4.45 14.2 1.9 11.8 1.2Z"
            fill="#495057"
          />
          <path
            d="M12.5 10C12.7761 10 13 9.77614 13 9.5C13 9.22386 12.7761 9 12.5 9C12.2239 9 12 9.22386 12 9.5C12 9.77614 12.2239 10 12.5 10Z"
            fill="#495057"
          />
          <path
            d="M11 14V9.2L12.5 7.5L14 9.2V14H11ZM15 12V9.2C15 8.95 14.9 8.75 14.75 8.55L13.25 6.85C12.9 6.45 12.25 6.4 11.85 6.75C11.8 6.8 11.8 6.8 11.75 6.85L10.25 8.55C10.1 8.75 10 8.95 10 9.2V14C10 14.55 10.45 15 11 15H14C14.55 15 15 14.55 15 14V13"
            fill="#495057"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2905_74398">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RoomIcon;
