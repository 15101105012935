import { Box, Button, Typography } from '@mui/material';
import CloseIcon from 'app/components/icons/CloseIcon';
import WarningIcon from 'app/components/icons/WarningIcon';
import colors from 'app/styles/colors';
import { useReservationDetailContext } from '../context/useReservationDetailContext';

interface Props {
  onCloseClick: () => void;
}

export default function ReservationDetailsPanelHeader({ onCloseClick }: Props) {
  const {
    headerUi: { customerName },
    bookingStatus,
  } = useReservationDetailContext();

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight={450} lineHeight="22px" fontSize="22px" color={colors.grey[700]}>
            {customerName}
          </Typography>
          {bookingStatus === 'CANCELED' && (
            <Box
              sx={{
                px: '9px',
                py: '6px',
                backgroundColor: colors.error[200],
                ml: 2,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
              data-testid="reservation-side-panel-booking-status"
            >
              <WarningIcon />
              <Typography
                fontSize="14px"
                fontWeight="450"
                letterSpacing="0.28px"
                color={colors.error[800]}
                ml="6px"
              >
                Canceled
              </Typography>
            </Box>
          )}
        </Box>
        <Button
          data-testid="close-side-reservation-button"
          onClick={onCloseClick}
          sx={{
            borderRadius: '4px',
            width: '44px',
            minWidth: 'unset',
            height: '44px',
            backgroundColor: colors.grey[200],
            display: 'flex',
            cursor: 'pointer',
            p: 0,
            m: 0,
            ':hover': { background: colors.grey[200] },
          }}
          aria-label="Close"
        >
          <CloseIcon style={{ margin: 'auto auto', color: 'black', width: '12px', height: '12px' }} />
        </Button>
      </Box>
    </Box>
  );
}
