import { SVGProps } from 'react';

function PhoneIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
      data-testid="phone-icon"
      {...props}>
      <path d="M15.2501 17.125H15.1438C2.86259 16.4187 1.11884 6.05625 0.875094 2.89375C0.855453 2.64786 0.884524 2.40052 0.96064 2.16588C1.03676 1.93125 1.15842 1.71394 1.31867 1.52641C1.47891 1.33888 1.67458 1.1848 1.89448 1.07302C2.11437 0.961244 2.35415 0.893951 2.60009 0.875001H6.04384C6.29419 0.874758 6.53885 0.949694 6.74611 1.0901C6.95338 1.23051 7.11371 1.42992 7.20634 1.6625L8.15634 4C8.24781 4.22722 8.27051 4.4763 8.22162 4.71631C8.17273 4.95631 8.05441 5.17667 7.88134 5.35L6.55009 6.69375C6.75804 7.87547 7.32396 8.96479 8.17128 9.81435C9.0186 10.6639 10.1064 11.2327 11.2876 11.4438L12.6438 10.1C12.8198 9.92885 13.0422 9.81328 13.2834 9.76771C13.5246 9.72214 13.7738 9.74859 14.0001 9.84375L16.3563 10.7875C16.5854 10.8831 16.7809 11.0446 16.9178 11.2517C17.0547 11.4587 17.1268 11.7018 17.1251 11.95V15.25C17.1251 15.7473 16.9275 16.2242 16.5759 16.5758C16.2243 16.9275 15.7474 17.125 15.2501 17.125ZM2.75009 2.125C2.58433 2.125 2.42536 2.19085 2.30815 2.30806C2.19094 2.42527 2.12509 2.58424 2.12509 2.75V2.8C2.41259 6.5 4.25634 15.25 15.2126 15.875C15.2947 15.8801 15.377 15.8689 15.4548 15.8421C15.5326 15.8153 15.6043 15.7734 15.6658 15.7188C15.7274 15.6642 15.7775 15.598 15.8135 15.524C15.8494 15.45 15.8703 15.3696 15.8751 15.2875V11.95L13.5188 11.0062L11.7251 12.7875L11.4251 12.75C5.98759 12.0688 5.25009 6.63125 5.25009 6.575L5.21259 6.275L6.98759 4.48125L6.05009 2.125H2.75009Z"
        fill="#495057" />
    </svg>
  );
}

export default PhoneIcon;
