import reactQueryClient from 'app/api/react-query/react-query-client';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useQuery } from 'react-query';
import { mapHotel } from '../lib/lib';
import { Hotel } from '../types';

export const invalidateHotelQuery = () => reactQueryClient.invalidateQueries(['Hotel']); // revisit
export const invalidateHotelByIdQuery = (hotelPublicId: string) =>
  reactQueryClient.invalidateQueries(['Hotel', hotelPublicId]);

export async function getHotel(hotelPublicId: string) {
  const response = await stationApiAxios({
    url: `rest/v1/hotels/${hotelPublicId}?expand[]=stripe&expand[]=spreedly`,
    method: 'GET',
  });
  const hotel = mapHotel(response.data);
  return hotel;
}

export const useHotelQuery = (hotelPublicId: string) => {
  const results = useQuery<Hotel>(
    ['Hotel', hotelPublicId],
    () => {
      return getHotel(hotelPublicId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: typeof hotelPublicId === 'string' && hotelPublicId.length > 0,
    },
  );
  return results;
};
