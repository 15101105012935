import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useQuery } from 'react-query';
import { PaymentLink, PaymentLinkTemplate1 } from 'app/features/payment-links/types';

export async function getPaymentLinksDetails(paymentLinksId: string) {
  const response = await stationApiAxios({
    url: `rest/v1/payment_links/${paymentLinksId}`,
    method: 'GET',
  });
  return response.data;
}

export const usePaymentLinksQuery = (paymentLinksId: string) => {
  const results = useQuery<PaymentLink<PaymentLinkTemplate1>>(
    ['PaymentLinks', paymentLinksId],
    () => {
      return getPaymentLinksDetails(paymentLinksId);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
  return results;
};
