import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import InputDatePicker from 'app/components/selfbook-date-picker/InputDatePicker';
import { selfbookRadioLabelStyle, selfbookRadioStyle } from 'app/components/selfbook-radio/styles';
import { formatDateFlow } from 'app/features/reservations/lib/reservationsTable';
import {
  ArrivalOrDepartCriteria,
  ArrivalOrDepartFilters,
  FiltersOptions,
} from 'app/features/reservations/types';
import useFocusFirstFormField from 'app/hooks/useFocusFirstFormField';
import useForm from 'app/hooks/useForm';
import { DateTimeStringObject } from 'app/lib/types';
import colors from 'app/styles/colors';
import { useRef } from 'react';

export const longDateOptions: DateTimeStringObject = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
} as DateTimeStringObject;
interface Props {
  label: string;
  filterKey: string;
  onApply: (args: FiltersOptions) => void;
  filterCriteria: FiltersOptions | undefined;
}

enum FormFields {
  criteria = 'criteria',
  exactDate = 'exactDate',
  dateAfter = 'dateAfter',
  firstDay = 'firstDay',
  lastDay = 'lastDay',
}

export default function ArrivalOrDepart({ label, filterKey, onApply, filterCriteria }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  useFocusFirstFormField(formRef);

  const filterValues = filterCriteria && (filterCriteria[filterKey] as ArrivalOrDepartFilters);
  const { values, errors, updateFieldValue, resetErrors, getIsFormValid, focusFirstFieldWithError } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.criteria]: filterValues?.criteria
          ? filterValues?.criteria
          : ('exact' as ArrivalOrDepartCriteria),
        [FormFields.exactDate]: filterValues?.exact ? filterValues.exact : undefined,
        [FormFields.dateAfter]: filterValues?.after ? filterValues?.after : undefined,
        [FormFields.firstDay]: filterValues?.between ? filterValues?.between?.firstDay : undefined,
        [FormFields.lastDay]: filterValues?.between ? filterValues?.between?.lastDay : undefined,
      },
      validations: {
        [FormFields.exactDate]: (val) => {
          if (!val && values.criteria === 'exact') return 'Date is required.';
          return true;
        },
        [FormFields.dateAfter]: (val) => {
          if (!val && values.criteria === 'after') return 'Date is required.';
          return true;
        },
        [FormFields.firstDay]: (val) => {
          if (!val && values.criteria === 'between') return 'Date is required.';
          return true;
        },
        [FormFields.lastDay]: (val) => {
          if (!val && values.criteria === 'between') return 'Date is required.';
          return true;
        },
      },
    },
  );

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const method = e.target.value as ArrivalOrDepartCriteria;
    updateFieldValue(FormFields.criteria, method);
  };

  const handleOnApply = () => {
    if (values.criteria === 'exact') {
      onApply({
        [filterKey]: {
          criteria: values.criteria,
          exact: values.exactDate,
        },
      });
    }

    if (values.criteria === 'after') {
      onApply({
        [filterKey]: {
          criteria: values.criteria,
          after: values.dateAfter,
        },
      });
    }

    if (values.criteria === 'between') {
      onApply({
        [filterKey]: {
          criteria: values.criteria,
          between: {
            firstDay: values.firstDay,
            lastDay: values.lastDay,
          },
        },
      });
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    handleOnApply();
  };

  return (
    <Box sx={{ width: '100%' }} key={filterKey} data-testid={`${filterKey}-filter`}>
      <Box
        data-testid={`arrival-depart-filter-header-${label}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${colors.grey[200]}`,
          p: 2,
        }}
      >
        <Typography
          fontSize="16px"
          fontWeight={450}
          lineHeight="100%"
          letterSpacing="0.026px"
          color={colors.grey[700]}
        >
          {label}
        </Typography>
        <Button
          variant="textMuted"
          sx={{
            minWidth: 'fit-content',
            textDecorationColor: colors.grey[500],
          }}
          data-testid={`${filterKey}-clear-button`}
          onClick={() => {
            onApply({
              [filterKey]: undefined,
            });
            updateFieldValue(FormFields.criteria, 'exact' as ArrivalOrDepartCriteria);
            updateFieldValue(FormFields.dateAfter, undefined);
            updateFieldValue(FormFields.exactDate, undefined);
            updateFieldValue(FormFields.firstDay, undefined);
            updateFieldValue(FormFields.lastDay, undefined);
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight={450}
            lineHeight="100%"
            letterSpacing="0.022px"
            color={colors.grey[500]}
          >
            Clear
          </Typography>
        </Button>
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit} ref={formRef}>
        <Box
          data-testid={`arrival-depart-filter-content-${label}`}
          sx={{ p: 2, borderBottom: `1px solid ${colors.grey[200]}` }}
        >
          <RadioGroup
            value={values.criteria}
            onChange={handleRadioChange}
            sx={{ '> :not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <FormControlLabel
                data-testid="exact-radio-option"
                value="exact"
                control={<Radio sx={selfbookRadioStyle} />}
                sx={{
                  ...selfbookRadioLabelStyle,
                  alignItems: 'flex-start',
                  '> span': { paddingTop: '0px', marginTop: '-3px' },
                }}
                label={
                  <Typography fontSize="14px" letterSpacing="0.14px" lineHeight="100%" fontWeight="450">
                    Exact {label.toLocaleLowerCase()} date
                  </Typography>
                }
              />
              <InputDatePicker
                ButtonProps={{
                  label: 'SELECT DATE',
                  sx: { width: '100%' },
                  formattedDate: values.exactDate
                    ? formatDateFlow(values.exactDate, 'en', longDateOptions, true)
                    : '',
                  error: errors.exactDate as string,
                }}
                selectedDate={values.exactDate}
                onDateSelect={(date) => {
                  updateFieldValue(FormFields.exactDate, date);
                }}
                disabled={values.criteria !== 'exact'}
              />
            </Box>

            <Box>
              <FormControlLabel
                data-testid="after-radio-option"
                value="after"
                control={<Radio sx={selfbookRadioStyle} />}
                sx={{
                  ...selfbookRadioLabelStyle,
                  alignItems: 'flex-start',
                  '> span': { paddingTop: '0px', marginTop: '-3px' },
                }}
                label={
                  <Typography fontSize="14px" letterSpacing="0.14px" lineHeight="100%" fontWeight="450">
                    {label} date after
                  </Typography>
                }
              />
              <InputDatePicker
                ButtonProps={{
                  label: 'SELECT DATE',
                  sx: { width: '100%' },
                  formattedDate: values.dateAfter
                    ? formatDateFlow(values.dateAfter, 'en', longDateOptions, true)
                    : '',
                  error: errors.dateAfter as string,
                }}
                disabled={values.criteria !== 'after'}
                selectedDate={values.dateAfter}
                onDateSelect={(date) => {
                  updateFieldValue(FormFields.dateAfter, date);
                }}
              />
            </Box>

            <Box>
              <FormControlLabel
                data-testid="between-radio-option"
                value="between"
                control={<Radio sx={selfbookRadioStyle} />}
                sx={{
                  ...selfbookRadioLabelStyle,
                  alignItems: 'flex-start',
                  '> span': { paddingTop: '0px', marginTop: '-3px' },
                }}
                label={
                  <Typography fontSize="14px" letterSpacing="0.14px" lineHeight="100%" fontWeight="450">
                    {label} date between
                  </Typography>
                }
              />
              <Box sx={{ gap: 0.5, width: '100%', display: 'flex', '> div': { width: '50%' } }}>
                <InputDatePicker
                  ButtonProps={{
                    label: 'FIRST DAY',
                    sx: { width: '100%' },
                    formattedDate: values.firstDay
                      ? formatDateFlow(values.firstDay, 'en', longDateOptions, true)
                      : '',
                    error: errors.firstDay as string,
                  }}
                  disabled={values.criteria !== 'between'}
                  selectedDate={values.firstDay}
                  onDateSelect={(date) => {
                    updateFieldValue(FormFields.firstDay, date);
                  }}
                  defaultMonth={values.firstDay}
                  fromMonth={values.firstDay}
                />
                <InputDatePicker
                  ButtonProps={{
                    label: 'LAST DAY',
                    sx: { width: '100%' },
                    formattedDate: values.lastDay
                      ? formatDateFlow(values.lastDay, 'en', longDateOptions, true)
                      : '',
                    error: errors.lastDay as string,
                  }}
                  disabled={values.criteria !== 'between'}
                  selectedDate={values.lastDay}
                  onDateSelect={(date) => {
                    updateFieldValue(FormFields.lastDay, date);
                  }}
                  defaultMonth={values.firstDay}
                  fromMonth={values.firstDay}
                  disabledDays={[{ before: values.firstDay! }]}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button type="submit" fullWidth>
            <Typography fontSize="14px" fontWeight="450" lineHeight="100%" letterSpacing="0.56px">
              Apply
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
