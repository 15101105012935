import { Alert, IconButton, SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { getErrorMessage } from 'app/api/lib/error-handling';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import ReactRouterLinkButton from 'app/components/react-router-link-button/ReactRouterLinkButton';
import SelfbookTextInput from 'app/components/selfbook-text-input/SelfbookTextInput';
import useForm from 'app/hooks/useForm';
import { stringHasValue } from 'app/lib/validation-functions';
import { AuthenticationPaths } from 'app/routing/route-paths';
import colors from 'app/styles/colors';
import EyeIcon from 'app/components/icons/EyeIcon';
import NoEyeIcon from 'app/components/icons/NoEyeIcon';
import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from '../../context/useAuthenticationContext';
import { useUser } from '../../context/useUser';
import { clearAllLocalStorageUserData } from '../../lib/lib';
import useLogin from '../api/useLogin';
import { LoginRequest } from '../types';

const containerStyles: SxProps = {
  alignItems: 'left',
  maxWidth: 400,
};

function Login() {
  const { isLoading, error, mutate } = useLogin();
  const { setLoginDetails } = useAuthenticationContext();
  const { clearCurrentUser } = useUser();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
  }, []);

  const formRef = useRef<HTMLFormElement>(null);

  enum FormFields {
    email = 'email',
    password = 'password',
  }

  const handleLoginSuccess = (response: AxiosResponse<any, any>, variables: LoginRequest) => {
    const { email } = variables;
    const { mfa_nonce } = response.data;
    setLoginDetails({ email, mfa_nonce });
    navigate(`../${AuthenticationPaths.VerifyCode}`);
  };

  const { values, errors, resetErrors, updateFieldValue, getIsFormValid, focusFirstFieldWithError } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.email]: '',
        [FormFields.password]: '',
      },
      validations: {
        [FormFields.email]: (val) => {
          if (!stringHasValue(val)) return 'Email is required.';
          return true;
        },
        [FormFields.password]: (val) => {
          if (!stringHasValue(val)) return 'Password is required.';
          return true;
        },
      },
    },
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    mutate(values, {
      onSuccess: handleLoginSuccess,
    });
  };

  return (
    <Box data-testid="login-screen" sx={containerStyles}>
      <Typography fontSize="34px" fontWeight="medium" letterSpacing="-.34px" fontFamily="PP Neue Montreal">
        Welcome to Station
      </Typography>
      <Typography fontSize="18px" fontWeight="300" color={colors.grey[700]} mt={2} mb={4}>
        Enter your credentials to access your account.
      </Typography>
      <Box component="form" sx={{ width: '100%' }} onSubmit={handleSubmit} noValidate ref={formRef}>
        <Stack spacing={1}>
          <SelfbookTextInput
            id="login-email"
            data-testid="login-email"
            required
            label="Email"
            type="email"
            value={values.email}
            name={FormFields.email}
            onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <Box position="relative">
            <SelfbookTextInput
              id="login-password"
              data-testid="login-password"
              required
              label="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              name={FormFields.password}
              onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              fullWidth
            />
            <Box
              sx={{
                position: 'absolute',
                display: 'grid',
                placeItems: 'center',
                right: 10,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                data-testid="toggle-password-visibility-button"
              >
                {showPassword ? <EyeIcon data-testid="eye-icon" /> : <NoEyeIcon data-testid="no-eye-icon" />}
              </IconButton>
            </Box>
          </Box>
        </Stack>

        <ReactRouterLinkButton
          sx={{ mt: 2, alignSelf: 'baseline', color: colors.grey[600] }}
          variant="textMuted"
          to={`../${AuthenticationPaths.ResetPasswordRequest}`}
        >
          Forgot password?
        </ReactRouterLinkButton>

        <LoadingButton loading={isLoading} type="submit" fullWidth sx={{ mt: 4 }}>
          Sign In
        </LoadingButton>

        {!!error && (
          <Alert data-testid="login-error" id="login-error" sx={{ boxShadow: 0, mt: 3 }} severity="error">
            {getErrorMessage(error)}
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default Login;
