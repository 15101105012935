export enum UserPermissionRole {
  HOTEL_ADMIN = 'HOTEL_ADMIN',
  SELFBOOK_CSM = 'SELFBOOK_CSM',
  SELFBOOK_MACHINE = 'SELFBOOK_MACHINE',
}

export const mapUserPermissionRoleToUIText = (role?: UserPermissionRole | string) => {
  if (!role) {
    return 'Unknown Role';
  }
  switch (role.toLowerCase()) {
    case UserPermissionRole.HOTEL_ADMIN.toLowerCase():
      return 'Merchant';

    case UserPermissionRole.SELFBOOK_CSM.toLowerCase():
      return 'Selfbook CSM';

    case UserPermissionRole.SELFBOOK_MACHINE.toLowerCase():
      return 'Selfbook Machine';

    default:
      return 'Unknown Role';
  }
};
