import { SVGProps } from 'react';

function ChevronRightIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="chevron-right-icon"
      {...props}
    >
      <path
        d="M9.50001 8L2.00001 15.5L0.950012 14.45L7.40001 8L0.950012 1.55L2.00001 0.5L9.50001 8Z"
        fill="#212529"
      />
    </svg>
  );
}

export default ChevronRightIcon;
