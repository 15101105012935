import { Box, Chip, SxProps, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import MagnifyingGlass from 'app/components/icons/MagnifyingGlass';
import SelfbookTextInput from 'app/components/selfbook-text-input/SelfbookTextInput';
import { borderRadius } from 'app/styles/layout';
import { useRef, useState } from 'react';
import useReservationSearch, { SearchFilter } from '../hooks/useReservationSearch';

interface Props {
  onSearchQueryChange: (value: string) => void;
}

function ReservationsSearch({ onSearchQueryChange }: Props) {
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { handleInputChange, handleSearchFilterChange, currentFilter, searchTerm, handleKeyDown } =
    useReservationSearch({
      onSearchQueryChange,
      anchorRef,
    });

  const handleInputFocus = () => {
    setSuggestionsOpen(true);
  };

  /* istanbul ignore next */ // There are tests for this
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setSuggestionsOpen(false);
  };

  /* istanbul ignore next */ // there are tests for this
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSuggestionsOpen(false);
    } else if (event.key === 'Escape') {
      setSuggestionsOpen(false);
    }
  };

  const renderSearchFilterChip = () => {
    if (currentFilter === SearchFilter.all) {
      return null;
    }
    return (
      <InputAdornment position="start" sx={{ ml: 2, mr: -1 }}>
        <Chip
          sx={{
            borderRadius: '4px',
            height: 16,
            '& .MuiChip-label': {
              px: 0.75,
              fontSize: 12,
              fontWeight: 300,
            },
          }}
          label={currentFilter}
          data-testid={`reservation-search-chip-${currentFilter}`}
        />
      </InputAdornment>
    );
  };

  const renderMenuItems = () => {
    const menuItemStyle: SxProps = {
      backgroundColor: 'grey.200',
      p: 1,
      borderRadius,
      color: 'grey.900',
      fontSize: 14,
      fontWeight: 300,
      lineHeight: '100%',
      mr: 1,
      letterSpacing: 0.35,
    };

    return [SearchFilter.all, SearchFilter.name, SearchFilter.email, SearchFilter.reservationId].map(
      (searchFilter) => {
        if (currentFilter === searchFilter) {
          return null;
        }

        return (
          <MenuItem
            data-testid={`reservation-search-suggestions-menu-item-${searchFilter}`}
            key={searchFilter}
            sx={menuItemStyle}
            onClick={() => handleSearchFilterChange(searchFilter)}
          >
            {searchFilter}
          </MenuItem>
        );
      },
    );
  };

  /* istanbul ignore next */ // conditional styling
  const getPopperStyle = (placement: PopperPlacementType) => {
    return {
      transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
    };
  };

  return (
    <Box sx={{ position: 'relative' }} id="reservations-search" data-testid="reservations-search">
      <SelfbookTextInput
        ref={anchorRef}
        id="reservation-search-input"
        data-testid="reservation-search-input"
        aria-controls={suggestionsOpen ? 'suggestions-menu' : undefined}
        aria-expanded={suggestionsOpen ? 'true' : undefined}
        aria-haspopup="true"
        onFocus={handleInputFocus}
        fullWidth
        label="Search"
        backgroundVariant="default"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={searchTerm}
        InputProps={{
          startAdornment: renderSearchFilterChip(),
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: 2 }}>
              <MagnifyingGlass />
            </InputAdornment>
          ),
        }}
      />

      <Popper
        open={suggestionsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ width: '100%', pt: '8px !important', zIndex: 1 }}
        data-testid="reservation-search-popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={getPopperStyle(placement)}>
            <Paper sx={{ p: 2 }}>
              <Typography sx={{ fontSize: 14, color: 'grey.600' }}>Search by:</Typography>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  data-testid="reservation-search-suggestions-menu"
                  autoFocusItem={false}
                  id="suggestions-menu"
                  aria-labelledby="reservation-search-input"
                  onKeyDown={handleListKeyDown}
                  sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', pt: 1.5 }}
                >
                  {renderMenuItems()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default ReservationsSearch;
