import colors from 'app/styles/colors';

export const adjustTimeZoneHours = (date: string) => {
  return new Date(
    `${date.split('T')[0].replace(/-/g, '/')} ${new Date().getHours()}:${new Date().getMinutes()}`,
  );
};

export const setMidNightHours = (date?: string) => {
  if (date && date.includes('T')) {
    return `${date.split('T')[0]}T23:00:00Z`;
  }

  return date;
};

export const getReservationPaymentStatusColors = (status: string) => {
  switch (status) {
    case 'CONVEYED':
      return {
        fontColor: colors.grey[700],
        bgColor: colors.grey[200],
      }
    case 'UNPAID':
      return {
        fontColor: colors.grey[900],
        bgColor: colors.grey[200],
      };
    case 'PAID':
      return {
        fontColor: colors.success[800],
        bgColor: colors.success[200],
      };

    case 'PARTIAL':
      return {
        fontColor: colors.grey[800],
        bgColor: colors.warning[200],
      };

    case 'FAILED':
      return {
        fontColor: colors.critical[800],
        bgColor: colors.critical[200],
      };

    default:
      return {
        fontColor: colors.success[800],
        bgColor: colors.success[200],
      };
  }
};

export const getReservationStatusClassname = (status: string) => {
  switch (status) {
    case 'CONVEYED':
      return 'reservation-status-wrapper-conveyed';
    case 'PARTIAL':
      return 'reservation-status-wrapper-partial';
    case 'FAILED':
      return 'reservation-status-wrapper-failed';
    case 'UNPAID':
      return 'reservation-status-wrapper-unpaid';
    default:
      return 'reservation-status-wrapper-paid';
  }
};
