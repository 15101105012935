import { SVGProps } from 'react';

function CurrencyIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden
      data-testid="currency-icon"
      {...props}
    >
      <g clipPath="url(#clip0_2966_125296)">
        <path
          d="M13.125 7.5V6.25H10.625V4.375H9.375V6.25H8.125C7.79359 6.25033 7.47583 6.38213 7.24149 6.61648C7.00714 6.85083 6.87534 7.16858 6.875 7.5V9.375C6.87534 9.70642 7.00714 10.0242 7.24149 10.2585C7.47583 10.4929 7.79359 10.6247 8.125 10.625H11.875V12.5H6.875V13.75H9.375V15.625H10.625V13.75H11.875C12.2064 13.7496 12.5241 13.6178 12.7585 13.3835C12.9928 13.1491 13.1246 12.8314 13.125 12.5V10.625C13.1247 10.2936 12.9929 9.97583 12.7585 9.74148C12.5242 9.50713 12.2064 9.37533 11.875 9.375H8.125V7.5H13.125Z"
          fill="#495057"
        />
        <path
          d="M10 2.5C11.4834 2.5 12.9334 2.93987 14.1668 3.76398C15.4002 4.58809 16.3614 5.75943 16.9291 7.12987C17.4968 8.50032 17.6453 10.0083 17.3559 11.4632C17.0665 12.918 16.3522 14.2544 15.3033 15.3033C14.2544 16.3522 12.918 17.0665 11.4632 17.3559C10.0083 17.6453 8.50032 17.4968 7.12988 16.9291C5.75943 16.3614 4.58809 15.4001 3.76398 14.1668C2.93987 12.9334 2.5 11.4834 2.5 10C2.50582 8.01267 3.29787 6.10839 4.70313 4.70313C6.1084 3.29787 8.01267 2.50582 10 2.5ZM10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.7432 7.68145 17.8192 5.45979 16.1797 3.82032C14.5402 2.18085 12.3186 1.25679 10 1.25Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_2966_125296">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default CurrencyIcon;
