import { SVGProps } from 'react';

function PayoutsIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="payouts-icon"
      {...props}
    >
      <path
        d="M5.5575 16.9425L2.8725 14.25H18V12.75H2.8725L5.5575 10.0575L4.5 9L0 13.5L4.5 18L5.5575 16.9425Z"
        fill="white"
      />
      <path
        d="M18 4.5L13.5 0L12.4425 1.0575L15.1275 3.75H0V5.25H15.1275L12.4425 7.9425L13.5 9L18 4.5Z"
        fill="white"
      />
    </svg>
  );
}

export default PayoutsIcon;
