import { SVGProps } from 'react';
import SrOnlyText from '../sr-only-text/SrOnlyText';

function JcbIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="jcb-icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V24C0 25.1046 0.89543 26 2 26H32C33.1046 26 34 25.1046 34 24V2C34 0.895431 33.1046 0 32 0H2ZM25.1853 10.3627V12.2373C25.1869 12.2373 25.1884 12.2373 25.1899 12.2373V12.2386C25.6541 12.2386 26.0175 12.2391 26.3037 12.2395C27.3227 12.241 27.3613 12.241 27.4782 12.2118C27.8792 12.1313 28.1999 11.7827 28.1999 11.3C28.2 10.8498 27.9014 10.4943 27.52 10.3977C27.4872 10.3882 27.4536 10.3804 27.4196 10.3743C27.4196 10.3743 27.4327 10.3734 27.3938 10.372C27.2871 10.3591 27.0796 10.3594 26.3082 10.3605C26.0214 10.3609 25.6566 10.3614 25.1899 10.3614V10.3627C25.1884 10.3627 25.1869 10.3627 25.1853 10.3627ZM7.39735 9.21951H10.7054C10.7054 9.85015 10.721 10.4456 10.7357 11.0044V11.0044V11.0045C10.8337 14.739 10.8887 16.8336 5.94647 16.8336C5.94647 16.8336 3.74112 16.8336 2 16.3519V14.727C3.0679 15.255 4.18798 15.5859 5.31968 15.5859C7.56154 15.5859 7.51245 13.8797 7.43161 11.0696C7.41514 10.497 7.39735 9.87864 7.39735 9.21951ZM11.4888 12.9221C11.4888 8.6159 17.3851 8.5521 21.0646 9.48061V11.1055C18.2963 9.68947 14.8258 9.84026 14.8258 12.9162C14.8258 15.9921 18.3021 16.1489 21.0646 14.7152V16.3577C17.7682 17.1935 11.4888 17.3965 11.4888 12.9221ZM29.5088 12.8291C30.7566 12.9163 31.7143 13.6125 31.7143 14.6865C31.7143 15.8645 30.6696 16.6189 29.2592 16.613H22.1383V9.21347C29.4856 9.21347 29.12 9.23099 29.12 9.23099C30.3561 9.28898 31.2674 9.92739 31.2674 11.03C31.2674 11.8832 30.6406 12.626 29.5088 12.7827V12.8291ZM26.3992 13.3796H26.3991C26.0878 13.38 25.6918 13.3806 25.1853 13.3806V15.4119C25.6891 15.4119 26.0836 15.4124 26.3942 15.4129C27.5004 15.4144 27.5423 15.4145 27.6692 15.3828C28.1045 15.2957 28.4527 14.9185 28.4527 14.3961C28.4527 13.8913 28.1046 13.4966 27.6692 13.4096C27.5427 13.3779 27.4971 13.378 26.3992 13.3796Z"
        fill="#212529"
      />
      <SrOnlyText>JCB Card</SrOnlyText>
    </svg>
  );
}

export default JcbIcon;
