import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { NewChargeRequestPayload } from '../types';

const useNewCharge = (hotelId: string, reservationId: string) => {
  const mutation = useMutation((request: NewChargeRequestPayload) => {
    return stationApiAxios({
      url: `/rest/v1/hotels/${hotelId}/reservations/${reservationId}/transactions`,
      method: 'POST',
      data: request
    });
  });

  return mutation;
};

export default useNewCharge;
