import { SVGProps } from 'react';

function KeyIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
      data-testid="key-icon"
      {...props}>
      <path d="M14.625 12.125C14.9702 12.125 15.25 11.8452 15.25 11.5C15.25 11.1548 14.9702 10.875 14.625 10.875C14.2798 10.875 14 11.1548 14 11.5C14 11.8452 14.2798 12.125 14.625 12.125Z" fill="#495057" />
      <path d="M11.1338 17.7501H8.375V14.9912L11.5211 11.8451C11.5071 11.7306 11.5001 11.6154 11.5 11.5001C11.4996 10.8672 11.6914 10.2491 12.05 9.72764C12.4085 9.20613 12.917 8.80579 13.5081 8.57959C14.0992 8.35339 14.745 8.31199 15.3601 8.46086C15.9752 8.60974 16.5307 8.94188 16.9529 9.41333C17.3751 9.88478 17.6442 10.4733 17.7247 11.1011C17.8051 11.7288 17.693 12.3662 17.4033 12.9289C17.1135 13.4915 16.6598 13.9529 16.102 14.2521C15.5443 14.5512 14.9089 14.6739 14.2799 14.6041L11.1338 17.7501ZM9.625 16.5001H10.6162L13.87 13.2462L14.2072 13.3236C14.609 13.4193 15.0311 13.3812 15.4093 13.2152C15.7875 13.0492 16.1012 12.7642 16.3027 12.4037C16.5042 12.0431 16.5825 11.6266 16.5257 11.2175C16.469 10.8083 16.2802 10.4289 15.9881 10.1369C15.696 9.8448 15.3166 9.65606 14.9074 9.59931C14.4983 9.54256 14.0818 9.62091 13.7213 9.82245C13.3607 10.024 13.0758 10.3377 12.9098 10.7159C12.7438 11.0942 12.7058 11.5163 12.8016 11.9181L12.8788 12.2551L9.625 15.5091V16.5001Z" fill="#495057" />
      <path d="M4 4H2.75V9H4V4Z" fill="#495057" />
      <path d="M1.5 4H0.25V9H1.5V4Z" fill="#495057" />
      <path d="M11.5 4H10.25V7.75H11.5V4Z" fill="#495057" />
      <path d="M7.75 9H6.5C6.16848 9 5.85054 8.8683 5.61612 8.63388C5.3817 8.39946 5.25 8.08152 5.25 7.75V5.25C5.25 4.91848 5.3817 4.60054 5.61612 4.36612C5.85054 4.1317 6.16848 4 6.5 4H7.75C8.08152 4 8.39946 4.1317 8.63388 4.36612C8.8683 4.60054 9 4.91848 9 5.25V7.75C9 8.08152 8.8683 8.39946 8.63388 8.63388C8.39946 8.8683 8.08152 9 7.75 9ZM6.5 7.75H7.75V5.25H6.5V7.75Z" fill="#495057" />
      <path d="M1.5 10.25H0.25V15.25H1.5V10.25Z" fill="#495057" />
      <path d="M9 10.25H7.75V12.75H9V10.25Z" fill="#495057" />
      <path d="M5.25 15.25H4C3.66848 15.25 3.35054 15.1183 3.11612 14.8839C2.8817 14.6495 2.75 14.3315 2.75 14V11.5C2.75 11.1685 2.8817 10.8505 3.11612 10.6161C3.35054 10.3817 3.66848 10.25 4 10.25H5.25C5.58152 10.25 5.89946 10.3817 6.13388 10.6161C6.3683 10.8505 6.5 11.1685 6.5 11.5V14C6.5 14.3315 6.3683 14.6495 6.13388 14.8839C5.89946 15.1183 5.58152 15.25 5.25 15.25ZM4 14H5.25V11.5H4V14Z" fill="#495057" />
      <path d="M1.5 0.25H0.25V2.75H1.5V0.25Z" fill="#495057" />
      <path d="M9 0.25H7.75V2.75H9V0.25Z" fill="#495057" />
      <path d="M11.5 0.25H10.25V2.75H11.5V0.25Z" fill="#495057" />
      <path d="M5.25 2.75H4C3.66848 2.75 3.35054 2.6183 3.11612 2.38388C2.8817 2.14946 2.75 1.83152 2.75 1.5V0.25H4V1.5H5.25V0.25H6.5V1.5C6.5 1.83152 6.3683 2.14946 6.13388 2.38388C5.89946 2.6183 5.58152 2.75 5.25 2.75Z" fill="#495057" />
    </svg>
  );
}

export default KeyIcon;
