import { useUser } from 'app/features/authentication/context/useUser';
import {
  clearAllLocalStorageUserData,
  getTempCsmAccessToken,
  getTempCsmRefreshToken,
  selfbookCsmLogoutOfUserAccount,
} from 'app/features/authentication/lib/lib';
import { AuthenticationPaths, IndexPath, TopLevelPaths } from 'app/routing/route-paths';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();
  const currentCsmJwt = getTempCsmAccessToken();
  const currentCsmRefreshToken = getTempCsmRefreshToken();
  const { fetchCurrentUserFromJwt, clearCurrentUser } = useUser();

  return async () => {
    /* 
      The order of events here is pretty important for a smooth UI
      When "Logout" is clicked, check to see if there is a value for
      a temp csm jwt and refresh token, that means a CSM assumed a user
      In this case we should logout of the assumed user role, but immediately
      log back in to the csm role and land on the dash. 
    */
    if (currentCsmJwt && currentCsmRefreshToken) {
      await selfbookCsmLogoutOfUserAccount({ refetchUserFn: fetchCurrentUserFromJwt });
      navigate(`/${IndexPath}/${TopLevelPaths.SelfbookAdmin}`, { replace: true });
    } else {
      clearAllLocalStorageUserData({ removeUserFn: clearCurrentUser });
      navigate(`../${TopLevelPaths.Authentication}/${AuthenticationPaths.Login}`, { replace: true });
    }
  };
};
export default useLogout;
