import { SxProps } from '@mui/material/styles';
import { STATION_SIDE_NAV_WIDTH } from 'app/styles/layout';
import theme from 'app/styles/material/theme';

// https://codesandbox.io/s/twsogf?file=/demo.tsx:948-1053
export const drawerOpenWidth = 218;
export const drawerClosedWidth = STATION_SIDE_NAV_WIDTH

const useSideDrawerStyles = ({ isOpen }: { isOpen: boolean }) => {
  const openedMixin = () => ({
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = () => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerClosedWidth,
  });

  const drawerPaperStyle: SxProps = {
    backgroundColor: 'grey.800',
    borderRadius: 0,
    pt: 4,
    pb: 1,
    boxSizing: 'border-box',
    position: 'fixed',
    left: '0px',
    top: '62px',
    height: 'calc(100% - 60px)',
  };

  const drawerContainerStyle = {
    width: drawerOpenWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isOpen && {
      ...openedMixin(),
      '& .MuiDrawer-paper': openedMixin(),
    }),
    ...(!isOpen && {
      ...closedMixin(),
      '& .MuiDrawer-paper': closedMixin(),
    }),
  };

  return { closedMixin, openedMixin, drawerPaperStyle, drawerContainerStyle };
};

export default useSideDrawerStyles;
