import { SVGProps } from 'react';

function MagnifyingGlass(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="magnifying-glass-icon"
      {...props}
    >
      <path
        d="M20.7499 19.6896L15.0859 14.0255C16.4469 12.3915 17.1256 10.2958 16.9808 8.17412C16.8359 6.05248 15.8787 4.06836 14.3082 2.63451C12.7378 1.20065 10.675 0.427456 8.54894 0.475768C6.42292 0.52408 4.39736 1.39018 2.89365 2.89389C1.38993 4.39761 0.523836 6.42316 0.475524 8.54918C0.427212 10.6752 1.20041 12.738 2.63426 14.3085C4.06812 15.8789 6.05224 16.8362 8.17387 16.981C10.2955 17.1258 12.3913 16.4471 14.0253 15.0861L19.6894 20.7502L20.7499 19.6896ZM1.99993 8.75018C1.99993 7.41515 2.39581 6.11011 3.13751 5.00008C3.87921 3.89005 4.93342 3.02488 6.16682 2.51399C7.40022 2.0031 8.75742 1.86943 10.0668 2.12988C11.3762 2.39033 12.5789 3.0332 13.5229 3.97721C14.4669 4.92121 15.1098 6.12395 15.3702 7.43332C15.6307 8.74269 15.497 10.0999 14.9861 11.3333C14.4752 12.5667 13.6101 13.6209 12.5 14.3626C11.39 15.1043 10.085 15.5002 8.74993 15.5002C6.96033 15.4982 5.2446 14.7864 3.97916 13.521C2.71371 12.2555 2.00192 10.5398 1.99993 8.75018Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MagnifyingGlass;
