
import { Avatar, Typography, SxProps } from '@mui/material';

interface Props {
    avatarText: string;
    isSelfbookAdmin?: boolean;
}

export function UserAvatar({ avatarText, isSelfbookAdmin }: Props) {
    const getAvatarStyle = (): SxProps => {
        if (isSelfbookAdmin) {
            return { bgcolor: 'grey.800', width: 'auto', px: 1.25, height: '36px', borderRadius: '110px' };
        }
        return { bgcolor: 'grey.800', width: '36px', height: '36px' };
    };

    return <Avatar sx={getAvatarStyle()} data-testid="user-menu-avatar">
        <Typography
            component="p"
            variant="subtitle1"
            lineHeight="16px"
            color="white"
            letterSpacing="0.08em"
            fontWeight="450"
        >
            {avatarText}
        </Typography>
    </Avatar>
}

