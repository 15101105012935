import { formatDateFlow } from 'app/features/reservations/lib/reservationsTable';
import { FiltersOptions } from 'app/features/reservations/types';
import { DateTimeStringObject } from 'app/lib/types';
import ArrivalOrDepart from '../components/ArrivalOrDepart';
import NumberOfGuests from '../components/NumberOfGuests';
import PaymentStatus, { PAYMENT_STATUS_FILTER_VALUES } from '../components/PaymentStatus';

export enum FILTERS_KEYS {
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure',
  NUMBER_OF_GUESTS = 'numberOfGuests',
  PAYMENT_STATUS = 'paymentStatus',
}

export const filters = [
  { key: FILTERS_KEYS.ARRIVAL, label: 'Arrival', path: 'arrive_at', component: ArrivalOrDepart },
  { key: FILTERS_KEYS.DEPARTURE, label: 'Departure', path: 'depart_at', component: ArrivalOrDepart },
  {
    key: FILTERS_KEYS.NUMBER_OF_GUESTS,
    label: 'Number of guests',
    path: 'guest_summary.total_number_of_guests',
    component: NumberOfGuests,
  },
  {
    key: FILTERS_KEYS.PAYMENT_STATUS,
    label: 'Payment status',
    path: 'invoice_lines[0].status',
    component: PaymentStatus,
  },
];

export const getFilterDateValue = (date: Date) => {
  const year: number = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}T00:00:00Z`;
};

const shortDateOptions: DateTimeStringObject = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
} as DateTimeStringObject;

function getArrivalOrDepartlabel(key: string, filterCriteria: FiltersOptions) {
  let formatedLabel = '';
  let hasFormattedlabel = false;

  if (filterCriteria[key]?.exact) {
    hasFormattedlabel = true;
    formatedLabel = `${key.toUpperCase()}: ${formatDateFlow(
      filterCriteria[key].exact,
      'en',
      shortDateOptions,
      true,
    )}`;
  }

  if (filterCriteria[key]?.after) {
    hasFormattedlabel = true;
    formatedLabel = `${key.toUpperCase()}: AFTER: ${formatDateFlow(
      filterCriteria[key].after,
      'en',
      shortDateOptions,
      true,
    )}`;
  }

  if (filterCriteria[key]?.between) {
    hasFormattedlabel = true;
    formatedLabel = `${key.toUpperCase()}: ${formatDateFlow(
      filterCriteria[key].between.firstDay,
      'en',
      shortDateOptions,
      true,
    )} - ${formatDateFlow(filterCriteria[key].between.lastDay, 'en', shortDateOptions, true)}`;
  }
  return { formatedLabel, hasFormattedlabel };
}

export function getFilterLabel(label: string, key: string, filterCriteria: FiltersOptions | undefined) {
  let formatedLabel = label;
  let hasFormattedlabel = false;

  if (key === FILTERS_KEYS.ARRIVAL && filterCriteria?.arrival) {
    ({ formatedLabel, hasFormattedlabel } = getArrivalOrDepartlabel(key, filterCriteria));
  }

  if (key === FILTERS_KEYS.DEPARTURE && filterCriteria?.departure) {
    ({ formatedLabel, hasFormattedlabel } = getArrivalOrDepartlabel(key, filterCriteria));
  }

  if (key === FILTERS_KEYS.NUMBER_OF_GUESTS && filterCriteria?.numberOfGuests) {
    if (filterCriteria?.numberOfGuests.exact) {
      formatedLabel = `GUEST NUMBER: ${filterCriteria?.numberOfGuests.exact}`;
      hasFormattedlabel = true;
    }

    if (filterCriteria?.numberOfGuests.between) {
      formatedLabel = `GUEST NUMBER: ${filterCriteria?.numberOfGuests.between.from} - ${filterCriteria?.numberOfGuests.between.to}`;
      hasFormattedlabel = true;
    }
  }

  if (key === FILTERS_KEYS.PAYMENT_STATUS && filterCriteria?.paymentStatus) {
    const mappedFilters = Object.keys(filterCriteria?.paymentStatus)
      .filter((k) => filterCriteria?.paymentStatus && filterCriteria?.paymentStatus[k])
      .map((k) => k.toUpperCase())
      .join(', ');

    formatedLabel = `PAYMENT STATUS: ${mappedFilters}`;
    hasFormattedlabel = true;
  }
  return { formatedLabel, hasFormattedlabel };
}

export function buildFiltersQuery(filterCriteria: FiltersOptions | undefined) {
  let query = '';
  const filterOP = ' AND ';

  if (filterCriteria?.arrival) {
    if (filterCriteria?.arrival.exact) {
      query += `${filters[0].path}='${getFilterDateValue(filterCriteria?.arrival.exact)}'`;
    } else if (filterCriteria?.arrival.after) {
      query += `${filters[0].path}>'${getFilterDateValue(filterCriteria?.arrival.after)}'`;
    } else if (filterCriteria?.arrival.between) {
      query += `${filters[0].path}>'${getFilterDateValue(filterCriteria?.arrival.between.firstDay)}' AND ${
        filters[0].path
      }<'${getFilterDateValue(filterCriteria?.arrival.between.lastDay)}'`;
    }
  }

  if (filterCriteria?.arrival && filterCriteria?.departure) {
    query += filterOP;
  }

  if (filterCriteria?.departure) {
    if (filterCriteria?.departure.exact) {
      query += `${filters[1].path}='${getFilterDateValue(filterCriteria?.departure.exact)}'`;
    } else if (filterCriteria?.departure.after) {
      query += `${filters[1].path}>'${getFilterDateValue(filterCriteria?.departure.after)}'`;
    } else if (filterCriteria?.departure.between) {
      query += `${filters[1].path}>'${getFilterDateValue(filterCriteria?.departure.between.firstDay)}' AND ${
        filters[1].path
      }<'${getFilterDateValue(filterCriteria?.departure.between.lastDay)}'`;
    }
  }

  if ((filterCriteria?.arrival || filterCriteria?.departure) && filterCriteria?.numberOfGuests) {
    query += filterOP;
  }

  if (filterCriteria?.numberOfGuests) {
    if (filterCriteria?.numberOfGuests.exact) {
      query += `${filters[2].path}=${filterCriteria?.numberOfGuests.exact}`;
    } else if (filterCriteria?.numberOfGuests.between) {
      query += `${filters[2].path}>${filterCriteria?.numberOfGuests.between.from} AND ${filters[2].path}<${filterCriteria?.numberOfGuests.between.to}`;
    }
  }

  if (
    (filterCriteria?.arrival || filterCriteria?.departure || filterCriteria?.numberOfGuests) &&
    filterCriteria?.paymentStatus
  ) {
    query += filterOP;
  }

  if (filterCriteria?.paymentStatus) {
    if (filterCriteria?.paymentStatus.failed) {
      query += `${filters[3].path}='${PAYMENT_STATUS_FILTER_VALUES.failed.toUpperCase()}'`;
    }

    if (filterCriteria?.paymentStatus.failed && filterCriteria?.paymentStatus.paid) {
      query += filterOP;
    }

    if (filterCriteria?.paymentStatus.paid) {
      query += `${filters[3].path}='${PAYMENT_STATUS_FILTER_VALUES.paid.toUpperCase()}'`;
    }

    if (
      (filterCriteria?.paymentStatus.failed || filterCriteria?.paymentStatus.paid) &&
      filterCriteria?.paymentStatus.unpaid
    ) {
      query += filterOP;
    }

    if (filterCriteria?.paymentStatus.unpaid) {
      query += `${filters[3].path}='${PAYMENT_STATUS_FILTER_VALUES.unpaid.toUpperCase()}'`;
    }

    if (
      (filterCriteria?.paymentStatus.failed ||
        filterCriteria?.paymentStatus.paid ||
        filterCriteria?.paymentStatus.unpaid) &&
      filterCriteria?.paymentStatus.partial
    ) {
      query += filterOP;
    }

    if (filterCriteria?.paymentStatus.partial) {
      query += `${filters[3].path}='${PAYMENT_STATUS_FILTER_VALUES.partial.toUpperCase()}'`;
    }
  }

  return query;
}
