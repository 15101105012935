import { SVGProps } from 'react';

function CalendarIcon2(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-testid="calendar-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2905_74395)">
        <path
          d="M10.5 15C9.70887 15 8.93552 14.7654 8.27772 14.3259C7.61992 13.8864 7.10723 13.2616 6.80448 12.5307C6.50173 11.7998 6.42252 10.9956 6.57686 10.2196C6.7312 9.44372 7.11216 8.73098 7.67157 8.17157C8.23098 7.61216 8.94372 7.2312 9.71964 7.07686C10.4956 6.92252 11.2998 7.00173 12.0307 7.30448C12.7616 7.60723 13.3864 8.11992 13.8259 8.77772C14.2654 9.43552 14.5 10.2089 14.5 11C14.5 12.0609 14.0786 13.0783 13.3284 13.8284C12.5783 14.5786 11.5609 15 10.5 15ZM10.5 8C9.90666 8 9.32664 8.17595 8.83329 8.50559C8.33994 8.83524 7.95542 9.30377 7.72836 9.85195C7.5013 10.4001 7.44189 11.0033 7.55764 11.5853C7.6734 12.1672 7.95912 12.7018 8.37868 13.1213C8.79824 13.5409 9.33279 13.8266 9.91473 13.9424C10.4967 14.0581 11.0999 13.9987 11.6481 13.7716C12.1962 13.5446 12.6648 13.1601 12.9944 12.6667C13.3241 12.1734 13.5 11.5933 13.5 11C13.5 10.2044 13.1839 9.44129 12.6213 8.87868C12.0587 8.31607 11.2956 8 10.5 8Z"
          fill="#495057"
        />
        <path d="M11.295 12.5L10 11.205V9H11V10.795L12 11.795L11.295 12.5Z" fill="#495057" />
        <path
          d="M14 3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H11V1H10V2H6V1H5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H5V13H3V3H5V4H6V3H10V4H11V3H13V6H14V3Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_2905_74395">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CalendarIcon2;
