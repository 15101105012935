import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { getErrorMessage } from 'app/api/lib/error-handling';
import ChevronRightIcon from 'app/components/icons/ChevronRightIcon';
import { useUser } from 'app/features/authentication/context/useUser';
import { usePaymentLinkMutation } from 'app/features/payment-links/api/usePaymentLinkMutation';
import UpdatePaymentMethodModal from 'app/features/payment-links/components/UpdatePaymentMethodModal';
import {
  PaymentLink,
  PaymentLinkTemplate1,
  PaymentLinkTemplate3MutationBody,
} from 'app/features/payment-links/types';
import colors from 'app/styles/colors';
import { useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import { showPaymentAutomationCanceledText } from '../sidebar-layer/paymentAutomation';
import { ReservationChargeUi } from '../sidebar-layer/roomCharge';
import CancelReservationModal from './CancelReservationModal';
import StopPaymentAutomationModal from './StopPaymentAutomationModal';

interface Props {
  onCloseClick: () => void;
  charge: ReservationChargeUi;
}

function Option({
  title,
  description,
  onClickAction,
  customIconButtonSx,
  disabled,
}: {
  title: string;
  description: string | ReactNode;
  onClickAction: () => void;
  customIconButtonSx?: SxProps;
  disabled?: boolean;
}) {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: '4px',
        backgroundColor: colors.grey[100],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '6px',
      }}
    >
      <Box>
        <Typography
          fontSize="18px"
          lineHeight="18px"
          fontWeight={450}
          letterSpacing="0.01em"
          color={disabled ? colors.grey[500] : colors.grey[700]}
        >
          {title}
        </Typography>
        <Typography
          fontSize="14px"
          lineHeight="14px"
          fontWeight={300}
          letterSpacing="0.01em"
          color={disabled ? colors.grey[500] : colors.grey[700]}
          mt={1}
        >
          {description}
        </Typography>
      </Box>{' '}
      <IconButton
        aria-label={`${title}-action-button`}
        data-testid={`${title}-action-button`}
        disabled={disabled}
        onClick={onClickAction}
        sx={{
          '&:focus-within svg': {
            outline: 'black solid thin',
          },
          backgroundColor: colors.white,
          py: '12.5px',
          px: 2,
          borderRadius: '4px',
          border: disabled ? `1px solid ${colors.grey[300]}` : 'none',
          ...customIconButtonSx,
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}

function ReservationDetailsPanelSettingsTab({ onCloseClick, charge }: Props) {
  const { currentHotelId } = useUser();
  const { mutate, data, isLoading } = usePaymentLinkMutation(currentHotelId!);
  const { enqueueSnackbar } = useSnackbar();

  const { reservation, reservationId } = useReservationDetailContext();
  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState(false);
  const [stopPaymentAutomationModal, showStopPaymentAutomationModal] = useState(false);
  const [selectedPaymentLinkToUpdate, setSelectedPaymentLinkToUpdate] = useState<boolean>(false);

  const isPaymentAutomationCancelled = showPaymentAutomationCanceledText(charge);
  // istanbul ignore next
  const isPaymentAutomationAvailable = charge.status === 'PARTIAL' || charge.status === 'UNPAID';
  const isCancelled = reservation?.status === 'CANCELED';

  return (
    <Box sx={{ mt: 10 }}>
      <Option
        title="Stop Payment Automation"
        description={
          isPaymentAutomationCancelled ? (
            <Box component="span" sx={{ color: colors.critical[600], pt: 1, fontSize: '14px' }}>
              Payment automation is stopped.
            </Box>
          ) : (
            'Stop any future automated deposits'
          )
        }
        onClickAction={() => showStopPaymentAutomationModal(true)}
        disabled={!isPaymentAutomationAvailable || isPaymentAutomationCancelled}
      />

      <Option
        title="Add payment method"
        description="Add a payment method to this reservation"
        onClickAction={() => {
          setSelectedPaymentLinkToUpdate(true);

          mutate(
            {
              id: 3,
              data: {
                reservation: {
                  id: reservationId,
                },
              },
            } as PaymentLinkTemplate3MutationBody,
            {
              onError: (error) => {
                setSelectedPaymentLinkToUpdate(false);
                enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
              },
            },
          );
        }}
      />

      <Option
        title="Cancel booking"
        description={
          isCancelled ? (
            <Box component="span" sx={{ color: colors.critical[600] }}>
              Booking has been cancelled.
            </Box>
          ) : (
            'Please note, canceling a booking is a non-reversible action'
          )
        }
        onClickAction={() => setCancelReservationModalOpen(true)}
        disabled={isCancelled}
      />

      <StopPaymentAutomationModal
        open={stopPaymentAutomationModal}
        showStopPaymentAutomationModal={showStopPaymentAutomationModal}
      />
      <CancelReservationModal
        open={cancelReservationModalOpen}
        onClose={() => setCancelReservationModalOpen(false)}
        reservationId={reservationId}
        onSuccessCallback={onCloseClick}
      />
      <UpdatePaymentMethodModal
        open={selectedPaymentLinkToUpdate}
        paymentLink={data?.data as PaymentLink<PaymentLinkTemplate1>}
        onClose={() => setSelectedPaymentLinkToUpdate(false)}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default ReservationDetailsPanelSettingsTab;
