import { getInitials } from 'app/features/user/lib/userInitials';
import { formatDateTimeToParts } from 'app/lib/dates';
import { formatPhoneNumber } from 'app/lib/strings';
import { DateTimeStringObject } from 'app/lib/types';
import flow from 'lodash/flow';
import {
  ApiReservationTransaction,
  Card,
  GuestSummary,
  InvoiceLine,
  Reservation,
  MappedInvoiceLine,
  InvoiceLineTransactionData,
  ReservationTransactionData,
  ReservationPaymentSchedule,
} from '../types';
import { getChargeOutstandingAmount, getChargePaidAmount } from './charges';
import { getGuestSummary, getReservationDetailCustomer } from './guests';
import { getReservationId, getReservationIdText } from './reservationIdText';

// Reservation Header Date
const dateFormatOptions: DateTimeStringObject = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as DateTimeStringObject;
const locale = 'en-US';
const detailDateFormatter = (dateString: string) =>
  formatDateTimeToParts(new Date(dateString), locale, dateFormatOptions);
const formatDetailDateString = (date: DateTimeStringObject) => `${date.month} ${date.day}, ${date.year}`;
export const formatDateFlow = flow(detailDateFormatter, formatDetailDateString);

const buildGuestSummaryText = (guestSummary: GuestSummary) => {
  return getGuestSummary(guestSummary);
};

export const getRefundsTotalAmount = (refunds: ApiReservationTransaction[] = []) => {
  const totalMoney = refunds.reduce((acc, refund) => {
    return acc + refund.amount;
  }, 0);

  return totalMoney;
};

export const reduceInvoiceLineTransactionData = (line: InvoiceLine) => {
  const lineTransactionData = (line.transactions || []).reduce(
    (accum: InvoiceLineTransactionData, trx: ApiReservationTransaction) => {
      if (trx.transaction_type === 'REFUND') {
        return {
          ...accum,
          refunds: [...accum.refunds, trx],
        };
      }

      // Don't show payment links as a charge
      if (trx.payment_link) {
        return accum;
      }

      return {
        ...accum,
        chargeTransactions: [...accum.chargeTransactions, trx],
      };
    },
    {
      refunds: [],
      chargeTransactions: [],
    } as InvoiceLineTransactionData,
  );

  return lineTransactionData;
};

// In order to refund, amount has to be greater than 0
const getChargeTransactionToRefundAgainst = (lineData: InvoiceLineTransactionData) => {
  return lineData.chargeTransactions.find(
    (transaction) => transaction.amount > 0 && transaction.transaction_type === 'CHARGE',
  );
};

const getAllTransactionData = (invoiceLines: InvoiceLine[]) => {
  const data: ReservationTransactionData = invoiceLines.reduce(
    (reducedData: ReservationTransactionData, line: InvoiceLine, index: number) => {
      const localLineData = reduceInvoiceLineTransactionData(line);
      const newInvoiceLine: MappedInvoiceLine = {
        ...line,
        transactions: null,
        chargeTransactionToRefundAgainst: getChargeTransactionToRefundAgainst(localLineData),
        chargeTransactions: localLineData.chargeTransactions,
        paidAmount: getChargePaidAmount(line),
        outstandingAmount: getChargeOutstandingAmount(line),
      };

      const returnVal: ReservationTransactionData = {
        ...reducedData,
        charges: [...reducedData.charges, newInvoiceLine],
        refunds: [...reducedData.refunds, ...localLineData.refunds],
        chargesTotal: reducedData.chargesTotal + newInvoiceLine.amount,
        refundsTotal: reducedData.refundsTotal + getRefundsTotalAmount(localLineData.refunds),
        totalPaid: reducedData.totalPaid + newInvoiceLine.paidAmount,
        totalOutstanding: reducedData.totalOutstanding + newInvoiceLine.outstandingAmount,
      };

      // Room charge will always be the first item
      if (index === 0) {
        return {
          ...returnVal,
          initialRoomCharge: newInvoiceLine,
          // default to the first value to make new charges against
          roomChargeTransaction: newInvoiceLine.chargeTransactions[0],
        };
      }

      return returnVal;
    },
    {
      charges: [],
      refunds: [],
      chargesTotal: 0,
      refundsTotal: 0,
      initialRoomCharge: null,
      roomChargeTransaction: null,
      totalPaid: 0,
      totalOutstanding: 0,
    } as ReservationTransactionData,
  );

  return data;
};

const getReservationCardDetails = (res?: Reservation): Card => {
  let card: Card = {
    code: '',
    expire_date: '',
    holder_name: '',
    number: '',
  };

  if (!res) return card;

  card = {
    ...res.reservation_data?.card,
  };

  return card;
};

export const getFailedScheduledTransactions = (paymentSchedule?: ReservationPaymentSchedule) => {
  if (!paymentSchedule) return [];
  const fails = (paymentSchedule?.scheduled_transactions || []).filter((st) => st.status === 'FAILED');
  return fails;
};

// Refund/Charge Date Collapse
const transactionDateTimeFormatOptions: DateTimeStringObject = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as DateTimeStringObject;
const transactionDateFormatter = (dateString: string) => {
  return formatDateTimeToParts(new Date(dateString), locale, transactionDateTimeFormatOptions);
};

const formatTransactionDateString = (date: DateTimeStringObject, hourAndMinutes: string) => {
  return `${date.month}/${date.day}/${date.year} at ${hourAndMinutes}`;
};

export const formatTransactionDate = (dateString: string) => {
  const formattedDate = transactionDateFormatter(dateString);
  const hourAndMinutes = new Date(dateString).toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });
  return formatTransactionDateString(formattedDate, hourAndMinutes);
};

const formatToBeCollectedDateString = (date: DateTimeStringObject) => {
  return `${date.month}/${date.day}/${date.year}`;
};

export const formatToBeCollectedString = flow(transactionDateFormatter, formatToBeCollectedDateString);

export const getToBeCollectedAtText = (paymentSchedule?: ReservationPaymentSchedule) => {
  const scheduledTransactions = paymentSchedule?.scheduled_transactions || [];
  // For initial version, we are only using this text for $0 pay now, remainder due all at once later on
  if (scheduledTransactions.length !== 1) return '';
  const runDate = scheduledTransactions[0].run_at;
  if (!runDate) return '';
  return `To be collected on ${formatToBeCollectedString(runDate)}.`;
};
export class ReservationDetailModel {
  reservation: Reservation | undefined;

  constructor(reservation?: Reservation) {
    this.reservation = reservation;
  }

  get customer() {
    return getReservationDetailCustomer(this.reservation);
  }

  get card() {
    return getReservationCardDetails(this.reservation);
  }

  get customerInitials() {
    return getInitials(this.customer?.full_name, this.customer?.email);
  }

  get customerPhone() {
    return formatPhoneNumber(this.customer?.phone);
  }

  get customerEmail() {
    return this.customer?.email;
  }

  get customerName() {
    return this.customer?.full_name;
  }

  get reservationId() {
    return this.reservation ? getReservationId(this.reservation) : '';
  }

  get itineraryId() {
    return this.reservation ? getReservationIdText(this.reservation) : '';
  }

  get arriveDate() {
    return this.reservation?.arriveAt ? formatDateFlow(this.reservation.arriveAt) : '';
  }

  get departDate() {
    return this.reservation?.departAt ? formatDateFlow(this.reservation.departAt) : '';
  }

  get guestSummaryText() {
    return this.reservation?.guestSummary ? buildGuestSummaryText(this.reservation.guestSummary) : '';
  }

  get reservationRooms() {
    return this.reservation?.reservation_data?.rooms ?? [];
  }

  get reservationToken() {
    return this.reservation?.reservation_data?.reservation_token ?? '';
  }

  get topLevelInvoice() {
    return this.reservation?.invoice;
  }

  get allTransactionData() {
    return getAllTransactionData(this.topLevelInvoice?.invoice_lines ?? []);
  }

  get reservationTotal() {
    return this.allTransactionData.chargesTotal - this.allTransactionData.refundsTotal;
  }

  get charges() {
    return this.allTransactionData.charges;
  }

  get refunds() {
    return this.allTransactionData.refunds;
  }

  get initialRoomCharge() {
    return this.allTransactionData.initialRoomCharge;
  }

  get initialRoomChargeTransaction() {
    return this.allTransactionData.roomChargeTransaction;
  }

  get synxisReservationId() {
    return this.reservation?.reservation_data?.reservation_id ?? '';
  }

  get reservationRate() {
    return this.reservation?.reservation_data?.rate;
  }

  get reservationTaxes() {
    return this.reservation?.reservation_data?.taxes;
  }

  get reservationServices() {
    return this.reservation?.reservation_data?.services;
  }

  get reservationNightlyRates() {
    return this.reservation?.reservation_data?.nightly_rates;
  }

  get showAddCharge() {
    if (
      // !!this.initialRoomCharge &&
      !!this.initialRoomChargeTransaction &&
      !!this.initialRoomChargeTransaction.payment_method?.brand &&
      !!this.initialRoomChargeTransaction.payment_method?.last4
    ) {
      return true;
    }

    return false;
  }

  get failedScheduledTransactions() {
    return getFailedScheduledTransactions(this.reservation?.payment_schedule);
  }

  get toBeCollectedText() {
    return getToBeCollectedAtText(this.reservation?.payment_schedule);
  }
}
