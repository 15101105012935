import { IconButton } from '@mui/material';
import { UserAvatar } from './UserAvatar';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  avatarText: string;
  isSelfbookAdmin?: boolean;
}

/* istanbul ignore next */ // There are tests for this but coverage doesn't recognize
function UserMenuButton({ onClick, open, avatarText, isSelfbookAdmin = false }: Props) {
  return (
    <IconButton
      onClick={onClick}
      size="small"
      aria-controls={open ? 'user-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      data-testid="user-menu-button"
    >
      <UserAvatar avatarText={avatarText} isSelfbookAdmin={isSelfbookAdmin} />
    </IconButton>
  );
}

export default UserMenuButton;
