import { AxiosError } from 'axios';
import { StationErrorResponse } from '../types';

export const isAxiosError = (tbd: unknown): tbd is AxiosError => {
  if ((tbd as AxiosError).response) {
    return true;
  }
  return false;
};

export const buildStationErrorMessage = (response: StationErrorResponse) => {
  if (typeof response.errors.message === 'string') return response.errors.message;
  if (Array.isArray(response.errors)) {
    if (response.errors.length === 1) {
      if (typeof response.errors[0].message === 'string') {
        return response.errors[0].message;
      }
    } else {
      const mappedErrors = response.errors.map((error) => {
        return error.message;
      });
      return mappedErrors.join(', ');
    }

    return 'An unknown error occured';
  }
  if (Array.isArray(response.errors.message)) return response.errors.message.join(', ');

  const errorArray = Object.keys(response.errors?.message).map((key) => {
    // @ts-ignore
    return `${key}: ${response.errors?.message[key]}`;
  });

  return errorArray.join(', ');
};

export const getErrorMessage = (error: unknown): string => {
  if (!error) {
    return 'An unknown error occured';
  }
  if (isAxiosError(error)) {
    if ((error.response?.data as StationErrorResponse)?.errors) {
      return buildStationErrorMessage(error.response?.data as StationErrorResponse);
    }
    // @ts-ignore
    return error.response.data?.message || error.message;
  }
  if (error instanceof Error) {
    return error?.message;
  }
  return 'An unknown error occured';
};
