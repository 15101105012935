import { Button } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useAfterLoginContext } from '../../after-login/context/useAfterLoginContext';

function StripeConnectButton() {
  const { shouldShowHeaderButton, handleCompleteAccountClick } = useAfterLoginContext();

  if (!shouldShowHeaderButton) {
    return null;
  }

  return (
    <Button
      sx={{
        backgroundColor: deepPurple[700],
        height: 30,
        fontSize: 13,
        fontWeight: 500,
        ':hover': {
          backgroundColor: deepPurple[600],
        },
      }}
      onClick={handleCompleteAccountClick}
      data-testid="stripe-redirect-button"
    >
      Complete Setup
    </Button>
  );
}

export default StripeConnectButton;
