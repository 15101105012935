import { useState } from 'react';
import { Box, Button, ButtonProps, Menu, SxProps, Typography } from '@mui/material';
import { FilterComponentProps } from 'app/features/reservations/types';
import colors from 'app/styles/colors';
import CloseIcon from 'app/components/icons/CloseIcon';
import ArrowUpIcon from 'app/components/icons/ArrowUpIcon';
import ArrowDownIcon from 'app/components/icons/ArrowDownIcon';

export interface FilterButtonProps extends ButtonProps {
  label: string;
  open?: boolean;
}

interface Props {
  btnProps: FilterButtonProps;
  disabled?: boolean;
  component: React.FunctionComponent<FilterComponentProps>;
  paperSX?: SxProps;
  componentProps: FilterComponentProps;
  hasFormattedlabel: boolean;
}

function Filter({
  btnProps,
  disabled,
  component: Component,
  paperSX,
  componentProps,
  hasFormattedlabel,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box data-testid={`${componentProps.label.replaceAll(' ', '-')}-filter`}>
      <Button
        {...btnProps}
        id={`${btnProps.label}-filter-button`}
        data-testid={`${btnProps.label}-filter-button`}
        aria-label={`${btnProps.label} filter`}
        aria-controls={open ? `${btnProps.label}-filter-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleButtonClick}
        disabled={disabled}
        title="test"
        sx={{
          backgroundColor: colors.grey[200],
          color: colors.grey[700],
          borderRadius: '4px',
          px: '10px',
          py: '6px',
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          ':hover': {
            backgroundColor: colors.grey[900],
            color: colors.white,
          },
          ...(open && {
            backgroundColor: colors.grey[900],
            color: colors.white,
          }),
          ...(hasFormattedlabel &&
            !open && {
              backgroundColor: colors.white,
              border: `1px solid ${colors.grey[400]}`,
            }),
        }}
      >
        <Typography
          fontSize="12px"
          fontWeight="450"
          lineHeight="110%"
          textTransform="uppercase"
          letterSpacing="0.48px"
        >
          {btnProps.label}
        </Typography>
        <Box sx={{ ml: 1 }}>
          {hasFormattedlabel && open === false && (
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation()
                componentProps.onApply({ [componentProps.filterKey]: undefined });
              }}
              data-testid={`${componentProps.filterKey}-clear-filter`}
            />
          )}
          {open ? <ArrowUpIcon /> : !hasFormattedlabel && <ArrowDownIcon />}
        </Box>
      </Button>

      <Menu
        id={`${btnProps.label}-filter-menu`}
        data-testid={`${btnProps.label}-filter-menu`}
        MenuListProps={{
          'aria-labelledby': `${btnProps.label}-filter-button`,
          sx: { p: 0 },
          role: 'none',
          tabIndex: -1,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        elevation={0}
        transitionDuration={{ exit: 0, enter: 200, appear: 200 }}
        PaperProps={{
          sx: {
            width: '378px',
            borderRadius: '4px',
            mt: 0.5,
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            backgroundColor: colors.white,
            ...paperSX,
          },
          role: 'dialog',
          'aria-label': 'Datepicker',
          tabIndex: -1,
        }}
      >
        {open && (
          <Box data-testid={`${btnProps.label}-filter-wrapper`} sx={{ display: 'flex', height: '100%' }}>
            <Component
              {...componentProps}
              onApply={(args) => {
                componentProps.onApply(args);
                handleMenuClose();
              }}
            />
          </Box>
        )}
      </Menu>
    </Box>
  );
}

export default Filter;
