import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import UserMenu from 'app/features/user/components/UserMenu';
import { format } from 'date-fns';
import StripeConnectButton from '../../../stripe-connect/components/StripeConnectButton';

function RightItems() {
  return (
    <Stack
      data-testid="top-nav-right"
      direction="row"
      component="nav"
      alignItems="center"
      justifyContent="space-between"
      spacing={4}
    >
      <StripeConnectButton />
      <Stack direction="row">
        <Typography
          component="p"
          variant="subtitle1"
          marginRight="8px"
          letterSpacing="0.02em"
          lineHeight="16px"
        >
          Today:{' '}
        </Typography>
        <Typography sx={{ fontWeight: 450, letterSpacing: '0.02em' }} component="p" variant="subtitle1">
          {format(new Date(), 'MMM d, yyyy')}
        </Typography>
      </Stack>
      <UserMenu />
    </Stack>
  );
}

export default RightItems;
