import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  tabelCellHeadStyles,
  getTableCellBodyStyles,
  tableRowClickableStyles,
} from 'app/components/selfbook-table/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { reservationTableBuilder } from '../lib/reservationsTable';
import { Reservation, ReservationsPage } from '../types';
import ReservationChip from './ReservationChip';

interface Props {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ReservationsPage, Error>>;
  dataLength: number;
  hasNextPage?: boolean;
  reservations: Reservation[];
  onReservationRowClick: (reservation: Reservation, disabled: boolean) => void;
}

function ReservationsTable({
  reservations = [],
  dataLength,
  hasNextPage,
  fetchNextPage,
  onReservationRowClick,
}: Props) {
  const COLUMNS = [
    { id: 'fname', label: 'First Name' },
    { id: 'lname', label: 'Last Name' },
    { id: 'reservationId', label: 'Booking ID' },
    { id: 'arrival', label: 'Arrival' },
    { id: 'departure', label: 'Departure' },
    { id: 'guests', label: 'Guests' },
    { id: 'status', label: 'Payment Status' },
  ];

  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={
        <Box sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      }
      style={{ overflow: 'hidden' }}
      data-testid="reservations-infinite-scroll"
    >
      <Table aria-label="Reservations table">
        <TableHead>
          <TableRow>
            {COLUMNS.map((column) => (
              <TableCell id={column.id} key={column.id} sx={tabelCellHeadStyles}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reservations.map((reservation: Reservation) => {
            const { firstName, lastName, reservationIdText, arrivalDate, departDate, guests, paymentStatus } =
              reservationTableBuilder(reservation);
            const disabled = paymentStatus === 'CONVEYED';
            const tableCellBodyStyles = getTableCellBodyStyles(disabled);
            return (
              <TableRow
                key={reservation.id}
                tabIndex={0}
                data-testid={`reservation-row-${reservation.id}`}
                sx={{ ...tableRowClickableStyles, cursor: 'pointer' }}
                onClick={() => onReservationRowClick(reservation, disabled)}
                onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
                  if (e.key === 'Enter') {
                    onReservationRowClick(reservation, disabled);
                  }
                }}
              >
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {firstName}
                </TableCell>
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {lastName}
                </TableCell>
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {reservationIdText}
                </TableCell>
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {arrivalDate}
                </TableCell>
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {departDate}
                </TableCell>
                <TableCell component="th" scope="row" sx={tableCellBodyStyles}>
                  {guests}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ ...tableCellBodyStyles, pr: '24px!important' }}>
                  <ReservationChip status={paymentStatus} sx={{ width: '100%', maxWidth: '185px' }} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
}

export default ReservationsTable;
