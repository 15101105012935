import { Box } from '@mui/material';

interface Props {
  wrapperTestId: string;
  name: string;
  videoUrl: string;
  children: React.ReactNode;
}

export default function RestrictedPage({ videoUrl, name, wrapperTestId, children }: Props) {
  return (
    <Box
      data-testid={wrapperTestId}
      sx={{ width: '100%', height: '100%', placeItems: 'center' }}
      display="grid"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={5}>
          <iframe
            title={name}
            src={videoUrl}
            width="912"
            height="483"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
