import { Tabs, Tab, SxProps } from '@mui/material';
import colors from 'app/styles/colors';

interface Props {
  selectedTab: number;
  changeTab: (_: React.SyntheticEvent, tab: number) => void;
  tabs: {
    name: string;
    value: number;
  }[];
  customSX?: SxProps;
  customTabsSX?: SxProps;
  ariaLabel?: string;
}

function TabsContainer({ selectedTab, changeTab, tabs, customSX, customTabsSX, ariaLabel = "content management tabs" }: Props) {
  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tab-${index}`,
    };
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={changeTab}
      aria-label={ariaLabel}
      sx={{
        background: colors.white,
        '> div > div': {
          columnGap: '4px',
        },
        '> div > span': {
          height: '4px',
        },
        '& .MuiTabs-indicator': {
          backgroundColor: colors.grey[900],
        },
        '& .Mui-selected': {
          color: colors.grey[900],
        },
        ...customSX,
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.name}
          sx={{
            textTransform: 'initial',
            width: '200px',
            alignItems: 'flex-start',
            borderBottom: `4px solid ${colors.grey[300]}`,
            letterSpacing: 'normal',
            fontSize: '16px',
            color: colors.grey[600],
            ...customTabsSX,
          }}
          {...getA11yProps(index)}
        />
      ))}
    </Tabs>
  );
}

export default TabsContainer;
