import RCInput, { CurrencyInputProps } from 'react-currency-input-field';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { Input, InputProps } from '@selfbook/design/dist/components/input';

type Props = InputProps &
  Omit<CurrencyInputProps, 'onValueChange'> & {
    onValueChange: (value: string, name?: string, values?: CurrencyInputOnChangeValues) => void;
  };

function PercentageInput({
  prefix = '% ',
  intlConfig = {
    locale: 'en-US',
  },
  onValueChange,
  ...rest
}: Props) {
  return (
    <RCInput
      customInput={Input}
      prefix={prefix}
      decimalsLimit={2}
      decimalScale={2}
      intlConfig={intlConfig}
      onValueChange={(value?: string, name?: string, values?: CurrencyInputOnChangeValues) =>
        onValueChange(value || '', name, values)
      }
      inputMode="numeric"
      {...rest}
    />
  );
}

export default PercentageInput;
