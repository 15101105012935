import { SxProps } from '@mui/material';

export const useSelfbookCheckboxStyles = ({ sx }: { sx?: SxProps }) => {
  const checkboxStyle: SxProps = {
    pt: 0.75,
    color: 'grey.900',
    '&.Mui-checked': {
      color: 'grey.900',
    },
  };

  const labelTypographyStyle: SxProps = {
    color: 'grey.700',
    fontSize: 14,
    fontWeight: 300,
    ml: 0.25,
  };

  const formControlStyle: SxProps = {
    alignItems: 'center',
    border: '0.75px solid',
    borderColor: 'transparent',
    pr: 1.25,
    '&:focus, &:focus-within': {
      borderColor: 'grey.500',
    },
    ...sx,
  };

  return { checkboxStyle, labelTypographyStyle, formControlStyle };
};
