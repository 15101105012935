export const IndexPath = 'app';

export enum TopLevelPaths {
  Main = 'main',
  Authentication = 'authentication',
  NewUser = 'new-user',
  SelfbookAdmin = 'admin',
}

export enum NewUserPaths {
  CreateInitialPassword = 'create-password',
  PasswordSetSuccessful = 'set-password-success',
}

export enum AuthenticationPaths {
  Login = 'login',
  ResetPasswordRequest = 'reset-password',
  ResetPasswordNew = 'set-password',
  PasswordResetSuccessful = 'reset-password-success',
  VerifyCode = 'verify-code',
  InvalidCode = 'code-invalid',
}

export enum MainPaths {
  Reservations = 'reservations',
  Payouts = 'payouts',
  Content = 'content',
  PaymentLinksManagement = 'payment-links',
}

export enum AdminPaths {
  Hotels = 'hotels',
  HotelById = 'hotels/:id',
}

export enum ContentPaths {
  Room = 'room',
  RoomById = 'room/:id',
}

// istanbul ignore next
export const TransactionReceiptPath = 'transaction_receipt';
export const PaymentLinkReceiptPath = 'payment_link_receipt';
