import { SxProps } from '@mui/material';
import Chip from 'app/components/chip/Chip';
import { getReservationPaymentStatusColors } from '../lib/reservationStatus';

function ReservationChip({ status, sx }: { status: string; sx?: SxProps }) {
  const resColors = getReservationPaymentStatusColors(status);
  return <Chip status={status} fontColor={resColors.fontColor} bgColor={resColors.bgColor} sx={sx} />;
}

export default ReservationChip;
