import { IconButton } from '@mui/material';
import CloseIcon from 'app/components/icons/CloseIcon';
import HamburgerIcon from 'app/components/icons/HamburgerIcon';
import { drawerClosedWidth } from 'app/components/side-drawer/useSideDrawerStyles';
import { useNavigationContext } from '../../context/useNavigationContext';

function SideNavigationToggle() {
  const { sideDrawerOpen, openSideDrawer, closeSideDrawer } = useNavigationContext();

  const handleClick = () => {
    if (sideDrawerOpen) closeSideDrawer();
    else openSideDrawer();
  };
  return (
    <IconButton
      data-testid="side-navigation-toggle"
      onClick={handleClick}
      sx={{
        color: 'white',
        width: drawerClosedWidth,
        backgroundColor: 'grey.800',
        borderRadius: 0,
        ':hover': { backgroundColor: 'grey.800' },
      }}
    >
      {sideDrawerOpen ? (
        <CloseIcon data-testid="side-navigation-close" aria-label="close side navigation" />
      ) : (
        <HamburgerIcon data-testid="side-navigation-open" aria-label="open side navigation" />
      )}
    </IconButton>
  );
}

export default SideNavigationToggle;
