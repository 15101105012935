import { RefObject, useEffect, useState } from 'react';
import { useTimeLineContext } from '../context/useTimeLineContext';

enum ValidQueryKeys {
  guestEmail = 'guest_information.email',
  guestName = 'guest_information.name',
  reservationId = 'id',
}

export enum SearchFilter {
  name = 'Guest name',
  email = 'Email',
  reservationId = 'Booking ID',
  all = 'All',
}

interface Props {
  onSearchQueryChange: (value: string) => void;
  anchorRef: RefObject<HTMLDivElement>;
}

const useReservationSearch = ({ onSearchQueryChange, anchorRef }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentFilter, setCurrentFilter] = useState<SearchFilter>(SearchFilter.all);
  const { setFilters } = useTimeLineContext();

  const generateDefaultQuery = (value: string) => {
    if (!value) return '';
    return `${ValidQueryKeys.guestEmail}~='${value}' OR ${ValidQueryKeys.guestName}~='${value}' OR ${ValidQueryKeys.reservationId}~='${value}'`;
  };

  const handleSearchFilterChange = (filter: SearchFilter) => {
    setCurrentFilter(filter);
    const i = anchorRef.current?.querySelector('input');
    i?.focus();
    setFilters({ status: 'All Bookings' });
  };

  const getFilterQuery = (value: string) => {
    let filteredQuery = generateDefaultQuery(value);

    if (currentFilter === SearchFilter.email) {
      filteredQuery = `${ValidQueryKeys.guestEmail}~='${value}'`;
    }
    if (currentFilter === SearchFilter.name) {
      filteredQuery = `${ValidQueryKeys.guestName}~='${value}'`;
    }
    if (currentFilter === SearchFilter.reservationId) {
      filteredQuery = `${ValidQueryKeys.reservationId}~='${value}'`;
    }

    return filteredQuery;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    setFilters({ status: 'All Bookings' });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace' && !searchTerm) {
      setCurrentFilter(SearchFilter.all);
    }
  };

  const updateSearchResults = () => {
    const filteredQuery = getFilterQuery(searchTerm);
    onSearchQueryChange(filteredQuery);
  };

  useEffect(() => {
    updateSearchResults();
  }, [searchTerm, currentFilter]);

  return { handleInputChange, handleSearchFilterChange, currentFilter, searchTerm, handleKeyDown };
};

export default useReservationSearch;
