import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';

const useRefundCharge = (hotelId: string, reservationId: string) => {
  const mutation = useMutation((request: { amount: number; id: string; description: string }) => {
    return stationApiAxios({
      url: `/rest/v1/hotels/${hotelId}/reservations/${reservationId}/transactions/`,
      method: 'POST',
      data: {
        amount: -request.amount,
        description: request.description,
        parent_transaction: {
          id: request.id,
        },
      },
    });
  });

  return mutation;
};

export default useRefundCharge;
