export const obfuscateEmailAddress = (emailAddress: string) => {
  if (!emailAddress) return null;
  const [name, domain] = emailAddress.split('@');
  if (!name || !domain) return emailAddress;
  const hashString = Array.from(Array(name.length - 1))
    .map(() => '*')
    .join('');
  const newName = `${name[0]}${hashString}`;
  return `${newName}@${domain}`;
};
