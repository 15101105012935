import Box from '@mui/material/Box';
import { NavigationContextProvider } from 'app/features/navigation/context/useNavigationContext';
import SideNavigation from 'app/features/navigation/side-navigation/components/SideNavigation';
import TopNavigation from 'app/features/navigation/top-navigation/components/TopNavigation';
import usePermissions from 'app/features/permissions/usePermissions';
import StationAdminAfterLogin from 'app/features/after-login/components/StationAdminAfterLogin';
import { AfterLoginContextProvider } from 'app/features/after-login/context/useAfterLoginContext';
import { IndexPath, MainPaths, TopLevelPaths } from 'app/routing/route-paths';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useUnAuthRedirect from './useUnAuthRedirect';

function MainSection() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userIsSelfbookAdmin, isFetchingPermissions } = usePermissions();

  useUnAuthRedirect();

  // If a CSM hits this page, send them to admin
  useEffect(() => {
    if (isFetchingPermissions) return;

    if (userIsSelfbookAdmin) {
      navigate(`../${TopLevelPaths.SelfbookAdmin}`);
    } else if (location.pathname === `/${IndexPath}/${TopLevelPaths.Main}`) {
      navigate(`${MainPaths.Reservations}`);
    }
  }, [userIsSelfbookAdmin, isFetchingPermissions]);

  return (
    <NavigationContextProvider>
      <AfterLoginContextProvider>
        <Box component="div" data-testid="main-section" sx={{ height: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
            <TopNavigation data-testid="top-section" />
            <Box sx={{ display: 'flex', width: '100%', marginTop: '62px', height: '100%' }}>
              <SideNavigation />
              <Box data-testid="main-section-content-container" minWidth="1200px" width="100%">
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Box>
        <StationAdminAfterLogin />
      </AfterLoginContextProvider>
    </NavigationContextProvider>
  );
}

export default MainSection;
