import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function IndexPageRedirect() {
  const navigate = useNavigate();

  // All of the React pages should be served from host.com/app
  // If someone lands on host.com/ -- redirect them to /app
  useEffect(() => {
    navigate(`/app`);
  }, []);

  return null;
}

export default IndexPageRedirect;
