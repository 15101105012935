import colors from 'app/styles/colors';

export const subHeaderTextStyles = {
  fontWeight: 300,
  letterSpacing: '0.01em',
  lineHeight: '16px',
};

export const datePickerFilterButtonStyles = (isOpen: boolean) => {
  return {
    display: 'flex',
    width: '201px',
    backgroundColor: 'transparent',
    color: colors.grey[700],
    border: '0',
    ...(isOpen && { backgroundColor: colors.grey[700], color: colors.white }),
    '&:hover': { backgroundColor: colors.grey[700], color: colors.white },
  };
};

export const filterButtonArrowsStyles = {
  marginLeft: '20.5px',
  width: '15px',
  height: '9px',
};

export const datePickerMenuPaperStyles = {
  width: '420px',
  filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,0.1))',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  mt: 1,
};
