import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import useLogout from 'app/hooks/useLogout';
import { TopLevelPaths } from 'app/routing/route-paths';
import { useUser } from 'app/features/authentication/context/useUser';
import usePermissions from 'app/features/permissions/usePermissions';
import UserMenuButton from './UserMenuButton';
import UserMenuContent from './UserMenuContent';

function UserMenu() {
  const logout = useLogout();
  const { userInitials, userName, currentUserHotelRole, userEmail } = useUser();
  const { userIsSelfbookAdmin, userIsMasqueradingAdmin } = usePermissions();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isSelfbookAdmin = userIsSelfbookAdmin || userIsMasqueradingAdmin;
  const isAdminRoute = location.pathname.includes(TopLevelPaths.SelfbookAdmin);
  const signOutText = isSelfbookAdmin && !isAdminRoute ? 'Return to admin panel' : 'Sign out';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAvatarText = () => {
    if (isSelfbookAdmin) {
      return 'SELFBOOK';
    }
    return userInitials.toUpperCase();
  };

  // istanbul ignore next
  const displayUserName = userName && userName?.length > 15 ? `${userName?.substring(0, 15)}...` : userName;
  // istanbul ignore next
  const displayUEmail = userEmail && userEmail?.length > 15 ? `${userEmail?.substring(0, 15)}...` : userEmail;

  return (
    <Fragment>
      <UserMenuButton
        onClick={handleClick}
        open={open}
        avatarText={getAvatarText()}
        isSelfbookAdmin={isSelfbookAdmin}
      />
      <UserMenuContent
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        userName={displayUserName || displayUEmail}
        currentUserHotelRole={currentUserHotelRole}
        onLogoutClick={logout}
        signOutText={signOutText}
      />
    </Fragment>
  );
}

export default UserMenu;
