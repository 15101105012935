export const formatNumberToPrice = ({
  price,
  locale = 'en-US',
  currency = 'USD',
  maximumFractionDigits = 2,
}: {
  price: number;
  locale?: string;
  currency?: string;
  maximumFractionDigits?: number;
}): string => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });

  return formattedPrice.format(price);
};

export const roundPrice = (priceNotInCents: number) => {
  const roundedAmount = priceNotInCents.toFixed(2);
  return Math.round(+roundedAmount * 100);
};
