import { Fragment, useState } from 'react';
import { DayPicker, Matcher, SelectSingleEventHandler } from 'react-day-picker';
import { Box, Menu, SxProps } from '@mui/material';
import InputLikeButton, { InputLikeButtonProps } from './InputLikeButton';
import SelfbookCaption from './SelfbookCaption';
import { formatWeekdayNameSingleLetter } from './utils';
import { inputDatePickerCss } from './inputDatePickerCss';
import 'react-day-picker/dist/style.css';

interface Props {
  ButtonProps: InputLikeButtonProps;
  onDateSelect: SelectSingleEventHandler;
  selectedDate?: Date;
  disabled?: boolean;
  defaultMonth?: Date;
  fromMonth?: Date;
  disabledDays?: Matcher | Matcher[] | undefined;
  paperSX?: SxProps;
}

function InputDatePicker({
  ButtonProps,
  onDateSelect,
  selectedDate,
  disabled,
  defaultMonth,
  fromMonth,
  disabledDays,
  paperSX,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect: SelectSingleEventHandler = (...args) => {
    onDateSelect(...args);
    handleMenuClose();
  };

  return (
    <Fragment>
      <style>{inputDatePickerCss}</style>

      <div>
        <InputLikeButton
          {...ButtonProps}
          id={`${ButtonProps.label}-datepicker-button`}
          data-testid={`${ButtonProps.label}-datepicker-button`}
          aria-label={`${ButtonProps.label} datepicker`}
          aria-controls={open ? `${ButtonProps.label}-datepicker-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleButtonClick}
          disabled={disabled}
        />

        <Menu
          id={`${ButtonProps.label}-datepicker-menu`}
          data-testid={`${ButtonProps.label}-datepicker-menu`}
          MenuListProps={{
            'aria-labelledby': `${ButtonProps.label}-datepicker-button`,
            sx: { p: 0 },
            role: 'none',
            tabIndex: -1,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          elevation={0}
          transitionDuration={{ exit: 0, enter: 400, appear: 400 }}
          PaperProps={{
            sx: {
              width: '288px',
              filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,0.1))',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '4px',
              mt: 1,
              ...paperSX,
            },
            role: 'dialog',
            'aria-label': 'Datepicker',
            tabIndex: -1,
          }}
        >
          {open && (
            <Box
              data-testid={`${ButtonProps.label}-datepicker-wrapper`}
              sx={{ display: 'flex', justifyContent: 'center', height: '100%', p: 2 }}
            >
              <DayPicker
                initialFocus={open}
                mode="single"
                today={selectedDate}
                formatters={{ formatWeekdayName: formatWeekdayNameSingleLetter }}
                selected={selectedDate}
                onSelect={handleDateSelect}
                defaultMonth={defaultMonth}
                fromMonth={fromMonth}
                disabled={disabledDays}
                components={{
                  Caption: SelfbookCaption,
                }}
              />
            </Box>
          )}
        </Menu>
      </div>
    </Fragment>
  );
}

export default InputDatePicker;
