import { FormHelperText, SxProps, TypographyProps } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import colors from 'app/styles/colors';
import { Fragment, ReactNode } from 'react';
import { useSelfbookCheckboxStyles } from './useSelfbookCheckboxStyles';

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  name: string;
  label: string | ReactNode;
  labelTypographyProps?: TypographyProps;
  checkboxProps?: CheckboxProps;
  sx?: SxProps;
  error?: string;
}

function SelfbookCheckbox({
  checked,
  name,
  onChange,
  checkboxProps,
  label,
  labelTypographyProps,
  sx,
  error,
}: Props) {
  const { checkboxStyle, labelTypographyStyle, formControlStyle } = useSelfbookCheckboxStyles({ sx });
  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            sx={checkboxStyle}
            size="small"
            checked={checked}
            onChange={onChange}
            name={name}
            {...checkboxProps}
          />
        }
        componentsProps={{
          typography: {
            sx: labelTypographyStyle,
            ...labelTypographyProps,
          },
        }}
        sx={{
          ...formControlStyle,
          ...(error && {
            borderColor: colors.critical[600],
            borderRadius: 1
          }),
        }}
        label={label}
      />
      {error && <FormHelperText sx={{ color: colors.critical[600] }}>{error}</FormHelperText>}
    </Fragment>
  );
}

export default SelfbookCheckbox;
