import Button, { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';

interface Props extends Omit<ButtonProps, 'href'> {
  to: string;
}

function ReactRouterLinkButton({ to, ...rest }: Props) {
  // @ts-ignore -- <Link> needs the "to" prop, which Material TS doesn't like
  return <Button LinkComponent={Link} to={to} {...rest} />;
}

export default ReactRouterLinkButton;
