import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownIcon from 'app/components/icons/ArrowDownIcon';
import ArrowUpIcon from 'app/components/icons/ArrowUpIcon';
import ReceiptIcon from 'app/components/icons/ReceiptIcon';
import Spacer from 'app/components/spacer/Spacer';
import colors from 'app/styles/colors';
import uniqueId from 'lodash/uniqueId';
import ReturnIcon from 'app/components/icons/ReturnIcon';
import SendIcon from 'app/components/icons/SendIcon';
import { ApiReservationTransactionStatus, ScheduledTransactionStatus } from '../types';
import { ReservationChargeUi, TransactionBreakdownItem } from '../sidebar-layer/roomCharge';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import { showPaymentAutomationCanceledText } from '../sidebar-layer/paymentAutomation';

// istanbul ignore next
const getStatusStyles = (status: 'CONVEYED' | 'FAILED' | 'PAID' | 'PARTIAL' | 'PENDING' | 'UNPAID') => {
  switch (status) {
    case 'FAILED':
      return { color: colors.critical[800], backgroundColor: colors.critical[200] };
    case 'PARTIAL':
      return { color: colors.grey[800], backgroundColor: colors.warning[200] };
    case 'PENDING':
      return { color: colors.grey[900], backgroundColor: colors.grey[400] };
    case 'UNPAID':
      return { color: colors.grey[900], backgroundColor: colors.grey[300] };
    default:
      return { color: colors.success[800], backgroundColor: colors.success[200] };
  }
};

// istanbul ignore next
const getTransactionItemStatusColor = (
  status: ScheduledTransactionStatus | ApiReservationTransactionStatus,
) => {
  switch (status) {
    case 'FAILED':
    case 'ABANDONED':
      return colors.critical[600];
    case 'PENDING':
      return colors.grey[300];
    case 'SUCCESSFUL':
      return colors.success[600];
    default:
      return colors.grey[300];
  }
};

interface Props {
  charge: ReservationChargeUi;
}

export default function ReservationCharge({ charge }: Props) {
  const { openRefundCharge, triggerScheduledTransactionPaymentLink } = useReservationDetailContext();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const lineItems = charge.lineItems.map((lineItem) => {
    return (
      <Stack
        key={`${lineItem}-${uniqueId()}`}
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 4,
        }}
        component="dl"
      >
        <Typography fontSize={16} fontWeight={300} color={colors.grey[700]} component="dt">
          {lineItem.name}
        </Typography>

        <Typography fontSize={16} fontWeight={450} color={colors.grey[700]} sx={{ ml: 1 }} component="dd">
          {lineItem.amount}
        </Typography>
      </Stack>
    );
  });

  const createdAtDate = (
    <Typography fontSize={16} fontWeight={450} color={colors.grey[700]}>
      {charge.createdAt}
    </Typography>
  );

  const transactionItemDivider = <Spacer height={8} />;

  const refundButton = (chargeBreakdownItem: TransactionBreakdownItem) => {
    if (!chargeBreakdownItem.refundAction) return null;

    return (
      <Tooltip title={`Refund ${chargeBreakdownItem.name}`} placement="top">
        <IconButton
          data-testid={`refund-${chargeBreakdownItem.name}`}
          sx={{ backgroundColor: colors.white, borderRadius: '4px', height: 40, width: 40, p: 1 }}
          onClick={() => openRefundCharge(chargeBreakdownItem.refundAction!)}
        >
          <ReturnIcon aria-label={`Refund ${chargeBreakdownItem.name}`} style={{ color: colors.grey[700] }} />
        </IconButton>
      </Tooltip>
    );
  };

  const receiptButton = (chargeBreakdownItem: TransactionBreakdownItem) => {
    if (!chargeBreakdownItem.viewReceiptAction) return null;

    return (
      <Tooltip title="View Receipt" placement="top">
        <IconButton
          data-testid={`receipt-${chargeBreakdownItem.name}`}
          sx={{ backgroundColor: colors.white, borderRadius: '4px', height: 40, width: 40, p: 1 }}
          onClick={() =>
            window.open(
              `${window.location.origin}/transaction_receipt?id=${
                chargeBreakdownItem.viewReceiptAction!.chargeId
              }&hotelId=${
                chargeBreakdownItem.viewReceiptAction!.hotelId
              }`,
              '_blank',
            )
          }
        >
          <ReceiptIcon aria-label={`Receipt ${chargeBreakdownItem.viewReceiptAction.chargeId}`} />
        </IconButton>
      </Tooltip>
    );
  };

  const sendPaymentLinkButton = (chargeBreakdownItem: TransactionBreakdownItem) => {
    if (!chargeBreakdownItem.paymentLinkAction) return null;

    return (
      <Tooltip title="Send payment request" placement="top">
        <IconButton
          data-testid={`send-payment-link-${chargeBreakdownItem.name}`}
          sx={{ backgroundColor: colors.white, borderRadius: '4px', height: 40, width: 40, p: 1 }}
          onClick={() => triggerScheduledTransactionPaymentLink(chargeBreakdownItem.paymentLinkAction!)}
        >
          <SendIcon aria-label="Send payment request" height={20} width={20} />
        </IconButton>
      </Tooltip>
    );
  };

  const transactionItems = charge.transactionItems.map((transactionItem) => {
    const statusIcon = (
      <Box
        aria-label={transactionItem.status}
        sx={{
          backgroundColor: getTransactionItemStatusColor(transactionItem.status),
          borderRadius: 9999,
          height: 12,
          width: 12,
        }}
      />
    );

    return (
      <Stack
        key={`${transactionItem.name}-${uniqueId()}`}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2, backgroundColor: colors.grey[100], pr: 4 }}
      >
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontSize={16} fontWeight={500} color={colors.grey[900]}>
              {transactionItem.name}
            </Typography>
            {transactionItem.associatedTransactionId && (
              <Typography fontSize={12} fontWeight={300} color={colors.grey[900]}>
                {transactionItem.associatedTransactionId}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {statusIcon}
            <Typography fontSize={12} fontWeight={500} color={colors.grey[700]}>
              {transactionItem.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {refundButton(transactionItem)}
          {receiptButton(transactionItem)}
          {sendPaymentLinkButton(transactionItem)}
          <Typography fontSize={16} fontWeight={500} color={colors.grey[900]}>
            {transactionItem.amount}
          </Typography>
        </Stack>
      </Stack>
    );
  });

  // istanbul ignore next
  const stopPaymentAutomation = (
    <Box>
      {showPaymentAutomationCanceledText(charge) && (
        <Typography textAlign="right" fontSize="14px" pt={1} sx={{ color: colors.grey[800] }}>
          Payment automation is stopped.
        </Typography>
      )}
    </Box>
  );

  return (
    <Card
      sx={{
        boxShadow: 'none',
        transition: 'all 0.3s',
        borderBottom: `1px solid ${colors.grey[200]}`,
        borderRadius: 0,
        py: '17px',
      }}
    >
      <CardActions sx={{ pt: 0, p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pr: '20.5px',
            flex: 1,
          }}
          component="dl"
        >
          <Typography
            fontSize="18px"
            fontWeight={300}
            lineHeight="18px"
            color={colors.grey[700]}
            flexWrap="wrap"
            component="dt"
            sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
          >
            <Box
              component="span"
              sx={{
                maxWidth: '250px',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {charge.name}
            </Box>
          </Typography>

          <Typography
            fontSize="16px"
            fontWeight={450}
            lineHeight="20.8px"
            letterSpacing="0.02em"
            color={colors.grey[700]}
            sx={{ ml: 1, alignItems: 'center', display: 'flex' }}
            component="dd"
            flexShrink={0}
          >
            {charge.status && (
              <Box component="span" sx={{ display: 'inline-flex', mr: 2 }}>
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight={450}
                  letterSpacing="0.04em"
                  sx={{
                    ...getStatusStyles(charge.status),
                    pt: '6.5px',
                    pb: '5.5px',
                    px: 1,
                    borderRadius: '4px',
                  }}
                  data-testid="reservation-charge-status"
                >
                  {charge.status}
                </Typography>
              </Box>
            )}

            <Box component="span">{charge.price}</Box>
          </Typography>
        </Box>
        <IconButton
          aria-expanded={!!isExpanded}
          key={charge.id}
          data-testid={`expand-transaction-details-${charge.id}`}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label="expand"
          size="small"
        >
          {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ pl: 0, pr: 2 }}>
        <Stack spacing={0.5} sx={{ mt: 1 }}>
          {lineItems}
          {createdAtDate}
          {charge.transactionItems.length > 0 && (
            <Fragment>
              {transactionItemDivider}
              {transactionItems}
              {stopPaymentAutomation}
            </Fragment>
          )}
        </Stack>
      </Collapse>
    </Card>
  );
}
