import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { CaptionProps, useNavigation } from 'react-day-picker';
import colors from 'app/styles/colors';
import ChevronLeftIcon from 'app/components/icons/ChevronLeftIcon';
import ChevronRightIcon from 'app/components/icons/ChevronRightIcon';

function SelfbookCaption(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const { displayMonth } = props;
  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button
        variant="textMuted"
        data-testid="menu-chevron-left-button"
        sx={{
          ':hover,:focus, :focus-within': { border: 0 },
          border: 0,
          justifyContent: 'flex-start',
          pl: 1,
        }}
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        aria-label="previous month"
      >
        <ChevronLeftIcon />
      </Button>
      <Typography
        fontSize={18}
        letterSpacing="0.01em"
        color={colors.grey[700]}
        fontWeight={450}
        lineHeight="130%"
        data-testid="menu-date-text"
        className="sb-caption-month"
      >
        {format(displayMonth, 'MMMM yyyy')}
      </Typography>
      <Button
        variant="textMuted"
        sx={{
          ':hover,:focus, :focus-within': { border: 0 },
          border: 0,
          justifyContent: 'flex-end',
          pr: 1,
        }}
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        aria-label="next month"
        data-testid="menu-chevron-right-button"
      >
        <ChevronRightIcon />
      </Button>
    </Box>
  );
}

export default SelfbookCaption;
