import { CardOnRecord, InvoiceLine, Reservation, ReservationStatus } from '../types';
import {
  getReservationAdditionalCharges,
  getAdditionalChargeBreakdown,
  buildAdditionalChargesUi,
} from './additionalCharges';
import {
  getAllInvoiceLines,
  getReservationTransactionTotalAmount,
  getReservationCurrency,
  getPaymentMethods,
} from './data';
import { FooterUi, buildFooterUi } from './footer';
import { buildHeaderUi, HeaderUi } from './header';
import { getRefundTransactions, buildRefundsUi, buildRefundsUiItems, RefundsUi } from './refunds';
import {
  getRoomChargeInvoiceLine,
  getRoomChargeTransactions,
  getRoomChargesTotalAmount,
  getRoomChargeScheduledTransactions,
  buildRoomChargeUi,
  ReservationChargeUi,
} from './roomCharge';
import { RoomsUi, buildRoomsUi } from './rooms';
import { SubheaderUi, buildSubheaderUi } from './subheader';

export type PaymentsUi = {
  payments: ReservationChargeUi[];
};

export type ReservationSidebar = {
  headerUi: HeaderUi;
  subheaderUi: SubheaderUi;
  roomsUi: RoomsUi;
  footerUi: FooterUi;
  paymentsUi: PaymentsUi;
  refundsUi: RefundsUi;
  paymentMethods: CardOnRecord[] | null;
  roomChargeInvoiceLine: InvoiceLine | undefined;
  bookingStatus: ReservationStatus | undefined;
};

const emptySidebarState: ReservationSidebar = {
  headerUi: {
    customerName: '',
    customerInitials: '',
  },
  subheaderUi: {
    arriveDate: '',
    departDate: '',
    customerPhone: '',
    customerEmail: '',
    reservationId: '',
  },
  roomsUi: {
    rooms: [],
  },
  footerUi: {
    reservationTotal: '',
    showAddChargesButton: false,
  },
  paymentsUi: { payments: [] },
  refundsUi: { refunds: [] },
  paymentMethods: [
    {
      paymentToken: undefined,
      cardBrand: undefined,
      cardLast4: undefined,
      gateway: undefined,
      customerId: undefined,
    },
  ],
  roomChargeInvoiceLine: undefined,
  bookingStatus: undefined,
};

export const getReservationSidebar = (apiData?: Reservation): ReservationSidebar => {
  if (!apiData) return emptySidebarState;

  const allInvoiceLines = getAllInvoiceLines(apiData);
  const roomChargeInvoiceLine = getRoomChargeInvoiceLine(allInvoiceLines);

  const roomChargeTransactions = getRoomChargeTransactions(roomChargeInvoiceLine);
  const roomChargeChargesTotalAmount = getRoomChargesTotalAmount(roomChargeInvoiceLine);

  const paymentMethods = getPaymentMethods(apiData);

  const roomChargeRefunds = getRefundTransactions(roomChargeTransactions);
  const roomChargeRefundTotalAmount = getReservationTransactionTotalAmount(roomChargeRefunds);
  const roomChargeScheduledTransactions = getRoomChargeScheduledTransactions(apiData);
  const additionalCharges = getReservationAdditionalCharges(allInvoiceLines);
  const additionalChargeBreakdown = getAdditionalChargeBreakdown(additionalCharges);

  const additionalChargeCharges = additionalChargeBreakdown.charges;
  const additionalChargeChargesTotalAmount = getReservationTransactionTotalAmount(additionalChargeCharges);

  const additionalChargeRefunds = additionalChargeBreakdown.refunds;
  const additionalChargeRefundsTotalAmount = getReservationTransactionTotalAmount(additionalChargeRefunds);

  const totalChargesAmount = roomChargeChargesTotalAmount + additionalChargeChargesTotalAmount;
  const totalRefundsAmount = additionalChargeRefundsTotalAmount + roomChargeRefundTotalAmount;
  const reservationTotal = totalChargesAmount - totalRefundsAmount;
  const currency = getReservationCurrency(roomChargeInvoiceLine);

  const roomChargeUi = buildRoomChargeUi(roomChargeInvoiceLine, roomChargeScheduledTransactions);
  const additionalChargesUi = buildAdditionalChargesUi(additionalChargeCharges);
  const roomChargeRefundsUiItems = buildRefundsUiItems(roomChargeRefunds, { isRoomChargeRefund: true });
  const additionalChargesRefundsUiItems = buildRefundsUiItems(additionalChargeRefunds);
  const refundsUi = buildRefundsUi([...roomChargeRefundsUiItems, ...additionalChargesRefundsUiItems]);
  const payments = [...additionalChargesUi];
  // istanbul ignore next
  if (roomChargeUi) {
    payments.unshift(roomChargeUi);
  }

  const paymentsUi = { payments };

  const headerUi = buildHeaderUi(apiData);
  const subheaderUi = buildSubheaderUi(apiData);
  const roomsUi = buildRoomsUi(apiData);
  const footerUi = buildFooterUi(reservationTotal, currency, paymentMethods);

  const bookingStatus = apiData.status;
  const sidebar = {
    headerUi,
    subheaderUi,
    roomsUi,
    footerUi,
    paymentsUi,
    refundsUi,
    paymentMethods,
    roomChargeInvoiceLine,
    bookingStatus,
  };

  return sidebar;
};
