import Stack from '@mui/material/Stack';
import { Box, Grid, Typography } from '@mui/material';

function PageNotFound() {
  return (
    <Grid
      container
      sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}
      data-testid="initial-screen"
    >
      <Grid item>
        <Box
          sx={{
            py: 6,
            px: 4,
            width: { sm: '400px' },
            mt: -5,
          }}
        >
          <Stack sx={{ alignItems: 'center', width: '100%' }}>
            <Typography component="h1" variant="h1">
              Page Not Found
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PageNotFound;
