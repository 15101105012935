import { SVGProps } from 'react';

function ReservationsIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="reservations-icon"
      {...props}
    >
      <path
        d="M12.75 21.5C11.5633 21.5 10.4033 21.1481 9.41658 20.4888C8.42988 19.8295 7.66085 18.8925 7.20672 17.7961C6.7526 16.6997 6.63378 15.4933 6.86529 14.3295C7.0968 13.1656 7.66824 12.0965 8.50736 11.2574C9.34647 10.4182 10.4156 9.8468 11.5795 9.61529C12.7433 9.38378 13.9497 9.5026 15.0461 9.95672C16.1425 10.4108 17.0795 11.1799 17.7388 12.1666C18.3981 13.1533 18.75 14.3133 18.75 15.5C18.75 17.0913 18.1179 18.6174 16.9926 19.7426C15.8674 20.8679 14.3413 21.5 12.75 21.5ZM12.75 11C11.86 11 10.99 11.2639 10.2499 11.7584C9.50991 12.2529 8.93314 12.9557 8.59254 13.7779C8.25195 14.6002 8.16283 15.505 8.33647 16.3779C8.5101 17.2508 8.93868 18.0526 9.56802 18.682C10.1974 19.3113 10.9992 19.7399 11.8721 19.9135C12.745 20.0872 13.6498 19.9981 14.4721 19.6575C15.2943 19.3169 15.9971 18.7401 16.4916 18.0001C16.9861 17.26 17.25 16.39 17.25 15.5C17.25 14.3065 16.7759 13.1619 15.932 12.318C15.0881 11.4741 13.9435 11 12.75 11Z"
        fill="white"
      />
      <path d="M13.9425 17.75L12 15.8075V12.5H13.5V15.1925L15 16.6925L13.9425 17.75Z" fill="white" />
      <path
        d="M18 3.5C18 3.10218 17.842 2.72064 17.5607 2.43934C17.2794 2.15804 16.8978 2 16.5 2H13.5V0.5H12V2H6V0.5H4.5V2H1.5C1.10218 2 0.720644 2.15804 0.43934 2.43934C0.158035 2.72064 0 3.10218 0 3.5V18.5C0 18.8978 0.158035 19.2794 0.43934 19.5607C0.720644 19.842 1.10218 20 1.5 20H4.5V18.5H1.5V3.5H4.5V5H6V3.5H12V5H13.5V3.5H16.5V8H18V3.5Z"
        fill="white"
      />
    </svg>
  );
}

export default ReservationsIcon;
