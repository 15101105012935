export enum CRSType {
  OPERA = 'OPERA',
  SIMULATOR = 'SIMULATOR',
  STATION = 'STATION',
  SYNXIS = 'SYNXIS',
  TRAVELCLICK = 'TRAVELCLICK',
}

export enum HotelStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  INITIALIZED = 'INITIALIZED',
  STRIPE_SETUP_STARTED = 'STRIPE_SETUP_STARTED',
  STRIPE_SETUP_ONGOING = 'STRIPE_SETUP_ONGOING',
  STRIPE_SETUP_COMPLETE = 'STRIPE_SETUP_COMPLETE',
}

export type Hotel = {
  id: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  spreedly: any; // TODO: update the type later once we use it
  status: HotelStatus;
  email: string;
  telephone_number: string;
  logo_url: string;
  stripe?: {
    connected_account_id?: string;
    connected_account_link?: string;
    connected_account_publishable_key?: string;
  };
  crs_type: CRSType;
  currency: string;
  address: string;
};

export type HotelMetrics = {
  created_at: string;
  updated_at: string;
  hotel: {
    id: string;
  };
  hotel_group?: {
    id?: string;
  };
  template: {
    id: number;
    data: {
      total_reservations: number;
      total_reservations_using_affirm: number;
      total_reservations_using_apple_pay: number;
      total_reservations_using_cards: number;
      total_reservations_using_google_pay: number;
      add_on_amount?: number;
      add_on_currency?: string;
      total_reservations_amount?: number;
      total_reservations_currency?: string;
      total_reservations_using_direct?: number;
      percent_change_using_mobile?: number;
      percent_change_using_desktop?: number;
      total_reservations_using_desktop?: number;
      total_reservations_using_mobile?: number;
      amount?: number;
      date?: string;
      currency?: string;
    };
  };
};
