import FullCalendar from '@fullcalendar/react';
import React, { createContext, useContext, useMemo, useRef, useState } from 'react';

export type ReservationsFilter = {
  selectedDay?: Date;
  status?: string;
}

type State = {
  timelineRef: React.RefObject<FullCalendar> | null;
  setFilters: (filters: ReservationsFilter) => void;
  filters: ReservationsFilter;
};

const TimeLineContext = createContext<State | undefined>(undefined);

function TimeLineContextProvider({ children }: { children: React.ReactNode }) {
  const timelineRef = useRef<FullCalendar | null>(null);
  const [filters, setFilters] = useState<ReservationsFilter>({ status: 'All Bookings' });


  const value = useMemo(
    () => ({
      timelineRef,
      filters,
      setFilters,
    }),
    [timelineRef, filters, setFilters],
  );

  return <TimeLineContext.Provider value={value}>{children}</TimeLineContext.Provider>;
}

const useTimeLineContext = () => {
  const context = useContext(TimeLineContext);
  if (context === undefined) {
    throw new Error('useTimeLineContext must be used within a TimeLineContext');
  }
  return context;
};

export { useTimeLineContext, TimeLineContextProvider };
