export function getChartWidth(is1280View: boolean, is1440View: boolean, is1680View: boolean) {
  if (is1280View) {
    return 295;
  }

  if (is1440View || is1680View) {
    return 317;
  }

  return 361;
}

export function getChartHeight(is1280View: boolean, is1440View: boolean, is1680View: boolean) {
  if (is1280View) {
    return 220;
  }

  if (is1440View || is1680View) {
    return 225.11;
  }

  return 256;
}
