import { SVGProps } from 'react';

function SignOutIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
            data-testid="signout-icon"
            {...props}>
            <path d="M1.75 17.75H9.25C9.5814 17.7496 9.89913 17.6178 10.1335 17.3835C10.3678 17.1491 10.4996 16.8314 10.5 16.5V14.625H9.25V16.5H1.75V1.5H9.25V3.375H10.5V1.5C10.4996 1.1686 10.3678 0.850875 10.1335 0.616537C9.89913 0.382199 9.5814 0.25038 9.25 0.25H1.75C1.4186 0.25038 1.10087 0.382199 0.866537 0.616537C0.632199 0.850875 0.50038 1.1686 0.5 1.5V16.5C0.50038 16.8314 0.632199 17.1491 0.866537 17.3835C1.10087 17.6178 1.4186 17.7496 1.75 17.75Z" fill="#495057" />
            <path d="M10.8663 11.8663L13.1075 9.625H4.25V8.375H13.1075L10.8663 6.13375L11.75 5.25L15.5 9L11.75 12.75L10.8663 11.8663Z" fill="#495057" />
        </svg>

    );
}

export default SignOutIcon;
