import { Box, Stack, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import ReservationsSearch from './ReservationsSearch';

interface Props {
  onSearchQueryChange: (value: string) => void;
  navigationComponent?: ReactNode;
  sx?: SxProps;
}

function ReservationsActionsBar({ onSearchQueryChange, navigationComponent, sx }: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderRadius: 1,
        ...sx,
      }}
    >
      {navigationComponent || <Box />}
      <Box sx={{ width: '320px' }}>
        <ReservationsSearch onSearchQueryChange={onSearchQueryChange} />
      </Box>
    </Stack>
  );
}

export default ReservationsActionsBar;
