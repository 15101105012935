import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { LayersPaths } from './route-paths';

const LayersSection = lazy(() => import('app/layers/entry'));
const IndexRoute = lazy(() => import('app/layers/index-route'));
const RoomsLayout = lazy(() => import('app/layers/features/rooms/components/RoomsLayout'));
const RoomsList = lazy(() => import('app/layers/features/rooms/screens/RoomsList'));
const RoomDetailLayout = lazy(() => import('app/layers/features/room-detail/components/RoomDetailLayout'));
const RoomDetailEntry = lazy(() => import('app/layers/features/room-detail/entry'));
const RoomDetailImages = lazy(
  () => import('app/layers/features/room-detail/images/screens/RoomDetailImages'),
);
const RoomDetailInformation = lazy(
  () => import('app/layers/features/room-detail/information/screens/RoomDetailInformation'),
);
const SettingsLayout = lazy(() => import('app/layers/features/settings/components/SettingsLayout'));
const SettingsIndex = lazy(() => import('app/layers/features/settings/entry'));
const GeneralSettings = lazy(() => import('app/layers/features/settings/general/screens/GeneralSettings'));
const GuestSettings = lazy(() => import('app/layers/features/settings/guests/screens/GuestSettings'));

const WorkflowsLayout = lazy(() => import('app/layers/features/workflows/components/WorkflowsLayout'));
const WorkflowsList = lazy(() => import('app/layers/features/workflows/screens/WorkflowsList'));
const WorkflowDetailLayout = lazy(
  () => import('app/layers/features/workflow-detail/components/WorkflowDetailLayout'),
);
const WorkflowDetailEntry = lazy(() => import('app/layers/features/workflow-detail/entry'));
const WorkflowDetailTheme = lazy(
  () => import('app/layers/features/workflow-detail/theme/screens/ThemeBuilder'),
);

export const LayersRouter = (
  <Route path={LayersPaths.TopLevel} element={<LayersSection />}>
    <Route index element={<IndexRoute />} />
    <Route path={LayersPaths.Settings} element={<SettingsLayout />}>
      <Route index element={<SettingsIndex />} />
      <Route path={LayersPaths.GeneralSettings} element={<GeneralSettings />} />
      <Route path={LayersPaths.GuestSettings} element={<GuestSettings />} />
    </Route>
    <Route path={LayersPaths.Rooms} element={<RoomsLayout />}>
      <Route index element={<RoomsList />} />
      <Route path={LayersPaths.RoomDetail} element={<RoomDetailLayout />}>
        <Route index element={<RoomDetailEntry />} />
        <Route path={LayersPaths.RoomDetailImages} element={<RoomDetailImages />} />
        <Route path={LayersPaths.RoomDetailInformation} element={<RoomDetailInformation />} />
      </Route>
    </Route>
    <Route path={LayersPaths.Workflows} element={<WorkflowsLayout />}>
      <Route index element={<WorkflowsList />} />
      <Route path={LayersPaths.WorkflowDetail} element={<WorkflowDetailLayout />}>
        <Route index element={<WorkflowDetailEntry />} />
        <Route path={LayersPaths.WorkflowDetailTheme} element={<WorkflowDetailTheme />} />
      </Route>
    </Route>
  </Route>
);
