import Box from '@mui/material/Box';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function SrOnlyText({ children }: Props) {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        borderWidth: 0,
      }}
      data-testid="sr-only-text"
      component="span"
    >
      {children}
    </Box>
  );
}

export default SrOnlyText;
