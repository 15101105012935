import { formatNumberToPrice } from 'app/lib/numbers';
import { CardOnRecord } from '../types';

export type FooterUi = {
  reservationTotal: string;
  showAddChargesButton: boolean;
};

export const buildFooterUi = (
  reservationTotal: number,
  currency: string,
  paymentMethods: CardOnRecord[] | null,
): FooterUi => {
  const formattedTotal = formatNumberToPrice({
    price: reservationTotal / 100,
    currency,
  });

  const shouldShowAddChargeButton =
    !!paymentMethods && paymentMethods.length > 0 && !!paymentMethods[0].cardBrand && !!paymentMethods[0].cardLast4;

  const footerUi = {
    reservationTotal: formattedTotal,
    showAddChargesButton: shouldShowAddChargeButton,
  };

  return footerUi;
};
