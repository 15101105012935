import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReactRouterLinkButton from 'app/components/react-router-link-button/ReactRouterLinkButton';
import { AuthenticationPaths } from 'app/routing/route-paths';

function InvalidCodeScreen() {
  return (
    <Box data-testid="invalid-code" sx={{ maxWidth: '400px' }}>
      <Stack>
        <Typography component="h1" variant="h2" fontFamily="PP Neue Montreal" fontSize="34px">
          Invalid Code
        </Typography>
        <Typography component="p" variant="subtitle1" sx={{ mt: 2 }} fontSize="18px" lineHeight="1.3">
          You have entered the wrong code multiple times. Please try logging in again, or resetting your
          password.
        </Typography>
      </Stack>

      <ReactRouterLinkButton to={`../${AuthenticationPaths.Login}`} fullWidth sx={{ mt: 4 }}>
        Back to Login
      </ReactRouterLinkButton>

      <ReactRouterLinkButton
        sx={{ mt: 3 }}
        variant="textMuted"
        to={`../${AuthenticationPaths.ResetPasswordRequest}`}
      >
        Reset Password
      </ReactRouterLinkButton>
    </Box>
  );
}

export default InvalidCodeScreen;
