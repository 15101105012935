import { SVGProps } from 'react';

function CreditCardIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="34"
            height="26"
            viewBox="0 0 34 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden
            data-testid="credit-card-icon"
            {...props}
        >
            <path d="M17.5 3.75H2.5C2.16848 3.75 1.85054 3.8817 1.61612 4.11612C1.3817 4.35054 1.25 4.66848 1.25 5V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H17.5C17.8315 16.25 18.1495 16.1183 18.3839 15.8839C18.6183 15.6495 18.75 15.3315 18.75 15V5C18.75 4.66848 18.6183 4.35054 18.3839 4.11612C18.1495 3.8817 17.8315 3.75 17.5 3.75ZM17.5 5V6.875H2.5V5H17.5ZM2.5 15V8.125H17.5V15H2.5Z" fill="#495057" />
            <path d="M10 12.5H3.75V13.75H10V12.5Z" fill="#495057" />
        </svg>
    );
};

export default CreditCardIcon;
