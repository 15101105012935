import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';

const useSendPaymentLink = (hotelId: string) => {
  const mutation = useMutation((template: Object) => {
    return stationApiAxios({
      url: `/rest/v1/hotels/${hotelId}/payment_links`,
      method: 'POST',
      data: template,
    });
  });

  return mutation;
};

export default useSendPaymentLink;

export const generateScheduledTransactionPaymentLink = ({
  reservationId,
  transactionId,
}: {
  reservationId: string;
  transactionId: string;
}) => {
  return {
    template: {
      id: '1',
      data: {
        reservation: {
          id: reservationId,
        },
        scheduled_transaction: {
          id: transactionId,
        },
      },
    },
  };
};
