import { SVGProps } from 'react';

function NoEyeIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2411_10987)">
        <path
          d="M3.92968 16.8825L5.00218 15.8175C3.82784 14.7631 2.90516 13.4585 2.30218 12C3.82468 8.1975 8.02468 5.25 11.9997 5.25C13.0226 5.2635 14.0363 5.44596 14.9997 5.79L16.1622 4.62C14.8442 4.06299 13.4305 3.76751 11.9997 3.75C9.55507 3.84193 7.19097 4.64817 5.19958 6.06906C3.20819 7.48996 1.6768 9.46324 0.794681 11.745C0.735106 11.9098 0.735106 12.0902 0.794681 12.255C1.46087 14.0228 2.535 15.6083 3.92968 16.8825Z"
          fill="#495057"
        />
        <path
          d="M8.99968 11.7975C9.05184 11.0788 9.36095 10.4029 9.87049 9.89331C10.38 9.38377 11.056 9.07466 11.7747 9.0225L13.1322 7.6575C12.3715 7.45721 11.5716 7.45981 10.8122 7.66505C10.0529 7.87028 9.36059 8.27098 8.80438 8.8272C8.24816 9.38341 7.84746 10.0757 7.64223 10.8351C7.43699 11.5944 7.43439 12.3943 7.63468 13.155L8.99968 11.7975Z"
          fill="#495057"
        />
        <path
          d="M23.2047 11.745C22.3447 9.5049 20.8482 7.56527 18.8997 6.165L22.4997 2.5575L21.4422 1.5L1.49968 21.4425L2.55718 22.5L6.38218 18.675C8.08759 19.6755 10.0227 20.2181 11.9997 20.25C14.4443 20.1581 16.8084 19.3518 18.7998 17.9309C20.7912 16.51 22.3226 14.5368 23.2047 12.255C23.2643 12.0902 23.2643 11.9098 23.2047 11.745ZM14.9997 12C14.9965 12.5251 14.8556 13.0401 14.591 13.4937C14.3264 13.9472 13.9474 14.3234 13.4919 14.5845C13.0364 14.8457 12.5203 14.9827 11.9952 14.982C11.4701 14.9812 10.9544 14.8426 10.4997 14.58L14.5797 10.5C14.8493 10.9543 14.9942 11.4717 14.9997 12ZM11.9997 18.75C10.4262 18.7225 8.8826 18.316 7.49968 17.565L9.40468 15.66C10.2712 16.2612 11.3213 16.539 12.3718 16.4449C13.4222 16.3507 14.4062 15.8906 15.152 15.1448C15.8977 14.399 16.3579 13.415 16.452 12.3646C16.5462 11.3142 16.2684 10.264 15.6672 9.3975L17.8197 7.245C19.5406 8.42624 20.8864 10.0766 21.6972 12C20.1747 15.8025 15.9747 18.75 11.9997 18.75Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_2411_10987">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoEyeIcon;
