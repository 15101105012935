import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { useUser } from 'app/features/authentication/context/useUser';
import { useHotelMetricsQuery } from 'app/features/hotel/api/useHoteMetrics';
import { formatNumberToPrice } from 'app/lib/numbers';
import colors from 'app/styles/colors';
import { CUSTOM_BREAKPOINT_DIFFERENCE } from 'app/styles/material/theme';
import { Fragment } from 'react';
import { getMetricsPercentage, mapRevenueMetrics } from '../lib/reservationMetrics';

export default function ReservationsRevenueMetrics() {
  const theme = useTheme();

  const { currentHotelId } = useUser();
  const { data: hotelMetrics1, status: status1 } = useHotelMetricsQuery(currentHotelId as string, 1);
  const { data: hotelMetrics2, status: status2 } = useHotelMetricsQuery(currentHotelId as string, 2);
  const mappedMetrics1 = mapRevenueMetrics(hotelMetrics1);
  const mappedMetrics2 = mapRevenueMetrics(hotelMetrics2);

  return (
    <Box
      data-testid="reservations-revenue-metrics"
      sx={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',

        [theme.breakpoints.up('sm')]: {
          ' > :not(:last-child)': { mr: '14px' },
        },
        [theme.breakpoints.up('xl')]: {
          ' > :not(:last-child)': { mr: 4 },
        },
        [theme.breakpoints.between('lg', 'xl')]: {
          justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
          alignItems: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
      }}
    >
      {status1 === 'loading' || status2 === 'loading' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '100%',
            flex: 1,
            gap: 2,
          }}
        >
          <Skeleton sx={{ flex: 1, py: 2 }} />
          <Skeleton sx={{ flex: 1 }} />
          <Skeleton sx={{ flex: 1 }} />
        </Box>
      ) : (
        <Fragment>
          <Box
            component="dl"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              mb: 1,
              [theme.breakpoints.up('lg')]: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: 'unset',
                mb: 0,
              },
              [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                alignItems: 'center',
                flexDirection: 'row',
              },
            }}
          >
            <Typography
              component="dd"
              fontWeight="450"
              fontSize="14px"
              lineHeight="14px"
              letterSpacing="0.04em"
              color={colors.grey[700]}
              width="80px"
              sx={{
                [theme.breakpoints.up('lg')]: {
                  fontSize: '26px',
                  lineHeight: '26px',
                  letterSpacing: '-0.01em',
                  mb: 0.5,
                },
                [theme.breakpoints.up('xl')]: {
                  fontSize: '30px',
                  lineHeight: '30px',
                  letterSpacing: '-0.03em',
                  mb: '6px',
                  width: 'unset',
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                  width: 'unset',
                  mb: 0,
                  fontSize: '41px',
                  lineHeight: '41px',
                  letterSpacing: '-0.03em',
                },
              }}
            >
              {formatNumberToPrice({
                price: (mappedMetrics2.total_reservations_amount! || 0) / 100,
                currency: mappedMetrics2.total_reservations_currency,
              })}
            </Typography>
            <Typography
              component="dt"
              fontWeight="450"
              fontSize="14px"
              lineHeight="14px"
              letterSpacing="0.04em"
              color={colors.grey[700]}
              sx={{
                ml: '20px',
                [theme.breakpoints.up('lg')]: {
                  fontSize: '14px',
                  lineHeight: '14px',
                  letterSpacing: '0.04em',
                  ml: 0,
                  whiteSpace: 'nowrap',
                },
                [theme.breakpoints.up('xl')]: {
                  fontSize: '14px',
                  lineHeight: '14px',
                  letterSpacing: '0.04em',
                  ml: 0,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                  width: '76px',
                  ml: 1,
                  fontSize: '16px',
                  lineHeight: '16px',
                  whiteSpace: 'normal',
                },
              }}
            >
              DIRECT REVENUE
            </Typography>
          </Box>
          {mappedMetrics2.add_on_amount! > 0 && (
            <Box
              component="dl"
              data-testid="addons-metrics-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                [theme.breakpoints.up('lg')]: {
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: 'unset',
                  mb: 0,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                  alignItems: 'center',
                  flexDirection: 'row',
                },
              }}
            >
              <Typography
                component="dd"
                fontWeight="450"
                fontSize="14px"
                lineHeight="14px"
                letterSpacing="-0.03em"
                color={colors.grey[700]}
                width="80px"
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '26px',
                    lineHeight: '26px',
                    letterSpacing: '-0.01em',
                    mb: 0.5,
                  },
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '30px',
                    lineHeight: '30px',
                    letterSpacing: '-0.03em',
                    mb: '6px',
                    width: 'unset',
                  },
                  [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                    width: 'unset',
                    mb: 0,
                    fontSize: '41px',
                    lineHeight: '41px',
                    letterSpacing: '-0.03em',
                  },
                }}
              >
                {formatNumberToPrice({
                  price: (mappedMetrics2.add_on_amount! || /* istanbul ignore next */ 0) / 100,
                  currency: mappedMetrics2.add_on_currency,
                })}
              </Typography>
              <Typography
                component="dt"
                fontWeight="450"
                fontSize="14px"
                lineHeight="14px"
                letterSpacing="0.04em"
                color={colors.grey[700]}
                sx={{
                  ml: '20px',
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '14px',
                    lineHeight: '14px',
                    letterSpacing: '0.04em',
                    ml: 0,
                    whiteSpace: 'nowrap',
                  },
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                    lineHeight: '14px',
                    letterSpacing: '0.04em',
                    ml: 0,
                  },
                  [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                    width: '76px',
                    ml: 1,
                    whiteSpace: 'normal',
                  },
                }}
              >
                ADD-ON REVENUE
              </Typography>
            </Box>
          )}
          <Box
            component="dl"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              [theme.breakpoints.up('lg')]: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: 'unset',
                mb: 0,
              },
              [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                alignItems: 'center',
                flexDirection: 'row',
              },
            }}
          >
            <Typography
              component="dd"
              fontWeight="450"
              fontSize="14px"
              lineHeight="14px"
              letterSpacing="-0.03em"
              color={colors.grey[700]}
              width="80px"
              sx={{
                [theme.breakpoints.up('lg')]: {
                  fontSize: '26px',
                  lineHeight: '26px',
                  letterSpacing: '-0.01em',
                  mb: 0.5,
                },
                [theme.breakpoints.up('xl')]: {
                  fontSize: '30px',
                  lineHeight: '30px',
                  letterSpacing: '-0.03em',
                  mb: '6px',
                  width: 'unset',
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                  width: 'unset',
                  mb: 0,
                  fontSize: '41px',
                  lineHeight: '41px',
                  letterSpacing: '-0.03em',
                },
              }}
            >
              {getMetricsPercentage(
                (mappedMetrics1.total_reservations_using_apple_pay! || 0) +
                  (mappedMetrics1.total_reservations_using_google_pay! || 0),
                mappedMetrics1.total_reservations! || 1,
              ).toFixed(0)}
              %
            </Typography>
            <Typography
              component="dt"
              fontWeight="450"
              fontSize="14px"
              lineHeight="14px"
              letterSpacing="0.04em"
              color={colors.grey[700]}
              sx={{
                ml: '20px',
                [theme.breakpoints.up('lg')]: {
                  fontSize: '14px',
                  lineHeight: '14px',
                  letterSpacing: '0.04em',
                  ml: 0,
                  whiteSpace: 'nowrap',
                },
                [theme.breakpoints.up('xl')]: {
                  fontSize: '14px',
                  lineHeight: '14px',
                  letterSpacing: '0.04em',
                  ml: 0,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                  width: '76px',
                  ml: 1,
                  whiteSpace: 'normal',
                },
              }}
            >
              DIGITAL WALLETS
            </Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
