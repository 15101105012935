import { Customer, GuestSummary, Reservation } from '../types';

export const getGuestFirstName = (name?: string) => {
  const splitGuestName = name?.split(' ');
  const firstName = splitGuestName?.[0] ?? '';
  return firstName;
};

export const getGuestLastName = (name?: string) => {
  const splitGuestName = name?.split(' ');
  const lastName = splitGuestName?.[1] ?? '';
  return lastName;
};

export const getGuestSummary = (guestSummary: GuestSummary) => {
  const aText = guestSummary.number_of_adults === 1 ? 'Adult' : 'Adults';
  const cText = guestSummary.number_of_children === 1 ? 'Child' : 'Children';
  if (guestSummary.number_of_adults > 0 && guestSummary.number_of_children > 0) {
    return `${guestSummary.number_of_adults} ${aText}, ${guestSummary.number_of_children} ${cText}`;
  }
  if (guestSummary.number_of_adults > 0) {
    return `${guestSummary.number_of_adults} ${aText}`;
  }
  if (guestSummary.number_of_children > 0) {
    return `${guestSummary.number_of_children} ${cText}`;
  }
  return '';
};

export const getNarrowGuestSummary = (
  guestSummary: GuestSummary,
  narrowReservationThreshold: number,
  daysToShow: number,
) => {
  if (
    (daysToShow === 15 && narrowReservationThreshold > 1) ||
    (daysToShow === 7 && narrowReservationThreshold >= 1)
  ) {
    const label = guestSummary.total_number_of_guests > 1 ? 'guests' : 'guest';

    if (guestSummary.number_of_adults > 0 && guestSummary.number_of_children > 0) {
      return `${guestSummary.number_of_adults + guestSummary.number_of_children} ${label}`;
    }
    if (guestSummary.number_of_adults > 0 || guestSummary.number_of_children > 0) {
      return `${guestSummary.number_of_adults + guestSummary.number_of_children} ${label}`;
    }
    return ``;
  }

  return `${guestSummary.number_of_adults + guestSummary.number_of_children}`;
};

export const getCustomerEmail = (res: Reservation) => {
  const customerEmail = res.guest_information?.email || res.reservation_data?.customer?.email || '';
  return customerEmail;
};

export const getCustomerFullName = (res: Reservation) => {
  const customerName = res.guest_information?.name || res.reservation_data?.customer?.full_name || '';
  return customerName;
};

export const getCustomerPhone = (res: Reservation) => {
  const customerPhone =  res.guest_information?.telephone_number || res.reservation_data?.customer?.phone || '';
  return customerPhone;
};

export const getReservationDetailCustomer = (res?: Reservation): Customer => {
  const customer: Customer = {
    full_name: '',
    email: '',
    phone: '',
  };

  if (!res) return customer;
  customer.full_name = getCustomerFullName(res);
  customer.email = getCustomerEmail(res);
  customer.phone = getCustomerPhone(res);

  return customer;
};
