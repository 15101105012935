import { colors } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import ReservationCharge from './ReservationCharge';

function ReservationPayments() {
  const { paymentsUi } = useReservationDetailContext();

  if (paymentsUi.payments.length === 0) return null;

  return (
    <Stack sx={{ mt: 5, pr: '10px' }}>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 450,
          fontSize: 20,
          lineHeight: '24px',
          letterSpacing: '0.01em',
          color: colors.grey[900],
          mb: '8.5px',
        }}
      >
        Charges
      </Typography>
      <Stack>
        {paymentsUi.payments.map((payment) => {
          return <ReservationCharge key={payment.id} charge={payment} />;
        })}
      </Stack>
    </Stack>
  );
}

export default ReservationPayments;
