import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, Ref } from 'react';
import useSelfbookInputStyles from './useSelfbookInputStyles';

export interface Props {
  backgroundVariant?: 'default' | 'white';
}

function SelfbookTextInput(
  { error, backgroundVariant = 'default', multiline, ...rest }: Props & TextFieldProps,
  ref: Ref<any>,
) {
  const { textFieldRootStyle, inputRootStyle, inputInputStyle, labelStyle, helperTextStyle } =
    useSelfbookInputStyles({ backgroundVariant, multiline });
  return (
    <TextField
      {...rest}
      ref={ref}
      multiline={multiline}
      color="info"
      variant="filled"
      sx={textFieldRootStyle}
      InputProps={{
        ...rest.InputProps,
        sx: inputRootStyle,
        inputProps: {
          sx: inputInputStyle,
          ...rest.InputProps?.inputProps,
        },
      }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        sx: labelStyle,
      }}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        sx: helperTextStyle,
      }}
      error={error}
    />
  );
}

export default forwardRef(SelfbookTextInput);
