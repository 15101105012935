import { useState } from 'react';
import { Box } from '@mui/material';
import useRequestPasswordReset from '../api/useRequestPasswordReset';
import ResetPasswordRequestReceived from './ResetPasswordRequestReceived';
import ResetPasswordRequestSubmit from './ResetPasswordRequestSubmit';

function ResetPasswordRequest() {
  const { mutate, isLoading, error } = useRequestPasswordReset();
  const [component, setComponent] = useState<'submit' | 'received'>('submit');
  const [submittedEmail, setSubmittedEmail] = useState<string>();

  const handleResetPasswordRequestSubmit = (email: string) => {
    mutate(
      { email },
      {
        onSuccess: () => {
          setSubmittedEmail(email);
          setComponent('received');
        },
      },
    );
  };

  return (
    <Box data-testid="reset-password-request-screen" sx={{ display: 'grid', placeItems: 'center' }}>
      {component === 'submit' && (
        <ResetPasswordRequestSubmit
          onSubmit={handleResetPasswordRequestSubmit}
          isLoading={isLoading}
          submitError={error}
        />
      )}
      {component === 'received' && <ResetPasswordRequestReceived submittedEmail={submittedEmail} />}
    </Box>
  );
}

export default ResetPasswordRequest;
