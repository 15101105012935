import { flow } from 'lodash';
import { Reservation } from '../types';

export const getReservationId = (reservation: Reservation) => {
  return reservation.id;
};

export const formatReservationIdText = (id: string) => {
  if (id.length > 10) {
    return `${id.substring(0, 10)}...`;
  }

  return id;
};

export const getReservationIdText = flow(getReservationId, formatReservationIdText);
