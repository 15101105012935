import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import colors from 'app/styles/colors';
import { useState } from 'react';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import { Reservation } from '../types';
import NewChargeModal from './NewChargeModal';

export default function ReservationDetailsPanelFooter() {
  const [newChargeModalState, setNewChargeModalState] = useState<{
    isOpen: boolean;
    reservation?: Reservation;
  }>({ isOpen: false });

  const {
    reservation,
    reservationId,
    paymentMethods,
    footerUi: { reservationTotal, showAddChargesButton },
  } = useReservationDetailContext();

  const handleNewChargeModalClose = () => {
    setNewChargeModalState({ isOpen: false, reservation: undefined });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: 1,
      }}
    >
      <Divider
        sx={{
          width: '100%',
          backgroundColor: `1px solid ${colors.grey[300]}`,
          position: 'absolute',
          left: 0,
        }}
      />

      <Box sx={{ pt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h3" fontSize="18px" lineHeight="18px" letterSpacing="0.01em">
              Total amount
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" fontSize="18px" lineHeight="18px" letterSpacing="0.01em">
              {reservationTotal}
            </Typography>
          </Box>
        </Box>
      </Box>
      {showAddChargesButton && (
        <LoadingButton
          sx={{ mt: 3 }}
          loading={false}
          fullWidth
          onClick={() => {
            setNewChargeModalState({
              isOpen: true,
              reservation,
            });
          }}
        >
          <Typography fontWeight={450} letterSpacing="0.04em">
            ADD CHARGE
          </Typography>
        </LoadingButton>
      )}

      <NewChargeModal
        reservationId={reservationId!}
        open={newChargeModalState.isOpen && showAddChargesButton}
        onClose={handleNewChargeModalClose}
        paymentMethods={paymentMethods}
      />
    </Box>
  );
}
