import { SVGProps } from 'react';

function CalendarIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden
            data-testid="calendar-icon"
            {...props}>
            <path d="M11.125 17.75C10.1361 17.75 9.1694 17.4568 8.34715 16.9073C7.5249 16.3579 6.88404 15.577 6.5056 14.6634C6.12716 13.7498 6.02815 12.7445 6.22107 11.7745C6.414 10.8046 6.8902 9.91373 7.58947 9.21447C8.28873 8.5152 9.17964 8.039 10.1495 7.84607C11.1195 7.65315 12.1248 7.75216 13.0384 8.1306C13.952 8.50904 14.7329 9.1499 15.2823 9.97215C15.8318 10.7944 16.125 11.7611 16.125 12.75C16.125 14.0761 15.5982 15.3479 14.6605 16.2855C13.7229 17.2232 12.4511 17.75 11.125 17.75ZM11.125 9C10.3833 9 9.6583 9.21993 9.04161 9.63199C8.42493 10.044 7.94428 10.6297 7.66045 11.3149C7.37662 12.0002 7.30236 12.7542 7.44705 13.4816C7.59175 14.209 7.9489 14.8772 8.47335 15.4017C8.9978 15.9261 9.66598 16.2833 10.3934 16.4279C11.1208 16.5726 11.8748 16.4984 12.5601 16.2145C13.2453 15.9307 13.831 15.4501 14.243 14.8334C14.6551 14.2167 14.875 13.4917 14.875 12.75C14.875 11.7554 14.4799 10.8016 13.7767 10.0983C13.0734 9.39509 12.1196 9 11.125 9Z" fill="#495057" />
            <path d="M12.1187 14.625L10.5 13.0063V10.25H11.75V12.4937L13 13.7437L12.1187 14.625Z" fill="#495057" />
            <path d="M15.5 2.75C15.5 2.41848 15.3683 2.10054 15.1339 1.86612C14.8995 1.6317 14.5815 1.5 14.25 1.5H11.75V0.25H10.5V1.5H5.5V0.25H4.25V1.5H1.75C1.41848 1.5 1.10054 1.6317 0.866116 1.86612C0.631696 2.10054 0.5 2.41848 0.5 2.75V15.25C0.5 15.5815 0.631696 15.8995 0.866116 16.1339C1.10054 16.3683 1.41848 16.5 1.75 16.5H4.25V15.25H1.75V2.75H4.25V4H5.5V2.75H10.5V4H11.75V2.75H14.25V6.5H15.5V2.75Z" fill="#495057" />
        </svg >
    );
}

export default CalendarIcon;
