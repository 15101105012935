import { useFlags } from 'launchdarkly-react-client-sdk';

const useFeatureFlags = () => {
  const { stationTimetable, brav1004CancelPaymentLink } = useFlags();

  const showTimeline = stationTimetable;
  const showCancelPaymentLink = brav1004CancelPaymentLink;

  return {
    showTimeline,
    showCancelPaymentLink,
  };
};

export default useFeatureFlags;
