import {
  Box,
  CircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  Stack,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  loading: boolean;
  showContentWhenLoading?: boolean;
  CircularProgressProps?: MuiCircularProgressProps;
}

function LoadingButton({
  children,
  loading,
  showContentWhenLoading = false,
  CircularProgressProps = {},
  ...rest
}: Props & ButtonProps) {
  const loadingSpinner = <CircularProgress sx={{ color: 'white' }} size={20} {...CircularProgressProps} />;

  return (
    <Button data-testid="loading-button" disabled={loading} {...rest}>
      {loading && showContentWhenLoading && (
        <Stack direction="row" alignItems="center">
          {children}
          <Box sx={{ px: 1 }} />
          {loadingSpinner}
        </Stack>
      )}
      {loading && !showContentWhenLoading && loadingSpinner}
      {!loading && children}
    </Button>
  );
}

export default LoadingButton;
