import { Box, SxProps, Typography } from '@mui/material';
import ChevronRightIcon from 'app/components/icons/ChevronRightIcon';
import { getStepContainerStyles } from '../styles';
import { StationAdminAfterLoginSetupSteps } from '../types';

const stepsContainerStyles: SxProps = {
  display: 'flex',
  flexFlow: 'row nowrap',
  padding: '8px 0px 24px 0px',
  columnGap: '56px',
};

interface Props {
  currentStep: StationAdminAfterLoginSetupSteps;
}

function StationAdminAfterLoginSteps({ currentStep }: Props) {
  return (
    <Box sx={stepsContainerStyles} data-testid="station-admin-after-login-steps">
      <Box sx={getStepContainerStyles(StationAdminAfterLoginSetupSteps.UPLOAD_LOGO, currentStep)}>
        <Box>1</Box>
        <Typography>UPLOAD LOGO</Typography>
        <ChevronRightIcon width="6px" height="10px" />
      </Box>
      <Box sx={getStepContainerStyles(StationAdminAfterLoginSetupSteps.STRIPE_SETUP, currentStep)}>
        <Box>2</Box>
        <Typography>ACCOUNT SETUP</Typography>
      </Box>
    </Box>
  );
}

export default StationAdminAfterLoginSteps;
