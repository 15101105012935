import { formatNumberToPrice } from 'app/lib/numbers';
import { formatTransactionDate, formatToBeCollectedString } from '../lib/reservationDetail';
import { InvoiceLine, ApiReservationTransaction } from '../types';
import { getTransactionIdText } from './data';
import { ReservationChargeUi, ReservationChargeUiLineItem } from './roomCharge';

export const getReservationAdditionalCharges = (allInvoiceLines: InvoiceLine[] = []) => {
  const additionalCharges = allInvoiceLines.length < 2 ? [] : allInvoiceLines.slice(1);
  return additionalCharges;
};

export const getAdditionalChargeBreakdown = (additionalCharges: InvoiceLine[] = []) => {
  const additionalChargeBreakdown = additionalCharges.reduce(
    (accum: { charges: ApiReservationTransaction[]; refunds: ApiReservationTransaction[] }, charge) => {
      charge.transactions?.forEach((trx) => {
        if (trx.transaction_type === 'CHARGE') {
          accum.charges.push(trx);
        }
        if (trx.transaction_type === 'REFUND') {
          accum.refunds.push(trx);
        }
      });

      return accum;
    },
    { charges: [], refunds: [] },
  );

  return additionalChargeBreakdown;
};

export const buildAdditionalChargesUi = (additionalChargeCharges: ApiReservationTransaction[]) => {
  const charges: ReservationChargeUi[] = additionalChargeCharges.map((charge) => {
    const priceFromCents = charge.amount / 100;
    const currency = charge.currency || 'USD';

    const amountLineItem: ReservationChargeUiLineItem = {
      name: 'Total',
      amount: formatNumberToPrice({
        price: priceFromCents,
        currency,
      }),
    };

    return {
      id: charge.id,
      name: charge.description,
      status: null,
      price: formatNumberToPrice({
        price: priceFromCents,
        currency,
      }),
      lineItems: [amountLineItem],
      createdAt: formatTransactionDate(charge.created_at),
      transactionItems: [
        {
          name: 'Charge',
          description: `PAID ON ${formatToBeCollectedString(charge.created_at)}`,
          associatedTransactionId: `#${getTransactionIdText(charge.id)}`,
          status: charge.status!,
          amount: formatNumberToPrice({
            price: priceFromCents,
            currency,
          }),
          refundAction: {
            chargeId: charge.id,
            chargeAmount: charge.amount,
            chargeCurrency: currency,
            chargeDescription: charge.description,
          },
          viewReceiptAction: {
            chargeId: charge.id,
            hotelId: charge.hotel.id,
          },
        },
      ],
    };
  });

  return charges;
};
