import reactQueryClient from 'app/api/react-query/react-query-client';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useQuery } from 'react-query';
import { mapReservation } from '../lib/reservationMapper';
import { Reservation } from '../types';

export const invalidateReservationDetailsQuery = (reservationId: string, hotelId: string) =>
  reactQueryClient.invalidateQueries(['ReservationDetail', reservationId, hotelId]);

export async function getReservationById(reservationId: string, hotelId: string) {
  const response = await stationApiAxios({
    url: `rest/v1/hotels/${hotelId}/reservations/${reservationId}?expand[]=payment_methods&expand[]=invoice&expand[]=invoice.invoice_lines&expand[]=invoice.invoice_lines.transactions&expand[]=payment_schedule&expand[]=payment_schedule.scheduled_transactions`,
    method: 'GET',
  });

  const reservation = mapReservation(response.data);
  return reservation;
}

export const useReservationDetail = (reservationId?: string, hotelId?: string) => {
  const results = useQuery<Reservation>(
    ['ReservationDetail', reservationId, hotelId],
    () => {
      // !'s are ok here since the `enabled` prop will confirm the present of these values
      return getReservationById(reservationId!, hotelId!);
    },
    {
      refetchOnWindowFocus: false,
      enabled: typeof hotelId === 'string' && hotelId.length > 0 && !!reservationId,
    },
  );
  return results;
};
