import { useState } from 'react';

export type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

type DataFetchState<T = any> = {
  data: T | null;
  status: FetchStatus;
  error?: string;
};

function useLocalDataFetch<T = any>() {
  const [state, setState] = useState<DataFetchState<T>>({
    data: null,
    status: 'idle',
    error: undefined,
  });

  return { ...state, setState };
}

export default useLocalDataFetch;
