import { SVGProps } from 'react';

function UndoIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden
      data-testid="undo-icon"
      {...props}
    >
      <g clipPath="url(#clip0_2966_125272)">
        <path
          d="M12.5 6.25H4.88431L7.12644 4.00881L6.25 3.125L2.5 6.875L6.25 10.625L7.12644 9.74087L4.88619 7.5H12.5C13.4946 7.5 14.4484 7.89509 15.1517 8.59835C15.8549 9.30161 16.25 10.2554 16.25 11.25C16.25 12.2446 15.8549 13.1984 15.1517 13.9017C14.4484 14.6049 13.4946 15 12.5 15H7.5V16.25H12.5C13.8261 16.25 15.0979 15.7232 16.0355 14.7855C16.9732 13.8479 17.5 12.5761 17.5 11.25C17.5 9.92392 16.9732 8.65215 16.0355 7.71447C15.0979 6.77678 13.8261 6.25 12.5 6.25Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_2966_125272">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default UndoIcon;
