import { Alert, Button, Stack, Typography } from '@mui/material';
import { getErrorMessage } from 'app/api/lib/error-handling';
import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import { useUser } from 'app/features/authentication/context/useUser';
import { useSnackbar } from 'notistack';
import useCancelReservation from '../api/useCancelReservation';
import { invalidateReservationDetailsQuery } from '../api/useReservationDetail';
import { invalidateReservationsQuery } from '../api/useReservations';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccessCallback: () => void;
  reservationId?: string;
}

function CancelReservationModal({ open, onClose, reservationId, onSuccessCallback }: Props) {
  const { currentHotelId } = useUser();
  const { mutate, error, reset } = useCancelReservation(currentHotelId!);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleModalClose = () => {
    onClose();
    reset();
  };

  // istanbul ignore next // this is tested
  const handleCancelSubmit = () => {
    mutate(reservationId, {
      onSuccess: () => {
        onSuccessCallback();
        invalidateReservationsQuery();
        invalidateReservationDetailsQuery(reservationId!, currentHotelId!);
        const id = enqueueSnackbar('Reservation successfully cancelled', {
          onClick: () => closeSnackbar(id),
        });
        handleModalClose();
      },
    });
  };

  const prompt = (
    <Stack spacing={2}>
      <Typography fontSize="20px">Do you really want to cancel this booking?</Typography>
      <Stack direction="row" spacing={1}>
        <Button sx={{ flex: 1 }} variant="outlined" onClick={handleModalClose}>
          No
        </Button>
        <Button data-testid="cancel-reservation-modal-submit" sx={{ flex: 1 }} color="error" onClick={handleCancelSubmit}>
          Cancel Booking
        </Button>
      </Stack>
      {!!error && (
        <Alert
          id="cancel-reservation-error"
          data-testid="cancel-reservation-error"
          sx={{ boxShadow: 0, mt: 1 }}
          severity="error"
        >
          {`${getErrorMessage(error)}. Please try again.`}
        </Alert>
      )}
    </Stack>
  );

  return (
    <SelfbookDialog data-testid="cancel-reservation-modal" open={open} onClose={handleModalClose}>
      <SelfbookDialog.Title
        data-testid="cancel-reservation-modal-title"
        onClose={handleModalClose}
        aria-label="Cancel Booking"
      >
        Cancel Booking
      </SelfbookDialog.Title>
      <SelfbookDialog.Content data-testid="cancel-reservation-modal-content" sx={{ width: '460px' }}>
        {prompt}
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default CancelReservationModal;
