import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useQuery } from 'react-query';
import { ReservationEvent } from '../types';

export async function getReservationEvents(hotelId: string, reservationId: string) {
  const response = await stationApiAxios({
    url: `rest/v1/hotels/${hotelId}/reservations/${reservationId}/events`,
    method: 'GET',
  });

  return response.data.items as ReservationEvent[];
}

export const useReservationEventsQuery = (hotelId: string, reservationId: string) => {
  const results = useQuery<ReservationEvent[]>(
    ['ReservationEvents', hotelId, reservationId],
    () => {
      return getReservationEvents(hotelId, reservationId);
    },
    { refetchOnMount: 'always' },
  );
  return results;
};
