import { getErrorMessage } from 'app/api/lib/error-handling';
import { getHotel, invalidateHotelQuery } from 'app/features/hotel/api/useHotel';
import { getInitials } from 'app/features/user/lib/userInitials';
import { UserPermissionRole } from 'app/features/user/lib/userRoles';
import { SelectedHotel, User, UserPermission } from 'app/features/user/types';
import useLocalDataFetch, { FetchStatus } from 'app/hooks/useLocalDataFetch';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { getCurrentUserFn, getUserIdFromJwt } from '../lib/user';

type State = {
  currentUser: User | null;
  clearCurrentUser: () => void;
  fetchCurrentUserWithSpecificId: (userId?: string) => Promise<User | null>;
  fetchCurrentUserFromJwt: () => Promise<User | null>;
  firstFetchComplete: boolean;
  fetchStatus: FetchStatus;
  fetchError?: string;
  userId?: string;
  userEmail?: string;
  userName?: string;
  userPermissions?: UserPermission[];
  userInitials: string;
  selectedHotel?: UserPermission;
  currentHotelName: string;
  currentHotelId?: string;
  currentHotelCurrency: string;
  currentUserHotelRole: string;
  setCurrentSelectedHotel: (data: SelectedHotel) => void;
  currentSelectedHotel: SelectedHotel;
};

const UserContext = createContext<State | undefined>(undefined);

function UserContextProvider({ children }: { children: React.ReactNode }) {
  const ldClient = useLDClient();

  const {
    data: currentUser,
    status: fetchStatus,
    error: fetchError,
    setState,
  } = useLocalDataFetch<User | null>();

  const [firstFetchComplete, setFirstFetchComplete] = useState<boolean>(false);
  const [currentHotelCurrency, setCurrentHotelCurrency] = useState('USD');
  const [currentSelectedHotel, setCurrentSelectedHotel] = useState<SelectedHotel>({
    name: 'Unknown Hotel Name',
    id: undefined,
    userHotelRole: 'Unknown Role',
  });

  const previousUser = usePrevious(currentUser);

  // istanbul ignore next
  useEffect(() => {
    if (previousUser !== currentUser) {
      if (currentUser?.user_permissions?.[0].role === UserPermissionRole.HOTEL_ADMIN) {
        ldClient?.identify({
          key: currentUser?.user_permissions?.[0].hotel?.id,
          name: currentUser?.user_permissions?.[0].hotel?.name,
        });
      }
    }

    const userPermissions = currentUser?.user_permissions;
    const selectedHotel = userPermissions?.[0];
    const currentHotelName = selectedHotel?.hotel?.name || 'Unknown Hotel Name';
    const currentHotelId = selectedHotel?.hotel?.id;
    const currentUserHotelRole = selectedHotel?.role || 'Unknown Role';

    setCurrentSelectedHotel({
      id: currentHotelId,
      name: currentHotelName,
      userHotelRole: currentUserHotelRole,
    });
  }, [currentUser]);

  const getHotelCurrency = async (hotelId: string) => {
    if (!hotelId) {
      setCurrentHotelCurrency('USD');
      return;
    }

    const { currency } = await getHotel(hotelId);
    setCurrentHotelCurrency(currency);
  };

  const fetchCurrentUserWithSpecificId = async (userId?: string) => {
    invalidateHotelQuery();
    if (!userId) {
      setFirstFetchComplete(true);
      return Promise.resolve(null);
    }

    setState((prev) => ({
      ...prev,
      status: 'loading',
      error: undefined,
    }));

    return getCurrentUserFn()
      .then((res) => {
        setState({
          data: res.data,
          status: 'success',
          error: undefined,
        });

        getHotelCurrency(res.data!.user_permissions[0].hotel?.id);
        return res.data;
      })
      .catch((err) => {
        setState({
          data: null,
          status: 'error',
          error: getErrorMessage(err),
        });

        return null;
      })
      .finally(() => {
        setFirstFetchComplete(true);
      });
  };

  const fetchCurrentUserFromJwt = () => {
    const userId = getUserIdFromJwt();
    return fetchCurrentUserWithSpecificId(userId);
  };

  const clearCurrentUser = () => {
    invalidateHotelQuery();
    setState({
      data: null,
      status: 'idle',
      error: undefined,
    });
  };

  const userId = currentUser?.id;
  const userEmail = currentUser?.email;
  const userName = currentUser?.name;
  const userInitials = getInitials(userName, userEmail);
  const userPermissions = currentUser?.user_permissions;
  const selectedHotel = userPermissions?.[0];

  const value = useMemo(
    () => ({
      userId,
      currentUser,
      userEmail,
      userName,
      userPermissions,
      userInitials,
      selectedHotel,
      currentHotelName: currentSelectedHotel.name,
      currentHotelId: currentSelectedHotel.id,
      currentHotelCurrency,
      currentUserHotelRole: currentSelectedHotel.userHotelRole,
      fetchCurrentUserWithSpecificId,
      fetchCurrentUserFromJwt,
      fetchStatus,
      fetchError,
      clearCurrentUser,
      firstFetchComplete,
      setCurrentSelectedHotel,
      currentSelectedHotel,
    }),
    [
      userId,
      currentUser,
      userEmail,
      userName,
      userPermissions,
      userInitials,
      selectedHotel,
      currentHotelCurrency,
      fetchCurrentUserWithSpecificId,
      fetchCurrentUserFromJwt,
      fetchStatus,
      fetchError,
      clearCurrentUser,
      firstFetchComplete,
      currentSelectedHotel,
      setCurrentSelectedHotel,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContext');
  }
  return context;
};

export { useUser, UserContextProvider };
