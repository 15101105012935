import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';

const useCancelReservation = (hotelId: string) => {
  const mutation = useMutation((reservationId?: string) => {
    return stationApiAxios({
      url: `/rest/v1/hotels/${hotelId}/reservations/${reservationId}`,
      method: 'PATCH',
      data: { status: 'CANCELED' },
    });
  });

  return mutation;
};

export default useCancelReservation;
