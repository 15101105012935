import reactQueryClient from 'app/api/react-query/react-query-client';
import { getPaymentLinksDetails } from 'app/payment-links/api/usePaymentLinks';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function PaymentLinksShortCodeRedirect() {
  const navigate = useNavigate();
  const params = useParams();
  const wildcardParam = params['*'];

  useEffect(() => {
    if (wildcardParam && wildcardParam.startsWith('P') && wildcardParam.length === 11) {
      reactQueryClient
        .fetchQuery({
          queryKey: ['PaymentLinks', wildcardParam.substring(1)],
          queryFn: () => getPaymentLinksDetails(wildcardParam?.substring(1) as string),
        })
        .then((response) => {
          navigate(`/payment_links?id=${response.id}`);
        });
    } else {
      navigate(`/app`);
    }
  }, []);

  return null;
}

export default PaymentLinksShortCodeRedirect;
