import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useQuery } from 'react-query';
import { HotelMetrics } from '../types';

export async function getHotelMetrics(hotelPublicId: string, template: number) {
  const response = await stationApiAxios({
    url: `/rest/v1/hotels/${hotelPublicId}/metrics?template[id]=${template}`,
    method: 'GET',
  });

  return response.data.items as HotelMetrics[];
}

export const useHotelMetricsQuery = (hotelPublicId: string, template: number = 1) => {
  const results = useQuery<HotelMetrics[]>(['HotelMetrics', hotelPublicId, template], () => {
    return getHotelMetrics(hotelPublicId, template);
  });
  return results;
};
