// @ts-expect-error;
import pica from 'pica';

interface ResizeImageParams {
  image: File;
  width?: number;
  quality?: number;
}

// istanbul ignore next
export const resizeImage = async ({ image, width, quality }: ResizeImageParams): Promise<Blob> => {
  const picaInstance = pica();

  const img = new Image();

  const imageLoadPromise = new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = URL.createObjectURL(image);
  });

  const loadedImage = await imageLoadPromise;

  const newWidth = width ?? loadedImage.width;
  const aspectRatio = loadedImage.width / loadedImage.height;

  const canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newWidth / aspectRatio;

  await picaInstance.resize(loadedImage, canvas);

  const resizedImage = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to convert canvas to Blob'));
        }
      },
      'image/png',
      quality,
    );
  });

  return resizedImage;
};
