import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../icons/CloseIcon';
import useSelfbookDialogStyles from './useSelfbookDialogStyles';

interface TitleProps extends DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

function SelfbookDialogTitle({ children, onClose, ...rest }: TitleProps) {
  const { titleStyle } = useSelfbookDialogStyles();
  return (
    <DialogTitle sx={titleStyle} {...rest}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          data-testid="dialog-close"
          onClick={onClose}
          sx={{
            color: 'grey.900',
            '&:focus-within svg': {
              outline: 'black solid thin',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ContentProps extends DialogContentProps {}
function SelfbookDialogContent({ ...rest }: ContentProps) {
  const { contentStyle } = useSelfbookDialogStyles();
  return <DialogContent sx={contentStyle} dividers {...rest} />;
}

interface Props extends DialogProps {}
function SelfbookDialog({ ...rest }: Props) {
  return <Dialog maxWidth="lg" {...rest} />;
}

SelfbookDialog.Title = SelfbookDialogTitle;
SelfbookDialog.Content = SelfbookDialogContent;
export default SelfbookDialog;
