import { Reservation, ApiReservationTransaction, CardOnRecord, InvoiceLine } from '../types';

export const getAllInvoiceLines = (apiData: Reservation) => {
  const allInvoiceLines = apiData.invoice?.invoice_lines || [];
  return allInvoiceLines;
};

export const getPaymentMethods = (reservation: Reservation): CardOnRecord[] | null => {
  if (!reservation?.payment_methods) return null;
  const mappedPaymentMethods: CardOnRecord[] = [] as CardOnRecord[];

  reservation.payment_methods.forEach((paymentMethod) => {
    const mappedPaymentMethod: CardOnRecord = {} as CardOnRecord;
    mappedPaymentMethod.cardBrand = paymentMethod.brand;
    mappedPaymentMethod.cardLast4 = paymentMethod.last4;
    mappedPaymentMethod.paymentToken = paymentMethod.stripe
      ? paymentMethod.stripe.payment_method_id
      : paymentMethod.spreedly?.payment_method_token;
    mappedPaymentMethod.gateway = paymentMethod.stripe ? 'STRIPE' : 'SPREEDLY';
    mappedPaymentMethod.customerId = paymentMethod.stripe ? paymentMethod.stripe.customer_id : '';

    mappedPaymentMethods.push(mappedPaymentMethod);
  });

  return mappedPaymentMethods;
};

export const getChargeTransactions = (transactions: ApiReservationTransaction[] = []) => {
  const charges = transactions.filter((t) => t.transaction_type === 'CHARGE');
  return charges;
};

export const getReservationTransactionTotalAmount = (transactions: ApiReservationTransaction[] = []) => {
  const amount = transactions.reduce((sum, charge) => sum + charge.amount, 0);
  return amount;
};

export const getReservationCurrency = (roomChargeInvoiceLine?: InvoiceLine) => {
  const currency = roomChargeInvoiceLine?.currency || 'USD';
  return currency;
};

export const getTransactionIdText = (id: string) => {
  if (id.length > 12) {
    return `${id.substring(0, 12)}...`;
  }

  return id;
};
