import { HotelMetrics } from 'app/features/hotel/types';
import colors from 'app/styles/colors';

export type PaymentMethodsMetrics = {
  color: string;
  count: number;
  percentage: number;
};

export type RevenueMetrics = {
  add_on_amount?: number;
  add_on_currency?: string;
  total_reservations?: number;
  total_reservations_amount?: number;
  total_reservations_currency?: string;
  total_reservations_using_direct?: number;
  total_reservations_using_apple_pay?: number;
  total_reservations_using_google_pay?: number;
};

export type DeviceMetric = {
  device: string;
  percentageChange: number;
  totalReservations: number;
};

export const getMetricsPercentage = (metricCount: number, total: number) => {
  return (metricCount / total) * 100;
};

export const mapPaymentMethodsMetrics = (hotelMetrics?: HotelMetrics[]) => {
  const paymentMethodsMetrics = new Map<string, PaymentMethodsMetrics>();

  if (hotelMetrics && hotelMetrics[0].template && hotelMetrics[0].template.data) {
    const {
      total_reservations_using_affirm,
      total_reservations_using_apple_pay,
      total_reservations_using_google_pay,
      total_reservations_using_cards,
      total_reservations,
    } = hotelMetrics[0].template.data;

    paymentMethodsMetrics.set('Affirm', {
      count: total_reservations_using_affirm,
      percentage: getMetricsPercentage(total_reservations_using_affirm, total_reservations),
      color: colors.affirm[600],
    } as PaymentMethodsMetrics);
    paymentMethodsMetrics.set('Apple Pay', {
      count: total_reservations_using_apple_pay,
      percentage: getMetricsPercentage(total_reservations_using_apple_pay, total_reservations),
      color: colors.grey[900],
    } as PaymentMethodsMetrics);
    paymentMethodsMetrics.set('Google Pay', {
      count: total_reservations_using_google_pay,
      percentage: getMetricsPercentage(total_reservations_using_google_pay, total_reservations),
      color: colors.googlePay,
    } as PaymentMethodsMetrics);
    paymentMethodsMetrics.set('Credit Card', {
      count: total_reservations_using_cards,
      percentage: getMetricsPercentage(total_reservations_using_cards, total_reservations),
      color: colors.creditCard,
    } as PaymentMethodsMetrics);
  }

  return paymentMethodsMetrics;
};

export const mapRevenueMetrics = (hotelMetrics?: HotelMetrics[]) => {
  let paymentMethodsMetrics = {} as RevenueMetrics;

  if (hotelMetrics && hotelMetrics[0] && hotelMetrics[0].template && hotelMetrics[0].template.data) {
    paymentMethodsMetrics = { ...(hotelMetrics[0].template.data as RevenueMetrics) };
  }

  return paymentMethodsMetrics;
};

export const mapDeviceMetrics = (hotelMetrics?: HotelMetrics[]) => {
  const deviceMetrics = [] as DeviceMetric[];
  if (hotelMetrics && hotelMetrics[0].template && hotelMetrics[0].template.data) {
    deviceMetrics.push(
      {
        device: 'mobile bookings',
        percentageChange: hotelMetrics[0].template.data.percent_change_using_mobile!,
        totalReservations: hotelMetrics[0].template.data.total_reservations_using_mobile!,
      },
      {
        device: 'desktop bookings',
        percentageChange: hotelMetrics[0].template.data.percent_change_using_desktop!,
        totalReservations: hotelMetrics[0].template.data.total_reservations_using_desktop!,
      },
    );
  }

  return deviceMetrics;
};
