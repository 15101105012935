import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { getErrorMessage } from 'app/api/lib/error-handling';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import SelfbookSelect from 'app/components/selfbook-select/SelfbookSelect';
import useFocusFirstFormField from 'app/hooks/useFocusFirstFormField';
import { useRef } from 'react';
import useForm from 'app/hooks/useForm';
import { useInvoiceCategoriesQuery } from 'app/features/selfbook-admin/invoices/api/useInvoiceCategories';
import colors from 'app/styles/colors';
import SelfbookTextInput from 'app/components/selfbook-text-input/SelfbookTextInput';
import {
  selfbookRadioContainerStyle,
  selfbookRadioLabelStyle,
  selfbookRadioStyle,
} from 'app/components/selfbook-radio/styles';
import { FEE_TYPES } from 'app/features/selfbook-admin/invoices/constants';
import PaymentMethodIcon from 'app/components/payment-method/PaymentMethodIcon';
import { formatNumberToPrice, roundPrice } from 'app/lib/numbers';
import { InvoiceCategory } from 'app/features/selfbook-admin/invoices/types';
import { useUser } from 'app/features/authentication/context/useUser';
import CurrencyInput from 'app/components/currency-input/CurrencyInput';
import { formatValue } from 'react-currency-input-field';
import { computeFeeAdditionalPrice, getTotalNewChargeAmount } from '../lib/charges';
import { CardOnRecord, NewChargeRequestPayload } from '../types';

interface Props {
  onSubmit: (reqPayload: NewChargeRequestPayload) => void;
  isLoading?: boolean;
  submitError?: any;
  paymentMethods: CardOnRecord[] | null;
}

enum FormFields {
  category = 'category',
  amount = 'amount',
  chargeDescription = 'chargeDescription',
  paymentMethod = 'paymentMethod',
}

function getSelectedCategory(allCategories: InvoiceCategory[], categoryId: string) {
  return allCategories.find((category) => category.id === categoryId);
}

function renderPaymentMethodsView(
  paymentMethods: CardOnRecord[] | null,
  values: {
    category: { label: string; value: string };
    amount: string;
    chargeDescription: string;
    paymentMethod: CardOnRecord | null | undefined;
  },
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void,
) {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography
        fontSize={14}
        fontWeight={450}
        letterSpacing="0.04em"
        color={colors.grey[700]}
        marginBottom="8px"
      >
        PAYMENT METHOD
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          name={FormFields.paymentMethod}
          value={JSON.stringify(values.paymentMethod)}
          data-testid="new-charge-modal-form-payment-method"
          onChange={handleRadioChange}
        >
          {paymentMethods?.map((paymentMethod, index) => {
            return (
              <Box
                sx={{
                  ...selfbookRadioContainerStyle,
                  ':not(:last-child)': {
                    mb: '10px',
                  },
                }}
                key={paymentMethod.paymentToken}
              >
                <FormControlLabel
                  value={JSON.stringify(paymentMethod)}
                  sx={{ ...selfbookRadioLabelStyle, 'span:nth-of-type(2)': { width: '100%!important' } }}
                  control={<Radio sx={{ ...selfbookRadioStyle }} />}
                  data-testid={`reservation-payment-method-${index}`}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ml: '4px',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PaymentMethodIcon paymentMethod={paymentMethod?.cardBrand} />
                        <Typography sx={{ ml: 2 }}>{capitalize(paymentMethod?.cardBrand)}</Typography>
                      </Box>
                      <Box>**** {paymentMethod?.cardLast4}</Box>
                    </Box>
                  }
                />
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

function renderNewChargeTotals(
  values: {
    category: { label: string; value: string };
    amount: string;
    chargeDescription: string;
    paymentMethod: CardOnRecord | null | undefined;
  },
  currency: string,
  selectedCategory?: InvoiceCategory,
) {
  return (
    values.amount &&
    values.chargeDescription &&
    values.category.value && (
      <Box
        sx={{ display: 'flex', mt: 3, flexDirection: 'column' }}
        data-testid="new-charge-form-totals"
        component="dl"
      >
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1" lineHeight="16px" letterSpacing="0.01em" component="dt">
            {values.chargeDescription}
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={450}
            lineHeight="16px"
            letterSpacing="0.02em"
            component="dd"
          >
            {formatValue({
              value: values.amount,
              decimalScale: 2,
              intlConfig: { locale: 'en-US', currency },
            })}
          </Typography>
        </Box>
        {selectedCategory?.invoice_category_fees?.map((fee) => {
          const isFlatFee = fee.fee_type === FEE_TYPES.FLAT_FEE;
          const additionalFee = computeFeeAdditionalPrice({ isFlatFee, fee, amount: values.amount });
          return (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 2 }} key={fee.id}>
              <Typography variant="subtitle1" lineHeight="16px" letterSpacing="0.01em" component="dt">
                {isFlatFee ? `${fee.name} - $${fee.amount / 100}` : `${fee.amount}% ${fee.name}`}
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={450}
                lineHeight="16px"
                letterSpacing="0.02em"
                component="dd"
              >
                {formatNumberToPrice({ price: additionalFee })}
              </Typography>
            </Box>
          );
        })}

        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 2, mb: 3 }}>
          <Typography
            variant="subtitle1"
            fontWeight={450}
            lineHeight="16px"
            letterSpacing="0.01em"
            component="dt"
          >
            Total
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={450}
            lineHeight="16px"
            letterSpacing="0.02em"
            data-testid="new-charge-form-total-price"
            component="dd"
          >
            {formatNumberToPrice({
              price: getTotalNewChargeAmount(Number(values.amount), selectedCategory?.invoice_category_fees),
              locale: 'en-US',
              currency,
            })}
          </Typography>
        </Box>
        <Divider sx={{ borderBottom: `1px solid ${colors.grey[300]}`, height: '1px' }} />
      </Box>
    )
  );
}

function NewChargeModalForm({ onSubmit, isLoading = false, submitError, paymentMethods }: Props) {
  const { currentHotelId, currentHotelCurrency } = useUser();
  const { allInvoiceCategories } = useInvoiceCategoriesQuery(currentHotelId!);

  const invoiceCategoriesOptions: { value: string; label: string }[] =
    allInvoiceCategories.length > 0
      ? allInvoiceCategories.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        })
      : [{ value: '', label: '' }];

  const formRef = useRef<HTMLFormElement>(null);
  useFocusFirstFormField(formRef);

  const { values, resetErrors, getIsFormValid, focusFirstFieldWithError, updateFieldValue, errors } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.category]: { label: '', value: '' },
        [FormFields.amount]: '',
        [FormFields.chargeDescription]: '',
        [FormFields.paymentMethod]: paymentMethods && (paymentMethods[0] as CardOnRecord),
      },
      validations: {
        [FormFields.category]: (val) => (!val.value ? 'Category is required.' : true),
        [FormFields.chargeDescription]: (val) => (!val ? 'Description is required.' : true),
        [FormFields.amount]: (val) =>
          !val || Number(val) <= 0 || Number.isNaN(Number(val)) ? 'Amount must be greater than 0.' : true,
      },
    },
  );

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const paymentMethod = JSON.parse(value) as CardOnRecord;
    updateFieldValue(FormFields.paymentMethod, paymentMethod);
  };

  const selectedCategory = getSelectedCategory(allInvoiceCategories, values.category?.value);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    const totalAmount = getTotalNewChargeAmount(
      Number(values.amount),
      selectedCategory?.invoice_category_fees,
    );

    const roundedAmount = roundPrice(totalAmount);

    const stripePayload = {
      stripe: {
        payment_method_id: values.paymentMethod?.paymentToken,
        customer_id: values.paymentMethod?.customerId,
      },
    };

    const spreedlyPayload = {
      spreedly: {
        payment_method_token: values.paymentMethod?.paymentToken,
      },
    };

    const gatway = values.paymentMethod?.gateway === 'STRIPE' ? stripePayload : spreedlyPayload;

    const totalAmountInCents = roundedAmount;
    onSubmit({
      amount: totalAmountInCents,
      currency: currentHotelCurrency,
      description: values.chargeDescription,
      ...gatway,
    });
  };

  return (
    <Box
      data-testid="new-charge-modal-form"
      component="form"
      sx={{ width: '100%' }}
      onSubmit={handleSubmit}
      noValidate
      ref={formRef}
    >
      <Box component="fieldset" sx={{ padding: 0, margin: 0, border: 0 }}>
        <Typography
          fontSize={14}
          fontWeight={450}
          letterSpacing="0.04em"
          color={colors.grey[700]}
          marginBottom="8px"
          component="legend"
        >
          CHARGE DETAILS
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <Stack direction="column" spacing={1} sx={{ my: 0.5 }}>
            <SelfbookSelect
              fullWidth
              data-testid="new-charge-modal-form-category"
              id="new-charge-modal-form-category"
              name={FormFields.category}
              value={values.category.value}
              label="CATEGORY"
              onChange={(e) => {
                updateFieldValue(FormFields.category, e.target);
              }}
              options={invoiceCategoriesOptions}
              required
              helperText={errors.category as string}
              error={!!errors.category}
            />

            <CurrencyInput
              intlConfig={{ locale: 'en-US', currency: currentHotelCurrency }}
              data-testid="new-charge-modal-form-amount"
              id="new-charge-modal-form-amount"
              name={FormFields.amount}
              required
              label="AMOUNT"
              type="numeric"
              value={values.amount}
              onValueChange={(value: string) => {
                updateFieldValue(FormFields.amount, value);
              }}
              error={errors.amount}
            />

            <SelfbookTextInput
              data-testid="new-charge-modal-form-description"
              id="new-charge-modal-form-description"
              name={FormFields.chargeDescription}
              required
              label="DESCRIPTION"
              value={values.chargeDescription}
              onChange={(e) => updateFieldValue(FormFields.chargeDescription, e.target.value)}
              error={!!errors.chargeDescription}
              helperText={errors.chargeDescription}
            />
          </Stack>
        </FormControl>
      </Box>
      {renderPaymentMethodsView(paymentMethods, values, handleRadioChange)}
      {renderNewChargeTotals(values, currentHotelCurrency, selectedCategory)}

      {!!submitError && (
        <Alert
          data-testid="new-charge-modal-error"
          id="new-charge-modal-error"
          sx={{ boxShadow: 0, mt: 3 }}
          severity="error"
        >
          {getErrorMessage(submitError)}
        </Alert>
      )}

      <LoadingButton loading={isLoading} type="submit" fullWidth sx={{ mt: 3 }} disabled={isLoading}>
        Process Charge
      </LoadingButton>
    </Box>
  );
}

export default NewChargeModalForm;
