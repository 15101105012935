import {
  Box,
  Step,
  StepLabel,
  Stepper,
  StepContent,
  CircularProgress,
  Typography,
  Alert,
} from '@mui/material';
import CurrencyIcon from 'app/components/icons/CurrencyIcon';
import EventIcon from 'app/components/icons/EventIcon';
import { DateTimeStringObject } from 'app/lib/types';
import { getErrorMessage } from 'app/api/lib/error-handling';
import OutlineCloseIcon from 'app/components/icons/OutlineCloseIcon';
import UndoIcon from 'app/components/icons/UndoIcon';
import ArrowDownIcon from 'app/components/icons/ArrowDownIcon';
import ArrowUpIcon from 'app/components/icons/ArrowUpIcon';
import colors from 'app/styles/colors';
import { formatNumberToPrice } from 'app/lib/numbers';
import { useUser } from 'app/features/authentication/context/useUser';
import { useState } from 'react';
import { useReservationEventsQuery } from '../api/useReservationEvents';
import { ReservationEvent, ReservationEventType } from '../types';

interface Props {
  reservationId?: string;
}

const eventDateTimeFormatOptions: DateTimeStringObject = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true,
} as DateTimeStringObject;

const eventDateFormatter = (dateString: string) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', eventDateTimeFormatOptions);
  const parts = dateTimeFormat.formatToParts(new Date(Date.parse(dateString)));
  const dateTimeParts: DateTimeStringObject = {} as DateTimeStringObject;
  parts.forEach((part) => {
    dateTimeParts[part.type] = part.value;
  });

  return dateTimeParts;
};

function getIcon(eventType: ReservationEventType) {
  let icon;
  switch (eventType) {
    case 'CHARGE_CREATED':
      icon = <CurrencyIcon />;
      break;

    case 'CHARGE_FAILED':
      icon = <OutlineCloseIcon />;
      break;

    case 'RESERVATION_CANCELED':
      icon = <OutlineCloseIcon />;
      break;

    case 'RESERVATION_CREATED':
      icon = <EventIcon />;
      break;

    case 'REFUND_CREATED':
      icon = <UndoIcon />;
      break;

    case 'SCHEDULED_TRANSACTION_CANCELED':
      icon = <OutlineCloseIcon />;
      break;

    default:
      icon = <EventIcon />;
      break;
  }

  return (
    <Box component="span" sx={{ pl: '3px!important' }}>
      {icon}
    </Box>
  );
}

function ReservationDetailsPanelActivityTab({ reservationId }: Props) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>();
  const { currentHotelId } = useUser();
  const { data, isLoading, error } = useReservationEventsQuery(currentHotelId!, reservationId!);

  if (isLoading) {
    return (
      <Box
        data-testid="reservation-activity-tab-loading"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 3,
          position: 'relative',
          mt: 10,
          zIndex: -1,
          pointerEvents: 'auto',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert
        severity="error"
        data-testid="reservation-activity-tab-error"
        sx={{ position: 'relative', mt: 10, zIndex: -1, pointerEvents: 'auto' }}
      >
        {getErrorMessage(error)}
      </Alert>
    );
  }

  if (data?.length === 0) {
    return (
      <Alert
        severity="info"
        data-testid="reservation-activity-tab-no-data"
        sx={{ position: 'relative', mt: 10, zIndex: -1, pointerEvents: 'auto' }}
      >
        No events yet.
      </Alert>
    );
  }

  return (
    <Box
      sx={{ position: 'relative', mt: 10, zIndex: -1, pointerEvents: 'auto' }}
      data-testid="reservation-activity-tab"
    >
      <Stepper
        orientation="vertical"
        data-testid="reservation-activity-tab-list"
        connector={
          <Box
            component="span"
            className="activity-tab"
            sx={{ height: '40px', width: '2px', backgroundColor: '#F0F2F4', ml: '12px', mt: '-23px' }}
          />
        }
      >
        {data &&
          data.length > 0 &&
          data.map((event: ReservationEvent, index) => {
            const occuredAt = eventDateFormatter(event.occurred_at);
            const isExpanded = expandedIndex === index;
            const isReservationCreatedEvent = event.reservation_event_type === 'RESERVATION_CREATED';

            return (
              <Step
                key={event.id}
                expanded={isExpanded}
                className={
                  /* istanbul ignore next */ isExpanded
                    ? `expanded-tab${event.description ? '-has-desc' : ''}`
                    : ''
                }
                active={false}
              >
                <StepLabel
                  StepIconComponent={() => getIcon(event.reservation_event_type)}
                  data-testid={`reservation-activity-tab-${event.id}`}
                  sx={{
                    cursor: isReservationCreatedEvent ? 'default!important' : 'pointer!important',
                    pb: '10px',
                    '.MuiStepLabel-iconContainer': {
                      alignSelf: 'flex-start',
                      pr: 3,
                      mb: 0.5,
                    },
                  }}
                  onClick={() => {
                    if (isReservationCreatedEvent) {
                      return;
                    }
                    if (!isExpanded) {
                      setExpandedIndex(index);
                    } else {
                      setExpandedIndex(null);
                    }
                  }}
                  optional={
                    <Typography
                      letterSpacing="0.14px"
                      fontWeight={300}
                      fontSize="14px"
                      color={colors.grey[900]}
                      sx={{ lineHeight: '100%' }}
                    >
                      {`${occuredAt.month} ${occuredAt.day}, ${occuredAt.year} at ${occuredAt.hour}:${occuredAt.minute} ${occuredAt.dayPeriod}`}
                    </Typography>
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: '10px',
                    }}
                  >
                    <Typography
                      letterSpacing="0.058px"
                      fontWeight={450}
                      fontSize="18px"
                      color={colors.grey[700]}
                      sx={{ lineHeight: '100%' }}
                    >
                      {event.title}
                    </Typography>
                    {!isReservationCreatedEvent && (isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}
                  </Box>
                </StepLabel>
                <StepContent transitionDuration={{ exit: 0, enter: 300, appear: 300 }}>
                  <Box
                    sx={{
                      backgroundColor: colors.grey[100],
                      px: '20px',
                      py: '12px',
                      ml: '12px',
                      mr: 3,
                      borderRadius: '2px',
                      mb: 3,
                    }}
                    data-testid={`reservation-activity-tab-expanded-${event.id}-content`}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {event.description && (
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: '300',
                              color: colors.grey[700],
                              lineHeight: '100%',
                              mb: 1,
                            }}
                          >
                            {event.description}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '450',
                            color: colors.grey[700],
                            lineHeight: '100%',
                          }}
                        >
                          {event.user.name}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '300',
                          color: colors.grey[700],
                          lineHeight: '100%',
                        }}
                      >
                        {event.amount
                          ? formatNumberToPrice({
                              price: event.amount / 100,
                              currency: event.currency,
                            })
                          : '/'}
                      </Typography>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );
}

export default ReservationDetailsPanelActivityTab;
