import { Hotel } from '../types';

export const mapHotel = (item: any) => {
  const hotel: Hotel = {} as Hotel;

  hotel.createdAt = item.created_at;
  hotel.name = item.name;
  hotel.email = item.email;
  hotel.telephone_number = item.telephone_number;
  hotel.logo_url = item.logo_url;
  hotel.spreedly = item.spreedly;
  hotel.id = item.id;
  hotel.code = item.code;
  hotel.updatedAt = item.updated_at;
  hotel.stripe = item.stripe;
  hotel.status = item.status;
  hotel.crs_type = item.crs_type;
  hotel.currency = item.currency;
  hotel.address = item.address;

  return hotel;
};
