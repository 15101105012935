import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import materialTheme from 'app/styles/material/theme';
import Router from 'app/routing/router';
import { QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import reactQueryClient from 'app/api/react-query/react-query-client';
import { UserContextProvider } from 'app/features/authentication/context/useUser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { applyGlobalCss } from '@selfbook/design/dist/exported-utils/apply-global-css';
import reportWebVitals from './reportWebVitals';
import './app/styles/index.css';

applyGlobalCss();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID as string,
  });
  root.render(
    <React.StrictMode>
      <LDProvider>
        <UserContextProvider>
          <QueryClientProvider client={reactQueryClient}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <ThemeProvider theme={materialTheme}>
                <Router />
              </ThemeProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </UserContextProvider>
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window {
    Stripe: any;
  }
}
