import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CopyIcon from 'app/components/icons/CopyIcon';
import SendIcon from 'app/components/icons/SendIcon';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import {
  selfbookRadioContainerStyle,
  selfbookRadioLabelStyle,
  selfbookRadioStyle,
} from 'app/components/selfbook-radio/styles';
import { useReservationDetailContext } from 'app/features/reservations/context/useReservationDetailContext';
import colors from 'app/styles/colors';
import { useSnackbar } from 'notistack';
import { PAY_URL } from 'app/config/constants';
import { FormEvent, Fragment, useState } from 'react';
import { PaymentLink, PaymentLinkTemplate1 } from '../types';
import { useSharePaymentLink } from '../api/useSharePaymentLink';

interface Props {
  open: boolean;
  paymentLink?: PaymentLink<PaymentLinkTemplate1>;
  onClose: () => void;
  isLoading: boolean;
}

function UpdatePaymentMethodModal({ open, onClose, paymentLink, isLoading }: Props) {
  const { mutate: sharePaymentLinkMutate, isLoading: sharePaymentLinkLoading } = useSharePaymentLink();

  const {
    subheaderUi: { customerEmail, customerPhone },
  } = useReservationDetailContext();

  const [sendPaymentLinkMethod, setSendPaymentLinkMethod] = useState<'email' | 'sms'>('email');
  const { enqueueSnackbar } = useSnackbar();

  const getPaymentLinkLink = () => {
    return `${PAY_URL}/P${paymentLink?.short_code}`;
  };

  const getPaymentLinkDisplayLink = () => {
    const url = new URL(getPaymentLinkLink());
    return `${url.host}${url.pathname}`;
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const method = e.target.value as 'email' | 'sms';
    setSendPaymentLinkMethod(method);
  };

  const handleCopyPaymentLink = () => {
    navigator.clipboard.writeText(getPaymentLinkLink());
    enqueueSnackbar('Link Copied', { variant: 'success', role: 'alert' });
  };

  const handleSendPaymentLink = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sharePaymentLinkMutate(
      {
        hotelId: paymentLink!.hotel.id,
        paymentLinkId: paymentLink!.id,
        target:
          sendPaymentLinkMethod === 'email'
            ? paymentLink!.template.data.reservation.guest_information.email
            : paymentLink!.template.data.reservation.guest_information.telephone_number,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Payment Link Sent', { variant: 'success', role: 'alert' });
          onClose();
        },
      },
    );
  };

  return (
    <SelfbookDialog data-testid="request-new-payment-method-modal" open={open} onClose={onClose}>
      <SelfbookDialog.Title
        data-testid="request-new-payment-method-modal-title"
        onClose={onClose}
        aria-label="Request New Payment Method Title"
      >
        Add Payment Method
      </SelfbookDialog.Title>
      <SelfbookDialog.Content
        data-testid="request-new-payment-method-modal-content"
        sx={{ width: '450px', paddingBottom: '24px' }}
      >
        {
          /* istanbul ignore next */ isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 6 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Fragment>
              {' '}
              <Box mt={1} mb={2}>
                <Typography fontSize="15px" mb={2} color={colors.grey[600]} fontWeight={300}>
                  Guest will not be charged. By default, any future charges for this booking will use the
                  updated payment method.
                </Typography>

                <Typography fontSize="18px">Copy Link</Typography>
                <Box
                  component="button"
                  data-testid="copy-payment-link-button"
                  onClick={handleCopyPaymentLink}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                  p={2}
                  width="100%"
                  sx={{
                    borderRadius: '4px',
                    backgroundColor: colors.grey[200],
                    cursor: 'pointer',
                    border: 'unset',
                    textAlign: 'left',
                  }}
                >
                  <Typography fontWeight="300" fontSize="16px">
                    {getPaymentLinkDisplayLink()}
                  </Typography>
                  <CopyIcon style={{ minHeight: '24px', minWidth: '24px' }} />
                </Box>
              </Box>
              <Box component="form" onSubmit={handleSendPaymentLink}>
                <Typography fontSize="18px">Send Link</Typography>
                <RadioGroup value={sendPaymentLinkMethod} onChange={handleRadioChange}>
                  <Box mb={1} mt={1} sx={selfbookRadioContainerStyle}>
                    <FormControlLabel
                      data-testid="email-radio-option"
                      value="email"
                      control={<Radio sx={selfbookRadioStyle} />}
                      sx={selfbookRadioLabelStyle}
                      label={
                        <Box ml={1}>
                          <Typography variant="body1" fontWeight="400">
                            Email
                          </Typography>
                          <Typography fontWeight="300">Send to {customerEmail}</Typography>
                        </Box>
                      }
                    />
                  </Box>
                  <Box sx={selfbookRadioContainerStyle}>
                    <FormControlLabel
                      data-testid="sms-radio-option"
                      value="sms"
                      control={<Radio sx={selfbookRadioStyle} />}
                      sx={selfbookRadioLabelStyle}
                      label={
                        <Box ml={1}>
                          <Typography variant="body1" fontWeight="400">
                            SMS
                          </Typography>
                          <Typography fontWeight="300">Send to {customerPhone}</Typography>
                        </Box>
                      }
                    />
                  </Box>
                </RadioGroup>
                <LoadingButton
                  fullWidth
                  loading={sharePaymentLinkLoading}
                  startIcon={<SendIcon />}
                  type="submit"
                  sx={{ marginTop: '16px' }}
                >
                  SEND LINK
                </LoadingButton>
              </Box>
            </Fragment>
          )
        }
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default UpdatePaymentMethodModal;
