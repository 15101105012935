import { SxProps } from '@mui/material';

const useSelfbookDialogStyles = () => {
  const titleStyle: SxProps = {
    m: 0,
    px: 3,
    pt: 2,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'grey.700',
    fontSize: '18px',
    alignItems: 'center',
  };

  const contentStyle: SxProps = { px: 3, pt: 3, pb: 4 };

  return { titleStyle, contentStyle };
};

export default useSelfbookDialogStyles;
