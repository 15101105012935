import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  length: number;
}

function SelfbookSkeleton({ length = 10 }: Props) {
  return (
    <Stack>
      {Array.from({ length }, (_, i) => (
        <div key={i}>
          <Skeleton height="65px" />
        </div>
      ))}
    </Stack>
  );
}

export default SelfbookSkeleton;
