export enum TabsEnum {
  ROOMS,
  GLOBAL_RATES,
  ADDONS,
  DESCRIPTIONS,
  GALLERY,
  ACTIVE_PAYMENT_LINKS,
  ARCHIVE_PAYMENT_LINKS,
  PAYMENT,
  ACTIONS,
  ACTIVITY
}
