import FeatureRestrictedPage from 'app/features/permissions/components/FeatureRestricted';

interface Props {
  element: JSX.Element;
  hasAccess: boolean;
  noAccessMessage: string;
}

function FeatureRoute({ element, hasAccess, noAccessMessage }: Props) {
  if (!hasAccess) return <FeatureRestrictedPage message={noAccessMessage} />;
  return element;
}

export default FeatureRoute;
