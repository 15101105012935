import { useUser } from 'app/features/authentication/context/useUser';
import { useStopPaymentAutomation } from '../api/useStopPaymentAutomation';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import { getRoomChargeScheduledTransactions } from '../sidebar-layer/roomCharge';
import StopPaymentAutomationConfirmationModal from './StopPaymentAutomationConfirmationModal';

function StopPaymentAutomationModal({
  open,
  showStopPaymentAutomationModal,
}: {
  open: boolean;
  showStopPaymentAutomationModal: (state: boolean) => void;
}) {
  const { currentHotelId } = useUser();
  const { reservation, reservationRefetch } = useReservationDetailContext();

  const roomChargeScheduledTransactions = getRoomChargeScheduledTransactions(reservation!);

  const { mutate: mutateStopPaymentAutomation, isLoading: loadingStopPaymentAutomation } =
    useStopPaymentAutomation(currentHotelId!);

  const togglePaymentAutomationModal = () => {
    showStopPaymentAutomationModal(!open);
  };

  const handleSuccessStopPaymentAutomation = () => {
    showStopPaymentAutomationModal(false);
    reservationRefetch();
  };

  const handleStopPaymentAutomation = () => {
    mutateStopPaymentAutomation(
      { scheduledTransactions: roomChargeScheduledTransactions },
      {
        onSuccess: handleSuccessStopPaymentAutomation,
      },
    );
  };

  return (
    <StopPaymentAutomationConfirmationModal
      isModalOpen={open}
      togglePaymentAutomationModal={togglePaymentAutomationModal}
      loadingStopPaymentAutomation={loadingStopPaymentAutomation}
      handleStopPaymentAutomation={handleStopPaymentAutomation}
    />
  );
}

export default StopPaymentAutomationModal;
