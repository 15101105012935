import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { getErrorMessage } from 'app/api/lib/error-handling';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import {
  selfbookRadioContainerStyle,
  selfbookRadioLabelStyle,
  selfbookRadioStyle,
} from 'app/components/selfbook-radio/styles';
import SelfbookSelect from 'app/components/selfbook-select/SelfbookSelect';
import useFocusFirstFormField from 'app/hooks/useFocusFirstFormField';
import CurrencyInput from 'app/components/currency-input/CurrencyInput';
import PercentageInput from 'app/components/percentage-input/PercentageInput';
import { formatNumberToPrice, roundPrice } from 'app/lib/numbers';
import { useRef } from 'react';
import useForm from 'app/hooks/useForm';
import { useUser } from 'app/features/authentication/context/useUser';
import { getAmountToRefund, PartialRefundType, RefundChargeType } from '../lib/refunds';
import { RefundActionPayload } from '../sidebar-layer/roomCharge';
import { mapCurrencyToSymbol } from '../lib/mapCurrencyToSymbol';

interface Props {
  chargeToRefund: RefundActionPayload;
  onSubmit: (amount: number) => void;
  isLoading?: boolean;
  submitError?: any;
}

function RefundModalForm({ chargeToRefund, onSubmit, isLoading = false, submitError }: Props) {
  const { currentHotelCurrency } = useUser();
  const formRef = useRef<HTMLFormElement>(null);
  useFocusFirstFormField(formRef);

  enum FormFields {
    type = 'type',
    partialRefundType = 'partialRefundType',
    partialRefundAmount = 'partialRefundAmount',
  }

  const refundTypeOptions: { value: PartialRefundType; label: string }[] = [
    {
      value: PartialRefundType.Amount,
      label: `Amount (${mapCurrencyToSymbol(chargeToRefund.chargeCurrency)})`,
    },
    { value: PartialRefundType.Percentage, label: 'Percentage (%)' },
  ];

  const { values, errors, focusFirstFieldWithError, getIsFormValid, resetErrors, updateFieldValue } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.type]: RefundChargeType.Full,
        [FormFields.partialRefundType]: PartialRefundType.Amount as PartialRefundType,
        [FormFields.partialRefundAmount]: '',
      },
      validations: {
        [FormFields.partialRefundAmount]: (val, formValues) => {
          if (formValues.type === RefundChargeType.Partial) {
            if (!val) {
              return 'Amount must be greater than 0';
            }

            if (Number(val) <= 0) {
              return 'Amount must be greater than 0';
            }

            // istanbul ignore next // there are tests for this
            if (formValues.partialRefundType === PartialRefundType.Percentage && Number(val) > 100) {
              return 'Amount cannot be greater than 100%';
            }
          }

          return true;
        },
      },
    },
  );

  const amountToRefund = () =>
    getAmountToRefund({
      chargeAmount: chargeToRefund.chargeAmount,
      refundChargeType: values.type,
      partialRefundAmount: +values.partialRefundAmount || 0,
      partialRefundType: values.partialRefundType,
    });

  const priceNotInCents = amountToRefund() / 100;
  const formattedAmountToRefund = formatNumberToPrice({
    price: priceNotInCents,
    currency: chargeToRefund.chargeCurrency,
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    const roundedAmount = roundPrice(priceNotInCents);

    onSubmit(roundedAmount);
  };

  const amountFieldProps = {
    'dataTestId': 'create-refund-modal-form-partial-refund-amount',
    id: 'create-refund-modal-form-partial-refund-amount',
    name: FormFields.partialRefundAmount,
    required: true,
    label: 'Amount',
    type: 'numeric',
    value: values.partialRefundAmount,
    onValueChange: (value: string) => {
      updateFieldValue(FormFields.partialRefundAmount, value);
    },
    error: errors.partialRefundAmount,
  };

  const amountField =
    values.partialRefundType === PartialRefundType.Amount ? (
      <CurrencyInput intlConfig={{ locale: 'en-US', currency: currentHotelCurrency }} {...amountFieldProps} />
    ) : (
      <PercentageInput {...amountFieldProps} />
    );

  return (
    <Box
      data-testid="create-refund-modal-form"
      component="form"
      sx={{ width: '100%' }}
      onSubmit={handleSubmit}
      noValidate
      ref={formRef}
    >
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          name={FormFields.type}
          value={values.type}
          onChange={(e) => updateFieldValue(FormFields.type, e.target.value)}
          data-testid="create-refund-modal-form-type"
        >
          <Box sx={{ ...selfbookRadioContainerStyle }}>
            <FormControlLabel
              value="full"
              sx={{ ...selfbookRadioLabelStyle }}
              control={<Radio sx={{ ...selfbookRadioStyle }} />}
              label="Full Refund"
            />
          </Box>
          <Box sx={{ ...selfbookRadioContainerStyle, mt: 1 }}>
            <FormControlLabel
              value="partial"
              sx={{ ...selfbookRadioLabelStyle }}
              control={<Radio sx={{ ...selfbookRadioStyle }} />}
              label="Partial Refund"
              data-testid="partial-refund"
            />
            {values.type === RefundChargeType.Partial && (
              <Stack direction="row" spacing={1} sx={{ my: 0.5 }}>
                <SelfbookSelect
                  fullWidth
                  data-testid="create-refund-modal-form-partial-refund-type"
                  id="create-refund-modal-form-partial-refund-type"
                  name={FormFields.partialRefundType}
                  value={values.partialRefundType}
                  label="Refund Type"
                  onChange={(e) => {
                    updateFieldValue(FormFields.partialRefundType, e.target.value);
                    updateFieldValue(FormFields.partialRefundAmount, '');
                  }}
                  options={refundTypeOptions}
                  required
                />

                {amountField}
              </Stack>
            )}
          </Box>
        </RadioGroup>
      </FormControl>

      {!!submitError && (
        <Alert
          data-testid="create-refund-modal-error"
          id="create-refund-modal-error"
          sx={{ boxShadow: 0, mt: 3 }}
          severity="error"
        >
          {getErrorMessage(submitError)}
        </Alert>
      )}

      <LoadingButton loading={isLoading} type="submit" fullWidth sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <span>Refund</span>
          <span>{formattedAmountToRefund}</span>
        </Stack>
      </LoadingButton>
    </Box>
  );
}

export default RefundModalForm;
