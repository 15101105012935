import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AdyenIcon from 'app/components/icons/AdyenIcon';
import InfoIcon from 'app/components/icons/InfoIcon';
import PlanetIcon from 'app/components/icons/PlanetIcon';
import StripeIcon from 'app/components/icons/StripeIcon';
import {
  selfbookRadioContainerStyle,
  selfbookRadioLabelStyle,
  selfbookRadioStyle,
} from 'app/components/selfbook-radio/styles';
import colors from 'app/styles/colors';
import { useAfterLoginContext } from '../../after-login/context/useAfterLoginContext';

function StripeConnectPrompt() {
  const { closeAfterLoginModal, handleCompleteAccountClick } = useAfterLoginContext();

  const handleModalClose = () => {
    closeAfterLoginModal();
  };

  const handleClick = () => {
    handleCompleteAccountClick();
    handleModalClose();
  };

  return (
    <Stack sx={{ pb: 1.5 }} data-testid="stripe-connect-prompt">
      <Stack>
        <Typography
          component="p"
          fontSize="16px"
          fontWeight="300"
          lineHeight="20.8px"
          letterSpacing="0.051px"
          color={colors.grey[700]}
        >
          To finish setting up your account, select a payment terminal to process payments in Station.{' '}
        </Typography>

        <RadioGroup
          sx={{ width: '100%', mt: 2 }}
          value="stripe"
          name="stripe"
          data-testid="stripe-connect-prompt-psp-method"
        >
          <Box
            sx={{
              ...selfbookRadioContainerStyle,
              ':not(:last-child)': {
                mb: '4px',
              },
              backgroundColor: colors.grey[100],
              borderColor: colors.grey[600],
            }}
            key="stripe"
          >
            <FormControlLabel
              value="stripe"
              disabled
              sx={{ ...selfbookRadioLabelStyle, 'span:nth-of-type(2)': { width: '100%!important' } }}
              control={<Radio sx={{ ...selfbookRadioStyle }} />}
              data-testid="stripe-psp"
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    ml: 2,
                  }}
                >
                  <StripeIcon />
                </Box>
              }
            />
          </Box>

          <Box
            sx={{
              ...selfbookRadioContainerStyle,
              ':not(:last-child)': {
                mb: '4px',
              },
              borderColor: colors.grey[200],
              ':focus, :focus-within, :hover': {
                borderColor: 'grey.200',
              },
            }}
            key="adyen"
          >
            <FormControlLabel
              value="adyen"
              disabled
              sx={{ ...selfbookRadioLabelStyle, 'span:nth-of-type(2)': { width: '100%!important' } }}
              control={<Radio sx={{ ...selfbookRadioStyle }} />}
              data-testid="adyen-psp"
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    ml: 2,
                  }}
                >
                  <AdyenIcon />
                </Box>
              }
            />
          </Box>

          <Box
            sx={{
              ...selfbookRadioContainerStyle,
              borderColor: colors.grey[200],
              ':focus, :focus-within, :hover': {
                borderColor: 'grey.200',
              },
            }}
            key="planet"
          >
            <FormControlLabel
              value="planet"
              disabled
              sx={{ ...selfbookRadioLabelStyle, 'span:nth-of-type(2)': { width: '100%!important' } }}
              control={<Radio sx={{ ...selfbookRadioStyle }} />}
              data-testid="planet-psp"
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    ml: 2,
                  }}
                >
                  <PlanetIcon />
                </Box>
              }
            />
          </Box>
        </RadioGroup>

        <Box sx={{ display: 'flex', mt: 2, alignItems: 'center', gap: '4px' }}>
          <InfoIcon />
          <Typography
            fontSize="14px"
            fontWeight="300"
            lineHeight="18.2px"
            letterSpacing="0.045px"
            color={colors.grey[700]}
          >
            Adyen and Planet available soon.
          </Typography>
        </Box>
      </Stack>

      <Button
        fullWidth
        sx={{ mt: 3, fontSize: '14px', fontWeight: '450', letterSpacing: '0.56px', lineHeight: '100%' }}
        onClick={handleClick}
      >
        Complete Account Setup
      </Button>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleModalClose}
          variant="textMuted"
          sx={{
            mt: 2,
            fontSize: '14px',
            letterSpacing: '0.56px',
            lineHeight: '100%',
            fontWeight: '450',
            textTransform: 'uppercase',
          }}
        >
          Skip for now
        </Button>
      </Box>
    </Stack>
  );
}

export default StripeConnectPrompt;
