export const STATION_API_URL = process.env.REACT_APP_STATION_API_URL || 'http://localhost:3000';
export const DIRECT_API_URL =
  process.env.REACT_APP_DIRECT_API_URL || 'http://localhost:3000/direct_api_proxy';
export const ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY || 'banana';
export const REFRESH_TOKEN_KEY = process.env.REACT_APP_REFRESH_TOKEN_KEY || 'kiwi';
export const CLIENT_ID_KEY = process.env.REACT_APP_CLIENT_ID_KEY || 'jackfruit';
export const PAY_URL = process.env.REACT_APP_PAY_URL || 'http://localhost:9000';
export const SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY =
  process.env.REACT_APP_SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY || 'mango';
export const SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY =
  process.env.REACT_APP_SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY || 'papaya';
