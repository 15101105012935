import { SVGProps } from 'react';

function LinkIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.2813 1.22503C17.9329 0.875428 17.5189 0.598039 17.0631 0.408769C16.6073 0.219499 16.1186 0.12207 15.625 0.12207C15.1315 0.12207 14.6428 0.219499 14.187 0.408769C13.7312 0.598039 13.3172 0.875428 12.9688 1.22503L13.8563 2.11253C14.089 1.87984 14.3652 1.69526 14.6692 1.56934C14.9733 1.44341 15.2991 1.37859 15.6282 1.37859C15.9572 1.37859 16.2831 1.44341 16.5871 1.56934C16.8911 1.69526 17.1674 1.87984 17.4 2.11253C17.6327 2.34521 17.8173 2.62145 17.9432 2.92547C18.0692 3.22949 18.134 3.55533 18.134 3.8844C18.134 4.21347 18.0692 4.53931 17.9432 4.84333C17.8173 5.14735 17.6327 5.42359 17.4 5.65628L12.4 10.6563C11.9309 11.1262 11.2944 11.3905 10.6304 11.3911C9.96638 11.3917 9.32935 11.1285 8.85942 10.6594C8.38949 10.1903 8.12515 9.55373 8.12457 8.88974C8.12398 8.22574 8.38719 7.58871 8.85629 7.11878L9.73754 6.23128L8.85629 5.34378L7.96879 6.23128C7.61919 6.57967 7.3418 6.99364 7.15253 7.44946C6.96326 7.90528 6.86583 8.39397 6.86583 8.88753C6.86583 9.38108 6.96326 9.86978 7.15253 10.3256C7.3418 10.7814 7.61919 11.1954 7.96879 11.5438C8.67597 12.2419 9.63134 12.6308 10.625 12.625C11.1205 12.6271 11.6114 12.5309 12.0695 12.3421C12.5276 12.1533 12.9437 11.8756 13.2938 11.525L18.2938 6.52503C18.9944 5.82024 19.3866 4.86619 19.3842 3.87244C19.3819 2.87869 18.9852 1.9265 18.2813 1.22503Z"
        fill="white"
      />
      <path
        d="M2.61879 12.5125C2.38541 12.2802 2.20022 12.0041 2.07386 11.7C1.94749 11.396 1.88244 11.0699 1.88244 10.7407C1.88244 10.4114 1.94749 10.0853 2.07386 9.78126C2.20022 9.47719 2.38541 9.20107 2.61879 8.96878L7.61879 3.96878C7.85109 3.7354 8.1272 3.55021 8.43127 3.42384C8.73534 3.29748 9.06138 3.23243 9.39067 3.23243C9.71995 3.23243 10.046 3.29748 10.3501 3.42384C10.6541 3.55021 10.9302 3.7354 11.1625 3.96878C11.3944 4.2029 11.577 4.48119 11.6994 4.78716C11.8218 5.09313 11.8815 5.42055 11.875 5.75003C11.8769 6.08053 11.8133 6.40813 11.6878 6.71388C11.5623 7.01962 11.3774 7.29745 11.1438 7.53128L9.81879 8.87503L10.7063 9.76253L12.0313 8.43753C12.7366 7.73222 13.1328 6.77561 13.1328 5.77815C13.1328 4.78069 12.7366 3.82409 12.0313 3.11878C11.326 2.41347 10.3694 2.01723 9.37192 2.01723C8.37446 2.01723 7.41785 2.41347 6.71254 3.11878L1.71254 8.11878C1.362 8.46728 1.08382 8.88164 0.893994 9.33804C0.704168 9.79443 0.606445 10.2839 0.606445 10.7782C0.606445 11.2725 0.704168 11.7619 0.893994 12.2183C1.08382 12.6747 1.362 13.089 1.71254 13.4375C2.42431 14.1303 3.38185 14.5124 4.37504 14.5C5.37698 14.501 6.33862 14.1055 7.05004 13.4L6.16254 12.5125C5.93025 12.7459 5.65413 12.9311 5.35006 13.0575C5.04599 13.1838 4.71995 13.2489 4.39067 13.2489C4.06138 13.2489 3.73534 13.1838 3.43127 13.0575C3.1272 12.9311 2.85109 12.7459 2.61879 12.5125Z"
        fill="white"
      />
    </svg>
  );
}

export default LinkIcon;
