import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Input } from '@selfbook/design/dist/components/input/index';
import { selfbookRadioLabelStyle, selfbookRadioStyle } from 'app/components/selfbook-radio/styles';
import { FiltersOptions, NumberOfGuestsFilter } from 'app/features/reservations/types';
import useFocusFirstFormField from 'app/hooks/useFocusFirstFormField';
import useForm from 'app/hooks/useForm';
import colors from 'app/styles/colors';
import { useRef } from 'react';

interface Props {
  label: string;
  filterKey: string;
  onApply: (args: FiltersOptions) => void;
  filterCriteria: FiltersOptions | undefined;
}

enum FormFields {
  criteria = 'criteria',
  exact = 'exact',
  from = 'from',
  to = 'to',
}

enum NumberOfGuestsFilterCriteria {
  IN_BOOKING = 'in-booking',
  BETWEEN = 'between',
}

export default function NumberOfGuests({ label, filterKey, onApply, filterCriteria }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  useFocusFirstFormField(formRef);

  const filterValues = filterCriteria && (filterCriteria[filterKey] as NumberOfGuestsFilter);
  const { values, errors, updateFieldValue, resetErrors, getIsFormValid, focusFirstFieldWithError } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.criteria]: filterValues?.criteria
          ? filterValues?.criteria
          : NumberOfGuestsFilterCriteria.IN_BOOKING,
        [FormFields.exact]: filterValues?.exact ? filterValues?.exact : '',
        [FormFields.from]: filterValues?.between ? filterValues?.between.from : '',
        [FormFields.to]: filterValues?.between ? filterValues?.between.to : '',
      },
      validations: {
        [FormFields.exact]: (val) => {
          if (!val && values.criteria === NumberOfGuestsFilterCriteria.IN_BOOKING)
            return 'Number is required.';
          return true;
        },
        [FormFields.from]: (val) => {
          if (!val && values.criteria === NumberOfGuestsFilterCriteria.BETWEEN) return 'Number is required.';
          return true;
        },
        [FormFields.to]: (val) => {
          if (!val && values.criteria === NumberOfGuestsFilterCriteria.BETWEEN) return 'Number is required.';
          return true;
        },
      },
    },
  );

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const method = e.target.value as NumberOfGuestsFilterCriteria;
    updateFieldValue(FormFields.criteria, method);
    updateFieldValue(FormFields.exact, '');
    updateFieldValue(FormFields.from, '');
    updateFieldValue(FormFields.to, '');
  };

  const handleOnApply = () => {
    if (values.criteria === NumberOfGuestsFilterCriteria.IN_BOOKING) {
      onApply({
        [filterKey]: {
          criteria: values.criteria,
          exact: values.exact,
        },
      });
    }

    if (values.criteria === NumberOfGuestsFilterCriteria.BETWEEN) {
      onApply({
        [filterKey]: {
          criteria: values.criteria,
          between: {
            from: values.from,
            to: values.to,
          },
        },
      });
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();
    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    handleOnApply();
  };

  return (
    <Box sx={{ width: '100%' }} key={filterKey} data-testid={`${filterKey}-filter`}>
      <Box
        data-testid="number-of-guests-filter-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${colors.grey[200]}`,
          p: 2,
        }}
      >
        <Typography
          fontSize="16px"
          fontWeight={450}
          lineHeight="100%"
          letterSpacing="0.026px"
          color={colors.grey[700]}
        >
          {label}
        </Typography>
        <Button
          variant="textMuted"
          sx={{ minWidth: 'fit-content',    textDecorationColor: colors.grey[500], }}
          data-testid={`${filterKey}-clear-button`}
          onClick={() => {
            onApply({
              [filterKey]: undefined,
            });
            updateFieldValue(FormFields.criteria, NumberOfGuestsFilterCriteria.IN_BOOKING);
            updateFieldValue(FormFields.exact, '');
            updateFieldValue(FormFields.from, '');
            updateFieldValue(FormFields.to, '');
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight={450}
            lineHeight="100%"
            letterSpacing="0.022px"
            color={colors.grey[500]}
          >
            Clear
          </Typography>
        </Button>
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit} ref={formRef}>
        <Box
          data-testid="number-of-guests-filter-content"
          sx={{ p: 2, borderBottom: `1px solid ${colors.grey[200]}` }}
        >
          <RadioGroup
            value={values.criteria}
            onChange={handleRadioChange}
            sx={{ '> :not(:last-child)': { mb: 2 } }}
          >
            <Box>
              <FormControlLabel
                data-testid="in-booking-radio-option"
                value={NumberOfGuestsFilterCriteria.IN_BOOKING} // make it variable!
                control={<Radio sx={selfbookRadioStyle} />}
                sx={{
                  ...selfbookRadioLabelStyle,
                  alignItems: 'flex-start',
                  '> span': { paddingTop: '0px', marginTop: '-3px' },
                }}
                label={
                  <Typography fontSize="14px" letterSpacing="0.14px" lineHeight="100%" fontWeight="450">
                    Exact guest count
                  </Typography>
                }
              />
              <Input
                label="guest number"
                disabled={values.criteria !== NumberOfGuestsFilterCriteria.IN_BOOKING}
                id="guest-number"
                data-testid="guest-number"
                required
                type="text"
                value={values.exact}
                name={FormFields.exact}
                onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
                error={errors.exact as string}
              />
            </Box>

            <Box>
              <FormControlLabel
                data-testid="between-radio-option"
                value={NumberOfGuestsFilterCriteria.BETWEEN}
                control={<Radio sx={selfbookRadioStyle} />}
                sx={{
                  ...selfbookRadioLabelStyle,
                  alignItems: 'flex-start',
                  '> span': { paddingTop: '0px', marginTop: '-3px' },
                }}
                label={
                  <Typography fontSize="14px" letterSpacing="0.14px" lineHeight="100%" fontWeight="450">
                    Guest count between
                  </Typography>
                }
              />
              <Box sx={{ gap: 0.5, width: '100%', display: 'flex', '> div': { width: '50%' } }}>
                <Input
                  label="from"
                  disabled={values.criteria !== NumberOfGuestsFilterCriteria.BETWEEN}
                  id="guest-number-from"
                  data-testid="guest-number-from"
                  required
                  type="text"
                  value={values.from}
                  name={FormFields.from}
                  onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
                  error={errors.from as string}
                />
                <Input
                  label="to"
                  disabled={values.criteria !== NumberOfGuestsFilterCriteria.BETWEEN}
                  id="guest-number-to"
                  data-testid="guest-number-to"
                  required
                  type="text"
                  value={values.to}
                  name={FormFields.to}
                  onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
                  error={errors.to as string}
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button type="submit" fullWidth>
            <Typography fontSize="14px" fontWeight="450" lineHeight="100%" letterSpacing="0.56px">
              Apply
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
