import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from 'app/styles/colors';
import { useReservationDetailContext } from '../context/useReservationDetailContext';
import ReservationCharge from './ReservationCharge';

function ReservationRefunds() {
  const { refundsUi } = useReservationDetailContext();

  if (refundsUi.refunds.length === 0) return null;
  return (
    <Stack spacing={1} mt={4} pr="10px">
      <Typography
        variant="h3"
        sx={{
          fontWeight: 450,
          fontSize: 20,
          lineHeight: '24px',
          letterSpacing: '0.01em',
          color: colors.grey[900],
        }}
      >
        Refunds
      </Typography>
      <Stack spacing={0.5}>
        {refundsUi.refunds.map((refund) => {
          return <ReservationCharge key={refund.id} charge={refund} />;
        })}
      </Stack>
    </Stack>
  );
}

export default ReservationRefunds;
