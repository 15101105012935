import { Alert, Typography } from '@mui/material';
import { lazy, Suspense, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import colors from 'app/styles/colors';
import { getErrorMessage } from 'app/api/lib/error-handling';
import useFeatureFlags from 'app/services/feature-flags/useFeatureFlags';
import { useUser } from 'app/features/authentication/context/useUser';
import SelfbookSkeleton from 'app/components/selfbook-skeleton/SelfbookSkeleton';
import { useReservationsQuery } from '../api/useReservations';
import ReservationsTable from './ReservationsTable';
import ReservationsLoading from './ReservationsLoading';
import ReservationsActionsBar from './ReservationsActionsBar';
import { Reservation } from '../types';
import ReservationDetailsPanel from './ReservationDetailsPanel';
import { TimeLineContextProvider } from '../context/useTimeLineContext';
import ReservationsMetrics from './ReservationMetrics';
import ReservationsFilters from './ReservationsFilters';

const TimelineNavigation = lazy(() => import('./TimelineNavigation'));
const Timeline = lazy(() => import('./TimeLine'));

function Reservations() {
  const { showTimeline } = useFeatureFlags();

  const { currentHotelId } = useUser();
  const {
    allReservations,
    error,
    fetchNextPage,
    hasNextPage,
    status,
    isLoading,
    handleSearchQueryChange,
    dataLength,
  } = useReservationsQuery(currentHotelId!);

  const [sideSelectedReservation, setSideSelectedReservation] = useState<Reservation | null>(null);

  const handleDetailCloseClick = () => {
    setSideSelectedReservation(null);
  };

  /* istanbul ignore next */ // there are several tests for this
  const handleViewDetailClick = (reservation: Reservation, disabled: boolean = false) => {
    if (!disabled) {
      setSideSelectedReservation(reservation);
    }
  };

  if (status === 'error') {
    return (
      <Alert
        data-testid="reservations-error"
        severity="error"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px',
          width: '100%',
          margin: '52px',
        }}
      >
        <Typography>{getErrorMessage(error)}</Typography>
      </Alert>
    );
  }

  const renderLoading = () => {
    return (
      <Paper sx={{ pt: 6, paddingX: 5 }}>
        <ReservationsLoading />
      </Paper>
    );
  };

  const renderTimeline = () => {
    return (
      <Paper sx={{ px: 5, pb: 5, height: '100%', overflow: 'hidden' }}>
        <Timeline reservations={allReservations} onReservationRowClick={handleViewDetailClick} />
      </Paper>
    );
  };

  const renderTable = () => {
    return (
      <ReservationsTable
        reservations={allReservations}
        dataLength={dataLength}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        onReservationRowClick={handleViewDetailClick}
      />
    );
  };

  return (
    <Suspense fallback={<SelfbookSkeleton length={10} />}>
      <TimeLineContextProvider>
        <Box
          data-testid="reservations-page"
          sx={{ backgroundColor: colors.grey[200], padding: 0, width: '100%' }}
        >
          {!showTimeline && (
            <Box sx={{ p: 5, backgroundColor: colors.white }}>
              <Typography variant="h1" lineHeight="32px" fontWeight="450" color={colors.grey[700]}>
                Bookings
              </Typography>
            </Box>
          )}
          <ReservationsMetrics />
          <Box sx={{ backgroundColor: colors.white, borderRadius: '4px', mx: 5 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                p: 3,
                pb: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ReservationsFilters handleSearchQueryChange={handleSearchQueryChange} />
                <ReservationsActionsBar
                  onSearchQueryChange={handleSearchQueryChange}
                  navigationComponent={showTimeline ? <TimelineNavigation /> : null}
                />
              </Box>
            </Box>

            {isLoading && renderLoading()}
            {!isLoading && showTimeline && renderTimeline()}
            {!isLoading && !showTimeline && renderTable()}
          </Box>
        </Box>
        <ReservationDetailsPanel
          reservationId={sideSelectedReservation?.id}
          onCloseClick={handleDetailCloseClick}
        />
      </TimeLineContextProvider>
    </Suspense>
  );
}

export default Reservations;
