// @ts-nocheck
/* eslint-disable */
import { Box, Typography, useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import solidGauge from 'highcharts/modules/solid-gauge';
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { HotelMetrics } from 'app/features/hotel/types';
import { mapPaymentMethodsMetrics } from '../lib/reservationMetrics';
import colors from 'app/styles/colors';
import { getChartHeight, getChartWidth } from '../lib/reservationsCharts';
import { useState } from 'react';

highchartsMore(Highcharts);
solidGauge(Highcharts);
highchartsHeatmap(Highcharts);
accessibility(Highcharts);

const getChartTitle = ({
  paymentMethod,
  count,
  percentage,
}: {
  paymentMethod: string;
  count: number;
  percentage: number;
}) => {
  const isAllBookingSelected = paymentMethod === 'ALL BOOKINGS';

  return `
  <div style="display: flex;flex-direction: column;align-items: center;">
    <div style="font-size: 16px;
                font-weight: 450;
                line-height: 12.8px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #495057;
                margin-bottom: 3px;">
      ${paymentMethod}
    </div>
    <div style="font-size: 90px; font-weight: 450;line-height:72px;color: #495057;
    margin-bottom: 6px;">
      ${isAllBookingSelected ? `${count}` : `${percentage}%`}
    </div>
    <div style="font-size: 14px; font-weight: 450;line-height:14px;letter-spacing: 0.1em;color: #495057;">
      ${isAllBookingSelected ? 'DIRECT' : `${count} RESERVATIONS`}
    </div>
  </div>`;
};

export default function ReservationDoughnutGraph({ hotelMetrics }: { hotelMetrics?: HotelMetrics[] }) {
  const mappedHotelMetrics = mapPaymentMethodsMetrics(hotelMetrics);
  const is1280View = useMediaQuery('(max-width:1280px)');
  const is1440View = useMediaQuery('(max-width:1440px)');
  const is1680View = useMediaQuery('(max-width:1679px)');
  const [chartOptions] = useState({
    chart: {
      renderTo: 'container',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      width: getChartWidth(is1280View, is1440View, is1680View),
      height: getChartHeight(is1280View, is1440View, is1680View),
      margin: '-45',
      marginTop: -150,
      marginLeft: -5,
      marginRight: -5,
    },
    credits: {
      enabled: false,
    },
    colors: ['#4A4AF4', '#212529', '#1A73E8', '#FF718B'],
    title: {
      text: null,
      y: 0,
    },
    tooltip: {
      enabled: false,
    },
    pane: {
      center: ['50%', '73%'],
      size: '78%',
      startAngle: -110,
      endAngle: 110,
      background: {
        borderWidth: 0,
        backgroundColor: 'none',
        innerRadius: '65%',
        outerRadius: '50%',
        shape: 'arc',
      },
    },
    yAxis: [
      {
        lineWidth: 0,
        min: 0,
        max: 100,
        minorTickLength: 20,
        labels: {
          enabled: false,
        },
        title: {
          text: getChartTitle({
            paymentMethod: 'ALL BOOKINGS',
            percentage: 0,
            count: hotelMetrics && hotelMetrics[0]?.template?.data?.total_reservations,
          }),
          useHTML: true,
          y: 10,
        },
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
      pie: {
        dataLabels: {
          enabled: false,
        },
        startAngle: -110,
        endAngle: 110,
        center: ['50%', '75%'],
        point: {
          events: {
            mouseOver: function () {
              const { name, y, percentage, color } = this;
              this.series.data.forEach((yAxisItem: any) => {
                yAxisItem.update({
                  color: '#F0F2F4',
                });
              });

              this.update({
                color: color,
              });

              this.series.chart.yAxis[0].setTitle({
                text: getChartTitle({
                  paymentMethod: name,
                  percentage: percentage.toFixed(0),
                  count: y,
                }),
              });
            },
            mouseOut: function () {
              this.series.data.forEach((yAxisItem: any, index: number) => {
                const mappedPaymentMethod = [...Array.from(mappedHotelMetrics)][index];
                yAxisItem?.update({
                  color: mappedPaymentMethod[1].color,
                });
              });

              this.series.chart.yAxis[0].setTitle({
                text: getChartTitle({
                  paymentMethod: 'ALL BOOKINGS',
                  percentage: 0,
                  count: hotelMetrics && hotelMetrics[0]?.template?.data?.total_reservations,
                }),
              });
            },
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '92%',
        showInLegend: false,
        data: [...Array.from(mappedHotelMetrics)].map((paymentMethod) => {
          return {
            y: paymentMethod[1].count,
            name: paymentMethod[0],
            ...paymentMethod[1],
          };
        }),
      },
      {
        type: 'gauge',
        data: [80],
        visible: false,
        dial: {
          rearLength: 10,
        },
      },
    ],
  });

  return (
    <Box
      sx={{
        minWidth: `${getChartWidth(is1280View, is1440View, is1680View)}px`,
        overflow: 'hidden',
        position: 'relative',
      }}
      data-testid="reservations-doughnut-graph"
    >
      <Box sx={{ width: '100% !important', height: '100% !important' }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Box>
      <Box sx={{ position: 'absolute', color: 'red', bottom: '5px', left: '17px', pointerEvents: 'none' }}>
        <Typography
          fontWeight={450}
          fontSize="16px"
          lineHeight="12.8px"
          letterSpacing="0.04em"
          color={colors.grey[600]}
        >
          0%
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', color: 'red', bottom: '5px', right: '4px', pointerEvents: 'none' }}>
        <Typography
          fontWeight={450}
          fontSize="16px"
          lineHeight="12.8px"
          letterSpacing="0.04em"
          color={colors.grey[600]}
        >
          100%
        </Typography>
      </Box>
    </Box>
  );
}
