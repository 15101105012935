import { useMutation } from 'react-query';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { MfaValidateRequest } from '../types';

const useMfaValidate = () => {
  const mutation = useMutation((request: MfaValidateRequest) => {
    return stationApiAxios({
      url: '/api/v1/mfa/validate',
      method: 'POST',
      data: request,
    });
  });

  return mutation;
};

export default useMfaValidate;
