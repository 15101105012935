import { useRef, Fragment } from 'react';
import { Alert, Box, Stack } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { Input } from '@selfbook/design/dist/components/input';
import useFocusFirstFormField from 'app/hooks/useFocusFirstFormField';
import useForm from 'app/hooks/useForm';
import { getErrorMessage } from 'app/api/lib/error-handling';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import { AddHotelUser } from '../../invoices/types';

interface Props {
  onSubmit: (val: AddHotelUser) => void;
  submitError?: any;
  isLoading?: boolean;
}

function AddHotelAdminForm({ onSubmit, submitError, isLoading = false }: Props) {

  const formRef = useRef<HTMLFormElement>(null);
  useFocusFirstFormField(formRef);

  enum FormFields {
    name = 'name',
    email = 'email',
  }

  const { values, updateFieldValue, getIsFormValid, focusFirstFieldWithError, resetErrors, errors } = useForm(
    {
      formRef,
      initialValues: {
        [FormFields.name]: '',
        [FormFields.email]: '',
      },
      validations: {
        [FormFields.name]: (val) => {
          if (!val) return 'Full Name is required.';
          return true;
        },
        [FormFields.email]: (val) => {
          if (!val) return 'Email is required.';
          if (!isEmail(val)) return 'Please enter a valid email.';
          return true;
        },
      },
    },
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetErrors();

    const formValid = getIsFormValid();

    if (!formValid) {
      focusFirstFieldWithError();
      return;
    }

    const convertedValues: AddHotelUser = {
      name: values.name,
      email: values.email,
      user_permissions: [{ role: 'HOTEL_ADMIN' }],
    };

    onSubmit(convertedValues);

    updateFieldValue(FormFields.name, '');
    updateFieldValue(FormFields.email, '');
  };

  return (
    <Fragment>
      <Stack
        onSubmit={handleSubmit}
        data-testid="add-hotel-admin-form"
        component="form"
        noValidate
        ref={formRef}
        sx={{ mt: 2 }}
        direction="column"
      >
        <Box sx={{ mb: 2 }}>
          <Input
            data-testid="hotel-admin-name-input"
            label="Full Name"
            required
            id={FormFields.name}
            type={FormFields.name}
            name={FormFields.name}
            error={errors.name}
            onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
            value={values.name}
            containerCss={{ marginBottom: '8px' }}
          />

          <Input
            data-testid="hotel-admin-email-input"
            label="Email"
            required
            id={FormFields.email}
            type={FormFields.email}
            name={FormFields.email}
            error={errors.email}
            onChange={(e) => updateFieldValue(e.target.name as FormFields, e.target.value)}
            value={values.email}
          />
        </Box>

        <LoadingButton loading={isLoading} type="submit" fullWidth sx={{ height: '48px', fontSize: '14px' }}>
          INVITE ADMIN
        </LoadingButton>
      </Stack>
      {!!submitError && (
        <Alert
          data-testid="add-hotel-admin-error"
          id="add-hotel-admin-error"
          sx={{ boxShadow: 0, mt: 3 }}
          severity="error"
        >
          {getErrorMessage(submitError)}
        </Alert>
      )}
    </Fragment>
  );
}

export default AddHotelAdminForm;
