import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';

interface Props {
  onCloseClick: () => void;
}

function NewChargeModalConfirm({ onCloseClick }: Props) {
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef.current]);

  return (
    <Stack data-testid="refund-confirm">
      <Stack>
        <Typography component="h1" variant="h2">
          New charge successfully submitted
        </Typography>
        <Typography component="p" variant="subtitle1" sx={{ mt: 1 }}>
          Return to the reservation details to see the updated charges
        </Typography>
      </Stack>
      <Button ref={closeButtonRef} onClick={onCloseClick} fullWidth sx={{ mt: 3 }}>
        Close
      </Button>
    </Stack>
  );
}

export default NewChargeModalConfirm;
