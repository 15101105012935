import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function ReservationsLoading() {
  return (
    <Box data-testid="reservations-loading">
      {Array.from({ length: 10 }, (_, i) => (
        <div key={i}>
          <Skeleton height="65px" />
        </div>
      ))}
    </Box>
  );
}

export default ReservationsLoading;
