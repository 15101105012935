import { createContext, useContext, useMemo, useState } from 'react';

type LoginState = {
  email: string;
  mfa_nonce: string;
};

type State = {
  loginState: LoginState;
  setLoginDetails: (details: LoginState) => void;
};

const AuthenticationContext = createContext<State | undefined>(undefined);

function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const [loginState, setLoginState] = useState<LoginState>({ email: '', mfa_nonce: '' });

  const setLoginDetails = (details: LoginState) => {
    setLoginState(details);
  };

  const value = useMemo(() => ({ loginState, setLoginDetails }), [loginState, setLoginDetails]);

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}

const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationContext');
  }
  return context;
};

export { useAuthenticationContext, AuthenticationContextProvider };
