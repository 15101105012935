import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { DateFormatter } from 'react-day-picker';

export const formatWeekdayName: DateFormatter = (date, options) => {
  const month = format(date, 'EEE', { locale: options?.locale });
  return <span>{capitalize(month)}</span>;
};

export const formatWeekdayNameSingleLetter: DateFormatter = (date, options) => {
  const month = format(date, 'EEEEE', { locale: options?.locale });
  return <span>{capitalize(month)}</span>;
};

export const stopPropagationForTab: React.KeyboardEventHandler<HTMLDivElement> = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  // istanbul ignore next
  if (event.key === 'Tab') {
    event.stopPropagation();
  }
};
