import { Fragment, useState } from 'react';
import { Alert, AlertTitle, Box, CircularProgress, Divider, Drawer } from '@mui/material';
import colors from 'app/styles/colors';
import TabsContainer from 'app/components/selfbook-tabs/TabsContainer';
import { TabsEnum } from 'app/components/types';
import ReservationDetailsPanelFooter from './ReservationDetailsPanelFooter';
import ReservationDetailsPanelHeader from './ReservationDetailsPanelHeader';
import ReservationDetailsPanelSubHeader from './ReservationDetailsPanelSubHeader';
import {
  ReservationDetailContextProvider,
  useReservationDetailContext,
} from '../context/useReservationDetailContext';
import PaymentLinkSendingModal from './PaymentLinkSendingModal';
import ReservationPayments from './ReservationPayments';
import ReservationRefunds from './ReservationRefunds';
import RefundChargeModal from './RefundModal';
import ReservationDetailsPanelSettingsTab from './ReservationDetailsPanelSettingsTab';
import ReservationDetailsPanelActivityTab from './ReservationDetailsPanelActivityTab';

const tabs = [
  { name: 'PAYMENT', value: TabsEnum.PAYMENT, visible: true },
  { name: 'ACTIONS', value: TabsEnum.ACTIONS, visible: true },
  { name: 'ACTIVITY', value: TabsEnum.ACTIVITY, visible: true },
];

interface Props {
  onCloseClick: () => void;
}

// This file is pretty well tested at the moment -- ignore for now until we find a better way to test conditional renders
/* istanbul ignore next */
function ReservationDetailsPanel({ onCloseClick }: Props) {
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.PAYMENT);
  const {
    reservation,
    reservationId,
    reservationLoading,
    roomChargeInvoiceLine,
    sendingPaymentLinkOpen,
    closePaymentLinkSend,
    sendPaymentLinkStatus,
    sendPaymentLinkError,
    closeRefundCharge,
    refundChargeOpen,
    refundActionPayload,
    paymentsUi,
  } = useReservationDetailContext();
  const isLoading = reservationLoading;
  tabs[2].visible = reservation ? new Date(reservation.createdAt) > new Date(2023, 8, 19) : false;

  const changeTab = (_: React.SyntheticEvent, tab: TabsEnum) => {
    setSelectedTab(tab);
  };

  const handleClose = () => {
    setSelectedTab(TabsEnum.PAYMENT);
    onCloseClick();
  };

  const dataLoadingAlert = (
    <Alert severity="info" sx={{ backgroundColor: 'grey.700', color: 'grey.100', mt: 6 }}>
      <AlertTitle>Some reservation information is currently processing.</AlertTitle>
      Check back in a few minutes to view the complete reservation details.
    </Alert>
  );

  const tabContent = (theSelectedTab: TabsEnum) => {
    switch (theSelectedTab) {
      case TabsEnum.PAYMENT:
        return (
          <Box sx={{ position: 'relative', mt: 10, zIndex: -1, pointerEvents: 'auto' }}>
            <ReservationPayments />
            <ReservationRefunds />
          </Box>
        );
      case TabsEnum.ACTIONS:
        return (
          <ReservationDetailsPanelSettingsTab onCloseClick={handleClose} charge={paymentsUi?.payments[0]} />
        );

      case TabsEnum.ACTIVITY:
        return <ReservationDetailsPanelActivityTab reservationId={reservationId} />;

      default:
        return null;
    }
  };

  const paymentsSection = (
    <Fragment>
      <Box
        sx={{
          borderRadius: '4px',
          overflowY: 'scroll',
          flexGrow: 1,
          zIndex: 10,
        }}
      >
        <Box sx={{ position: 'absolute', width: '100%', pointerEvents: 'auto' }}>
          <TabsContainer
            selectedTab={selectedTab}
            changeTab={changeTab}
            tabs={tabs.filter(({ visible }) => visible === true)}
            customSX={{
              px: 0,
              borderBottom: '0px solid white !important',
            }}
            customTabsSX={{
              px: 0,
              fontSize: '14px',
              fontWeight: '450',
              lineHeight: '14px',
              letterSpacing: '0.02em',
              color: colors.grey[900],
              width: 'fit-content',
              minWidth: 'unset',
              mr: 4,
              pb: '21px',
              borderBottom: 'none',
              ':not(.Mui-selected)': {
                borderBottom: 'none',
                color: colors.grey[600],
              },
              '&.Mui-selected': {
                color: colors.grey[900],
                borderBottom: 'none',
              },
            }}
          />
          <Divider sx={{ width: '100%', ml: '-24px' }} />
        </Box>
        {tabContent(selectedTab)}
      </Box>
      {selectedTab === TabsEnum.PAYMENT && <ReservationDetailsPanelFooter />}
    </Fragment>
  );

  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={!!reservationId}
        data-testid="reservation-side-panel-drawer"
        PaperProps={{
          sx: {
            px: 3,
            pt: 4,
            pb: 3,
            width: '576px',
            borderRadius: 0,
            height: '95%',
            overflowX: 'hidden',
          },
        }}
        disableEscapeKeyDown={false}
        onClose={() => handleClose()}
        BackdropProps={{
          sx: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {(!reservation || isLoading) && (
          <Box sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        {!!reservation && !isLoading && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}
            data-testid="reservation-side-details"
          >
            <ReservationDetailsPanelHeader onCloseClick={handleClose} />
            <ReservationDetailsPanelSubHeader />
            <Divider sx={{ borderBottom: 'none', height: '32px' }} />

            {!roomChargeInvoiceLine ? dataLoadingAlert : paymentsSection}
          </Box>
        )}
      </Drawer>
      <PaymentLinkSendingModal
        open={sendingPaymentLinkOpen}
        onClose={closePaymentLinkSend}
        submitError={sendPaymentLinkError}
        // Since this is just a progress modal we want to include idle in the "submitting state"
        submitting={sendPaymentLinkStatus === 'idle' || sendPaymentLinkStatus === 'loading'}
      />
      <RefundChargeModal
        chargeToRefund={refundActionPayload}
        open={refundChargeOpen}
        onClose={closeRefundCharge}
        reservationId={reservationId!}
      />
    </Fragment>
  );
}

export default function PanelWithContext({
  reservationId,
  onCloseClick,
}: {
  reservationId?: string;
  onCloseClick: () => void;
}) {
  return (
    // Utilize a local context to avoid prop drilling
    <ReservationDetailContextProvider reservationId={reservationId}>
      <ReservationDetailsPanel onCloseClick={onCloseClick} />
    </ReservationDetailContextProvider>
  );
}
