import validatorIsEmail from 'validator/lib/isEmail';

export const stringHasValue = (str = '') => {
  return !!str && !!str.trim();
};

export const numberGreaterThanZero = (number = 0) => {
  return number > 0;
};

export const isEmail = (str = '') => {
  if (!str) {
    return false;
  }
  return validatorIsEmail(str);
};
