import { Box, IconButton, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import InfoIcon from 'app/components/icons/InfoIcon';
import { useUser } from 'app/features/authentication/context/useUser';
import { useHotelMetricsQuery } from 'app/features/hotel/api/useHoteMetrics';
import colors from 'app/styles/colors';
import { CUSTOM_BREAKPOINT_DIFFERENCE } from 'app/styles/material/theme';
import { DeviceMetric, getMetricsPercentage, mapDeviceMetrics } from '../lib/reservationMetrics';
import ReservationsDeviceGraph from './ReservationsDeviceGraph';

const loaderStyles = {
  ml: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.white,
  height: 'fit-content',
  p: 4,
  borderRadius: 1,
  ':not(:last-child)': { mb: 1 },
};

export default function ReservationsDeviceMetrics() {
  const theme = useTheme();
  const { currentHotelId } = useUser();
  const { data: hotelMetrics, status } = useHotelMetricsQuery(currentHotelId as string, 3);

  const mappedDeviceMetrics = mapDeviceMetrics(hotelMetrics);

  // istanbul ignore next
  const reservationDeviceGraph = (metric: DeviceMetric) => {
    const totalReservations = hotelMetrics && hotelMetrics[0].template.data.total_reservations!;
    return (
      <ReservationsDeviceGraph
        reservationsCount={getMetricsPercentage(
          metric.totalReservations,
          totalReservations === 0 ? 1 : totalReservations!,
        )}
      />
    );
  };

  return (
    <Box
      data-testid="reservations-device-metrics"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      {status === 'loading' ? (
        <Box data-testid="reservations-device-metrics-loading">
          <Box sx={loaderStyles}>
            <Skeleton height="50px" width="250px" />
            <Skeleton height="50px" width="250px" />
          </Box>
          <Box sx={loaderStyles}>
            <Skeleton height="50px" width="250px" />
            <Skeleton height="50px" width="250px" />
          </Box>
        </Box>
      ) : (
        mappedDeviceMetrics.map((metric, index) => (
          <Box
            sx={{
              ml: 1,
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: colors.white,
              height: 'fit-content',
              flexWrap: 'wrap',
              px: 4,
              py: '18px',
              borderRadius: 1,
              justifyContent: 'space-between',
              [theme.breakpoints.up('lg')]: {
                px: 3,
                py: '22px',
              },
              [theme.breakpoints.up('xl')]: {
                px: 4,
                py: '29.5px',
                flexWrap: 'nowrap',
              },
              [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                py: '32.5px',
              },
            }}
            component="dl"
            key={metric.device}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontWeight={450}
                fontSize="12px"
                lineHeight="13.2px"
                letterSpacing="0.04em"
                color={colors.grey[700]}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  textTransform: 'uppercase',
                  mb: 1,
                  width: '67px',
                  whiteSpace: 'wrap',
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                    lineHeight: '14px',
                    letterSpacing: '0.04em',
                    width: 'unset',
                    mb: 1.5,
                    whiteSpace: 'nowrap',
                  },
                  [theme.breakpoints.up(theme.breakpoints.values.xl + CUSTOM_BREAKPOINT_DIFFERENCE)]: {
                    whiteSpace: 'nowrap',
                  },
                }}
                component="dt"
              >
                {metric.device}
                <Tooltip
                  title={
                    <Box sx={{ m: 1 }}>
                      <Typography fontWeight={300} fontSize={16}>
                        {`${metric.percentageChange > 0 ? '+' : ''}${metric.percentageChange}% ${
                          metric.percentageChange > 0 ? 'increase' : 'decrease'
                        } over a 30 day period`}
                      </Typography>
                    </Box>
                  }
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: {
                      [`.MuiTooltip-tooltip`]: {
                        backgroundColor: '#21252a',
                      },
                      [`.MuiTooltip-arrow`]: {
                        color: '#21252a',
                      },
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      background: 'none',
                      boxShadow: 'none',
                      border: 'none',
                      width: 'fit-content',
                      height: 'fit-content',
                      padding: 0,
                      margin: 0,
                      minWidth: 'fit-content',
                      ':hover': { background: 'none', boxShadow: 'none', border: 'none' },
                      ml: 1,
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Typography
                fontWeight={450}
                fontSize="52px"
                lineHeight="41.6px"
                color={colors.grey[900]}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '64px',
                    lineHeight: '51.2px',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                  },
                }}
                component="dd"
              >
                {metric.totalReservations}
                <Typography
                  fontWeight={450}
                  fontSize="12px"
                  lineHeight="15.18px"
                  color={metric.percentageChange > 0 ? colors.success[800] : colors.error[800]}
                  bgcolor={metric.percentageChange > 0 ? colors.success[200] : colors.error[200]}
                  borderRadius={1}
                  padding="1px 6px 2px"
                  sx={{
                    mt: '6px',
                    [theme.breakpoints.up('xl')]: {
                      ml: 1,
                      mt: 0,
                    },
                  }}
                  data-testid={`device-${index}-percentage-change`}
                >
                  {`${metric.percentageChange > 0 ? '+' : ''}${metric.percentageChange}%`}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '90px',
                ml: '25px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {reservationDeviceGraph(metric)}
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
}
