import Stack from '@mui/material/Stack';
import { useUser } from 'app/features/authentication/context/useUser';
import usePermissions from 'app/features/permissions/usePermissions';
import colors from 'app/styles/colors';
import SideNavigationToggle from '../../side-navigation/components/SideNavigationToggle';
import LeftItems from './LeftItems';
import RightItems from './RightItems';

function TopNavigation() {
  const { currentHotelName, userPermissions, setCurrentSelectedHotel } = useUser();
  const { userIsMasqueradingAdmin } = usePermissions();

  /* istanbul ignore next */ // conditional styling
  const backgroundColor = userIsMasqueradingAdmin ? colors.warning[400] : colors.white;

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        top: '0px',
        zIndex: 10,
        width: '100%',
        left: '0px',
      }}
    >
      <SideNavigationToggle />
      <Stack
        data-testid="top-nav"
        direction="row"
        sx={{
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
          backgroundColor,
          paddingRight: 6,
          paddingY: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
          minWidth: 'calc(1200px - 60px)',
          paddingLeft: 5
        }}
      >
        <LeftItems
          hotelName={currentHotelName}
          userPermissions={userPermissions}
          setCurrentSelectedHotel={setCurrentSelectedHotel}
        />
        <RightItems />
      </Stack>
    </Stack>
  );
}

export default TopNavigation;
