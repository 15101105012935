import { SVGProps } from 'react';

function InfoIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      data-testid="visa-icon"
      {...props}
    >
      <path d="M8.5 11.5889V7.58887H6.5V8.58887H7.5V11.5889H6V12.5889H10V11.5889H8.5Z" fill="#212529" />
      <path
        d="M8 4.58887C7.85167 4.58887 7.70666 4.63286 7.58333 4.71527C7.45999 4.79768 7.36386 4.91481 7.30709 5.05186C7.25033 5.1889 7.23548 5.3397 7.26441 5.48519C7.29335 5.63067 7.36478 5.76431 7.46967 5.8692C7.57456 5.97409 7.7082 6.04552 7.85369 6.07446C7.99917 6.1034 8.14997 6.08855 8.28701 6.03178C8.42406 5.97501 8.54119 5.87888 8.6236 5.75555C8.70602 5.63221 8.75 5.48721 8.75 5.33887C8.75 5.13996 8.67098 4.94919 8.53033 4.80854C8.38968 4.66789 8.19892 4.58887 8 4.58887Z"
        fill="#212529"
      />
      <path
        d="M8 15.5889C6.61553 15.5889 5.26216 15.1783 4.11101 14.4092C2.95987 13.64 2.06266 12.5467 1.53285 11.2677C1.00303 9.98857 0.86441 8.58111 1.13451 7.22324C1.4046 5.86537 2.07129 4.61809 3.05026 3.63912C4.02922 2.66016 5.2765 1.99347 6.63437 1.72337C7.99224 1.45328 9.3997 1.5919 10.6788 2.12171C11.9579 2.65153 13.0511 3.54873 13.8203 4.69988C14.5895 5.85102 15 7.2044 15 8.58887C15 10.4454 14.2625 12.2259 12.9497 13.5386C11.637 14.8514 9.85652 15.5889 8 15.5889ZM8 2.58887C6.81332 2.58887 5.65328 2.94076 4.66658 3.60005C3.67989 4.25934 2.91085 5.19641 2.45673 6.29277C2.0026 7.38913 1.88378 8.59553 2.11529 9.75941C2.3468 10.9233 2.91825 11.9924 3.75736 12.8315C4.59648 13.6706 5.66558 14.2421 6.82946 14.4736C7.99335 14.7051 9.19975 14.5863 10.2961 14.1321C11.3925 13.678 12.3295 12.909 12.9888 11.9223C13.6481 10.9356 14 9.77556 14 8.58887C14 6.99757 13.3679 5.47145 12.2426 4.34623C11.1174 3.22101 9.5913 2.58887 8 2.58887Z"
        fill="#212529"
      />
    </svg>
  );
}
export default InfoIcon;
