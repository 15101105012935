import jwtDecode from 'jwt-decode';
import isAfter from 'date-fns/isAfter';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { logMessage } from 'app/lib/logging';
import {
  ACCESS_TOKEN_KEY,
  CLIENT_ID_KEY,
  REFRESH_TOKEN_KEY,
  SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY,
  SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY,
} from 'app/config/constants';
import { clearHasSeenStripePromptInSession } from 'app/features/stripe-connect/lib';
import reactQueryClient from 'app/api/react-query/react-query-client';
import { User } from 'app/features/user/types';
import { StationJWTPayload } from '../types';

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const clearAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const clearRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setClientId = (clientId: string) => {
  localStorage.setItem(CLIENT_ID_KEY, clientId);
};

export const clearClientId = () => {
  localStorage.removeItem(CLIENT_ID_KEY);
};

export const getClientId = () => {
  return localStorage.getItem(CLIENT_ID_KEY);
};

export const setTempCsmAccessToken = (token: string) => {
  localStorage.setItem(SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY, token);
};

export const clearTempCsmAccessToken = () => {
  localStorage.removeItem(SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY);
};

export const getTempCsmAccessToken = () => {
  return localStorage.getItem(SELFBOOK_CSM_TEMP_ACCESS_TOKEN_KEY);
};

export const setTempCsmRefreshToken = (token: string) => {
  localStorage.setItem(SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY, token);
};

export const clearTempCsmRefreshToken = () => {
  localStorage.removeItem(SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY);
};

export const getTempCsmRefreshToken = () => {
  return localStorage.getItem(SELFBOOK_CSM_TEMP_REFRESH_TOKEN_KEY);
};

export const clearAllLocalStorageUserData = ({ removeUserFn }: { removeUserFn?: () => void } = {}) => {
  reactQueryClient.clear();
  removeUserFn?.();
  clearAccessToken();
  clearRefreshToken();
  clearClientId();
  clearTempCsmAccessToken();
  clearTempCsmRefreshToken();
  clearHasSeenStripePromptInSession();
};

// If a Selfbook CSM enters a support code and assumes
// the identity of another user, store the original CSM
// creds in a separate place, so when they "log out" of the user
// account, their original tokens can be shuffled back in to
// the JWT and Refresh slots in localstorage
export const selfbookCsmAssumeUser = ({
  accessToken,
  refreshToken,
  refetchUserFn,
}: {
  accessToken: string;
  refreshToken: string;
  // refetch using new local storage jwt
  refetchUserFn: () => Promise<User | null>;
}) => {
  const currentCsmJwt = getAccessToken();
  const currentCsmRefreshToken = getRefreshToken();
  if (!currentCsmJwt || !currentCsmRefreshToken) {
    return;
  }
  setTempCsmAccessToken(currentCsmJwt);
  setTempCsmRefreshToken(currentCsmRefreshToken);
  reactQueryClient.clear();
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  refetchUserFn();
};

/* 
  The order of events here is pretty important for a smooth UI
*/
export const selfbookCsmLogoutOfUserAccount = async ({
  refetchUserFn,
}: {
  // refetch using new local storage jwt
  refetchUserFn: () => Promise<User | null>;
}) => {
  const currentCsmJwt = getTempCsmAccessToken();
  const currentCsmRefreshToken = getTempCsmRefreshToken();
  if (!currentCsmJwt || !currentCsmRefreshToken) {
    return;
  }
  setAccessToken(currentCsmJwt);
  setRefreshToken(currentCsmRefreshToken);
  reactQueryClient.clear();
  await refetchUserFn();
  clearTempCsmAccessToken();
  clearTempCsmRefreshToken();
};

export const getDecodedJwt = (): StationJWTPayload | null => {
  try {
    const jwt = getAccessToken();
    if (!jwt) return null;
    const decodedToken = jwtDecode<StationJWTPayload>(jwt);
    return decodedToken;
  } catch {
    /* istanbul ignore next */
    return null;
  }
};

export const getJwtExpiredAtTime = () => {
  const decodedToken = getDecodedJwt();
  return decodedToken ? decodedToken.exp * 1000 : null;
};

export const getJwtIsExpired = () => {
  const expiresAtTime = getJwtExpiredAtTime();
  if (!expiresAtTime) return true;
  return isAfter(Date.now(), expiresAtTime);
};

/* istanbul ignore next */ // I have 4 tests for this but jest still says it not covered
export const getJwtExpiresIn = ({ minutes = 15 }: { minutes: number }) => {
  const expiresAtTime = getJwtExpiredAtTime();
  if (!expiresAtTime) return true;
  const timeStampForMinutesInFuture = Date.now() + minutes * 60 * 1000;
  return expiresAtTime < timeStampForMinutesInFuture;
};

export const refreshCredentials = () => {
  const token = getAccessToken();
  const refreshToken = getRefreshToken();
  const clientId = getClientId();

  return stationApiAxios
    .post(
      '/api/v1/token',
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: clientId,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    .then((res) => {
      setAccessToken(res.data?.access_token);
      setRefreshToken(res.data?.refresh_token);
    })
    .catch((err) => {
      logMessage({ refreshCredentialsErr: err });
    });
};
