import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useMutation } from 'react-query';
import { AddHotelUser } from '../../invoices/types';

const useAddHotelUser = () => {
  const mutation = useMutation(
    ({ hotelId, ...request }: AddHotelUser & { hotelId: string }) => {
      return stationApiAxios({
        url: `rest/v1/hotels/${hotelId}/users`,
        method: 'POST',
        data: request
      });
    }
  );

  return mutation
};

export default useAddHotelUser;