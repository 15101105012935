import { formatDateTimeToParts } from 'app/lib/dates';
import { DateTimeStringObject } from 'app/lib/types';
import flow from 'lodash/flow';
import { Reservation } from '../types';
import { getReservationIdText } from './reservationIdText';
import { getGuestSummary } from './guests';

const formatTableDateString = (date: DateTimeStringObject) => `${date.month} ${date.day}, ${date.year}`;
export const formatDateFlow = flow(formatDateTimeToParts, formatTableDateString);

export const reservationTableBuilder = (reservation: Reservation) => {
  return {
    firstName: reservation.firstName.toUpperCase(),
    lastName: reservation.lastName?.toUpperCase() ?? '',
    reservationIdText: getReservationIdText(reservation),
    arrivalDate: formatDateFlow(new Date(reservation.arriveAt), 'en', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),
    departDate: formatDateFlow(new Date(reservation.departAt), 'en', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),
    // TODO: account for plurals
    guests: getGuestSummary(reservation.guestSummary),
    paymentStatus: reservation?.invoice?.invoice_lines[0]?.status,
  };
};
