import { SxProps } from '@mui/material';
import colors from 'app/styles/colors';
import { StationAdminAfterLoginSetupSteps } from './types';

export const getStepContainerStyles = (
  step: StationAdminAfterLoginSetupSteps,
  currentStep: StationAdminAfterLoginSetupSteps,
): SxProps => {
  return {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    columnGap: '8px',
    '> svg': {
      position: 'absolute',
      right: '-32px',
      ...(step >= currentStep && {
        '> path': {
          fill: colors.grey[500],
        },
      }),
    },
    '> div': {
      background: colors.grey[700],
      color: colors.white,
      height: '22px',
      width: '22px',
      borderRadius: '50%',
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      fontWeight: 450,
      ...(/* istanbul ignore next */ step > currentStep && {
        background: colors.grey[400],
      }),
    },
    '> p': {
      letterSpacing: '1px',
      fontSize: '14px',
      color: colors.grey[700],
    },
  };
};
