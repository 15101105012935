import { Box, BoxProps, colors } from '@mui/material';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from 'react';
import ReactCodeInput, { ReactCodeInputProps, InputModeTypes } from 'react-code-input';
import SrOnlyText from '../sr-only-text/SrOnlyText';
import useCodeInputStyles from './useCodeInputStyles';

interface Props {
  value: string;
  onChange: (value: string) => void;
  fieldCount?: number;
  inputMode?: InputModeTypes;
  hiddenLabelText?: string;
  name?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  ariaDescribedBy?: string;
  CodeInputProps?: ReactCodeInputProps;
}

function CodeInput(
  {
    value,
    onChange,
    fieldCount = 6,
    inputMode = 'numeric',
    hiddenLabelText,
    name = 'code input',
    hasError = false,
    isDisabled = false,
    ariaDescribedBy,
    CodeInputProps,
    ...rest
  }: Props & BoxProps,
  ref: Ref<any>,
) {
  const inputRef = useRef<any>(null);
  const codeInputStyle = useCodeInputStyles({ hasError, isDisabled });

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const inputs = inputRef.current.textInput;
      (inputs || []).forEach((input: HTMLElement, i: number) => {
        input.setAttribute('aria-label', `confirmation code digit ${i + 1}`);
        if (ariaDescribedBy) {
          input.setAttribute('aria-describedby', ariaDescribedBy);
        }
      });
    }
  }, [inputRef]);

  // This is fun -- https://github.com/40818419/react-code-input/issues/69
  const clearAllInputs = () => {
    if (inputRef && inputRef.current) {
      const inputs = inputRef.current.textInput;
      (inputs || []).forEach((input: HTMLInputElement, i: number) => {
        input.setAttribute('value', '');
        inputRef.current.state.input[i] = '';
        if (i === 0) {
          input.focus();
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.textInput?.[0]?.focus();
    },
    clear: () => {
      clearAllInputs();
    },
    get inputsArray() {
      return inputRef.current?.textInput || [];
    },
  }));

  return (
    <Box component="legend" sx={codeInputStyle} data-testid="code-input" {...rest}>
      {hiddenLabelText && <SrOnlyText>{hiddenLabelText}</SrOnlyText>}
      <ReactCodeInput
        name={name}
        value={value}
        onChange={onChange}
        fields={fieldCount}
        inputStyle={{ color: isDisabled ? colors.grey[500] : colors.grey[900] }}
        ref={inputRef}
        inputMode={inputMode}
        {...CodeInputProps}
      />
    </Box>
  );
}

export default forwardRef(CodeInput);
