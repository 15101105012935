import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useMutation } from 'react-query';
import { PaymentLinkTemplate2MutationBody, PaymentLinkTemplate3MutationBody } from '../types';

export const usePaymentLinkMutation = (hotelId: string) => {
  const mutation = useMutation(
    (request: PaymentLinkTemplate2MutationBody | PaymentLinkTemplate3MutationBody) => {
      return stationApiAxios({
        url: `/rest/v1/hotels/${hotelId}/payment_links`,
        method: 'POST',
        data: {
          template: {
            ...request,
          },
        },
      });
    },
  );
  return mutation;
};
