import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { getErrorMessage } from 'app/api/lib/error-handling';
import SelfbookDialog from 'app/components/selfbook-dialog/SelfbookDialog';
import { useRef } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  submitting: boolean;
  submitError?: any;
}

function PaymentLinkSendingModal({ open, onClose, submitting, submitError }: Props) {
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleModalClose = () => {
    onClose();
  };

  const getBodyText = () => {
    if (submitting) {
      return 'Sending payment request';
    }
    if (submitError) {
      return 'There was an error submitting the payment request';
    }

    return 'Payment request sent successfully';
  };

  return (
    <SelfbookDialog data-testid="payment-link-sending-modal" open={open} onClose={handleModalClose}>
      <SelfbookDialog.Title
        data-testid="payment-link-sending-modal-title"
        onClose={handleModalClose}
        aria-label="Payment Request"
      >
        Payment Request
      </SelfbookDialog.Title>
      <SelfbookDialog.Content data-testid="payment-link-sending-modal-content" sx={{ width: '460px' }}>
        <Stack alignItems="center" spacing={3} pb={2}>
          <Typography component="p" variant="subtitle1" sx={{ mt: 1 }}>
            {getBodyText()}
          </Typography>

          {!!submitError && (
            <Alert
              data-testid="payment-link-sending-error"
              id="payment-link-sending-error"
              sx={{ boxShadow: 0 }}
              severity="error"
            >
              {getErrorMessage(submitError)}
            </Alert>
          )}

          {submitting ? (
            <CircularProgress />
          ) : (
            <Button ref={closeButtonRef} onClick={handleModalClose} fullWidth sx={{ mt: 3 }}>
              Close
            </Button>
          )}
        </Stack>
      </SelfbookDialog.Content>
    </SelfbookDialog>
  );
}

export default PaymentLinkSendingModal;
