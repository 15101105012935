import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import { Input } from '@selfbook/design/dist/components/input';
import { getErrorMessage } from 'app/api/lib/error-handling';
import AddIcon from 'app/components/icons/AddIcon';
import LoadingButton from 'app/components/loading-button/LoadingButton';
import { useUser } from 'app/features/authentication/context/useUser';
import { useEditHotel } from 'app/features/selfbook-admin/hotels/api/useEditHotel';
import colors from 'app/styles/colors';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useAfterLoginContext } from '../context/useAfterLoginContext';
import { StationAdminAfterLoginSetupSteps } from '../types';

function UploadLogo() {
  const { shouldShowStripePrompt, closeAfterLoginModal, setCurrentStep } = useAfterLoginContext();
  const { enqueueSnackbar } = useSnackbar();

  const { currentHotelId } = useUser();
  const {
    mutate: editHotelMutation,
    error: editHotelError,
    isLoading: editHotelIsLoading,
    status,
  } = useEditHotel(currentHotelId!, true);

  const hiddenHotelLogoInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  // istanbul ignore next
  const handleUploadButtonClick = () => {
    hiddenHotelLogoInputRef.current?.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setUploadedFile(fileUploaded);
  };

  const handleSubmit = () => {
    return editHotelMutation(
      {
        logo: uploadedFile as File,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Logo added succesfully!', {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar('Unable to upload the logo', {
            variant: 'error',
          });
        },
      },
    );
  };

  useEffect(() => {
    if (status === 'success') {
      if (shouldShowStripePrompt) {
        setCurrentStep(StationAdminAfterLoginSetupSteps.STRIPE_SETUP);
      } else {
        closeAfterLoginModal();
      }
    }
  }, [status]);

  return (
    <Box data-testid="station-admin-after-login-upload-logo">
      <Typography
        fontSize="16px"
        fontWeight="300"
        letterSpacing="0.051px"
        lineHeight="130%"
        color={colors.grey[700]}
        mt={1}
        mb={2}
      >
        Upload your logo below to be displayed on payment links.
      </Typography>

      <Box display="grid" gridTemplateColumns="auto 120px" gap={1}>
        <Input
          dataTestId="upload-logo-input"
          id="upload-logo-input"
          label="Hotel Logo"
          type="text"
          name="logo"
          value={uploadedFile ? uploadedFile.name : ''}
          disabled
        />
        <Button
          startIcon={<AddIcon color={colors.grey[700]} />}
          variant="outlined"
          sx={{ height: '100%' }}
          onClick={handleUploadButtonClick}
          data-testid="upload-hotel-logo-button"
        >
          ADD FILE
        </Button>
        <Input
          dataTestId="hidden-logo-input"
          ref={hiddenHotelLogoInputRef}
          onChange={handleChange}
          type="file"
          css={{ display: 'none' }}
        />

        <Typography
          fontSize="14px"
          fontWeight="300"
          letterSpacing="0.28px"
          lineHeight="14px"
          color={colors.grey[600]}
        >
          Only .png files less than 1 MB. Min width: 400px
        </Typography>
      </Box>

      {!!editHotelError && (
        <Alert
          data-testid="upload-logo-error"
          id="upload-logo-error"
          sx={{ boxShadow: 0, mt: 3 }}
          severity="error"
        >
          {getErrorMessage(editHotelError)}
        </Alert>
      )}

      <LoadingButton
        fullWidth
        sx={{ mt: 3 }}
        onClick={handleSubmit}
        loading={editHotelIsLoading}
        disabled={!uploadedFile || editHotelIsLoading}
      >
        CONTINUE
      </LoadingButton>

      <Divider sx={{ my: 3, border: `1px solid ${colors.grey[300]}` }} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => setCurrentStep(StationAdminAfterLoginSetupSteps.STRIPE_SETUP)}
          variant="textMuted"
          sx={{
            mt: 0,
            fontSize: '14px',
            letterSpacing: '0.56px',
            lineHeight: '100%',
            fontWeight: '450',
            textTransform: 'uppercase',
          }}
        >
          Skip for now
        </Button>
      </Box>
    </Box>
  );
}

export default UploadLogo;
