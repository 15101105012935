import { SVGProps } from 'react';

function AdyenIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg width="81" height="27" viewBox="0 0 81 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8323 6.41084H0.706043V9.9522H7.95617C8.40689 9.9522 8.76746 10.3128 8.76746 10.7635V17.0349H7.22214C6.77142 17.0349 6.41085 16.6743 6.41085 16.2236V11.7164H3.34596C1.77489 11.7164 0.5 12.9913 0.5 14.5624V17.7303C0.5 19.3014 1.77489 20.5763 3.34596 20.5763H14.6783V9.24392C14.6654 7.68573 13.4034 6.41084 11.8323 6.41084Z"
        fill="#B2B2B2"
      />
      <path
        d="M25.3024 17.0349H23.7571C23.3064 17.0349 22.9458 16.6744 22.9458 16.2236V6.41085H19.8809C18.3099 6.41085 17.035 7.68574 17.035 9.25682V17.7432C17.035 19.3143 18.3099 20.5892 19.8809 20.5892H31.2133V0.5H25.3024V17.0349Z"
        fill="#B2B2B2"
      />
      <path
        d="M41.8374 17.0349H40.2921C39.8414 17.0349 39.4808 16.6743 39.4808 16.2236V6.41084H33.5699V17.7432C33.5699 19.3143 34.8448 20.5892 36.4159 20.5892H41.8374V22.3663H33.776V26.5H44.9023C46.4734 26.5 47.7483 25.2251 47.7483 23.654V6.41084H41.8374V17.0349Z"
        fill="#B2B2B2"
      />
      <path
        d="M61.4244 6.41084H50.0921V17.7432C50.0921 19.3143 51.367 20.5892 52.9381 20.5892H64.0644V17.0478H56.8142C56.3635 17.0478 56.0029 16.6872 56.0029 16.2365V9.96507H57.5483C57.999 9.96507 58.3596 10.3256 58.3596 10.7764V15.2836H61.4244C62.9955 15.2836 64.2704 14.0087 64.2704 12.4376V9.26968C64.2704 7.68572 62.9955 6.41084 61.4244 6.41084Z"
        fill="#B2B2B2"
      />
      <path
        d="M77.9592 6.41084H66.6268V20.5892H72.5377V9.96507H74.083C74.5337 9.96507 74.8943 10.3256 74.8943 10.7764V20.5892H80.8051V9.2568C80.8051 7.68573 79.5302 6.41084 77.9592 6.41084Z"
        fill="#B2B2B2"
      />
    </svg>
  );
}

export default AdyenIcon;
