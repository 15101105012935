import { createTheme } from '@mui/material/styles';
import colors from 'app/styles/colors';
import { borderRadius } from '../layout';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textMuted: true;
  }
}

const materialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 1024,
      lg: 1240,
      xl: 1440,
      // xxl: 1680 -> see CUSTOM_BREAKPOINT_DIFFERENCE
    },
  },
  typography: {
    fontFamily: "'Circular Std'",
    // https://www.figma.com/file/bvLLbltKQM3zLiTOu9ft9K/Station?node-id=1229%3A70343
    h1: {
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '40px',
      color: colors.grey[900],
    },
    // https://www.figma.com/file/bvLLbltKQM3zLiTOu9ft9K/Station?node-id=1203%3A99184
    h2: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '30px',
      color: colors.grey[800],
    },
    h3: {
      fontWeight: 450,
      fontSize: '20px',
      lineHeight: '20px',
      color: colors.grey[700],
    },
    h6: {
      fontWeight: 450,
      fontSize: '12px',
      lineHeight: '100%',
      color: colors.grey[700],
    },
    // https://www.figma.com/file/bvLLbltKQM3zLiTOu9ft9K/Station?node-id=1203%3A99184
    subtitle1: {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '18px',
      color: colors.grey[700],
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '100%',
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  },
  palette: {
    primary: {
      light: colors.primary[400],
      main: colors.primary[600],
      dark: colors.primary[800],
      contrastText: colors.white,
    },
    secondary: {
      light: colors.secondary[400],
      main: colors.secondary[600],
      dark: colors.secondary[800],
      contrastText: colors.white,
    },
    info: {
      light: colors.info[400],
      main: colors.info[600],
      dark: colors.info[800],
      contrastText: colors.white,
    },
    success: {
      light: colors.success[400],
      main: colors.success[600],
      dark: colors.success[800],
      contrastText: colors.white,
    },
    error: {
      light: colors.error[400],
      main: colors.error[600],
      dark: colors.error[800],
      contrastText: colors.white,
    },
    warning: {
      light: colors.warning[200],
      main: colors.warning[400],
      dark: colors.warning[600],
      contrastText: colors.white,
    },
    grey: colors.grey,
    common: {
      black: colors.grey[900],
      white: colors.white,
    },
    background: {
      default: colors.grey[400],
      paper: colors.white,
    },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[700],
      disabled: colors.grey[400],
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: 'primary',
        variant: 'contained',
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            height: '48px',
            padding: '16px',
            borderRadius: borderRadius.base,
            textAlign: 'center',
            background: colors.primary[900],
            color: colors.white,
            border: '2px solid',
            borderColor: 'transparent',
            ':hover': {
              background: colors.primary[800],
            },
            ':focus, :focus-within': {
              borderColor: colors.grey[500],
            },
            '&.Mui-disabled': {
              background: colors.grey[800],
              opacity: 0.7,
              color: colors.grey[100],
              cursor: 'not-allowed',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            height: '48px',
            padding: '16px',
            borderRadius: borderRadius.base,
            textAlign: 'center',
            backgroundColor: colors.critical[200],
            border: '1px solid',
            borderColor: colors.critical[600],
            color: colors.grey[900],
            '&:hover, &:focus-within, &:focus': {
              backgroundColor: colors.critical[200],
              borderColor: colors.grey[700],
              color: colors.critical[800],
              transition:
                'background-color 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: 2,
            color: colors.grey[900],
            textDecoration: 'underline',
            textTransform: 'none',
            border: '1px solid',
            borderColor: 'transparent',
            ':focus, :focus-within, :hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              borderColor: colors.grey[500],
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: colors.grey[900],
            borderColor: colors.grey[900],
            '&:hover, &:focus-within, &:focus': {
              backgroundColor: colors.grey[100],
              transition: 'background-color 200ms ease-in-out',
            },
          },
        },
        {
          props: { variant: 'textMuted' },
          style: {
            padding: 2,
            color: colors.grey[700],
            textDecoration: 'underline',
            textTransform: 'none',
            letterSpacing: 0.2,
            border: '1px solid',
            borderColor: 'transparent',
            ':focus, :focus-within, :hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              borderColor: colors.grey[500],
            },
          },
        },
      ],
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
          borderRadius: borderRadius.base,
        },
      },
    },
  },
});

export const CUSTOM_BREAKPOINT_DIFFERENCE = 240;
export default materialTheme;
export type MaterialTheme = typeof materialTheme;
