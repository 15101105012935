import { useCallback, useEffect, useState } from 'react';
import reactQueryClient from 'app/api/react-query/react-query-client';
import stationApiAxios from 'app/api/station-api/station-api-axios';
import { useInfiniteQuery } from 'react-query';
import debounce from 'lodash/debounce';
import { Reservation, ReservationsPage } from '../types';
import { mapReservations } from '../lib/reservationMapper';

export const invalidateReservationsQuery = () => reactQueryClient.invalidateQueries(['Reservations']);

async function getReservations({
  hotelPublicId,
  pageParam = 1,
  searchQuery,
}: {
  hotelPublicId: string;
  pageParam?: number;
  searchQuery?: string;
}) {
  const response = await stationApiAxios({
    url: `rest/v1/hotels/${hotelPublicId}/reservations?expand[]=payment_methods&expand[]=items.guests&expand[]=items.payments&expand[]=invoice&expand[]=invoice.invoice_lines&page=${pageParam}&query=${searchQuery}`,
    method: 'GET',
  });

  const data: ReservationsPage = {
    items: mapReservations(response.data?.items),
    total: response.data?.total_items_without_paging,
  };
  return data;
}

export const useReservationsQuery = (hotelPublicId: string) => {
  const [searchQuery, setSearchQuery] = useState('');

  const getAllReservationsFromReservationsPageResponse = (pages: ReservationsPage[] = []) => {
    const allHotels: Reservation[] = pages.reduce((accum, page) => {
      return [...accum, ...page.items];
    }, [] as Reservation[]);

    return allHotels;
  };

  const results = useInfiniteQuery<ReservationsPage, Error>(
    'Reservations',
    (args) => {
      return getReservations({
        hotelPublicId,
        pageParam: args.pageParam,
        searchQuery,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const allReservations: Reservation[] = getAllReservationsFromReservationsPageResponse(allPages);
        const dataLength = allReservations.length;
        return lastPage.total - dataLength > 0 ? allPages.length + 1 : undefined;
      },
      refetchOnWindowFocus: false,
      enabled: !!hotelPublicId,
    },
  );

  const allReservations: Reservation[] = getAllReservationsFromReservationsPageResponse(results?.data?.pages);
  const dataLength = allReservations.length;

  /* istanbul ignore next */ // there is a test for this
  const makeSearch = () => {
    results.refetch();
  };

  const delayedSearch = useCallback(debounce(makeSearch, 600), [searchQuery]);

  useEffect(() => {
    delayedSearch();

    // Cancel the debounce on useEffect cleanup.
    return delayedSearch.cancel;
  }, [searchQuery, delayedSearch]);

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
  };

  return { ...results, handleSearchQueryChange, allReservations, dataLength, searchQuery };
};
