import { Menu, Box, Typography, Divider, colors, MenuItem } from '@mui/material';
import SignOutIcon from 'app/components/icons/SignOutIcon';
import UserFollowIcon from 'app/components/icons/UserFollowIcon';
import { useUser } from 'app/features/authentication/context/useUser';
import AddHotelAdminModal from 'app/features/selfbook-admin/hotels/components/AddHotelAdminModal';
import { Fragment, useState } from 'react';
import { mapUserPermissionRoleToUIText } from '../lib/userRoles';

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  currentUserHotelRole: string;
  onLogoutClick: () => void;
  signOutText?: string;
  userName?: string;
}

function UserMenuContent({
  anchorEl,
  open,
  onClose,
  userName,
  currentUserHotelRole,
  onLogoutClick,
  signOutText = 'Sign out',
}: Props) {
  
  const [ isAddHotelAdminModalActive, setIsAddHotelAdminModalActive ] = useState(false);
  const { currentHotelId } = useUser();

  const openAddAdminModal = () => { setIsAddHotelAdminModalActive(true); }

  // istanbul ignore next
  const closeAddAdminModal = () => { setIsAddHotelAdminModalActive(false); }

  return (
    <Fragment>
    <Menu
      anchorEl={anchorEl}
      id="user-menu-content"
      data-testid="user-menu-content"
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,0.1))',
          mt: 2,
          '& .MuiAvatar-root': {
            width: 36,
            height: 36,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            borderBottom: '13px solid white',
            borderRight: '10px solid transparent',
            borderLeft: '10px solid transparent',
            borderTop: '7px solid transparent',
            zIndex: 10,
            top: -20,
            right: 13,
            filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,0.1))',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: 'white',
          borderRadius: '4px',
          pt: 2,
          pb: 1,
          minWidth: '216px',
        }}
        data-testid="user-menu-wrapper"
      >
        <Box data-testid="user-info" sx={{ px: 2 }}>
          <Typography
            variant="h3"
            sx={{ letterSpacing: '0.01em', marginBottom: 1, textOverflow: 'ellipsis' }}
          >
            {userName}
          </Typography>
          <Typography variant="h6" sx={{ letterSpacing: '0.06em' }} color="grey.600">
            {mapUserPermissionRoleToUIText(currentUserHotelRole)}
          </Typography>
        </Box>
        <Divider sx={{ borderBottom: `1px solid ${colors.grey[400]}`, mt: 3 }} />
        <Box sx={{ marginTop: 2, px: 2 }}>
          <MenuItem onClick={onLogoutClick} disableRipple data-testid="sign-out-menu">
            <Box sx={{ marginRight: '10.5px', display: 'flex', paddingLeft: '2px' }}>
              <SignOutIcon />
            </Box>
            <Typography variant="subtitle1">{signOutText}</Typography>
          </MenuItem>
          {
            currentUserHotelRole === 'HOTEL_ADMIN' && (
              <MenuItem onClick={openAddAdminModal} disableRipple data-testid="add-admin-menu" sx={{ marginTop: 1 }}>
                <Box sx={{ marginRight: '8px', display: 'flex' }}>
                  <UserFollowIcon />
                </Box>
                <Typography variant="subtitle1">Add admin</Typography>
              </MenuItem>
            )
          }
        </Box>
      </Box>
    </Menu>
    <AddHotelAdminModal open={isAddHotelAdminModalActive} onClose={closeAddAdminModal} hotelId={currentHotelId as string} />
    </Fragment>
  );
}

export default UserMenuContent;
