export enum RefundChargeType {
  Full = 'full',
  Partial = 'partial',
}

export enum PartialRefundType {
  Percentage = 'percentage',
  Amount = 'amount',
}

export type RefundChargeValues = {
  type: RefundChargeType;
  partialAmount?: number;
};

export const calculateRefundPercentageAmount = ({
  refundPercentageAmount = 0,
  chargeAmount = 0,
}: {
  refundPercentageAmount: number;
  chargeAmount: number;
}) => {
  return (refundPercentageAmount / 100) * chargeAmount;
};

export const getAmountToRefund = ({
  chargeAmount,
  refundChargeType,
  partialRefundAmount = 0,
  partialRefundType = PartialRefundType.Amount,
}: {
  chargeAmount: number;
  refundChargeType: RefundChargeType;
  partialRefundType?: PartialRefundType;
  partialRefundAmount?: number;
}) => {
  if (refundChargeType === RefundChargeType.Full) {
    return chargeAmount;
  }

  // Not a full refund, must be a partial refund

  // Partial refund for specific "dollar" amount
  if (partialRefundType === PartialRefundType.Amount) {
    // Convert to cents
    return partialRefundAmount * 100;
  }

  // Not a full refund, not a dollar amount refund, must be partial percentage refund 
  return calculateRefundPercentageAmount({ refundPercentageAmount: partialRefundAmount, chargeAmount });
};
