import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import solidGauge from 'highcharts/modules/solid-gauge';
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';

highchartsMore(Highcharts);
solidGauge(Highcharts);
highchartsHeatmap(Highcharts);
accessibility(Highcharts);

// istanbul ignore next
export default function ReservationsDeviceGraph({ reservationsCount = 0 }: { reservationsCount: number }) {
  const [chartOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: 90,
      height: 90,
      margin: -10,
      marginLeft: -20,
      marginRight: -20,
    },
    tooltip: {
      enabled: false,
    },

    plotOptions: {
      series: {
        enableMouseTracking: false,
      },
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: `${reservationsCount.toFixed(0)}%`,
      y: 20,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        colorByPoint: true,
        innerSize: '75%',
        data: [
          {
            y: reservationsCount,
            color: '#495057',
          },
          {
            y: 100 - reservationsCount,
            color: '#DEE2E6',
          },
        ],
      },
    ],
  });

  return (
    <Box data-testid={`reservations-device-graph-${reservationsCount.toFixed(0)}`} sx={{ width: '100%' }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Box>
  );
}
