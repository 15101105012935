import Box from '@mui/material/Box';
import { AuthenticationContextProvider } from 'app/features/authentication/context/useAuthenticationContext';
import { Outlet } from 'react-router-dom';
import colors from 'app/styles/colors';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';

const containerStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: '56.25% 43.75%',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  p: 2,
  backgroundColor: 'white',
  '@media (max-width: 1100px)': {
    gridTemplateColumns: 'auto 450px',
  },
  '@media (max-width: 850px)': {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'min-content',
    p: 0,
  },
};

const selfbookLogoContainerStyles: SxProps = {
  display: 'grid',
  backgroundPosition: 'center',
  backgroundColor: colors.grey[900],
  backgroundSize: 'cover',
  borderRadius: 4,
  placeItems: 'center',
  '@media (max-width: 850px)': {
    borderRadius: 0,
    height: '100px',
  },
};

const selfbookLogoStyles: SxProps = {
  fontSize: '54px',
  fontWeight: 'medium',
  letterSpacing: '-.54px',
  fontFamily: 'PP Neue Montreal',
  color: colors.white,
  '@media (max-width: 850px)': {
    fontSize: '32px',
  },
};

const outletContainerStyles: SxProps = {
  flexGrow: 1,
  display: 'grid',
  placeItems: 'center',
  px: 2,
  mt: -4,
  '@media (max-width: 850px)': {
    justifyContent: 'center',
    marginTop: 4,
    placeItems: 'unset',
  },
};

function AuthenticationSection() {
  const logoRef = useRef<HTMLElement>(null);
  const outletContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        (logoRef?.current as HTMLElement).style.width = 'auto';
        return;
      }

      const el = outletContainerRef.current?.children[0] as HTMLElement;
      if (el && logoRef.current) {
        logoRef.current.style.width = `${el.offsetWidth}px`;
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AuthenticationContextProvider>
      <Box data-testid="authentication-section" sx={containerStyles}>
        <Box sx={selfbookLogoContainerStyles}>
          <Typography ref={logoRef} sx={selfbookLogoStyles}>
            Selfbook
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" position="relative">
          <Box ref={outletContainerRef} sx={outletContainerStyles}>
            <Outlet />
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="textMuted"
              href="https://selfbook.com/terms/"
              target="_blank"
              rel="noreferrer"
              sx={{ fontSize: '12px', opacity: '.7' }}
            >
              Terms and conditions
            </Button>
            <Button
              variant="textMuted"
              href="https://selfbook.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              sx={{ fontSize: '12px', opacity: '.7' }}
            >
              Privacy policy
            </Button>
          </Stack>
        </Box>
      </Box>
    </AuthenticationContextProvider>
  );
}

export default AuthenticationSection;
